import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Logger } from '@app/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

const log = new Logger('KendoTypeSelectComponent');

@Component({
  selector: 'app-kendo-type-select',
  templateUrl: './kendo-type-select.component.html',
  styleUrls: ['./kendo-type-select.component.scss'],
})
export class KendoTypeSelectComponent extends BaseFilterCellComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() filter: CompositeFilterDescriptor;
  @Input() field = 'type_id';
  @Input() groupType: string = null;

  constructor(private formBuilder: FormBuilder, filterService: FilterService) {
    super(filterService);
  }

  ngOnInit() {
    let initVal = null;

    // ако има филтър зададен по default го избира от селекта
    if (this.filter && this.filter.filters) {
      this.filter.filters.forEach((filter: any) => {
        if (filter.field === this.field) {
          initVal = filter.value;
        }
      });
    }

    this.form = this.formBuilder.group({
      value: [initVal],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    let filterFound = false;
    if (this.filter && this.filter.filters) {
      this.filter.filters.forEach((filter: any) => {
        if (filter.field === this.field) {
          filterFound = true;
          if (this.form) {
            this.form.get('value').patchValue(filter.value);
          }
        }
      });
    }
    // Изчиства формата
    // Използва се, за да чисти филтрите, когато се сетне null state на таблицата
    if ((this.form && changes && changes.filter && !changes.filter.currentValue) || (this.form && !filterFound)) {
      this.form.reset();
    }
  }

  onSelect(event: any) {
    this.filter = this.removeFilter(this.field);

    const filters = [];
    if (event) {
      filters.push({
        field: this.field,
        operator: 'eq',
        value: event.id,
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: [],
    };

    if (filters.length) {
      root.filters.push(...filters);
    }
    this.filterService.filter(root);
    log.debug('filters', filters);
  }
}
