import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplate } from '@app/settings/templates/email-template.model';
import { TemplatesService } from '@app/settings/templates/templates.service';
import { ApiQuery } from '@app/core/http/api-query';
import { Logger } from '@app/core';
import { EmailTemplateCompiler } from '@app/settings/templates/email-template-compiler.model';
import { TemplatesPreviewFormService } from '@app/settings/templates/templates-preview-form.service';

const log = new Logger('TemplatesFormComponent');

@Component({
  selector: 'app-templates-form',
  templateUrl: './templates-form.component.html',
  styleUrls: ['./templates-form.component.scss'],
})
export class TemplatesFormComponent implements OnInit {
  @Input() template: EmailTemplate = null;
  form: FormGroup;
  editorMode = false;
  variables: any = {
    testkey: 'testvalue',
    testkey2: 'testvalue2',
    testkey3: [
      {
        arrayteatkey1: 'arraytestvalue1',
        arrayteatkey2: 'arraytestvalue2',
        arrayteatkey3: 'arraytestvalue3',
      },
    ],
  };

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private templateService: TemplatesService,
    private templatePreviewFormService: TemplatesPreviewFormService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  save() {
    if (this.template && this.template.id) {
      const template: EmailTemplate = this.template.deserialize(this.form.value);
      this.templateService.update(template).subscribe((res) => this.modal.close('Save click'));
    } else {
      const template: EmailTemplate = new EmailTemplate().deserialize(this.form.value);
      this.templateService.store(template).subscribe((res) => this.modal.close(res));
    }
  }

  saveAndPreview() {
    const compileObj = new EmailTemplateCompiler().deserialize({
      object_id: this.form.value.object_id,
      type_id: this.form.value.type_id,
      html_template: this.form.value.html_template,
      subject: this.form.value.subject,
    });
    log.debug('compileObj', compileObj);
    this.templateService.compile(compileObj).subscribe((res: any) => {
      this.templatePreviewFormService.open(res.compiled).then(
        (response) => {},
        (response) => {}
      );
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required]],
      subject: [null, [Validators.required]],
      html_template: [null, [Validators.required]],
      company_id: [null],
      company: [null],
      locale_id: [null, [Validators.required]],
      locale: [null],
      type_id: [null, [Validators.required]],
      type: [null],
      object_id: [null],
    });
  }

  private patchForm() {
    if (this.template && this.template.id) {
      const apiQuery: ApiQuery = new ApiQuery().addIncludes('company', 'locale', 'type');
      this.templateService.show(this.template.id, apiQuery).subscribe((template: EmailTemplate) => {
        this.form.patchValue(template);
      });
    }
  }
}
