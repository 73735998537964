import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { SalesService } from '@app/sales/sales/sales.service';
import { SalesFormService } from '@app/sales/sales/sales-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { AuthenticationService, Logger } from '@app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService } from '@app/core/access/access.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { DataSet } from '@app/shared/kendo/data-set';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { Sale } from '@app/sales/sales/sale.model';

const log = new Logger('BonusesListComponent');

@Component({
  selector: 'app-sale-bonuses-list',
  templateUrl: './sale-bonuses-list.component.html',
  styleUrls: ['./sale-bonuses-list.component.scss'],
})
export class SaleBonusesListComponent extends KendoGridComponent implements OnInit {
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  UserRoleType = UserRoleType;
  loader = false;

  constructor(
    private salesService: SalesService,
    private salesFormService: SalesFormService,
    protected kendoGridService: KendoGridService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit(): void {
    this.state = new DataSet().addSort('created_at', 'desc').addFilter('total_bonus_amount', 0, 'gt');

    const storageFilter = CustomKendoFilter.getStateFromStorage('SaleBonusesListComponent');
    if (storageFilter) {
      this.state = storageFilter;
    }

    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData', state);
    this.loader = true;
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'company',
        'event',
        'client.country',
        'sales_agent',
        'customer_care_agent',
        'reservation_agent',
        'status'
      );

    this.salesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.requestsResponse = response;
      this.data = response.getGridData();
      this.loader = false;
    });

    CustomKendoFilter.pushStateInStorage('SaleBonusesListComponent', this.state);
  }

  edit(sale: Sale) {
    this.salesFormService
      .open(sale, null, false)
      .then(() => {
        this.loadData(this.state);
      })
      .then(
        (res) => {
          log.debug(res);
          this.loadData(this.state);
        },
        (res) => {}
      );
  }

  clearFilters() {
    this.router.navigate(['/bonuses']);

    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData(new DataSet().addSort('created_at', 'desc').addFilter('total_bonus_amount', 0, 'gt'));
    });
  }
}
