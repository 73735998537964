import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaleBonus } from '@app/bonuses/sale-bonuses/sale-bonus.model';
import { SaleBonusesFormComponent } from '@app/bonuses/sale-bonuses/sale-bonuses-form/sale-bonuses-form.component';

@Injectable({
  providedIn: 'root',
})
export class SaleBonusesFormService {
  constructor(private modalService: NgbModal) {}

  public open(saleBonus: null | SaleBonus = null): Promise<any> {
    const modal = this.modalService.open(SaleBonusesFormComponent, { size: 'lg' });
    modal.componentInstance.saleBonus = saleBonus;
    return modal.result;
  }
}
