import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SmtpSettings } from '@app/shared/components/smtp-settings/smtp-settings.model';

const log = new Logger('SmtpSettingsComponent');

@Component({
  selector: 'app-smtp-settings',
  templateUrl: './smtp-settings.component.html',
  styleUrls: ['./smtp-settings.component.scss'],
})
export class SmtpSettingsComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;

  public static create(): FormGroup {
    const controls = {
      id: new FormControl(null),
      host: new FormControl(null, [Validators.required]),
      port: new FormControl(null, [Validators.required]),
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      encryption: new FormControl(null),
      from_email: new FormControl(null, [Validators.required]),
      from_name: new FormControl(null, [Validators.required]),

      company_id: new FormControl(null, [Validators.required]),
      imap_port: new FormControl(null, [Validators.required]),
      signature: new FormControl(null, [Validators.required]),
    };

    return new FormGroup(controls);
  }

  public static createMany(smtpSettings: Array<SmtpSettings>): FormArray {
    const formArray: FormArray = new FormArray([]);
    smtpSettings.forEach((smtpSetting: SmtpSettings) => {
      formArray.push(SmtpSettingsComponent.create());
    });
    return formArray;
  }

  constructor() {}

  ngOnInit() {}

  addSmtpSetting() {
    log.debug('addSmtpSetting');
    // @ts-ignore
    this.parentFormGroup.get('smtp_settings').push(SmtpSettingsComponent.create());
  }

  removeSmtpSetting(index: number) {
    this.parentFormGroup.get('smtp_settings')['controls'].splice(index, 1);
    this.parentFormGroup.get('smtp_settings').updateValueAndValidity();
  }

  imageUpload(callback: any) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = (files: any) => {
      const file = files.path[0].files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        callback(reader.result);
      };
    };
    input.click();
  }
}
