import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { Model } from '@app/shared/models/model.model';
import { SaleFinal } from '@app/sales/sales/sale-final.model';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';

@Injectable({
  providedIn: 'root',
})
export class SalesFinalService {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/sales/finals', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((request: any) => {
          return new SaleFinal().deserialize(request);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  itemsIndex(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/sales/finals/items', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((request: any) => {
          return request;
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  store(saleId: number, saleFinal: SaleFinal): Observable<SaleFinal> {
    return this.http.post('v1/sales/' + saleId + '/finals ', saleFinal).pipe(
      map((response: HttpResponse) => {
        return new SaleFinal().deserialize(response.data);
      })
    );
  }

  update(saleId: number, saleFinal: SaleFinal): Observable<SaleFinal> {
    return this.http.put('v1/sales/' + saleId + '/finals/' + saleFinal.id, saleFinal).pipe(
      map((response: HttpResponse) => {
        return new SaleFinal().deserialize(response.data);
      })
    );
  }

  show(saleId: number, saleFinalId: number, query: ApiQuery = new ApiQuery()): Observable<Model> {
    return this.http.get('v1/sales/' + saleId + '/finals/' + saleFinalId, query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return new SaleFinal().deserialize(response.data);
      })
    );
  }

  destroy(saleId: number, saleFinalId: number): Observable<any> {
    return this.http.delete('v1/sales/' + saleId + '/finals/' + saleFinalId);
  }
}
