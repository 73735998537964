import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { CommentsService } from '@app/shared/components/comments/comments-service.service';
import { ApiQuery } from '@app/core/http/api-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { Logger } from '@app/core';
import { DataSet } from '@app/shared/kendo/data-set';
import * as moment from 'moment';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { AccessService } from '@app/core/access/access.service';

const log = new Logger('ActivityListComponent');

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent extends KendoGridComponent implements OnInit {
  env = environment;
  commentsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();

  constructor(
    protected kendoGridService: KendoGridService,
    private commentsService: CommentsService,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    const storageFilter = CustomKendoFilter.getStateFromStorage('ActivityListComponent');

    if (storageFilter) {
      this.state = storageFilter;
    }

    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery.setDataSetFilters(state).addIncludes('user', 'type', 'commentable').addFilter('has_user', true);

    this.commentsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.commentsResponse = response;
      this.data = response.getGridData();
    });
    CustomKendoFilter.pushStateInStorage('ActivityListComponent', this.state);
  }

  clearFilters() {
    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData();
    });
  }
}
