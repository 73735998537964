<div *ngIf="bankAccounts && bankAccounts.length > 0">
  <div class="section-title mt-2 mb-1">
    <i class="fas fa-university"></i>
    {{ 'BANK_ACCOUNTS_BOX.BANK_ACCOUNTS' | translate }}
  </div>

  <div class="d-flex flex-wrap">
    <div *ngFor="let bankAccount of bankAccounts; let i = index" [ngClass]="{ 'mr-5': i !== bankAccounts.length - 1 }">
      {{ bankAccount.name }}

      <div class="d-table-row">
        <div class="d-table-cell sub-title iban">{{ 'BANK_ACCOUNTS_BOX.IBAN' | translate }}</div>
        <div class="value">
          {{ bankAccount.iban }}
        </div>
      </div>

      <div class="d-table-row">
        <div class="d-table-cell sub-title bic">{{ 'BANK_ACCOUNTS_BOX.BIC' | translate }}</div>
        <div class="value">
          {{ bankAccount.bic }}
        </div>
      </div>
    </div>
  </div>
</div>
