import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse, Logger } from '@app/core';

const log = new Logger('AssignService');

@Injectable({
  providedIn: 'root',
})
export class AssignService {
  constructor(private http: HttpClient) {}

  update(data: any): Observable<any> {
    return this.http.put('v1/assign', data).pipe(
      map((response: HttpResponse) => {
        log.debug('response', response);
        return response;
      })
    );
  }
}
