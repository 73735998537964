import { Component, Input, OnInit } from '@angular/core';
import { UserState, WebSocketsService } from '@app/core/websockets/websockets.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-user-states',
  templateUrl: './user-states.component.html',
  styleUrls: ['./user-states.component.scss'],
})
export class UserStatesComponent implements OnInit {
  public userStates: UserState[] = [];

  @Input() modelId: number;
  @Input() modelType: LaravelModelsEnum;
  @Input() excludeMe = true;
  @Input() countView = false;

  constructor(private _websockets: WebSocketsService, private _auth: AuthenticationService) {}

  ngOnInit() {
    this._websockets.userStatesContainer.subscribe((statesContainer: UserState[]) => {
      this.userStates = statesContainer.filter((item: UserState) => {
        if (this.excludeMe && item.userId === this._auth.accessToken.profile.id) {
          return false;
        }

        return this.modelId === item.modelId && this.modelType === item.modelType;
      });
    });
  }
}
