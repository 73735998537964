<div class="d-flex">
  <div class="assign-to-wrapper">
    <div class="super-small-text">{{ 'QUICK_SET_ASSIGN_AND_DATE.ASSIGN_TO' | translate }}</div>
    <app-user-and-group-select
      [parentFormGroup]="form"
      (select)="updateSaleAssign()"
      [roleType]="roleType"
      [selectableGroup]="selectableGroup"
    >
    </app-user-and-group-select>
  </div>

  <div class="assign-date-wrapper">
    <div class="super-small-text">{{ 'QUICK_SET_ASSIGN_AND_DATE.ASSIGN_DATE' | translate }}</div>
    <app-date-picker
      (select)="updateSaleAssignDate()"
      [FormControl]="form.get('assign_date')"
      [required]="false"
      [minDate]="dateNow"
    ></app-date-picker>
  </div>
</div>
