<ng-template #popContent>
  <div class="popover-wrapper">
    <form id="sale-mass-reassign-form" (ngSubmit)="reassign(SaleMassReassignPopOver)" [formGroup]="form" novalidate>
      <div class="form-group">
        <label>{{ 'SALE_MASS_REASSIGN.FIELD' | translate }}</label>
        <select class="form-control" formControlName="field" (change)="fieldChange()">
          <option value="assign_to_user_id">
            {{ 'SALE_MASS_REASSIGN.SALE' | translate }}
          </option>

          <option value="sales_agent_id">
            {{ 'SALE_MASS_REASSIGN.SALES_AGENT' | translate }}
          </option>

          <option value="reservation_agent_id">
            {{ 'SALE_MASS_REASSIGN.RESERVATION_AGENT' | translate }}
          </option>

          <option value="customer_care_agent_id">
            {{ 'SALE_MASS_REASSIGN.CUSTOMER_CARE_AGENT' | translate }}
          </option>
        </select>
      </div>

      <div *ngIf="form.get('field').value" class="modal-section-title">
        {{ 'SALE_MASS_REASSIGN.FROM' | translate }}
      </div>

      <app-user-and-group-select
        *ngIf="form.get('field').value == 'assign_to_user_id'"
        [parentFormGroup]="form"
        [passFormControlNameUserId]="'from_id'"
        [passFormControlNameRoleId]="'from_role_id'"
        [selectableGroup]="false"
        [onlyActive]="false"
      >
      </app-user-and-group-select>

      <app-user-select
        *ngIf="form.get('field').value == 'sales_agent_id'"
        [parentFormGroup]="form"
        [passFormControlName]="'from_id'"
        [hideLabel]="true"
        [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
        [onlyActive]="false"
      ></app-user-select>

      <app-user-select
        *ngIf="form.get('field').value == 'reservation_agent_id'"
        [parentFormGroup]="form"
        [passFormControlName]="'from_id'"
        [hideLabel]="true"
        [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
        [onlyActive]="false"
      ></app-user-select>

      <app-user-select
        *ngIf="form.get('field').value == 'customer_care_agent_id'"
        [parentFormGroup]="form"
        [passFormControlName]="'from_id'"
        [hideLabel]="true"
        [roleType]="[UserRoleType.CUSTOMER_CARE_AGENT, UserRoleType.CUSTOMER_CARE_MANAGER]"
        [onlyActive]="false"
      ></app-user-select>

      <div *ngIf="form.get('field').value" class="modal-section-title">
        {{ 'SALE_MASS_REASSIGN.TO' | translate }}
      </div>

      <app-user-and-group-select
        *ngIf="form.get('field').value == 'assign_to_user_id'"
        [parentFormGroup]="form"
        [passFormControlNameUserId]="'to_id'"
        [passFormControlNameRoleId]="'to_role_id'"
        [selectableGroup]="false"
      >
      </app-user-and-group-select>

      <app-user-select
        *ngIf="form.get('field').value == 'sales_agent_id'"
        [parentFormGroup]="form"
        [passFormControlName]="'to_id'"
        [hideLabel]="true"
        [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
      ></app-user-select>

      <app-user-select
        *ngIf="form.get('field').value == 'reservation_agent_id'"
        [parentFormGroup]="form"
        [passFormControlName]="'to_id'"
        [hideLabel]="true"
        [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
      ></app-user-select>

      <app-user-select
        *ngIf="form.get('field').value == 'customer_care_agent_id'"
        [parentFormGroup]="form"
        [passFormControlName]="'to_id'"
        [hideLabel]="true"
        [roleType]="[UserRoleType.CUSTOMER_CARE_AGENT, UserRoleType.CUSTOMER_CARE_MANAGER]"
      ></app-user-select>

      <div class="d-flex justify-content-between mt-2">
        <button [disabled]="form.invalid" type="submit" class="btn btn-primary btn-xs">
          {{ 'SALE_MASS_REASSIGN.REASSIGN' | translate }}
        </button>

        <button type="button" class="btn btn-danger btn-xs" (click)="close(SaleMassReassignPopOver)">
          {{ 'SALE_MASS_REASSIGN.POP_BTN_CLOSE' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #popTitle>
  {{ 'SALE_MASS_REASSIGN.MASS_REASSIGN' | translate }}
</ng-template>

<button
  container="body"
  [autoClose]="false"
  type="button"
  popoverClass="sales-mass-reassign-popover"
  class="btn btn-outline-secondary"
  #SaleMassReassignPopOver="ngbPopover"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
>
  {{ 'SALE_MASS_REASSIGN.MASS_REASSIGN' | translate }}
</button>
