import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-templates-preview-form',
  templateUrl: './templates-preview-form.component.html',
  styleUrls: ['./templates-preview-form.component.scss'],
})
export class TemplatesPreviewFormComponent implements OnInit {
  @Input() template: any = {
    subject: null,
    html_template: null,
  };

  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {}
}
