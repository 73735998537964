import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebOffer } from '@app/sales/web-offers/web-offer.model';
import { WebOffersFormComponent } from '@app/sales/web-offers/web-offers-form/web-offers-form.component';

@Injectable({
  providedIn: 'root',
})
export class WebOffersFormService {
  constructor(private modalService: NgbModal) {}

  public open(webOffer: WebOffer, saleAgentId: number = null): Promise<any> {
    const modal = this.modalService.open(WebOffersFormComponent, { size: 'full' as 'lg' });
    modal.componentInstance.webOffer = webOffer;
    modal.componentInstance.saleAgentId = saleAgentId;
    return modal.result;
  }
}
