import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Sale} from '@app/sales/sales/sale.model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SalesOffersService} from '@app/sales/sales/sales-offers.service';
import {Logger} from '@app/core';
import {HotelBookingsService} from '@app/hotels/hotel-bookings/hotel-bookings.service';
import {ApiQuery, GeoFenceFilter} from '@app/core/http/api-query';
import {DataStateChangeEvent} from '@progress/kendo-angular-grid';
import {Room} from '@app/hotels/rooms/room.model';
import {Night} from '@app/hotels/rooms/night.model';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SaleOffer} from '@app/sales/sales/sale-offer.model';
import {environment} from '@env/environment';
import {HotelBooking} from '@app/hotels/hotel-bookings/hotel-booking.model';
import {HotelBookingsFormService} from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import {HotelFinderService} from '@app/shared/components/hotel-finder/hotel-finder.service';
import {Hotel} from '@app/hotels/hotels/hotel.model';
import {HotelsService} from '@app/hotels/hotels/hotels.service';
import {AllotmentFinderService} from '@app/shared/components/allotment-finder/allotment-finder.service';
import {SaleRequest} from '@app/sales/sales/sale-request.model';
import {BookingPropertiesType} from '@app/settings/types/booking-properties-type.enum';
import {CustomKendoFilter} from '@app/shared/kendo/custom-kendo-filter';
import {SalesFinalService} from '@app/sales/sales/sales-final.service';
import {PaginatedResponse} from '@app/shared/models/paginated-response.model';
import {SaleFinal} from '@app/sales/sales/sale-final.model';
import {cloneDeep} from 'lodash';

const log = new Logger('SalesOffersFormComponent');

@Component({
  selector: 'app-sales-offers-form',
  templateUrl: './sales-offers-form.component.html',
  styleUrls: ['./sales-offers-form.component.scss'],
})
export class SalesOffersFormComponent implements OnInit {
  @Input() sale: Sale = null;
  @Input() offerId: number = null;
  form: FormGroup;
  currentRequestId: number = null;
  env = environment;

  allotmentsCountInFinals: Array<any> = [];

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private salesOffersService: SalesOffersService,
    private hotelBookingsService: HotelBookingsService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private hotelBookingsFormService: HotelBookingsFormService,
    private hotelFinderService: HotelFinderService,
    private hotelsService: HotelsService,
    private allotmentFinderService: AllotmentFinderService,
    private salesFinalService: SalesFinalService
  ) {
  }

  ngOnInit() {
    // this.sale.requests.map((request: any) => {
    //   return (request['filters'] = {
    //     ref_number: null,
    //     supplier: null,
    //     event: this.sale.event,
    //     hotel: null,
    //     type_id: null,
    //     meal_plan_id: null
    //   });
    // });


    /**
     * Ако е в едит на офър събира алотмънт количествата от всички файнъли(с натрупване)
     * и по-късно ги използва, за да ги начисли над тези, които идват от сървъра (available_rooms).
     * На 11.08.2022 стигнахме до този проблем и Венелин го коментира с Тесс по скайп и телефона
     */
    if (this.offerId) {
      const saleFinalQuery = new ApiQuery()
        .addIncludes(
          'items',
        )
        .setLimit(9999)
        .addFilter('sale_id', this.sale.id);

      this.salesFinalService.index(saleFinalQuery).subscribe((response: PaginatedResponse) => {
        response.data.forEach((saleFinal: SaleFinal) => {
          saleFinal.items.forEach((saleFinalItem: any) => {
            const allotInd = this.getAllotmentIndexFromFinalsArr(saleFinalItem.sale_offer_item.allotment_id);

            if (allotInd > -1) {
              this.allotmentsCountInFinals[allotInd].count += Number(saleFinalItem.sale_offer_item.count);
            } else {
              this.allotmentsCountInFinals.push({
                allotment_id: saleFinalItem.sale_offer_item.allotment_id,
                count: Number(saleFinalItem.sale_offer_item.count)
              });
            }
          });
        });
      });
    }


    log.debug('this.sale.requests', this.sale.requests);
    this.sale.requests.forEach((request: any) => {
      request['filters'] = {
        // ref_number: null,
        property_id: BookingPropertiesType.ADV,
        check_in: null,
        check_out: null,
        supplier: null,
        event: this.sale.event,
        radius: null,
        hotel: null,
        stars: null,
        type_id: null,
        //  meal_plan_id: null
      };
      // this.getHotelRooms(request);
    });
    log.debug('this.sale', this.sale);
    this.createForm();
    this.patchForm();
  }

  getAllotmentIndexFromFinalsArr(allotmentId: string): number {
    return this.allotmentsCountInFinals.findIndex(_element => _element.allotment_id === allotmentId);
  }

  save(saveAndNew: boolean = false) {
    if (this.offerId) {
      // const event: Events = this.event.deserialize(this.form.value);
      // this.eventService.update(event).subscribe(res => this.modal.close(res));
      this.salesOffersService.update(this.sale.id, this.form.value).subscribe((res: any) => {
        return this.modal.close({
          result: res,
          saveAndNew: saveAndNew,
        });
      });
    } else {
      // const saleOffer: SaleOffer = new SaleOffer().deserialize(this.form.value);
      this.salesOffersService.store(this.sale.id, this.form.value).subscribe((res: any) => {
        return this.modal.close({
          result: res,
          saveAndNew: saveAndNew,
        });
      });
    }
  }

  log(something: any) {
    log.debug(something);
    log.debug(this.form);
  }

  /* tslint:disable */
  getHotelRooms(request: any, state: DataStateChangeEvent = null) {
    request.state = state;

    // сетва еднакви филтри на всички requests

    const query = new ApiQuery();
    let eventFilter = request.filters.event ? request.filters.event.id : null;

    // Мята toastr съобщение ако е въведен радиус а не е избран евент
    if (request.filters.radius && !request.filters.event) {
      this.translate.get('SALES.SALES_OFFERS_FORM.EVENT_NOT_FOUND').subscribe((trans: string) => {
        this.toastr.info(trans);
      });
    }

    // Проверява дали избрания във филтъра event има закачено venue
    if (request.filters.radius && request.filters.event && !request.filters.event.venue) {
      this.translate.get('SALES.SALES_OFFERS_FORM.EVENT_VENUE_NOT_FOUND').subscribe((trans: string) => {
        this.toastr.error(trans + request.filters.event.name);
      });
    }

    // Проверява дали избрания във филтъра event има закачено venue и дали то има координати
    if (
      request.filters.radius &&
      request.filters.event &&
      request.filters.event.venue &&
      (!request.filters.event.venue.lat || !request.filters.event.venue.long)
    ) {
      this.translate.get('SALES.SALES_OFFERS_FORM.EVENT_VENUE_LAT_LONG_NOT_FOUND').subscribe((trans: string) => {
        this.toastr.error(trans + request.filters.event.venue.name);
      });
    }

    // Ако има валиден филтър закача към query-то geofence
    if (
      request.filters.radius &&
      request.filters.event &&
      request.filters.event.venue &&
      request.filters.event.venue.lat &&
      request.filters.event.venue.long
    ) {
      const geoFenceFilter = new GeoFenceFilter();
      geoFenceFilter.lat = Number(request.filters.event.venue.lat);
      geoFenceFilter.long = Number(request.filters.event.venue.long);
      geoFenceFilter.inner_radius = 0;
      geoFenceFilter.outer_radius = Number(request.filters.radius);
      query.setGeoFence(geoFenceFilter, 'hotel.geofence');
      eventFilter = null;
    }

    const formatedPost = {
      //   'booking.ref_number': request.filters.ref_number ? request.filters.ref_number : null,
      'booking.property_id': request.filters.property_id ? request.filters.property_id : null,
      check_in: request.filters.check_in ? moment(request.filters.check_in).format(this.env.apiPipeDateFormat) : null,
      check_out: request.filters.check_out
        ? moment(request.filters.check_out).format(this.env.apiPipeDateFormat)
        : null,
      'booking.supplier_id': request.filters.supplier ? request.filters.supplier.id : null,
      event_id: eventFilter,
      'hotel.name': request.filters.hotel ? request.filters.hotel : null,
      'hotel.stars': request.filters.stars ? request.filters.stars : null,
      'hotel_booking_rooms.type_id': request.filters.type_id ? request.filters.type_id : null,
      //     meal_plan_id: request.filters.meal_plan_id ? request.filters.meal_plan_id : null
    };

    // сетва филтър дати след request.check_in, като пази state-a от таблицата
    const filter: DataStateChangeEvent = {
      filter: {
        filters: [{field: 'check_in', operator: 'gte', value: moment(request.check_in).subtract(20, 'days')}],
        logic: 'and',
      },
      group: undefined,
      skip: request.state ? request.state.skip : 0,
      sort: request.state ? request.state.sort : undefined,
      take: request.state ? request.state.take : 0,
    };

    query.setDataSetFilters(filter).addFilters(formatedPost).addFilter('full_available', true).addIncludes(
      'hotel.venues',
      'booking.supplier',
      'booking.company',
      'booking.property',
      'booking.currency',
      'booking.tags',
      // 'booking.events',
      'hotel.city.country',
      'type',
      'meal_plan',
      'nights',
      'nights.net_price_currency'
    );

    if (this.offerId) {
      query.addFilter('exclude_sale_id', this.sale.id + '-' + this.offerId);
    } else {
      query.addFilter('exclude_sale_id', this.sale.id);
    }

    this.hotelBookingsService.indexRooms(query).subscribe((res: any) => {
      res.data.forEach((room: Room) => {
        room.hotel.venues.forEach((venue: any) => {
          if (venue.id === this.sale.event.venue_id) {
            room['distance_to_fair'] = venue.pivot.distance;
          }
        });

        // if (room.nights) {
        //   let netPrice = 0;
        //   let price = 0;
        //   room.nights.forEach((night: Night) => {
        //     netPrice += Number(night.net_price);
        //     price += Number(night.price);
        //   });
        //   room['net_price'] = netPrice / room.nights.length;
        //   room['net_price_currency'] = room.nights[0].net_price_currency;
        //   room['client_price'] = Number((price / room.nights.length).toFixed(2));
        // }

        room['net_price'] = room.net_price_eur_avg * room.booking.exchange_rate;
        room['net_price_currency'] = room.booking.currency;
        room['client_price'] = room.price_avg;
        room['count'] = room.available_rooms;

        const allotInd = this.getAllotmentIndexFromFinalsArr(room.allotment_id);
        if (allotInd > -1) {
          room['count'] += this.allotmentsCountInFinals[allotInd].count;
        }

        room['room_count'] = null;
      });
      request.roomsResults = res;
      this.syncAvailableRooms();
    });

    // share-ва филтрите между всички request-и
    this.sale.requests.forEach((request2: any) => {
      request2.filters = request.filters;
    });
  }

  /* tslint:enable */
  setCurrentRequest(id: number, request: any) {
    if (request) {
      this.getHotelRooms(request);
    }

    if (id === this.currentRequestId) {
      return false;
    }
    if (this.form.invalid) {
      this.translate.get('SALES.SALES_OFFERS_FORM.INVALID_VALUES_TOASTR_MSG').subscribe((trans: string) => {
        this.toastr.error(trans);
      });
      return false;
    }
    this.currentRequestId = id;

    setTimeout(() => {
      this.form.get('items').patchValue(this.form.controls.items.value);
    });
  }

  addRow(row: any, request: any) {
    // @ts-ignore
    const formArrayItems: FormArray = this.form.controls.items;

    // formArrayItems.push(
    //   new FormGroup({
    //     id: new FormControl(null),
    //     sale_request_id: new FormControl(request.id),
    //     type_id: new FormControl(null),
    //     meal_plan_id: new FormControl(null),
    //     hotel_id: new FormControl(null),
    //     check_in: new FormControl(null),
    //     check_out: new FormControl(null),
    //     people: new FormControl(null),
    //     count: new FormControl(null),
    //     price: new FormControl(null)
    //   })
    // );

    // @ts-ignore

    const control = this.getEmptyRow(request.id, row, request);

    const netPrice = this.getRowNetPrice(control.value);
    control.get('net_price').patchValue(netPrice);

    // new FormGroup({
    //   sale_request_id: new FormControl(request.id),
    //   hotel_id: new FormControl(row.hotel_id),
    //   hotel: new FormControl(row.hotel),
    //   stars: new FormControl(row.hotel.stars),
    //   distance_to_fair: new FormControl(row.distance_to_fair),
    //   count: new FormControl(row.room_count),
    //   type: new FormControl(row.type),
    //   type_id: new FormControl(row.type_id),
    //   meal_plan: new FormControl(row.meal_plan),
    //   meal_plan_id: new FormControl(row.meal_plan_id),
    //   check_in: new FormControl(request.check_in),
    //   check_out: new FormControl(request.check_out),
    //   ref_number: new FormControl(row.booking.ref_number),
    //   fake_price: new FormControl(row.price),
    //   fake_price_currency: new FormControl(row.price_currency),
    //   price: new FormControl(row.client_price),
    //   price_currency: new FormControl(row.client_price_currency),
    //   people: new FormControl(null)
    // });

    // control.get('hotel').valueChanges.subscribe((hotel: any) => {
    //
    //   let match = false;
    //   if (hotel) {
    //     hotel.venues.forEach((venue: any) => {
    //       if (venue.id === this.sale.event.venue_id) {
    //         match = true;
    //         control.get('distance_to_fair').patchValue(venue.pivot.distance);
    //       }
    //     });
    //   }
    //   if (!match) {
    //     control.get('distance_to_fair').patchValue(null);
    //   }
    // });

    formArrayItems.push(control);
    // this.form.get('items').updateValueAndValidity();
    setTimeout(() => {
      this.form.get('items').patchValue(this.form.controls.items.value);
      this.syncAvailableRooms();
    });
  }

  addEmptyRow() {
    // @ts-ignore
    const formArrayItems: FormArray = this.form.controls.items;

    const request = this.sale.requests.find((req: any) => {
      return req.id === this.currentRequestId;
    });

    formArrayItems.push(this.getEmptyRow(this.currentRequestId, null, request));
  }

  openHotelFinder() {
    let data = {};

    if (this.sale.event.venue) {
      data = {
        venue_id: this.sale.event.venue_id,
        venue: this.sale.event.venue,
      };
    }
    this.hotelFinderService.open(data).then(
      (res: any) => {
        // @ts-ignore
        const formArrayItems: FormArray = this.form.controls.items;

        const request = this.sale.requests.find((req: any) => {
          return req.id === this.currentRequestId;
        });

        // Компонента връща само id-та, затова тук се взима целия хотел
        this.hotelsService.show(res[0]).subscribe((hotel: Hotel) => {
          const row = {
            hotel_id: hotel.id,
            hotel: hotel,
            meal_plan_id: request.meal_plan_id,
            meal_plan: request.meal_plan,
            type: request.type,
            type_id: request.type_id,
          };

          formArrayItems.push(this.getEmptyRow(this.currentRequestId, row, request));
          setTimeout(() => {
            this.form.get('items').patchValue(this.form.controls.items.value);
          });
        });
      },
      (res: any) => {
        log.debug('res', res);
      }
    );
  }

  /* tslint:disable */
  getEmptyRow(requestId: number, row: any = null, request: any = null, itemId: number = null) {
    log.debug('row', row);

    let refNumberVal = null;

    if (row && row.ref_number) {
      refNumberVal = row.ref_number;
    }

    if (row && !row.ref_number && row.booking && row.booking.ref_number) {
      refNumberVal = row.booking.ref_number;
    }

    const control = new FormGroup({
      id: new FormControl(itemId),
      sale_request_id: new FormControl(requestId, [Validators.required]),
      hotel_id: new FormControl(row && row.hotel_id ? row.hotel_id : null, [Validators.required]),
      hotel: new FormControl(row && row.hotel ? row.hotel : null),
      supplier_id: new FormControl(row && row.booking && row.booking.supplier_id ? row.booking.supplier_id : null),
      supplier: new FormControl(row && row.booking && row.booking.supplier ? row.booking.supplier : null),
      stars: new FormControl(row && row.hotel && row.hotel.stars ? row.hotel.stars : null),
      distance_to_fair: new FormControl(row && row.distance_to_fair ? row.distance_to_fair : null),
      count: new FormControl(row && row.room_count ? row.room_count : request && request.count ? request.count : null, [
        Validators.required,
      ]),
      //  type: new FormControl(row && row.type ? row.type : null),
      type_id: new FormControl(row && row.type_id ? row.type_id : null, [Validators.required]),
      //   meal_plan: new FormControl(row && row.meal_plan ? row.meal_plan : null),
      meal_plan_id: new FormControl(row && row.meal_plan_id ? row.meal_plan_id : null, [Validators.required]),
      check_in: new FormControl(request && request.check_in ? request.check_in : null, [Validators.required]),
      check_out: new FormControl(request && request.check_out ? request.check_out : null, [Validators.required]),
      ref_number: new FormControl(refNumberVal),
      net_price: new FormControl(row && row.net_price ? Number(row.net_price).toFixed(2) : null, [Validators.required]),
      net_price_currency: new FormControl(row && row.net_price_currency ? row.net_price_currency : null),
      net_price_currency_id: new FormControl(
        row && row.net_price_currency && row.net_price_currency.id ? row.net_price_currency.id : null,
        [Validators.required]
      ),
      price: new FormControl(row && row.client_price ? row.client_price : null, [Validators.required]),
      price_currency: new FormControl(row && row.client_price_currency ? row.client_price_currency : null),
      people: new FormControl(row && row.people ? row.people : null, [Validators.required]),
      allotment_id: new FormControl(row && row.allotment_id ? row.allotment_id : null),

      allotment: new FormControl(row ? row : null),

      company_id: new FormControl(row && row.booking && row.booking.company_id ? row.booking.company_id : null, [
        Validators.required,
      ]),
      // company: new FormControl(row && row.booking && row.booking.company ? row.booking.company : null),

      client_penalty: new FormControl(null),
      supplier_penalty: new FormControl(null),
    });
    /* tslint:enable */

    // control.get('hotel').valueChanges.subscribe((hotel: any) => {
    //   log.debug('hotel change');
    //   let match = false;
    //   if (hotel) {
    //     hotel.venues.forEach((venue: any) => {
    //       if (venue.id === this.sale.event.venue_id) {
    //         log.debug('match', venue);
    //         match = true;
    //         setTimeout(() => {
    //           control.get('distance_to_fair').patchValue(venue.pivot.distance);
    //         });
    //       }
    //     });
    //   }
    //   if (!match) {
    //     control.get('distance_to_fair').patchValue(null);
    //   }
    // });
    return control;
  }

  /**
   *  Връща масив от стрингосани дати
   * @param date1 start date
   * @param date2 end date
   */
  getDatesArr(date1: string, date2: string): Array<string> {
    const datesArr: Array<string> = [];

    if (!date1 || !date2) {
      return [];
    }

    const datesCount = moment(date2).diff(moment(date1), 'days');

    for (let i = 0; i < datesCount; i++) {
      datesArr.push(moment(date1).add(i, 'days').format(this.env.apiPipeDateFormat));
    }

    return datesArr;
  }

  /**
   *  Връща цена на нощувка по дата
   * @param row реда в таблицата / allotment
   * @param date дата на нощувката
   */
  getNightPrice(row: any, date: string): number {
    let price = null;
    row.allotment.nights.forEach((night: Night) => {
      if (date === night.date) {
        price = night.net_price;
      }
    });

    if (price) {
      return price;
    }
    return null;
  }

  getRowNetPrice(row: any): number {
    let netPrice = 0;
    let divider = 0;
    const minNightsDatesArr = this.getDatesArr(row.allotment.min_nights_from, row.allotment.min_nights_to);
    const selectedDatesArr = this.getDatesArr(row.check_in, row.check_out);

    selectedDatesArr.forEach((date: string) => {
      if (minNightsDatesArr.indexOf(date) > -1) {
        minNightsDatesArr.splice(minNightsDatesArr.indexOf(date), 1);
      }
      const nightPrice = this.getNightPrice(row, date);
      if (nightPrice) {
        divider++;
        netPrice += nightPrice;
      }
    });

    minNightsDatesArr.forEach((date: string) => {
      const nightPrice = this.getNightPrice(row, date);
      if (nightPrice) {
        netPrice += nightPrice;
      }
    });

    if (!isFinite(netPrice / divider)) {
      log.warn('Net price is infinity');
      return null;
    }

    return Number((netPrice / divider).toFixed(2));
  }

  datesChange(row: any) {
    const netPrice = this.getRowNetPrice(row.value);

    if (netPrice) {
      row.get('net_price').patchValue(netPrice);
    } else {
      row.get('net_price').patchValue(null);
    }

    this.syncAvailableRooms();
  }

  removeRow(rowIndex: number) {
    this.form.get('items')['controls'].splice(rowIndex, 1);
    this.form.get('items').updateValueAndValidity();

    this.syncAvailableRooms();
  }

  // filterSelected(requestId: number) {
  //
  //   let filtered: any;
  //   // @ts-ignore
  //   filtered = this.form.get('items').controls.map((item: any) => {
  //     log.debug(item);
  //     return item.get('sale_request_id').value === requestId ? item : null;
  //   });
  //   log.debug('filtered', filtered);
  //   this.filteredSelected = filtered;
  // }

  getNightsCount(date1: string | Date, date2: string | Date) {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }

  /**
   * edit hotel booking then reload current request search
   * @param hotelBooking HotelBooking
   * @param request Трябва му за да знае след ъпдейт резултатите на кой request да ъпдейтне
   * @param allotmentId allotmentId
   */
  editHotelBooking(hotelBooking: HotelBooking, request: any, allotmentId: string): void {
    this.hotelBookingsFormService.open(new HotelBooking().deserialize(hotelBooking), allotmentId).then(
      (res: any) => {
        log.debug(res);
        this.getHotelRooms(request);
      },
      (res) => {
        log.debug(res);
      }
    );
  }

  /**
   *  Проверява дали първата дата е преди или еднаква с втората
   * @param firstDate Първа дата
   * @param secondDate Втора дата
   */
  isBefore(firstDate: string, secondDate: string): boolean {
    return moment(firstDate).isSameOrBefore(moment(secondDate));
  }

  /**
   *  Проверява дали дата подадена като първи аргумент е между датите подадени като втори(включително) и трети аргумент.
   *  moment.js documentation for '[)'
   * @param date Date to compare
   * @param dateFrom Date form
   * @param dateTo Date to
   */
  isBetween(date: string, dateFrom: string, dateTo: string) {
    return moment(date).isBetween(dateFrom, dateTo, null, '[)');
  }

  addBooking() {
    log.debug('this.sale', this.sale);

    let currentRequest: SaleRequest = null;
    this.sale.requests.forEach((request: any) => {
      if (this.currentRequestId === request.id) {
        currentRequest = request;
      }
    });

    const newBooking = new HotelBooking().deserialize({
      events: [this.sale.event],
      rooms: [
        {
          check_in: currentRequest.check_in,
          check_out: currentRequest.check_out,
          type_id: currentRequest.type_id,
          count: currentRequest.count,
          meal_plan_id: currentRequest.meal_plan_id,
          fake_net_price: null,
          fake_price: null,
          fake_web_price: null,
        },
      ],
    });

    this.hotelBookingsFormService.open(newBooking).then(
      (response) => {
        this.sale.requests.forEach((request: any) => {
          if (this.currentRequestId === request.id) {
            this.getHotelRooms(request);
          }
        });
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  /**
   * Смята колко свободни стаи има в allotment-a
   */
  syncAvailableRooms() {
    log.debug('sync');

    if (!this.sale.requests) {
      return false;
    }

    this.sale.requests.forEach((requestLine: any) => {
      if (requestLine.roomsResults) {
        requestLine.roomsResults.data.forEach((room: Room) => {
          room['available_count'] = cloneDeep(room.count);
          this.form.get('items').value.forEach((item: any) => {
            if (room.allotment_id === item.allotment_id) {
              room['available_count'] -= Number(item.count);
            }
          });
        });
      }
    });

    this.autoFillName();
  }

  /**
   * Попълва name с името на първия хотел в selected
   */
  autoFillName() {
    setTimeout(() => {
      if (
        !this.form.get('name').value &&
        this.form.get('items').value.length > 0 &&
        this.form.get('items').value[0].hotel &&
        this.form.get('items').value[0].hotel.name
      ) {
        this.form.get('name').patchValue(this.form.get('items').value[0].hotel.name);
      }
    });
  }

  advancedHotelSearch(request: any) {
    const eventId = request.filters && request.filters.event ? request.filters.event.id : null;

    // добавя event-a в storage-a
    if (request.filters && request.filters.event) {
      CustomKendoFilter.pushObjectInStorage(request.filters.event, 'events');
    }

    const additionalInfo = {
      event: this.sale.event,
      request: request,
    };

    this.allotmentFinderService.open('hotel', eventId, additionalInfo).then(
      (hotel: Hotel) => {
        log.debug('hotel', hotel);
        request.filters.hotel = hotel.name;
        this.getHotelRooms(request);
      },
      () => {
      }
    );
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      name: [null], // полето е 'маскирано' като задължително в html, за да не пречи в началото
      description: [null],
      note: [null],
      client_comment_type_id: [null],
      client_comment_type: [null],
      client_comment: [null],
      show_to_web: [false],
      items: new FormArray([]),
      quick_add_hotel: [null],
    });

    // const formArrayItems: FormArray = new FormArray([]);
    // this.sale.requests.forEach((request: any) => {
    //   formArrayItems.push(
    //     new FormGroup({
    //       id: new FormControl(null),
    //       sale_request_id: new FormControl(request.id),
    //       type_id: new FormControl(null),
    //       meal_plan_id: new FormControl(null),
    //       hotel_id: new FormControl(null),
    //       check_in: new FormControl(null),
    //       check_out: new FormControl(null),
    //       people: new FormControl(null),
    //       count: new FormControl(null),
    //       price: new FormControl(null)
    //     })
    //   );
    // });

    // this.form.setControl('items', formArrayItems);
  }

  private patchForm(): void {
    if (this.offerId) {
      const query = new ApiQuery().addIncludes(
        'items.hotel',
        'items.currency',
        'items.hotel.city.country',
        'items.hotel.venues',
        'items.supplier',
        'items.net_price_currency',
        'items.allotment.nights'
      );
      this.salesOffersService.show(this.sale.id, this.offerId, query).subscribe((offer: SaleOffer) => {
        log.debug('patch offer', offer);
        const formArray: FormArray = new FormArray([]);
        offer.items.forEach((item: any) => {
          formArray.push(this.getEmptyRow(item.sale_offer_id, null, null, item.id));
        });
        this.form.setControl('items', formArray);
        this.form.patchValue(offer);
      });
    }
  }
}
