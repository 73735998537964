import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferMultipleClientsComponent } from '@app/shared/components/offer-multiple-clients/offer-multiple-clients.component';

@Injectable({
  providedIn: 'root',
})
export class OfferMultipleClientsService {
  constructor(private modalService: NgbModal) {}

  public open(hotelBookingId: number): Promise<any> {
    const modal = this.modalService.open(OfferMultipleClientsComponent, { size: 'full' as 'lg' });
    modal.componentInstance.hotelBookingId = hotelBookingId;
    return modal.result;
  }
}
