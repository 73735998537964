import { Component, OnInit } from '@angular/core';
import { RemindersFormService } from '@app/reminders/reminders/reminders-form.service';
import { Logger } from '@app/core';
import { Reminder } from '@app/reminders/reminders/reminder.model';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ApiQuery } from '@app/core/http/api-query';
import { RemindersService } from '@app/reminders/reminders/reminders.service';
import { environment } from '@env/environment';

const log = new Logger('RemindersViewComponent');

@Component({
  selector: 'app-reminders-view',
  templateUrl: './reminders-view.component.html',
  styleUrls: ['./reminders-view.component.scss'],
})
export class RemindersViewComponent implements OnInit {
  reminderId: number;
  reminder: Reminder;
  env = environment;

  constructor(
    private remindersFormService: RemindersFormService,
    private remindersService: RemindersService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.reminderId = Number(params.get('id'));
        this.loadData();
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  loadData(): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes('user', 'creator', 'reminderable');
    this.remindersService.show(this.reminderId, apiQuery).subscribe((reminder: Reminder) => {
      log.debug(reminder);
      this.reminder = reminder;

      if (this.reminder.user) {
        this.titleService.setTitle(this.reminder.user.fake_first_name);
      }
    });
  }

  edit() {
    this.remindersFormService.open(this.reminder).then(
      (response) => {
        log.debug('res', response);
        this.loadData();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  complete() {
    this.reminder.completed = true;
    this.save();
  }

  inComplete() {
    this.reminder.completed = false;
    this.save();
  }

  save() {
    const reminder: Reminder = this.reminder.deserialize(this.reminder);
    this.remindersService.update(reminder).subscribe((res: Reminder) => {
      this.loadData();
    });
  }
}
