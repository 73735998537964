import { Component, Input, OnInit } from '@angular/core';
import { CommentModel } from '@app/shared/components/comments/comment.model';
import { Logger } from '@app/core';
import { Reminder } from '@app/reminders/reminders/reminder.model';
import { Router } from '@angular/router';

const log = new Logger('CommentViewButtonComponent');

@Component({
  selector: 'app-comment-view-button',
  templateUrl: './comment-view-button.component.html',
  styleUrls: ['./comment-view-button.component.scss'],
})
export class CommentViewButtonComponent implements OnInit {
  @Input() comment: CommentModel;

  constructor(private router: Router) {}

  ngOnInit() {
    if (!this.comment) {
      log.error('Comment not provided.');
    }
  }

  /**
   * Go to the reminder's target
   */
  preview() {
    log.debug('preview()', this.comment.cleanCommentableType);

    switch (this.comment.cleanCommentableType) {
      case 'Sale': {
        window.open(`/sales/view?id=${this.comment.commentable_id}`, '_blank');
        break;
      }
      case 'Hotel': {
        window.open(`/hotels/view?id=${this.comment.commentable_id}`, '_blank');
        break;
      }
      case 'HotelBooking': {
        window.open(`/hotel-bookings/view?id=${this.comment.commentable_id}`, '_blank');
        break;
      }
      case 'HotelBookingCancellation': {
        window.open(`/hotel-booking-cancellations/view?id=${this.comment.commentable_id}`, '_blank');
        break;
      }
      case 'EVENT': {
        window.open(`/events/view?id=${this.comment.commentable_id}`, '_blank');
        break;
      }

      case 'Client': {
        window.open(`/clients/view?id=${this.comment.commentable_id}`, '_blank');
        break;
      }
      case 'User': {
        window.open(`/settings/users/view?id=${this.comment.commentable_id}`, '_blank');
        break;
      }
      default: {
        log.error('Error finding the comment target');
      }
    }
  }
}
