import { Type } from '@app/settings/types/types.model';
import { Model } from '@app/shared/models/model.model';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';

export class SaleFinal extends Model {
  id: number;
  sale_offer_id: number;
  sale_offer?: SaleOffer;
  payment_method_id: number;
  payment_method: Type;
  client_penalty: string;
  items: Array<any>;
  meta_front?: any;
  completed: boolean;
  sale_id?: number;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.payment_method) {
      this.payment_method = new Type().deserialize(input.payment_method);
    }

    if (input.sale_offer) {
      this.sale_offer = new SaleOffer().deserialize(input.sale_offer);
    }

    return this;
  }
}
