import { Model } from '@app/shared/models/model.model';
import { WebOffersItem } from '@app/sales/web-offers/web-offers-item.model';
import { City } from '@app/settings/cities/cities.model';
import { Company } from '@app/settings/companies/company.model';

export class SmtpSettings extends Model {
  id: number;
  host: string;
  port: number;
  username: string;
  password: string;
  encryption: string;
  from_email: string;
  from_name: string;

  company_id: number;
  imap_port: number;
  signature: string;

  get nameAndEmail(): string {
    return this.from_name + '/' + this.from_email;
  }
}
