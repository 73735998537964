<div class="event-group-view-wrapper relative">
  <div class="top">
    <div class="preview-header d-flex justify-content-between">
      <div class="preview-title-wrapper">
        <span class="icon-holder"><i class="fal fa-calendar-week"></i></span>
        <span class="title">{{ eventGroup?.name }} / {{ eventGroup?.web_name }}</span>
        <span class="additional-info">
          <span class="sub-title" *ngIf="eventGroup?.active">
            {{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.ACTIVE' | translate }}
          </span>
          <span class="value" *ngIf="eventGroup?.active">
            <app-active-indicator [active]="eventGroup?.active"></app-active-indicator>
          </span>

          <span class="sub-title" *ngIf="eventGroup?.founded_year">
            {{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.FOUNDED_YEAR' | translate }}
          </span>
          <span class="value" *ngIf="eventGroup?.founded_year">{{ eventGroup?.founded_year }}</span>

          <span class="sub-title" *ngIf="eventGroup?.show_to_web">
            {{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.SHOW_TO_WEB' | translate }}
          </span>
          <span class="value" *ngIf="eventGroup?.show_to_web">
            <app-active-indicator [active]="eventGroup?.show_to_web"></app-active-indicator>
          </span>
        </span>
      </div>
      <div class="preview-btns-wrapper d-flex align-items-stretch">
        <app-seo-button
          class="mr-1"
          *ngIf="accessService.hasAccess([UserRoleType.IT, UserRoleType.MARKETING], ['event_groups.translations'])"
          (click)="editSeo(eventGroup)"
        ></app-seo-button>

        <app-reminder-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.IT,
                UserRoleType.MARKETING
              ],
              []
            )
          "
          class="mr-1"
          [reminderableId]="eventGroupId"
          [reminderableType]="LaravelModelsEnum.EVENTGROUP"
        ></app-reminder-button>

        <button
          *ngIf="accessService.hasAccess([UserRoleType.LEAD_GENERATION], ['event_groups.show'])"
          type="button"
          class="btn btn-sm btn-success mr-1"
          (click)="editEventGroup()"
        >
          {{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.EDIT_EVENT_GROUP_BTN' | translate }}
        </button>

        <button
          *ngIf="accessService.hasAccess([UserRoleType.LEAD_GENERATION], ['events.store'])"
          type="button"
          class="btn btn-sm btn-info"
          (click)="addEvent()"
        >
          {{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.NEW_EVENT_BTN' | translate }}
        </button>
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div *ngIf="eventGroup?.industry?.name" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.INDUSTRY' | translate }}</div>
        <div class="value">
          {{ eventGroup?.industry?.name }}
        </div>
      </div>

      <div *ngIf="eventGroup?.priority?.value" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.PRIORITY' | translate }}</div>
        <div class="value">
          {{ eventGroup?.priority?.value }}
        </div>
      </div>

      <div *ngIf="eventGroup?.visitors" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.VISITORS' | translate }}</div>
        <div class="value">
          {{ eventGroup?.visitors }}
        </div>
      </div>

      <div *ngIf="eventGroup?.organizer" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.ORGANIZER' | translate }}</div>
        <div class="value">
          {{ eventGroup?.organizer }}
        </div>
      </div>

      <div *ngIf="eventGroup?.exhibitors" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.EXHIBITORS' | translate }}</div>
        <div class="value">
          {{ eventGroup?.exhibitors }}
        </div>
      </div>

      <div *ngIf="eventGroup?.repeat?.value" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.REPEAT' | translate }}</div>
        <div class="value">
          {{ eventGroup?.repeat?.value }}
        </div>
      </div>

      <div *ngIf="eventGroup?.repeat?.value" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.REPEAT' | translate }}</div>
        <div class="value">
          {{ eventGroup?.repeat?.value }}
        </div>
      </div>

      <div *ngIf="eventGroup?.foreign_exhibitors" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.FOREIGN_EXHIBITORS' | translate }}</div>
        <div class="value">
          {{ eventGroup?.foreign_exhibitors }}
        </div>
      </div>

      <div *ngIf="eventGroup?.website_url" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.WEBSITE_URL' | translate }}</div>
        <div class="value">
          {{ eventGroup?.website_url }}
        </div>
      </div>

      <div *ngIf="eventGroup?.link_exhibitors" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.LINK_EXHIBITORS' | translate }}</div>
        <div class="value">
          {{ eventGroup?.link_exhibitors }}
        </div>
      </div>

      <div *ngIf="eventGroup?.year_exhibitors" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.YEAR_EXHIBITORS' | translate }}</div>
        <div class="value">
          {{ eventGroup?.year_exhibitors }}
        </div>
      </div>

      <div *ngIf="eventGroup?.venue?.name" class="mr-3">
        <div class="sub-title">{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.VENUE' | translate }}</div>
        <div class="value">
          {{ eventGroup?.venue?.name }}
        </div>
      </div>
    </div>
  </div>

  <div class="title2">
    {{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.EVENT_EDITIONS' | translate }}
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="eventsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="false"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadDataTable($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Events', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.ID' | translate }}"
      [width]="150"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="name"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.NAME' | translate }}"
      [hidden]="isHiddenColumn('name')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.id }">
          {{ dataItem.formattedName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="date_from"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.DATE_FROM' | translate }}"
      [hidden]="isHiddenColumn('date_from')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.date_from | date: env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="date_to"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.DATE_TO' | translate }}"
      [hidden]="isHiddenColumn('date_to')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.date_to | date: env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="country.name"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.COUNTRY' | translate }}"
      [hidden]="isHiddenColumn('country.name')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-country-select [filter]="filter" [field]="'country_id'"></app-kendo-country-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.venue?.city?.country?.name }}
        {{ dataItem?.city?.country?.name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="city_id"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.CITY' | translate }}"
      [hidden]="isHiddenColumn('city_id')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-city-select [filter]="filter"></app-kendo-city-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.venue?.city?.name }}
        {{ dataItem?.city?.name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="industry.name"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.INDUSTRY' | translate }}"
      [hidden]="isHiddenColumn('industry.name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-industry-select [filter]="filter"></app-kendo-industry-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="venue.name"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.VENUE' | translate }}"
      [hidden]="isHiddenColumn('venue.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-venue-select [filter]="filter"></app-kendo-venue-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="organizer"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.ORGANIZER' | translate }}"
      [hidden]="isHiddenColumn('organizer', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="website_url"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.WEBSITE_URL' | translate }}"
      [hidden]="isHiddenColumn('website_url', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="visitors"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.VISITORS' | translate }}"
      [hidden]="isHiddenColumn('visitors')"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="exhibitors"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.EXHIBITORS' | translate }}"
      [hidden]="isHiddenColumn('exhibitors', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="foreign_exhibitors"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.FOREIGN_EXHIBITORS' | translate }}"
      [hidden]="isHiddenColumn('foreign_exhibitors', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="link_exhibitors"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.LINK_EXHIBITORS' | translate }}"
      [hidden]="isHiddenColumn('link_exhibitors', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="year_exhibitors"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.YEAR_EXHIBITORS' | translate }}"
      [hidden]="isHiddenColumn('year_exhibitors', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="note"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.NOTE' | translate }}"
      [hidden]="isHiddenColumn('note', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="type.value"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.TYPE' | translate }}"
      [hidden]="isHiddenColumn('type.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select [groupType]="'event'" [filter]="filter"></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.type"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel_bookings_count"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.BOOKINGS' | translate }}"
      [width]="110"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a
          [routerLink]="['/hotel-bookings']"
          [queryParams]="{ event_id: dataItem.id }"
          (click)="pushToStorage(dataItem)"
          (contextmenu)="pushToStorage(dataItem)"
        >
          {{ dataItem.hotel_bookings_count }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="show_to_web"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.SHOW_TO_WEB' | translate }}"
      [hidden]="isHiddenColumn('show_to_web')"
      [width]="150"
      [class]="'text-center'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-active-indicator [active]="dataItem.show_to_web"></app-active-indicator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="active"
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.ACTIVE' | translate }}"
      [hidden]="isHiddenColumn('active')"
      [width]="150"
      [class]="'text-center'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-active-indicator [active]="dataItem.active"></app-active-indicator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'EVENT_GROUPS.EVENTS_GROUP_VIEW.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button
          *ngIf="accessService.hasAccess([UserRoleType.LEAD_GENERATION], ['events.show'])"
          (click)="editEvent(dataItem)"
        >
        </app-edit-button>

        <app-seo-button
          *ngIf="accessService.hasAccess([UserRoleType.IT, UserRoleType.MARKETING], ['events.translations'])"
          (click)="editEventSeo(dataItem)"
        ></app-seo-button>

        <app-delete-button
          *ngIf="accessService.hasAccess([UserRoleType.LEAD_GENERATION], ['events.destroy'])"
          [swal]="{}"
          (confirm)="deleteEvent(dataItem)"
        >
        </app-delete-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadDataTable(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
