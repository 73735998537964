import { HttpHeaders, HttpParams } from '@angular/common/http';

export enum VouchersTypes {
  pdf = 'pdf',
  word = 'word',
}

export class HttpParamsVouchers {
  private _httpParams = new HttpParams();

  /**
   *
   * @param id id
   * @param type type
   * @param format format
   * @param mediaId mediaId
   */
  constructor(id: number | Array<number>, type: string, format: VouchersTypes, mediaId: number = null) {
    this._httpParams = this._httpParams.set('format', format);

    if (mediaId) {
      this._httpParams = this._httpParams.set('media_id', mediaId.toString());
    }

    if (typeof id === 'number') {
      this._httpParams = this._httpParams.set(type + '[]', id.toString());
    } else {
      id.forEach((singleId: number) => {
        this._httpParams = this._httpParams.set(type + '[' + singleId + ']', singleId.toString());
      });
    }
  }

  get() {
    return {
      params: this._httpParams,
      headers: new HttpHeaders({
        'Content-Type': 'application/zip',
      }),
    };
  }
}
