import { Injectable } from '@angular/core';
import { ResourceInterface } from '@app/shared/models/resource.interface';
import { HttpClient } from '@angular/common/http';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { WebOffer } from '@app/sales/web-offers/web-offer.model';
import { Model } from '@app/shared/models/model.model';

@Injectable({
  providedIn: 'root',
})
export class WebOffersService implements ResourceInterface {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/web_offers', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((request: any) => {
          return new WebOffer().deserialize(request);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  update(request: WebOffer): Observable<WebOffer> {
    return this.http.put('v1/web_offers/' + request.id, request).pipe(
      map((response: HttpResponse) => {
        return new WebOffer().deserialize(response.data);
      })
    );
  }

  store(request: WebOffer): Observable<WebOffer> {
    return this.http.post('v1/web_offers', request).pipe(
      map((response: HttpResponse) => {
        return new WebOffer().deserialize(response.data);
      })
    );
  }

  show(id: number, query: ApiQuery = new ApiQuery()): Observable<Model> {
    return this.http.get('v1/web_offers/' + id, query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return new WebOffer().deserialize(response.data);
      })
    );
  }

  destroy(id: number): Observable<any> {
    return this.http.delete('v1/web_offers/' + id);
  }
}
