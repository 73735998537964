import { Component, Input, OnInit } from '@angular/core';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { Logger } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneCallsService } from '@app/shared/components/phone-calls-finder/phone-calls.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

const log = new Logger('PhoneCallsFinderComponent');

@Component({
  selector: 'app-phone-calls-finder',
  templateUrl: './phone-calls-finder.component.html',
  styleUrls: ['./phone-calls-finder.component.scss'],
})
export class PhoneCallsFinderComponent extends KendoGridComponent implements OnInit {
  @Input() id: number;
  @Input() type: LaravelModelsEnum;

  phoneCallsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;

  constructor(
    public modal: NgbActiveModal,
    private phoneCallsService: PhoneCallsService,
    protected kendoGridService: KendoGridService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    super(kendoGridService);
  }

  ngOnInit(): void {
    log.debug(this.id, this.type);

    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addFilters({
        model_type: this.type,
        model_id: this.id,
      })
      .addInclude('media');

    this.phoneCallsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((phoneCall: any) => {
        phoneCall['meta_front'] = {};
        phoneCall['meta_front']['blob'] = null;
        phoneCall['meta_front']['isLoading'] = false;
      });
      this.phoneCallsResponse = response;
      this.data = response.getGridData();
    });
  }

  loadBlob(dataItem: any) {
    dataItem.meta_front.isLoading = true;
    return this.http.download(dataItem.media[0].download_url).subscribe(
      (response: any) => {
        dataItem.meta_front.blob = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(response));
      },
      () => {},
      () => {
        dataItem.meta_front.isLoading = false;
      }
    );
  }
}
