import { LaravelModelsEnum, Model } from '@app/shared/models/model.model';
import { WebOffersItem } from '@app/sales/web-offers/web-offers-item.model';
import { User } from '@app/settings/users/user.model';

export class WebOffer extends Model {
  id: number;
  web_offerable_id: number;
  web_offerable_type: LaravelModelsEnum;
  title: string;
  description: string;
  active: boolean;
  items: Array<WebOffersItem>;
  sales_agent_names: string;
  sales_agent_email: string;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.items) {
      this.items = input.items.map((webOfferItem: WebOffersItem) => new WebOffersItem().deserialize(webOfferItem));
    }
    return this;
  }
}
