import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { Room } from '@app/hotels/rooms/room.model';
import { SalesService } from '@app/sales/sales/sales.service';
import * as moment from 'moment';
import { Sale } from '@app/sales/sales/sale.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('OfferMultipleClientsComponent');

@Component({
  selector: 'app-offer-multiple-clients',
  templateUrl: './offer-multiple-clients.component.html',
  styleUrls: ['./offer-multiple-clients.component.scss'],
})
export class OfferMultipleClientsComponent implements OnInit {
  @Input() hotelBookingId: number;
  env = environment;

  roomsResponse: PaginatedResponse;
  roomsGridData: GridDataResult;
  roomsState: DataStateChangeEvent;
  roomsApiQuery: ApiQuery = new ApiQuery();
  selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
  };
  public selectedRooms: Array<Room> = [];
  currentStep = 1;

  salesResponse: PaginatedResponse;
  salesData: GridDataResult;
  salesState: DataStateChangeEvent;
  salesApiQuery: ApiQuery = new ApiQuery();

  public selectedSales: Array<Sale> = [];

  finalData: {} = {}; // [room.id][sale.id] вървят така групиране
  selectedEventId: number = null;

  roomsLoading = false;

  constructor(
    public modal: NgbActiveModal,
    private hotelBookingsService: HotelBookingsService,
    private salesService: SalesService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  public mySelectionKey(context: RowArgs): string {
    return context.dataItem;
  }

  ngOnInit() {
    log.debug('OfferMultipleClientsComponent init');
    this.loadRoomsData();
  }

  loadRoomsData(state: DataStateChangeEvent = null): void {
    this.roomsLoading = true;
    this.roomsState = state;
    this.roomsApiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'booking.events',
        'booking.supplier',
        'booking.cancellation_terms',
        'booking.property',
        'hotel.city.country',
        'type',
        'meal_plan',
        'nights.net_price_currency',
        'nights.price_currency'
      )
      .addFilters({ 'booking.id': this.hotelBookingId });

    this.hotelBookingsService.indexRooms(this.roomsApiQuery).subscribe((response: PaginatedResponse) => {
      this.roomsResponse = response;
      this.roomsGridData = response.getGridData();
      this.roomsLoading = false;
    });
  }

  removeSelectedRoom(selectedRoom: Room) {
    const index = this.selectedRooms.indexOf(selectedRoom);
    this.selectedRooms.splice(index, 1);
    this.removeFinalDataObjRoom();
  }

  removeSelectedSale(selectedSale: Sale) {
    const index = this.selectedSales.indexOf(selectedSale);
    this.selectedSales.splice(index, 1);
    this.removeFinalDataObjSale();
  }

  setCurrentStep(number: number) {
    this.currentStep = number;

    if (this.currentStep === 2 && !this.salesData) {
      /**
       * @todo:
       * kendo-events-select.component.ts прави един request при init,
       * когато има pre selected event-и
       * за това е спряно това докато се намери решение
       */
      // this.loadSalesData();
    }

    if (this.currentStep === 2) {
      this.updateFinalDataObj();
      this.removeFinalDataObjRoom();
    }
  }

  loadSalesData(state: DataStateChangeEvent = null) {
    this.salesState = state;

    this.salesApiQuery
      .setDataSetFilters(state)
      .addIncludes('status', 'client', 'event', 'requests.type', 'requests.status', 'requests.hotel');

    this.salesService.index(this.salesApiQuery).subscribe((response: PaginatedResponse) => {
      this.salesResponse = response;
      this.salesData = response.getGridData();
    });
  }

  save() {
    if (!this.validationPass()) {
      return false;
    }
    this.formatPost();

    this.salesService.mass(this.formatPost()).subscribe(
      (res) => {
        this.modal.close(res);
      },
      () => {}
    );
  }

  /**
   * Валидира данните преди да ги изпрати
   */
  validationPass(): boolean {
    let valid = true;

    if (!this.selectedEventId) {
      this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.EVENT_SELECT').subscribe((trans: string) => {
        this.toastr.error(trans);
      });
      valid = false;
    }

    for (const roomId in this.finalData) {
      if (roomId) {
        const value = this.finalData[roomId];

        for (const saleId in value) {
          if (saleId) {
            const value2 = this.finalData[roomId][saleId];

            if (value2.selected) {
              if (!value2.allotment_id) {
                this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.ALLOTMENT').subscribe((trans: string) => {
                  this.toastr.error(trans);
                });
                valid = false;
              }

              if (!value2.type_id) {
                this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.TYPE_ID').subscribe((trans: string) => {
                  this.toastr.error(trans);
                });
                valid = false;
              }

              if (!value2.check_in) {
                this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.CHECK_IN').subscribe((trans: string) => {
                  this.toastr.error(trans);
                });
                valid = false;
              }

              if (!value2.check_out) {
                this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.CHECK_OUT').subscribe((trans: string) => {
                  this.toastr.error(trans);
                });
                valid = false;
              }

              if (!value2.price) {
                this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.PRICE').subscribe((trans: string) => {
                  this.toastr.error(trans);
                });
                valid = false;
              }

              if (!value2.count) {
                this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.COUNT').subscribe((trans: string) => {
                  this.toastr.error(trans);
                });
                valid = false;
              }

              if (!value2.people) {
                this.translate.get('OFFER_MULTIPLE_CLIENTS.ERRORS.PEOPLE').subscribe((trans: string) => {
                  this.toastr.error(trans);
                });
                valid = false;
              }
            }
          }
        }
      }
    }

    return valid;
  }

  /**
   * Наглася данните както ги чака api-то
   */
  formatPost(): any {
    const formatted: any = {
      event_id: this.selectedEventId,
      sales: [],
    };

    for (const roomId in this.finalData) {
      if (roomId) {
        const value = this.finalData[roomId];

        for (const saleId in value) {
          if (saleId) {
            const value2 = this.finalData[roomId][saleId];

            if (value2.selected) {
              let exist = false;

              formatted.sales.forEach((sale: any) => {
                if (saleId === sale.id) {
                  exist = true;
                }
              });

              const roomFormated = {
                request_id: value2.request_id,
                allotment_id: value2.allotment_id,
                type_id: value2.type_id,
                check_in: moment(value2.check_in, this.env.momentDateFormat).format(this.env.apiPipeDateFormat),
                check_out: moment(value2.check_out, this.env.momentDateFormat).format(this.env.apiPipeDateFormat),
                price: value2.price,
                count: value2.count,
                people: value2.people,
              };

              if (!exist) {
                formatted.sales.push({
                  id: saleId,
                  rooms: [roomFormated],
                });
              } else {
                formatted.sales.forEach((sale: any) => {
                  if (saleId === sale.id) {
                    sale.rooms.push(roomFormated);
                  }
                });
              }
            }
          }
        }
      }
    }
    return formatted;
  }

  /**
   * Вика се при промяна да избраните sales
   * @param grid kendo grid obj
   */
  selectedSalesChange(grid: any) {
    // версията на кендо грид, с която се работи в момента няма функционалност за автоматично отваряне на вс details редове
    this.selectedSales.forEach((item, idx) => {
      grid.expandRow(idx);
    });

    this.updateFinalDataObj();
    this.removeFinalDataObjSale();
  }

  // Добавя новите json-и/ключове ако ги няма. Следва структурата [room.id][sale.id][НЕЩО]
  updateFinalDataObj() {
    this.selectedRooms.forEach((room: any) => {
      this.selectedSales.forEach((sale: Sale) => {
        if (!this.finalData[room.id]) {
          this.finalData[room.id] = {};
        }

        if (!this.finalData[room.id][sale.id]) {
          this.finalData[room.id][sale.id] = {};
        }

        if (this.finalData[room.id][sale.id]['selected'] === undefined) {
          this.finalData[room.id][sale.id]['selected'] = true;
        }

        if (!this.finalData[room.id][sale.id]['price']) {
          this.finalData[room.id][sale.id]['price'] = null;
        }

        if (!this.finalData[room.id][sale.id]['count']) {
          this.finalData[room.id][sale.id]['count'] = null;
        }

        if (!this.finalData[room.id][sale.id]['type_id']) {
          this.finalData[room.id][sale.id]['type_id'] = room.type_id;
        }

        if (!this.finalData[room.id][sale.id]['check_in']) {
          this.finalData[room.id][sale.id]['check_in'] = moment(room.check_in).format(this.env.momentDateFormat);
        }

        if (!this.finalData[room.id][sale.id]['check_out']) {
          this.finalData[room.id][sale.id]['check_out'] = moment(room.check_out).format(this.env.momentDateFormat);
        }

        if (!this.finalData[room.id][sale.id]['allotment_id']) {
          this.finalData[room.id][sale.id]['allotment_id'] = room.allotment_id;
        }

        if (!this.finalData[room.id][sale.id]['people']) {
          this.finalData[room.id][sale.id]['people'] = room.people;
        }

        if (!this.finalData[room.id][sale.id]['request_id']) {
          this.finalData[room.id][sale.id]['request_id'] = null;
        }
      });
    });
  }

  /**
   * Премахва всички излишни sale от финалния обект
   */
  removeFinalDataObjSale() {
    const selectedSalesIds: Array<any> = [];

    this.selectedSales.forEach((sale: any) => {
      selectedSalesIds.push(sale.id);
    });

    for (const roomId in this.finalData) {
      if (roomId) {
        const value = this.finalData[roomId];

        for (const saleId in value) {
          if (selectedSalesIds.indexOf(Number(saleId)) < 0) {
            delete this.finalData[roomId][Number(saleId)];
          }
        }
      }
    }
  }

  /**
   * Премахва всички излишни rooms от финалния обект
   */
  removeFinalDataObjRoom() {
    const selectedRoomsIds: Array<any> = [];

    this.selectedRooms.forEach((room: any) => {
      selectedRoomsIds.push(room.id);
    });

    for (const roomId in this.finalData) {
      if (selectedRoomsIds.indexOf(Number(roomId)) < 0) {
        delete this.finalData[Number(roomId)];
      }
    }
  }
}
