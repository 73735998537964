<div class="modal-header">
  <h4 class="modal-title">
    <span><i class="fal fa-search"></i> {{ 'HOTEL_FINDER.HOTEL_FINDER' | translate }} </span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ps" [perfectScrollbar]>
  <form id="hotel-finder-form" (ngSubmit)="save()" [formGroup]="form" novalidate>
    <div class="row align-items-end mb-3">
      <div class="col-sm-3">
        <div>
          <label>{{ 'HOTEL_FINDER.VENUE' | translate }}</label>
          <app-venue-select
            [parentFormGroup]="form"
            [passFormControlName]="'venue_id'"
            (change)="loadData()"
          ></app-venue-select>
        </div>
      </div>
      <!--      <div class="col-sm-2">-->
      <!--        <button-->
      <!--          (click)="loadData()"-->
      <!--          type="button"-->
      <!--          class="btn btn-input btn-success">{{ 'HOTEL_FINDER.SEARCH' | translate }}</button>-->
      <!--      </div>-->
    </div>

    <kendo-grid
      [data]="gridData"
      [pageSize]="hotelsResponse?.meta.per_page"
      [sortable]="true"
      [filterable]="true"
      [columnMenu]="true"
      [filter]="state?.filter"
      [sort]="state?.sort"
      [skip]="state?.skip"
      [pageable]="true"
      (dataStateChange)="loadData($event)"
      [selectable]="selectableSettings"
      [kendoGridSelectBy]="'id'"
      [selectedKeys]="selectedHotels"
    >
      <kendo-grid-checkbox-column [width]="70" showSelectAll="true" class="text-center"></kendo-grid-checkbox-column>

      <kendo-grid-column field="name" title="{{ 'HOTEL_FINDER.NAME' | translate }}" filter="text">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <a [routerLink]="['/hotels/view']" [queryParams]="{ id: dataItem.id }" target="_blank">
            {{ dataItem.name }}
          </a>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="stars"
        title="{{ 'HOTEL_FINDER.STARS' | translate }}"
        filter="numeric"
        [width]="120"
      ></kendo-grid-column>

      <kendo-grid-column
        field="distance_to_fair"
        [filterable]="false"
        [sortable]="false"
        title="{{ 'HOTEL_FINDER.KM_TO_FAIR' | translate }}"
        [width]="100"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.distance_to_fair }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="city.country.name"
        title="{{ 'HOTEL_FINDER.COUNTRY' | translate }}"
        filter="text"
        [width]="170"
      >
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-kendo-country-select [filter]="filter" [field]="'city.country_id'"></app-kendo-country-select>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="city.name" title="{{ 'HOTEL_FINDER.CITY' | translate }}" filter="text" [width]="170">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-kendo-city-select [filter]="filter"></app-kendo-city-select>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="address"
        title="{{ 'HOTEL_FINDER.ADDRESS' | translate }}"
        filter="text"
      ></kendo-grid-column>

      <kendo-grid-column
        field="distance_to_center"
        title="{{ 'HOTEL_FINDER.DISTANCE_TO_CENTER' | translate }}"
        filter="numeric"
        [width]="140"
      ></kendo-grid-column>

      <kendo-grid-column
        field="distance_to_airport"
        title="{{ 'HOTEL_FINDER.DISTANCE_TO_AIRPORT' | translate }}"
        filter="numeric"
        [width]="140"
      ></kendo-grid-column>

      <kendo-grid-column
        field="distance_to_tr_point"
        title="{{ 'HOTEL_FINDER.DISTANCE_TO_TR_POINT' | translate }}"
        filter="numeric"
        [width]="140"
      ></kendo-grid-column>

      <!--      <kendo-grid-column-->
      <!--        field="distance_to_tr_point_name"-->
      <!--        title="{{ 'HOTEL_FINDER.DISTANCE_TO_TR_POINT_NAME' | translate }}"-->
      <!--        filter="text"-->
      <!--      ></kendo-grid-column>-->

      <!--      <kendo-grid-column-->
      <!--        field="type"-->
      <!--        title="{{ 'HOTEL_FINDER.TYPE' | translate }}"-->
      <!--        filter="text"-->
      <!--      >-->
      <!--        <ng-template kendoGridFilterCellTemplate let-filter>-->
      <!--          <app-kendo-type-select [groupType]="'hotels'" [filter]="filter"></app-kendo-type-select>-->
      <!--        </ng-template>-->
      <!--        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
      <!--          <app-type-badge [type]="dataItem.type"></app-type-badge>-->
      <!--        </ng-template>-->
      <!--      </kendo-grid-column>-->

      <kendo-grid-column
        field="rooms_count"
        title="{{ 'HOTEL_FINDER.ROOMS' | translate }}"
        filter="numeric"
        [width]="140"
      ></kendo-grid-column>

      <kendo-grid-column title="{{ 'HOTEL_FINDER.ACTIONS' | translate }}" [width]="120" [class]="'text-center'">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <button *ngIf="!multiple" (click)="selectHotel(dataItem)" type="button" class="btn btn-xs btn-info">
            {{ 'HOTEL_FINDER.SELECT' | translate }}
          </button>

          <app-edit-button class="inline-block" (click)="edit(dataItem)"></app-edit-button>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
        <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      </ng-template>
    </kendo-grid>
  </form>
</div>

<div class="modal-footer">
  <button
    type="submit"
    class="btn btn-outline-dark btn-xs"
    form="hotel-finder-form"
    [disabled]="(!multiple && selectedHotels.length !== 1) || (multiple && selectedHotels.length <= 0)"
  >
    <span *ngIf="selectedHotels.length > 0">({{ selectedHotels.length }})</span>
    {{ 'HOTEL_FINDER.SELECT' | translate }}
  </button>
</div>
