import { Model } from '@app/shared/models/model.model';
import { SaleOfferItem } from '@app/sales/sales/sale-offer-item.model';
import { CurrenciesAdditionsService } from '@app/shared/services/currencies-additions.service';
import * as moment from 'moment';
import { Type } from '@app/settings/types/types.model';

export class SaleOffer extends Model {
  id: number;
  name: string;
  description: string;
  note: string;
  client_comment_type_id: number;
  client_comment_type?: Type;
  client_comment: string;
  show_to_web: boolean;
  items: Array<SaleOfferItem>;
  meta_front?: any;
  sale_id: number;
  cancellation_terms: string;
  completed: boolean;
  sale_online_booking_terms: string;
  online_booking_payment_percent: number;
  online_booking_payment_date_to: string;

  get roomsTotal(): number {
    let total = 0;

    if (this.items.length > 0) {
      this.items.forEach((saleOfferItem: SaleOfferItem) => {
        total += Number(saleOfferItem.count);
      });
    }

    return total;
  }

  get peopleTotal(): number {
    let total = 0;

    if (this.items.length > 0) {
      this.items.forEach((saleOfferItem: SaleOfferItem) => {
        total += Number(saleOfferItem.people) * Number(saleOfferItem.count);
      });
    }

    return total;
  }

  get netPriceTotal(): number {
    let total = 0;

    if (this.items.length > 0) {
      this.items.forEach((saleOfferItem: SaleOfferItem) => {
        total +=
          CurrenciesAdditionsService.toEUR(saleOfferItem.net_price, saleOfferItem.net_price_currency) *
          saleOfferItem.count *
          this.getNightsCount(saleOfferItem.check_in, saleOfferItem.check_out);
      });
    }

    return total;
  }

  get priceTotal(): number {
    let total = 0;

    if (this.items.length > 0) {
      this.items.forEach((saleOfferItem: SaleOfferItem) => {
        total +=
          Number(saleOfferItem.price) *
          Number(saleOfferItem.count) *
          this.getNightsCount(saleOfferItem.check_in, saleOfferItem.check_out);
      });
    }

    return total;
  }

  get marginTotal(): number {
    let total = 0;

    if (this.items.length > 0) {
      this.items.forEach((saleOfferItem: SaleOfferItem) => {
        total +=
          saleOfferItem.price *
            saleOfferItem.count *
            this.getNightsCount(saleOfferItem.check_in, saleOfferItem.check_out) -
          CurrenciesAdditionsService.toEUR(
            saleOfferItem.net_price *
              saleOfferItem.count *
              this.getNightsCount(saleOfferItem.check_in, saleOfferItem.check_out),
            saleOfferItem.net_price_currency
          );
      });
    }

    return total;
  }

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.items) {
      this.items = input.items.map((item: SaleOfferItem) => new SaleOfferItem().deserialize(item));
    }

    return this;
  }

  /**
   * Връща разликата в дни между две дати
   * @param date1 Дата 1
   * @param date2 Дата 2
   */
  private getNightsCount(date1: string | Date, date2: string | Date): number {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }
}
