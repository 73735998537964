import { Component, Input, OnInit } from '@angular/core';
import { HttpParamsVouchers, VouchersTypes } from '@app/core/http/http-params-vouchers';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { SalesService } from '@app/sales/sales/sales.service';
import { Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { MediaLibraryService } from '@app/shared/components/media-library/media-library.service';

const log = new Logger('DownloadVouchersButtonComponent');

@Component({
  selector: 'app-download-vouchers-button',
  templateUrl: './download-vouchers-button.component.html',
  styleUrls: ['./download-vouchers-button.component.scss'],
})
export class DownloadVouchersButtonComponent implements OnInit {
  @Input() type: string;
  @Input() id: number | Array<number>;
  @Input() companyId: number;
  VouchersTypes = VouchersTypes;
  mediaData: PaginatedResponse = new PaginatedResponse();

  constructor(private salesService: SalesService, private mediaService: MediaLibraryService) {}

  ngOnInit() {
    if (!this.id || !this.type) {
      log.error('Cannot find id , type or company_id!');
    }
  }

  loadVouchersMedia(popover: any) {
    if (popover.isOpen()) {
      return false;
    }

    const query = new ApiQuery()
      .addFilters({
        model_id: this.companyId,
        model_type: LaravelModelsEnum.COMPANY,
      })
      .setLimit(9999999999);

    if (this.type) {
      query.addFilter('collection_name', 'sale_vouchers');
    }

    this.mediaService.index(query).subscribe((data: PaginatedResponse) => {
      this.mediaData = data;
      log.debug('this.mediaData', this.mediaData);
    });
  }

  downloadVouchers(vouchersTypes: VouchersTypes, mediaId: number = null, popover: any) {
    this.salesService
      .downloadVouchers(new HttpParamsVouchers(this.id, this.type, vouchersTypes, mediaId))
      .subscribe((response: PaginatedResponse) => {
        log.debug('downloadVouchers response', response);
      });
    popover.close();
  }
}
