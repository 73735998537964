<div [formGroup]="form" class="kendo-select-filter">
  <ng-select
    (change)="onSelect($event)"
    [items]="data"
    bindLabel="value"
    [bindValue]="'id'"
    [loading]="loader"
    [formControlName]="'value'"
    appendTo="body"
    class="colored-select kendo-types-select"
    [multiple]="true"
    [closeOnSelect]="false"
  >
    <ng-template ng-label-tmp let-item="item" let-items="items">
      <div *ngIf="form?.get('value')?.value.length === 1" class="selected-item">
        {{ item.value }}
      </div>

      <div *ngIf="form?.get('value')?.value.length > 1" class="selected-item">
        {{ form?.get('value')?.value.length }}
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div [ngStyle]="{ 'background-color': item.color, color: item.invertColor }" *ngIf="item.color" class="item">
        <span *ngIf="groupType == 'invoice_account' && item?.real_value">{{ item?.real_value }} -</span>

        <span *ngIf="isTypeSelected(item.id)"><i class="fas fa-times-hexagon"></i></span>

        {{ item.value }}

        <span *ngIf="groupType == 'tax_rates' && item?.real_value">({{ item?.real_value }}</span>
      </div>

      <div *ngIf="!item.color" class="item">
        <span *ngIf="groupType == 'invoice_account' && item?.real_value">{{ item?.real_value }} -</span>

        <span *ngIf="isTypeSelected(item.id)"><i class="fas fa-times-hexagon"></i></span>

        {{ item.value }}

        <span *ngIf="groupType == 'tax_rates' && item?.real_value">({{ item?.real_value }})</span>
      </div>
    </ng-template>
  </ng-select>
</div>
