import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { Locale } from '@app/settings/locales/locales.model';
import { ApiQuery } from '@app/core/http/api-query';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataSet } from '@app/shared/kendo/data-set';
import { environment } from '@env/environment';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { InvoiceType } from '@app/finance/invoices/invoice-type.enum';

const log = new Logger('InvoicesSelectComponent');

@Component({
  selector: 'app-invoices-select',
  templateUrl: './invoices-select.component.html',
  styleUrls: ['./invoices-select.component.scss'],
})
export class InvoicesSelectComponent implements OnInit {
  env = environment;
  data: Array<Locale>;
  apiQuery: ApiQuery = new ApiQuery();
  loader: boolean;
  @Input() FormControl: FormControl;
  form: FormGroup;
  @Input() required = false;
  @Input() disabled = false;
  @Input() clientableFormControl: FormControl;
  @Input() invoiceableId: number = null;
  @Input() invoiceableType: LaravelModelsEnum = null;
  @Input() depositsOnly = true;
  @Input() invoiceType: InvoiceType = null;

  constructor(private invoicesService: InvoicesService, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.loadInvoices();
    /*
     *  създава fake formGroup, за да може да пази временно стойностите някъде
     *  после чрез евентите add, remove и clear ги прехвърля към подадения на компонента контрол
     */
    if (this.required) {
      this.form = this.formBuilder.group({
        invoices_fake: [[], [Validators.required]],
      });
    } else {
      this.form = this.formBuilder.group({
        invoices_fake: [[]],
      });
    }

    /*
     * това помага за patch-a на parent формата
     * ако се пали в някой друг случай трябва да се измисли нещо по-добро
     */
    this.FormControl.valueChanges.subscribe((values: []) => {
      setTimeout(() => {
        const preSelectedValues: Array<any> = [];
        values.forEach((locale: any) => {
          preSelectedValues.push(locale.id);
        });
        this.form.get('invoices_fake').setValue(preSelectedValues);
      });
    });

    // this.localesSearch();
  }

  loadInvoices(keyword?: string) {
    this.loader = true;

    const query = new ApiQuery().addInclude('currency').setLimit(9999);

    if (this.invoiceableId) {
      query.addFilter('invoiceable_id', this.invoiceableId);
    }

    if (this.invoiceableType) {
      query.addFilter('invoiceable_type', this.invoiceableType);
    }

    if (this.invoiceType) {
      query.addFilter('type_id', this.invoiceType);
    }

    if (this.depositsOnly) {
      const dataset = new DataSet().addFilter('deposit_percent', 0, 'gt');
      query.setDataSetFilters(dataset);
    }

    this.invoicesService.index(query).subscribe((response: PaginatedResponse) => {
      log.debug('loadInvoices response.data', response.data);
      this.data = [...response.data];
      this.loader = false;
    });
  }

  add(id: number) {
    this.FormControl.value.push({ id: id });
  }

  remove(id: any) {
    let index: number;
    this.FormControl.value.forEach((value: any, ind: number) => {
      if (value.id === id) {
        index = ind;
      }
    });
    this.FormControl.value.splice(index, 1);
  }

  clear() {
    this.FormControl.setValue([]);
  }
}
