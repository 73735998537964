import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesFinalFormComponent } from '@app/sales/sales/sales-final-form/sales-final-form.component';
import { Sale } from '@app/sales/sales/sale.model';

@Injectable({
  providedIn: 'root',
})
export class SalesFinalFormService {
  constructor(private modalService: NgbModal) {}

  public open(sale: Sale, offerId: number, saleFinalId: number = null): Promise<any> {
    const modal = this.modalService.open(SalesFinalFormComponent, {
      size: 'full' as 'lg',
    });
    modal.componentInstance.sale = sale;
    modal.componentInstance.offerId = offerId;
    modal.componentInstance.saleFinalId = saleFinalId;
    return modal.result;
  }
}
