import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemindersFormComponent } from './reminders/reminders-form/reminders-form.component';
import { RemindersListComponent } from './reminders/reminders-list/reminders-list.component';
import { RemindersRoutingModule } from '@app/reminders/reminders-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { RemindersViewComponent } from './reminders/reminders-view/reminders-view.component';

@NgModule({
  declarations: [RemindersFormComponent, RemindersListComponent, RemindersViewComponent],
  imports: [CommonModule, RemindersRoutingModule, ReactiveFormsModule, SharedModule],
})
export class RemindersModule {}
