<div class="modal-header">
  <h4 class="modal-title">
    <ng-container *ngIf="reminder && reminder.id">
      {{ 'REMINDERS.REMINDERS_FORM.EDIT' | translate }}
    </ng-container>
    <ng-container *ngIf="!reminder || !reminder.id">
      {{ 'REMINDERS.REMINDERS_FORM.ADD' | translate }}
    </ng-container>
  </h4>
  {{ reminder?.cleanReminderableType }}
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form id="set-reminder-form" (ngSubmit)="save()" [formGroup]="form" novalidate>
    <div class="reminder-info pb-3" *ngIf="reminder?.id">
      <div class="row align-items-center justify-content-between">
        <div class="col-6">
          <div class="modal-section-title">Author</div>
          <app-user-box [user]="creator" class="d-inline-block"></app-user-box>
        </div>

        <div class="col-3">
          <div class="modal-section-title">{{ 'REMINDERS.REMINDERS_FORM.CREATED_AT' | translate }}:</div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="text">
              <i class="far fa-calendar-day"></i>
              <strong>{{ reminder?.created_at.date | date: env.pipeDateFormat }}</strong>
            </div>
            <div class="text">
              <i class="far fa-clock"></i>
              <strong>{{ reminder?.created_at.date | date: 'HH:mm' }}</strong>
            </div>
            <div class="info" *ngIf="reminder?.deleted_at && false">
              {{ 'REMINDERS.REMINDERS_FORM.DELETED_AT' | translate }}:
              <strong>{{ reminder?.deleted_at?.getDateTime() }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-stretch">
      <div class="col-12">
        <app-user-select [parentFormGroup]="form"></app-user-select>
      </div>
      <div class="col-9">
        <div class="form-group">
          <label>{{ 'REMINDERS.REMINDERS_FORM.REMIND_AT' | translate }}</label>
          <app-datetime-picker
            [minDate]="minDate"
            [defaultDate]="defaultDate"
            [FormControl]="form.get('remind_at')"
          ></app-datetime-picker>
        </div>
      </div>
      <div class="col-3 d-flex">
        <ng-template #popReminder>
          <div class="popover-wrapper">
            <div class="form-group">
              <label>{{ 'REMINDERS.REMINDERS_FORM.REMINDER_VALUE' | translate }}</label>
              <input type="number" class="form-control" value="7" #ReminderValueInput />
            </div>

            <div class="form-group">
              <label>{{ 'REMINDERS.REMINDERS_FORM.REMINDER_PERIOD' | translate }}</label>
              <select class="form-control" #ReminderPeriodInput>
                <option value="minutes" selected>
                  {{ 'REMINDERS.REMINDERS_FORM.REMINDER_PERIOD_MINUTES' | translate }}
                </option>
                <option value="hours" selected>
                  {{ 'REMINDERS.REMINDERS_FORM.REMINDER_PERIOD_HOURS' | translate }}
                </option>
                <option value="days" selected>
                  {{ 'REMINDERS.REMINDERS_FORM.REMINDER_PERIOD_DAYS' | translate }}
                </option>
                <option value="weeks">
                  {{ 'REMINDERS.REMINDERS_FORM.REMINDER_PERIOD_WEEKS' | translate }}
                </option>
                <option value="months">
                  {{ 'REMINDERS.REMINDERS_FORM.REMINDER_PERIOD_MONTHS' | translate }}
                </option>
              </select>
            </div>

            <button
              type="button"
              class="btn btn-primary btn-xs"
              (click)="fillReminderDate(ReminderValueInput.value, ReminderPeriodInput.value, ReminderPopover)"
            >
              {{ 'HOTEL_BOOKINGS.HOTEL_BOOKINGS_FORM.POP_BTN_SET' | translate }}
            </button>
            <button type="button" class="btn btn-danger btn-xs" (click)="ReminderPopover.close()">
              {{ 'HOTEL_BOOKINGS.HOTEL_BOOKINGS_FORM.POP_BTN_CLOSE' | translate }}
            </button>
          </div>
        </ng-template>
        <button
          type="button"
          class="btn btn-secondary btn-xs btn-quick-set"
          [ngbPopover]="popReminder"
          [autoClose]="false"
          triggers="manual"
          #ReminderPopover="ngbPopover"
          (click)="ReminderPopover.open()"
          popoverClass="quick_set_booking_deadline_popover"
          container="body"
        >
          {{ 'QUICK_SET' | translate }}
        </button>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="note-title mb-2">{{ 'SALES.SALES_VIEW.NOTE' | translate }}</label>
          <textarea class="form-control" pInputTextarea autoResize="true" formControlName="note"></textarea>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="mr-auto" *ngIf="reminder?.id">
    <button
      *ngIf="!reminder?.completed"
      type="button"
      class="btn btn-outline-success btn-complete btn-xs"
      [swal]="{
        titleText: 'REMINDERS.REMINDERS_FORM.COMPLETED.SWAL.TITLE' | translate,
        text: 'REMINDERS.REMINDERS_FORM.COMPLETED.SWAL.TEXT' | translate
      }"
      (confirm)="complete()"
      [ngbTooltip]="'REMINDERS.REMINDERS_FORM.COMPLETED.TOOLTIP' | translate"
      container="body"
    >
      <i class="far fa-check"></i>
      {{ 'REMINDERS.REMINDERS_FORM.COMPLETED.BUTTON' | translate }}
    </button>

    <button
      *ngIf="reminder?.completed"
      type="button"
      class="btn btn-outline-danger btn-complete btn-xs"
      [swal]="{
        titleText: 'REMINDERS.REMINDERS_FORM.INCOMPLETE.SWAL.TITLE' | translate,
        text: 'REMINDERS.REMINDERS_FORM.INCOMPLETE.SWAL.TEXT' | translate
      }"
      (confirm)="inComplete()"
      [ngbTooltip]="'REMINDERS.REMINDERS_FORM.INCOMPLETE.TOOLTIP' | translate"
      container="body"
    >
      <i class="far fa-check"></i>
      {{ 'REMINDERS.REMINDERS_FORM.INCOMPLETE.BUTTON' | translate }}
    </button>
  </div>
  <button type="submit" class="btn btn-outline-dark btn-xs" form="set-reminder-form" [disabled]="this.form.invalid">
    {{ 'REMINDERS.REMINDERS_FORM.SAVE' | translate }}
  </button>
</div>
