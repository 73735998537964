<div class="button mr-auto" [ngClass]="customClasses" [ngbTooltip]="tooltip">
  <div class="icon">
    <svg
      version="1.1"
      id="layer-1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      style="enable-background: new 0 0 18 18"
      xml:space="preserve"
    >
      <g>
        <path class="st0" d="M18,0v18C18,8.1,9.9,0,0,0H18z" />
      </g>
    </svg>
    <i [ngClass]="icon"></i>
    <svg
      version="1.1"
      id="layer-2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      style="enable-background: new 0 0 18 18"
      xml:space="preserve"
    >
      <g>
        <path class="st0" d="M18,0v18C18,8.1,9.9,0,0,0H18z" />
      </g>
    </svg>
  </div>
</div>
