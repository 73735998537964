import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneCallsFinderComponent } from '@app/shared/components/phone-calls-finder/phone-calls-finder.component';
import { LaravelModelsEnum } from '@app/shared/models/model.model';

@Injectable({
  providedIn: 'root',
})
export class PhoneCallsFinderService {
  constructor(private modalService: NgbModal) {}

  public open(id: number, type: LaravelModelsEnum): Promise<any> {
    const modal = this.modalService.open(PhoneCallsFinderComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.id = id;
    modal.componentInstance.type = type;
    return modal.result;
  }
}
