<div class="modal-header">
  <h4 class="modal-title">
    <span><i class="fal fa-history"></i> {{ 'CLIENT_HISTORY.CLIENT_HISTORY' | translate }} </span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ps" [perfectScrollbar]>
  <kendo-grid
    [data]="gridData"
    [pageSize]="saleFinalsResponse?.meta.per_page"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
  >
    <kendo-grid-column
      [width]="50"
      class="text-center"
      headerClass="text-center sm"
      title="{{ 'CLIENT_HISTORY.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="event" title="{{ 'CLIENT_HISTORY.EVENT' | translate }}" headerClass="sm">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem.sale_final.sale.event.id }" target="_blank">
          {{ dataItem.sale_final.sale.event.name }} -
          {{ dataItem.sale_final.sale.event.date_from | amDateFormat: 'YYYY' }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hotel" title="{{ 'CLIENT_HISTORY.HOTEL' | translate }}" headerClass="sm">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="['/hotels/view']" [queryParams]="{ id: dataItem.hotel_booking_room.hotel.id }" target="_blank">
          {{ dataItem.hotel_booking_room.hotel.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="meal_plan"
      title="{{ 'CLIENT_HISTORY.MEAL_PLAN' | translate }}"
      headerClass="sm"
      [width]="80"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem?.sale_offer_item?.meal_plan"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="count"
      title="{{ 'CLIENT_HISTORY.ROOMS_COUNT' | translate }}"
      headerClass="sm"
      [width]="100"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.count }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="nights" title="{{ 'CLIENT_HISTORY.NIGHTS' | translate }}" headerClass="sm" [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ getNightsCount(dataItem?.sale_offer_item?.check_in, dataItem?.sale_offer_item?.check_out) }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="price_per_night"
      title="{{ 'CLIENT_HISTORY.PRICE_PER_NIGHT' | translate }} {{ 'DEFAULT_CURRENCY' | translate }}"
      headerClass="sm"
      [width]="130"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem?.sale_offer_item?.price * dataItem.count | number: '1.2-2' }}
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
    </ng-template>
  </kendo-grid>
</div>

<div class="modal-footer">
  <button (click)="modal.dismiss()" type="button" class="btn btn-outline-dark btn-xs">
    {{ 'CLIENT_HISTORY.CLOSE' | translate }}
  </button>
</div>
