import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { SalesService } from '@app/sales/sales/sales.service';

const log = new Logger('SaleMassReassignComponent');

@Component({
  selector: 'app-sale-mass-reassign',
  templateUrl: './sale-mass-reassign.component.html',
  styleUrls: ['./sale-mass-reassign.component.scss'],
})
export class SaleMassReassignComponent implements OnInit {
  form: FormGroup;
  UserRoleType = UserRoleType;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private salesService: SalesService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  fieldChange() {
    this.form.get('from_id').patchValue(null);
    this.form.get('to_id').patchValue(null);
  }

  reassign(SaleMassReassignPopOver: NgbPopover) {
    log.debug('this.form.value', this.form.value);

    const formValueCopy = cloneDeep(this.form.value);
    delete formValueCopy.from_role_id;
    delete formValueCopy.to_role_id;
    delete formValueCopy.from;
    delete formValueCopy.to;

    this.salesService.massReassign(formValueCopy).subscribe(
      (response: any) => {
        if (response) {
          this.translate.get('SALE_MASS_REASSIGN.AFFECTED_ROWS').subscribe((trans: string) => {
            this.toastr.success(trans + ' ' + response.affected_rows);
            this.close(SaleMassReassignPopOver);
          });
        }
      },
      (response) => {
        if (response?.error?.message) {
          this.toastr.error(response.error.message);
        }
      }
    );
  }

  close(SaleMassReassignPopOver: NgbPopover) {
    this.form.reset();
    SaleMassReassignPopOver.close();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      field: [null, [Validators.required]],
      from_id: [null, [Validators.required]],
      from: [null], // това не трябва да го имам по принцип, но компонент има проблеми без него
      from_role_id: [null],
      to_id: [null, [Validators.required]],
      to: [null], // това не трябва да го имам по принцип, но компонент има проблеми без него
      to_role_id: [null],
    });
  }
}
