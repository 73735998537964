import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SaleRequest } from '@app/sales/sales/sale-request.model';
import { Sale } from '@app/sales/sales/sale.model';
import { SalesService } from '@app/sales/sales/sales.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Events } from '@app/events/events/events.model';
import { environment } from '@env/environment';
import { cloneDeep } from 'lodash';

const log = new Logger('SalesRequestsFormComponent');

@Component({
  selector: 'app-sales-requests-form',
  templateUrl: './sales-requests-form.component.html',
  styleUrls: ['./sales-requests-form.component.scss'],
})
export class SalesRequestsFormComponent implements OnInit {
  @Input() sale: Sale = null;
  form: FormGroup;
  massActionsForm: FormGroup;
  env = environment;
  checkAll = false;

  constructor(private salesService: SalesService, public modal: NgbActiveModal, private formBuilder: FormBuilder) {}

  ngOnInit() {
    log.debug(this.sale);
    this.crateMassActionsForm();
    this.createForm();
    this.patchForm();
  }

  save() {
    log.debug('this.form.value', this.form.value);
    this.salesService.requests(this.sale.id, this.form.value).subscribe((res: any) => {
      this.modal.close(res);
    });
  }

  removeRequest(index: number): void {
    this.form.get('requests')['controls'].splice(index, 1);
    this.form.get('requests').updateValueAndValidity();
  }

  addRequest() {
    // @ts-ignore
    this.form.get('requests').push(this.getEmptyRequest(this.sale && this.sale.event ? this.sale.event : null));
  }

  test(some?: any) {
    log.debug(this.form);
    log.debug(some);
  }

  setMassStatus() {
    // @ts-ignore
    this.form.get('requests').controls.forEach((control: FormControl) => {
      if (control.get('metaFrontCheckBox').value) {
        control.get('status_id').patchValue(this.massActionsForm.get('status_id').value);
      }
    });
  }

  // syncGuestNames(index: number) {
  //   // @ts-ignore
  //   const requestPeopleCount = Number(this.form.get('requests').controls[index].get('people').value);
  //   // @ts-ignore
  //   const oldNames = this.form.get('requests').controls[index].get('guest_names').value;
  //   const newNames = [];
  //
  //
  //   for (let i = 0; i < requestPeopleCount; i++) {
  //     newNames.push(oldNames[i] || null);
  //   }
  //   // @ts-ignore
  //   this.form.get('requests').controls[index].get('guest_names').patchValue(newNames);
  //
  //   // @ts-ignore
  //   this.form.get('requests').controls[index].get('guest_names').markAsDirty();
  //
  // }

  syncGuestNames(index: number) {
    // @ts-ignore
    const requestLineFormControls = this.form.get('requests').controls[index];

    const requestPeopleCount = Number(
      requestLineFormControls.get('people').value * requestLineFormControls.get('count').value
    );
    const oldNames = requestLineFormControls.get('guest_names').value;
    const newNames = new FormArray([]);

    for (let i = 0; i < requestPeopleCount; i++) {
      newNames.push(new FormControl(oldNames[i] || null));
    }

    requestLineFormControls.setControl('guest_names', newNames);
  }

  duplicateRequest(request: any) {
    const requestCopy = cloneDeep(request);
    requestCopy.get('id').patchValue(null);
    const guestNamesCount = requestCopy.get('guest_names')['controls'].length;
    // @ts-ignore
    this.form.get('requests').push(this.getEmptyRequest(null, guestNamesCount));
    const lastIndex = this.form.get('requests')['controls'].length - 1;
    setTimeout(() => {
      this.form.get('requests')['controls'][lastIndex].patchValue(requestCopy.value);
    });
  }

  toggleCheckAll() {
    this.checkAll = !this.checkAll;
    // @ts-ignore
    this.form.get('requests').controls.forEach((control: FormControl) => {
      control.get('metaFrontCheckBox').patchValue(this.checkAll);
    });
  }

  moveUp(index: number) {
    this.arrayMove(this.form.get('requests')['controls'], index, index - 1);
    this.form.get('requests').updateValueAndValidity();
  }

  moveDown(index: number) {
    this.arrayMove(this.form.get('requests')['controls'], index, index + 1);
    this.form.get('requests').updateValueAndValidity();
  }

  arrayMove(arr: Array<any>, old_index: number, new_index: number) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  private crateMassActionsForm() {
    this.massActionsForm = this.formBuilder.group({
      status_id: [null],
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      requests: new FormArray([]),
    });
  }

  private getEmptyRequest(event: Events = null, guestNamesCount: number = null): FormGroup {
    const guestNames = new FormArray([]);

    if (guestNamesCount) {
      for (let i = 0; i < guestNamesCount; i++) {
        guestNames.push(new FormControl(null));
      }
    }

    return new FormGroup({
      id: new FormControl(null),
      budget: new FormControl(null),
      check_in: new FormControl(event && event.date_from ? event.date_from : null, [Validators.required]),
      check_out: new FormControl(event && event.date_to ? event.date_to : null, [Validators.required]),
      count: new FormControl(null, [Validators.required]),
      hotel_id: new FormControl(null),
      hotel: new FormControl(null),
      hotel_stars: new FormControl(null),
      meal_plan_id: new FormControl(null),
      //   meal_plan: new FormControl(null),
      note: new FormControl(null),
      type_id: new FormControl(null, [Validators.required]),
      //   type: new FormControl(null),
      status_id: new FormControl(null, [Validators.required]),
      //  status: new FormControl(null)

      people: new FormControl(null),
      guest_names: guestNames,
      metaFrontCheckBox: new FormControl(null),
    });
  }

  private crateMany(requests: Array<SaleRequest>) {
    const formArray: FormArray = new FormArray([]);
    requests.forEach((request: SaleRequest) => {
      const guestNamesCount = (request.people || 0) * (request.count || 0);
      formArray.push(this.getEmptyRequest(null, guestNamesCount));
    });
    return formArray;
  }

  private patchForm() {
    if (this.sale && this.sale.requests.length > 0) {
      this.form.setControl('requests', this.crateMany(this.sale.requests));
      setTimeout(() => {
        this.form.patchValue(this.sale);
      });
    } else {
      // @ts-ignore
      // this.form.get('requests').push(this.getEmptyRequest());
      this.form.get('requests').push(this.getEmptyRequest(this.sale && this.sale.event ? this.sale.event : null));
    }
  }
}
