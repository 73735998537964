import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaleGuestNamesFormComponent } from '@app/sales/sales/sale-guest-names-form/sale-guest-names-form.component';

@Injectable({
  providedIn: 'root',
})
export class SaleGuestNamesFormService {
  constructor(private modalService: NgbModal) {}

  public open(saleId: number, finalId: number): Promise<any> {
    const modal = this.modalService.open(SaleGuestNamesFormComponent, {
      size: 'full' as 'lg',
    });
    modal.componentInstance.saleId = saleId;
    modal.componentInstance.finalId = finalId;
    return modal.result;
  }
}
