import {Component, OnInit} from '@angular/core';
import {AccessService} from '@app/core/access/access.service';
import {PaginatedResponse} from '@app/shared/models/paginated-response.model';
import {DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {ApiQuery} from '@app/core/http/api-query';
import {environment} from '@env/environment';
import {UserRoleType} from '@app/settings/users/user-role-type.enum';
import {CustomKendoFilter} from '@app/shared/kendo/custom-kendo-filter';
import {Logger} from '@app/core';
import {Sale} from '@app/sales/sales/sale.model';
import {Router} from '@angular/router';
import {KendoGridComponent} from '@app/shared/kendo/kendo-grid.component';
import {KendoGridService} from '@app/shared/kendo/kendo-grid.service';
import {SaleBonusesService} from '@app/bonuses/sale-bonuses/sale-bonuses.service';
import {Invoice} from '@app/finance/invoices/invoice.model';

const log = new Logger('UserBonusesListComponent');

@Component({
  selector: 'app-user-bonuses-list',
  templateUrl: './user-bonuses-list.component.html',
  styleUrls: ['./user-bonuses-list.component.scss'],
})
export class UserBonusesListComponent extends KendoGridComponent implements OnInit {
  response: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  UserRoleType = UserRoleType;
  loader = false;

  constructor(
    protected kendoGridService: KendoGridService,
    public accessService: AccessService,
    private router: Router,
    private saleBonusesService: SaleBonusesService
  ) {
    super(kendoGridService);
  }

  ngOnInit(): void {
    const storageFilter = CustomKendoFilter.getStateFromStorage('UserBonusesListComponent');
    if (storageFilter) {
      this.state = storageFilter;
    }

    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData', state);
    this.loader = true;
    this.state = state;
    this.apiQuery.setDataSetFilters(state).addIncludes(
      'user',
      'sale_bonus.sale.client.country',
      'sale_bonus.sale.event',
      'sale_bonus.sale.sales_agent',
      'sale_bonus.sale.reservation_agent',
      'sale_bonus.sale.customer_care_agent',
      'sale_bonus.sale.source',
      'sale_bonus.sale.event',
      'sale_bonus.invoices.invoiceable.items.hotel_booking_room.booking.hotel',
      'sale_bonus.invoices.invoiceable.items.hotel_booking_room.booking.contracting_agent',
    );

    this.saleBonusesService.indexRecipients(this.apiQuery).subscribe((response: PaginatedResponse) => {


      response.data.forEach((i: any) => {
        i['meta_front'] = {};
        i.meta_front['contracting_agents'] = [];
        i.meta_front['hotels'] = [];
        i.sale_bonus.invoices.forEach((item: Invoice) => {

          item.invoiceable.items.forEach((finalItem: any) => {
            const userName = finalItem.hotel_booking_room.booking.contracting_agent.fake_first_name + ' ' + finalItem.hotel_booking_room.booking.contracting_agent.fake_last_name;
            const hotelsNames = finalItem.hotel_booking_room.booking.hotel.name;

            if (i.meta_front['contracting_agents'].indexOf(userName) < 0) {
              i.meta_front['contracting_agents'].push(userName);
            }
            if (i.meta_front['hotels'].indexOf(hotelsNames) < 0) {
              i.meta_front['hotels'].push(hotelsNames);
            }
          });

        });
      });
      log.debug('response', response);


      this.response = response;
      this.data = response.getGridData();
      this.loader = false;
    });

    CustomKendoFilter.pushStateInStorage('UserBonusesListComponent', this.state);
  }

  edit(sale: Sale) {
    // this.salesFormService
    //   .open(sale, null, false)
    //   .then(() => {
    //     this.loadData(this.state);
    //   })
    //   .then(
    //     (res) => {
    //       log.debug(res);
    //       this.loadData(this.state);
    //     },
    //     (res) => {
    //     }
    //   );
  }

  clearFilters() {
    this.router.navigate(['/bonuses-users-bonuses']);

    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData(this.state);
    });
  }
}
