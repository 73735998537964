import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { EventGroup } from '@app/events/events-groups/event-group.model';
import { AuthenticationService, Logger } from '@app/core';
import { EventGroupsService } from '@app/events/events-groups/event-groups.service';
import { EventsService } from '@app/events/events/events.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { EventGroupsFormService } from '@app/events/events-groups/event-groups-form.service';
import { EventsFormService } from '@app/events/events/events-form.service';
import { Events } from '@app/events/events/events.model';
import { EventsSeoFormService } from '@app/events/events/events-seo-form.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { Title } from '@angular/platform-browser';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { AccessService } from '@app/core/access/access.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { EventGroupsSeoFormService } from '@app/events/events-groups/event-groups-seo-form.service';

const log = new Logger('EventGroupsViewComponent');

@Component({
  selector: 'app-event-groups-view',
  templateUrl: './event-groups-view.component.html',
  styleUrls: ['./event-groups-view.component.scss'],
})
export class EventGroupsViewComponent extends KendoGridComponent implements OnInit {
  LaravelModelsEnum = LaravelModelsEnum;
  eventGroupId: number;
  eventGroup: EventGroup;
  eventsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  AuthenticationService = AuthenticationService;

  UserRoleType = UserRoleType;

  constructor(
    private route: ActivatedRoute,
    private eventGroupService: EventGroupsService,
    protected kendoGridService: KendoGridService,
    private eventsService: EventsService,
    private eventGroupsFormService: EventGroupsFormService,
    private eventsFormService: EventsFormService,
    private eventSeoFormService: EventsSeoFormService,
    private titleService: Title,
    public accessService: AccessService,
    private eventGroupsSeoFormService: EventGroupsSeoFormService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.eventGroupId = Number(params.get('id'));
        this.loadData();
        this.loadDataTable();
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  loadData(): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes('industry', 'priority', 'repeat', 'venue');

    this.eventGroupService.show(this.eventGroupId, apiQuery).subscribe((eventGroup: EventGroup) => {
      this.eventGroup = eventGroup;
      log.debug('this.eventGroup', this.eventGroup);

      if (this.eventGroup.name) {
        this.titleService.setTitle(this.eventGroup.name);
      }
    });
  }

  loadDataTable(state: DataStateChangeEvent = null) {
    log.debug('loadDataTable');
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes('industry', 'venue.city.country', 'event_group', 'type', 'city.country', 'hotel_bookings_count')
      .addFilter('event_group_id', this.eventGroupId);

    this.eventsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.eventsResponse = response;
      this.data = response.getGridData();
      log.debug('this.data ', response);
    });
  }

  editEventGroup(): void {
    this.eventGroupsFormService.open(this.eventGroup).then(
      () => {
        this.loadData();
      },
      (res) => {
        log.debug(res);
      }
    );
  }

  addEvent() {
    this.eventsFormService.open().then(
      (response) => {
        this.loadDataTable();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  editEvent(event: Events) {
    this.eventsFormService.open(event).then(
      (response) => {
        this.loadDataTable();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  deleteEvent(event: Events) {
    this.eventsService.destroy(event.id).subscribe(() => {
      this.loadDataTable();
    });
  }

  editEventSeo(event: Events): void {
    this.eventSeoFormService.open(event);
  }

  pushToStorage(dataItem: Events) {
    CustomKendoFilter.pushObjectInStorage(dataItem, 'events');
  }

  /**
   *  Отваря SEO формата
   * @param eventGroup Event group
   */
  editSeo(eventGroup: EventGroup): void {
    this.eventGroupsSeoFormService.open(eventGroup);
  }
}
