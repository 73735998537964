<div class="container-fluid" *ngIf="accessService.hasAccess([], ['bonuses.menu'])">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.TITLE' | translate }}
      </h5>
    </div>

    <div class="d-flex align-items-center">
      <div>
        <button type="button" class="btn btn-link" (click)="clearFilters()">
          {{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.CLEAR_FILTERS' | translate }}
        </button>
      </div>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="response?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    [rowClass]="invoiceRowCallback"
    #grid
    (excelExport)="onExcelExport($event, 'Invoices', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.ID' | translate }}"
      filter="numeric"
      [width]="120"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      [sortable]="false"
      field="saleFinal.sale.client_id"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.CLIENTABLE_NAME' | translate }}"
      [hidden]="isHiddenColumn('saleFinal.sale.client_id')"
    >

      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter" [field]="'saleFinal.sale.client_id'"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="['/clients/view']" [queryParams]="{ id: dataItem.invoiceable?.sale?.client_id }"
           target="_blank">
          {{ dataItem.invoiceable?.sale?.client?.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="number"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.INVOICE_NUMBER' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('number')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.formatted_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="date"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.DATE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('date')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.date | date: env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="total_amount"
      filter="numeric"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.TOTAL_AMOUNT' | translate }}"
      [hidden]="isHiddenColumn('total_amount')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.total_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.sale.total_margin_amount"
      filter="numeric"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.TOTAL_MARGIN_AMOUNT' | translate }}"
      [hidden]="isHiddenColumn('saleFinal.sale.total_margin_amount')"
      [sortable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem?.invoiceable?.sale?.total_margin_amount">
          {{ dataItem.invoiceable.sale.total_margin_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </ng-container>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column
      field="saleFinal.sale.sales_agent.fake_first_name"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.SALES_AGENT_AND_SALES_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('invoiceable.sale.sales_agent.fake_first_name')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
          [field]="'saleFinal.sale.sales_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.invoiceable?.sale?.sales_agent?.fake_first_name }}
        {{ dataItem.invoiceable?.sale?.sales_agent?.fake_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.sale.reservation_agent.fake_first_name"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.RESERVATION_AGENT_AND_RESERVATION_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('saleFinal.sale.reservation_agent.fake_first_name')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
          [field]="'saleFinal.sale.reservation_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.invoiceable?.sale?.reservation_agent?.fake_first_name }}
        {{ dataItem.invoiceable?.sale?.reservation_agent?.fake_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.sale.customer_care_agent.fake_first_name"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.CUSTOMER_CARE_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('saleFinal.sale.customer_care_agent.fake_first_name')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.CUSTOMER_CARE_AGENT"
          [field]="'saleFinal.sale.customer_care_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.invoiceable?.sale?.customer_care_agent?.fake_first_name }}
        {{ dataItem.invoiceable?.sale?.customer_care_agent?.fake_last_name }}
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column
      field="saleFinal.items.hotel_booking_room.booking.contracting_agent.fake_first_name"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.CONTRACTING_AGENTS' | translate }}"
      [hidden]="isHiddenColumn('saleFinal.items.hotel_booking_room.booking.contracting_agent.fake_first_name')"
      [sortable]="false"
    >

      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.CONTRACTING_AGENT"
          [field]="'saleFinal.items.hotel_booking_room.booking.contracting_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let contracting_agent of dataItem?.meta_front?.contracting_agents; let k = index">
          {{ contracting_agent }}<span *ngIf="k !== dataItem?.meta_front?.contracting_agents?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.sale.source_id"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.SOURCE' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('saleFinal.sale.source_id')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_source'"
          [filter]="filter"
          [field]="'saleFinal.sale.source_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.invoiceable.sale.source"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.sale.event.name"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('saleFinal.sale.event.name')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select
          [filter]="filter"
          field="saleFinal.sale.event_id">
        </app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.invoiceable?.sale?.event_id }"
           target="_blank">
          {{dataItem?.invoiceable?.sale?.event?.name}}
          - {{ dataItem?.invoiceable?.sale?.event?.date_from | date: 'YYYY'}}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.items.hotel_booking_room.booking.hotel"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.HOTELS' | translate }}"
      [hidden]="isHiddenColumn('saleFinal.items.hotel_booking_room.booking.hotel')"
      [sortable]="false"
    >

      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-hotel-select
          [filter]="filter"
          field="saleFinal.items.hotel_booking_room.booking.hotel_id">
        </app-kendo-hotel-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let hotel of dataItem?.meta_front?.hotels; let k = index">
          {{ hotel }}
          <span *ngIf="k !== dataItem?.meta_front?.hotels?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="payments.date"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.DATE_OF_PTAKEN' | translate }}"
      [hidden]="isHiddenColumn('payments.date')"
      [sortable]="false"
      filter="date"
      format="{0:dd/MM/yyyy}"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let payment of dataItem?.payments; let k = index">
          {{ payment.date | date: env.pipeDateFormat}}<span
          *ngIf="k !== dataItem?.payments?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="bonuses.date"
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.DATE_OF_FINALIZING' | translate }}"
      [hidden]="isHiddenColumn('bonuses.date')"
      [sortable]="false"
      filter="date"
      format="{0:dd/MM/yyyy}"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let bonus of dataItem?.bonuses; let k = index">
          {{ bonus.date | date: env.pipeDateFormat}}<span
          *ngIf="k !== dataItem?.bonuses?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'BONUSES.INVOICES_WITHOUT_BONUSES_LIST.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

        <app-sale-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER_LEAD
              ],
              ['sales.show']
            )
          "
          [link]="['/sales/view']"
          [params]="{ id: dataItem.invoiceable?.sale_id }"
        >
        </app-sale-button>

        <app-edit-button
          *ngIf="accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], ['invoices.show'])"
          (click)="edit(dataItem)"
        >
        </app-edit-button>

      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
