import { Component, OnInit } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { AuthenticationService, Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { SalesService } from '@app/sales/sales/sales.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import * as moment from 'moment';
import { Sale } from '@app/sales/sales/sale.model';
import { SaleRequest } from '@app/sales/sales/sale-request.model';
import { SalesFormService } from '@app/sales/sales/sales-form.service';
import { DataSet } from '@app/shared/kendo/data-set';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { LaravelModelsEnum } from '@app/shared/models/model.model';

const log = new Logger('AssignmentsTableComponent');

@Component({
  selector: 'app-assignments-table',
  templateUrl: './assignments-table.component.html',
  styleUrls: ['./assignments-table.component.scss'],
})
export class AssignmentsTableComponent extends KendoGridComponent implements OnInit {
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  UserRoleType = UserRoleType;
  defaultFilterAD = {
    field: 'assign_date',
    operator: 'lte',
    value: moment().toDate(),
    ignoreCase: true,
  };
  LaravelModelsEnum = LaravelModelsEnum;

  constructor(
    private salesService: SalesService,
    private auth: AuthenticationService,
    private salesFormService: SalesFormService,
    protected kendoGridService: KendoGridService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.state = new DataSet()
      .addFilter('assign_date', moment().toDate(), 'lte')
      .addSort('assign_date', 'desc')
      .addSort('updated_at', 'desc');

    const stateFromStorage = CustomKendoFilter.getStateFromStorage('AssignmentsTableComponent');

    if (stateFromStorage) {
      // Override-ва дата-та с днешната

      if (stateFromStorage.filter && stateFromStorage.filter.filters) {
        let hasMatch = false;
        stateFromStorage.filter.filters.forEach((filter: any) => {
          if (filter.field === 'assign_date') {
            filter.value = moment().toDate();
            filter.operator = 'lte';
            hasMatch = true;
          }
        });

        if (!hasMatch) {
          stateFromStorage.filter.filters.push(this.defaultFilterAD);
        }
      }

      if (!stateFromStorage.filter) {
        stateFromStorage.filter = {
          filters: [this.defaultFilterAD],
          logic: 'and',
        };
      }

      this.state = stateFromStorage;
    }

    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData', state);

    this.state = state;

    this.apiQuery
      .addFilter('assign_to_user_id', this.auth.accessToken.profile.id)
      .setDataSetFilters(this.state)
      .addIncludes(
        'company',
        'event',
        'client.country',
        'client.city',
        'sales_agent',
        'customer_care_agent',
        'reservation_agent',
        'requests.status',
        'channel',
        'campaign',
        'tags',
        'requests.type',
        'arrivalRequest'
      );

    this.salesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((sale: Sale) => {
        sale['meta_front'] = {
          clientTime: null,
          clockInterval: null,
          requestsStatuses: [],
        };

        if (sale.client && sale.client.city && sale.client.city.timezone) {
          clearInterval(sale.meta_front.clockInterval);
          if (sale.client.city.timezone) {
            sale.meta_front.clockInterval = setInterval(() => {
              sale.meta_front.clientTime = moment().tz(sale.client.city.timezone).format('HH:mm:ss');
            }, 1000);
          }
        } else {
          clearInterval(sale.meta_front.clockInterval);
          sale.meta_front.clockInterval = 'false';
        }

        sale.requests.forEach((request: SaleRequest) => {
          if (sale.meta_front.requestsStatuses.indexOf(request.status.value) === -1) {
            sale.meta_front.requestsStatuses.push(request.status.value);
          }
        });
      });

      this.requestsResponse = response;
      this.data = response.getGridData();
    });

    CustomKendoFilter.pushStateInStorage('AssignmentsTableComponent', this.state);
  }

  edit(sale: Sale) {
    this.salesFormService
      .open(sale, null, false)
      .then(() => {
        this.loadData(this.state);
      })
      .then(
        (res) => {
          log.debug(res);
          this.loadData(this.state);
        },
        (res) => {}
      );
  }

  delete(sale: Sale) {
    this.salesService.destroy(sale.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  clearFilters() {
    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData(
        new DataSet()
          .addFilter('assign_date', moment().toDate(), 'lte')
          .addSort('assign_date', 'desc')
          .addSort('updated_at', 'desc')
      );
    });
  }
}
