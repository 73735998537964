<div class="kendo-select-filter" [formGroup]="form">
  <ng-select
    [items]="data"
    bindLabel="name"
    [formControlName]="'tags'"
    appendTo="body"
    (open)="loadData()"
    [loading]="loader"
    (change)="onSelect($event)"
  >
  </ng-select>
</div>
