import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { Logger } from '@app/core';
import { SalesFinalService } from '@app/sales/sales/sales-final.service';
import { environment } from '@env/environment';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { AccessService } from '@app/core/access/access.service';

const log = new Logger('InterCompanySalesListComponent');

@Component({
  selector: 'app-inter-company-sales',
  templateUrl: './inter-company-sales-list.component.html',
  styleUrls: ['./inter-company-sales-list.component.scss'],
})
export class InterCompanySalesListComponent extends KendoGridComponent implements OnInit {
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;

  constructor(
    private salesFinalsService: SalesFinalService,
    protected kendoGridService: KendoGridService,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.state = CustomKendoFilter.getStateFromStorage('InterCompanySalesListComponent');
    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData');
    this.state = state;
    this.apiQuery.setDataSetFilters(state).addIncludes('sale_final.sale.company', 'hotel_booking_room.booking.company');

    this.salesFinalsService.itemsIndex(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.requestsResponse = response;
      this.data = response.getGridData();
    });
    CustomKendoFilter.pushStateInStorage('InterCompanySalesListComponent', this.state);
  }

  clearFilters() {
    this.apiQuery = new ApiQuery();
    this.loadData();
  }
}
