import { Model } from '@app/shared/models/model.model';
import { Type } from '@app/settings/types/types.model';

export class InvoiceItem extends Model {
  id: number;
  name: string;
  description: string;
  quantity: number;
  unit_price: number;
  order_index: number;
  account_id: number;
  account?: Type;

  apply_to_net_amount: boolean;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.account) {
      this.account = new Type().deserialize(input.account);
    }

    return this;
  }
}
