import { Component, Input, OnInit } from '@angular/core';

import { Logger } from '@app/core';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { RemindersFormService } from '@app/reminders/reminders/reminders-form.service';
import { Reminder } from '@app/reminders/reminders/reminder.model';
import { Router } from '@angular/router';
import { DataSet } from '@app/shared/kendo/data-set';

const log = new Logger('ReminderButtonComponent');

@Component({
  selector: 'app-reminder-button',
  templateUrl: './reminder-button.component.html',
  styleUrls: ['./reminder-button.component.scss'],
})
export class ReminderButtonComponent implements OnInit {
  @Input() reminderableType: LaravelModelsEnum;
  @Input() reminderableId: number;
  @Input() modalType: string;

  constructor(private remindersModalService: RemindersFormService, private router: Router) {}

  ngOnInit() {
    if (!this.reminderableType && !this.reminderableId) {
      log.warn('No data passed to component!');
    }
  }

  openReminder() {
    const reminder = new Reminder().deserialize({
      reminderable_id: this.reminderableId,
      reminderable_type: this.reminderableType,
    });

    this.remindersModalService.open(reminder, this.modalType);
  }

  openReminders() {
    const reminder = new Reminder().deserialize({
      reminderable_type: this.reminderableType,
    });

    this.router.navigate(['/reminders'], {
      queryParams: {
        dataSet: new DataSet()
          .addFilter('reminderable_id', this.reminderableId)
          .addFilter('reminderable_type', reminder.cleanReminderableType, 'contains')
          .getStringifyDataSet(),
      },
    });
  }
}
