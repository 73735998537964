import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '@env/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Events } from '@app/events/events/events.model';
import { BaseFilterCellComponent, DataStateChangeEvent, FilterService } from '@progress/kendo-angular-grid';
import { Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { EventsService } from '@app/events/events/events.service';

const log = new Logger('KendoEventsSelectComponent');

@Component({
  selector: 'app-kendo-events-select',
  templateUrl: './kendo-events-select.component.html',
  styleUrls: ['./kendo-events-select.component.scss'],
})
export class KendoEventsSelectComponent extends BaseFilterCellComponent implements OnInit, OnChanges {
  form: FormGroup;
  env = environment;
  @Input() filter: CompositeFilterDescriptor;
  @Input() field = 'event_id';
  @Input() data: Array<Events>;
  apiQuery: ApiQuery = new ApiQuery();
  fullData: Observable<Events[]>;
  lastResponse: any = null;
  loader: boolean;
  input$ = new Subject<string>();

  constructor(private formBuilder: FormBuilder, filterService: FilterService, private eventService: EventsService) {
    super(filterService);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      value: [null],
    });

    const preSelectedEvents: Array<number> = [];
    if (this.data) {
      log.debug('this.data', this.data);
      this.data.forEach((event: Events) => {
        preSelectedEvents.push(event.id);
      });

      // @todo: прави един излишен request
      setTimeout(() => {
        this.form.get('value').patchValue(preSelectedEvents);
        this.onSelect(this.data);
        this.fullData = of(this.data);
      });
    }
    this.eventSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Изчиства формата
    // Използва се, за да чисти филтрите, когато се сетне null state на таблицата
    if (this.form && changes && changes.filter && !changes.filter.currentValue) {
      this.form.reset();
    }
  }

  onSelect(event: any) {
    this.filter = this.removeFilter(this.field);

    const filters: any = {
      logic: 'or',
      filters: [],
    };

    if (event.length > 0) {
      event.forEach((ev: any) => {
        filters.filters.push({
          field: this.field,
          operator: 'eq',
          value: ev.id,
        });
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: [],
    };

    root.filters.push(filters);
    this.filterService.filter(root);
  }

  eventSearch() {
    this.apiQuery.addFilter('active', true);

    // Премахва от request-a вече подадените
    if (this.data) {
      const filter: DataStateChangeEvent = {
        filter: {
          filters: [],
          logic: 'and',
        },
        group: undefined,
        skip: 0,
        sort: undefined,
        take: 0,
      };
      this.data.forEach((event: Events) => {
        filter.filter.filters.push({ field: 'id', operator: 'neq', value: event.id });
      });
      this.apiQuery.setDataSetFilters(filter);
    }

    this.input$
      .pipe(
        tap(() => (this.loader = true)),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((term: any) => {
          // За да не прави излишен request след като се избере нещо
          if (!term) {
            return of(this.lastResponse);
          }

          return this.eventService
            .index(
              this.apiQuery
                .addFilters({
                  name: term,
                })
                .addIncludes('venue.city.country')
            )
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.loader = false))
            );
        })
      )
      .subscribe((event: PaginatedResponse) => {
        this.fullData = of([...this.data, ...event.data]);
        this.lastResponse = event;
        this.loader = false;
      });
  }
}
