import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Logger } from '@app/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  GroupDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { environment } from '@env/environment';
import * as moment from 'moment';

const log = new Logger('DataSet');

export class DataSet implements DataStateChangeEvent {
  skip = 0;
  take = 15;
  sort: Array<SortDescriptor> = [];
  group: Array<GroupDescriptor>;
  filter: CompositeFilterDescriptor = {
    filters: [],
    logic: 'and',
  };

  public static decodeFromJson(dataSetJsonSting: string) {
    const dataSet = JSON.parse(dataSetJsonSting);

    dataSet.filter.filters.map((filter: FilterDescriptor) => {
      if (moment(filter.value, environment.apiPipeDateFormat, true).isValid()) {
        filter.value = moment(filter.value, environment.apiPipeDateFormat).toDate();
      }
      return filter;
    });

    log.debug('dataSet', dataSet);
    return dataSet;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  /**
   * Set amount to skip
   * @param number Amount to skip
   */
  setSkip(number: number): this {
    this.skip = number;
    return this;
  }

  /**
   * Page size
   * @param number Page size number
   */
  setTake(number: number = environment.kendoGridPageSizes[0]): this {
    this.take = number;
    return this;
  }

  /**
   * Set dataset filters
   * @param field string
   * @param operator string
   * @param value any
   * @param ignoreCase boolean
   * @param allowDuplicate boolean
   */
  addFilter(
    field: string,
    value: any,
    operator: string = 'eq',
    ignoreCase: boolean = true,
    allowDuplicate: boolean = false
  ): this {
    let filterFound = false,
      filterFoundIndex = -1;

    // предотвратява дублирането на филтър
    this.filter.filters.forEach((val: any, ind: number) => {
      if (val.field === field) {
        filterFound = true;
        filterFoundIndex = ind;
      }
    });

    if (filterFound && !allowDuplicate) {
      this.filter.filters[filterFoundIndex]['value'] = value;
      this.filter.filters[filterFoundIndex]['operator'] = operator;
      this.filter.filters[filterFoundIndex]['ignoreCase'] = ignoreCase;
    } else {
      this.filter.filters.push({
        field: field,
        operator: operator,
        value: value,
        ignoreCase: ignoreCase,
      });
    }

    return this;
  }

  // backup за всеки случай
  // addFilter(field: string, value: any, operator: string = 'eq', ignoreCase: boolean = true): this {
  //   this.filter.filters.push({
  //     field: field,
  //     operator: operator,
  //     value: value,
  //     ignoreCase: ignoreCase
  //   });
  //
  //   return this;
  // }

  /**
   * Return dataset as string
   */
  getStringifyDataSet(): string {
    return JSON.stringify(this);
  }

  /**
   * Set filter logic
   * @param logic 'or' | 'and'
   */
  setFilterLogic(logic: 'or' | 'and'): this {
    this.filter.logic = logic;
    return this;
  }

  addSort(field: string, dir: 'asc' | 'desc' = 'asc') {
    this.sort.push({ field: field, dir: dir });
    return this;
  }
}
