import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplate } from '@app/settings/templates/email-template.model';
import { TemplatesFormComponent } from '@app/settings/templates/templates-form/templates-form.component';

@Injectable({
  providedIn: 'root',
})
export class TemplatesFormService {
  constructor(private modalService: NgbModal) {}

  public open(template: null | EmailTemplate = null): Promise<any> {
    const modal = this.modalService.open(TemplatesFormComponent, { size: 'full' as 'lg' });
    modal.componentInstance.template = template;
    return modal.result;
  }
}
