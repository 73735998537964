<div [formGroup]="parentFormGroup">
  <div class="modal-section-title mb-2 mt-3">
    {{ 'SMTP_SETTINGS.SMTP_SETTINGS' | translate }}
  </div>

  <div formArrayName="smtp_settings">
    <div class="divider" *ngIf="parentFormGroup.get('smtp_settings')?.controls.length < 1"></div>
    <div
      *ngFor="let smtpSettings of parentFormGroup.get('smtp_settings')?.controls; let k = index"
      class="single-smtp-setting mb-2"
    >
      <button type="button" class="remove-smtp-setting" (click)="removeSmtpSetting(k)">
        <i class="fal fa-times"></i>
      </button>
      <div [formGroupName]="k">
        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.HOST' | translate }}</label>
              <input type="text" class="form-control" formControlName="host" />
            </div>
          </div>

          <div class="col-sm-1">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.PORT' | translate }}</label>
              <input type="text" class="form-control" formControlName="port" />
            </div>
          </div>

          <div class="col-sm-2">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.USERNAME' | translate }}</label>
              <input type="text" class="form-control" formControlName="username" />
            </div>
          </div>

          <div class="col-sm-2">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.PASSWORD' | translate }}</label>
              <input type="password" class="form-control" formControlName="password" />
            </div>
          </div>

          <div class="col-sm-1">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.ENCRYPTION' | translate }}</label>
              <input type="text" class="form-control" formControlName="encryption" />
            </div>
          </div>

          <div class="col-sm-2">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.FROM_EMAIL' | translate }}</label>
              <input type="text" class="form-control" formControlName="from_email" />
            </div>
          </div>

          <div class="col-sm-2">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.FROM_NAME' | translate }}</label>
              <input type="text" class="form-control" formControlName="from_name" />
            </div>
          </div>

          <div class="col-sm-2">
            <app-company-select [parentFormGroup]="smtpSettings"></app-company-select>
          </div>

          <div class="col-sm-2">
            <div class="form-group">
              <label>{{ 'SMTP_SETTINGS.IMAP_PORT' | translate }}</label>
              <input type="text" class="form-control" formControlName="imap_port" />
            </div>
          </div>

          <div class="col-sm-12">
            <label>{{ 'SMTP_SETTINGS.SIGNATURE' | translate }}</label>
            <editor
              formControlName="signature"
              [init]="{
                plugins: 'table link autoresize image paste',
                base_url: '/assets/tinymce',
                suffix: '.min',
                paste_data_images: true,
                file_picker_callback: imageUpload
              }"
            ></editor>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-xs btn-primary float-right mb-3" (click)="addSmtpSetting()">
    {{ 'SMTP_SETTINGS.ADD_SMTP_SETTING' | translate }}
  </button>
  <div class="clearfix"></div>
</div>
