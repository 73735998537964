import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TagsService } from '@app/shared/components/tags-select/tags.service';
import { ApiQuery } from '@app/core/http/api-query';
import { Logger } from '@app/core';
import { LaravelModelsEnum } from '@app/shared/models/model.model';

const log = new Logger('TagsSelectComponent');

@Component({
  selector: 'app-tags-select',
  templateUrl: './tags-select.component.html',
  styleUrls: ['./tags-select.component.scss'],
})
export class TagsSelectComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() model: LaravelModelsEnum;
  data: any;

  constructor(private tagsService: TagsService) {}

  ngOnInit() {
    if (this.model) {
      const query = new ApiQuery().setLimit(99999).addFilters({ model: this.model });
      this.tagsService.index(query).subscribe((tags: any) => {
        log.debug(tags);
        this.data = tags;
      });
    } else {
      throw new Error('No model provided.');
    }
  }

  addTagFn(name: string) {
    return name;
  }
}
