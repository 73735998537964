import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinanceRoutingModule } from './finance-routing.module';
import { SharedModule } from '@app/shared';
import { InvoicesFormComponent } from './invoices/invoices-form/invoices-form.component';
import { InvoicesListComponent } from './invoices/invoices-list/invoices-list.component';
import { RowFilterModule } from '@progress/kendo-angular-grid';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { InvoicesFormService } from '@app/finance/invoices/invoices-form.service';
import { PaymentsFormComponent } from './payments/payments-form/payments-form.component';
import { PaymentsListComponent } from '@app/finance/payments/payments-list/payments-list.component';
import { InterCompanySalesListComponent } from './inter-company-sales/inter-company-sales-list.component';
import { HotelsBookingsPaymentsListComponent } from './hotel-bookings-payments/hotels-bookings-payments-list/hotels-bookings-payments-list.component';
import { ClientInvoicesListComponent } from './invoices/client-invoices-list/client-invoices-list.component';

@NgModule({
  declarations: [
    InvoicesFormComponent,
    InvoicesListComponent,
    PaymentsFormComponent,
    PaymentsListComponent,
    InterCompanySalesListComponent,
    HotelsBookingsPaymentsListComponent,
    ClientInvoicesListComponent,
  ],
  imports: [CommonModule, FinanceRoutingModule, SharedModule, RowFilterModule],
  providers: [InvoicesService, InvoicesFormService],
})
export class FinanceModule {}
