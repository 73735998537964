import { LaravelModelsEnum, Model } from '@app/shared/models/model.model';

export class WebOffersItem extends Model {
  id: number;
  web_offerable_item_id: number;
  web_offerable_item_type: LaravelModelsEnum;
  web_offerable_item?: any;
  order_index: number;
  title: number;
}
