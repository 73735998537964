import { Calendar } from '@fullcalendar/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { ApiQuery } from '@app/core/http/api-query';
import { TimeTrackingService } from '@app/reports/time-tracking/time-tracking.service';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { Logger } from '@app/core';
import { UsersService } from '@app/settings/users/users.service';
import { User } from '@app/settings/users/user.model';
import { TimeTracking } from '@app/reports/time-tracking/time-tracking.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccessService } from '@app/core/access/access.service';

const log = new Logger('TimeTrackingComponent');
const moment = require('moment-business-days');

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.scss'],
})
export class TimeTrackingComponent implements OnInit {
  form: FormGroup;
  data: Array<TimeTracking>;
  loading = false;
  defaultDate: Date = null;
  usersTimeSum: Array<any> = [];
  calendarOptions: CalendarOptions = {
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    plugins: [resourceTimelinePlugin],
    initialView: 'resourceTimelineDay',
    firstDay: 1,
    resourceAreaWidth: '350px',
    height: 'calc(100vh - 130px)',
    lazyFetching: false,
    slotMinTime: '09:00',
    slotMaxTime: '18:00',
    businessHours: {
      daysOfWeek: [1, 2, 3, 4, 5],
      startTime: '00:00',
      endTime: '24:00',
    },
    views: {
      resourceTimelineMonth: {
        slotLabelFormat: [
          {
            weekday: 'short',
            day: 'numeric',
          },
        ],
      },
    },
    slotLabelFormat: [
      {
        weekday: 'short',
        day: 'numeric',
      },
      {
        hour12: false,
        hour: 'numeric',
      },
    ],
    resourceAreaColumns: [
      {
        field: 'title',
        headerContent: 'User',
      },
      {
        field: 'workHours',
        headerContent: 'Work',
        width: 70,
      },
      {
        field: 'breakHours',
        headerContent: 'Break',
        width: 70,
      },
    ],
    headerToolbar: {
      left: 'today prev,next',
      center: 'title',
      right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
    },
    loading: (loading: boolean) => {
      this.loading = loading;
    },
    events: (fetchInfo, successCallback, failureCallback) => {
      this.defaultDate = fetchInfo.start;
      this.form.get('date').patchValue(fetchInfo.start);

      const filter: any = {
        filter: {
          filters: [
            { field: 'created_at', operator: 'gte', value: fetchInfo.start },
            { field: 'created_at', operator: 'lt', value: fetchInfo.end },
          ],
          logic: 'and',
        },
        group: undefined,
        skip: 0,
        sort: undefined,
        take: 0,
        limit: 99999999,
      };

      const query = new ApiQuery().setDataSetFilters(filter);

      if (this.form.get('user_id').value) {
        query.addFilter('user_id', this.form.get('user_id').value);
      }

      this.timeTrackingService.index(query).subscribe((response: PaginatedResponse) => {
        let earliestHour = 23;
        let latestHour = 0;
        this.usersTimeSum = [];

        const data = response.data.map((timeTracking: TimeTracking) => {
          const startMoment = moment(timeTracking.created_at.date);
          const endMoment = moment(timeTracking.created_at.date).add(timeTracking.minutes, 'minutes');

          if (Number(startMoment.format('HH')) < earliestHour) {
            earliestHour = Number(startMoment.format('HH'));
          }

          if (Number(endMoment.format('HH')) > latestHour) {
            latestHour = Number(endMoment.format('HH'));
          }

          this.usersTimeSum[timeTracking.user_id] =
            (this.usersTimeSum[timeTracking.user_id] || 0) + Number(timeTracking.minutes);

          return {
            id: timeTracking.id,
            resourceId: timeTracking.user_id,
            extendedProps: {
              minutes: timeTracking.minutes,
              resourceId: timeTracking.user_id,
            },
            title: timeTracking.minutes,
            start: startMoment.toDate(),
            end: endMoment.toDate(),
          };
        }) as any;
        successCallback(data);

        if (!this.users) {
          this.loadUsersData();
        }
        this.updateUsersWorkHours();

        this.calendarComponent.getApi().setOption('slotMinTime', `${earliestHour}:00`);
        this.calendarComponent.getApi().setOption('slotMaxTime', `${latestHour + 1}:00`);
      });
    },
  };
  // настройките за datepicker-a
  en = {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear',
    weekHeader: 'Wk',
  };
  users: Array<User>;

  @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;

  constructor(
    private timeTrackingService: TimeTrackingService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    public accessService: AccessService
  ) {
    const name = Calendar.name;
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      user_id: [null],
      user: [null],
      date: [null],
    });
  }

  loadUsersData() {
    this.loading = true;
    const query = new ApiQuery().setLimit(9999999).addFilter('active', true);
    this.usersService.index(query).subscribe((response: PaginatedResponse) => {
      this.users = response.data;
      this.calendarOptions.resources = this.users.map((user: User) => {
        return {
          id: user.id,
          title: user.names,
          extendedProps: {
            workingMinutesPerDay: (user.working_hours_per_week / 5) * 60,
          },
        };
      }) as any;
      this.updateUsersWorkHours();
      this.loading = false;
    });
  }

  updateUsersWorkHours() {
    log.debug('updateUsersWorkHours');
    this.loading = true;
    setTimeout(() => {
      this.calendarComponent
        .getApi()
        .getTopLevelResources()
        .forEach((res: any) => {
          const workTimeFormatted = this.convertMinutesToHours(this.usersTimeSum[Number(res.id)]);
          res.setExtendedProp('workHours', workTimeFormatted);

          const currentDaysRange = moment(
            this.calendarComponent.getApi().getCurrentData().dateProfile.activeRange.end
          ).businessDiff(moment(this.calendarComponent.getApi().getCurrentData().dateProfile.activeRange.start));

          const breakHours =
            res.extendedProps.workingMinutesPerDay * currentDaysRange - this.usersTimeSum[Number(res.id)];
          const breakHoursFormatted = this.convertMinutesToHours(breakHours);
          res.setExtendedProp('breakHours', breakHoursFormatted);
          this.loading = false;
        });
    });
  }

  convertMinutesToHours(min: number) {
    if (min) {
      const num: number = min;
      const hours = num / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      return rhours + 'h ' + rminutes + 'm';
    }
    return null;
  }

  userSelect() {
    if (this.form.get('user_id').value) {
      this.users.forEach((user: User) => {
        if (user.id === this.form.get('user_id').value) {
          this.calendarOptions.resources = [
            {
              id: user.id,
              title: user.names,
              extendedProps: {
                workingMinutesPerDay: (user.working_hours_per_week / 5) * 60,
              },
            },
          ] as any;
        }
      });
    } else {
      this.calendarOptions.resources = this.users.map((user: User) => {
        return {
          id: user.id,
          title: user.names,
          extendedProps: {
            workingMinutesPerDay: (user.working_hours_per_week / 5) * 60,
          },
        };
      }) as any;
    }
    this.calendarComponent.getApi().refetchEvents();
  }

  selectDate() {
    if (this.form.get('date').value) {
      this.calendarComponent.getApi().gotoDate(moment(this.form.get('date').value).toDate());
    }
  }
}
