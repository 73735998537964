import { Component, OnInit } from '@angular/core';
import { AuthenticationService, Logger } from '@app/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { HotelBookingsFormService } from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { DataSet } from '@app/shared/kendo/data-set';
import * as moment from 'moment';

const log = new Logger('BookingsAssignmentsTableComponent');

@Component({
  selector: 'app-bookings-assignments-table',
  templateUrl: './bookings-assignments-table.component.html',
  styleUrls: ['./bookings-assignments-table.component.scss'],
})
export class BookingsAssignmentsTableComponent extends KendoGridComponent implements OnInit {
  hotelBookingsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  userAccess = false;
  defaultFilterAD = {
    field: 'assign_date',
    operator: 'lte',
    value: moment().toDate(),
    ignoreCase: true,
  };

  constructor(
    private hotelBookingService: HotelBookingsService,
    private hotelBookingsFormService: HotelBookingsFormService,
    protected kendoGridService: KendoGridService,
    private auth: AuthenticationService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
      if (
        role.id === UserRoleType.RESERVATION_AGENT ||
        role.id === UserRoleType.CONTRACTING_AGENT ||
        role.id === UserRoleType.RESERVATION_MANAGER ||
        role.id === UserRoleType.CONTRACTING_MANAGER
      ) {
        this.userAccess = true;
      }
    });

    if (this.userAccess) {
      this.state = new DataSet().addFilter('assign_date', moment().toDate(), 'lte').addSort('assign_date', 'desc');

      const stateFromStorage = CustomKendoFilter.getStateFromStorage('BookingsAssignmentsTableComponent');

      if (stateFromStorage) {
        // Override-ва дата-та с днешната

        if (stateFromStorage.filter && stateFromStorage.filter.filters) {
          let hasMatch = false;
          stateFromStorage.filter.filters.forEach((filter: any) => {
            if (filter.field === 'assign_date') {
              filter.value = moment().toDate();
              filter.operator = 'lte';
              hasMatch = true;
            }
          });

          if (!hasMatch) {
            stateFromStorage.filter.filters.push(this.defaultFilterAD);
          }
        }

        if (!stateFromStorage.filter) {
          stateFromStorage.filter = {
            filters: [this.defaultFilterAD],
            logic: 'and',
          };
        }

        this.state = stateFromStorage;
      }

      this.loadData(this.state);
    }
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData');
    this.state = state;
    this.apiQuery
      .addFilter('assign_to_user_id', this.auth.accessToken.profile.id)
      .setDataSetFilters(state)
      .addIncludes(
        'events',
        'rooms.type',
        'hotel',
        'supplier',
        'company',
        'property',
        'available_nights_count',
        'cancellations_count',
        'breakfast_price_per_night_currency',
        'tags',
        'contracting_agent',
        'reservation_agent'
      );

    this.hotelBookingService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.hotelBookingsResponse = response;
      this.data = response.getGridData();
    });

    CustomKendoFilter.pushStateInStorage('BookingsAssignmentsTableComponent', this.state);
  }

  edit(hotelBooking: HotelBooking) {
    this.hotelBookingsFormService.open(hotelBooking).then(
      (response) => {
        this.loadData(this.state);
      },
      (err) => {
        // this.loadData(this.state);
        this.loadData(this.state);
      }
    );
  }

  clearFilters() {
    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData(new DataSet().addFilter('assign_date', moment().toDate(), 'lte').addSort('assign_date', 'desc'));
    });
  }
}
