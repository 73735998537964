import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';
import { Model } from '@app/shared/models/model.model';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { SaleOfferItem } from '@app/sales/sales/sale-offer-item.model';

@Injectable({
  providedIn: 'root',
})
export class SalesOffersService {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/sales/offers', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((request: any) => {
          return new SaleOffer().deserialize(request);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  itemsIndex(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/sales/offers/items', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((request: any) => {
          return new SaleOfferItem().deserialize(request);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  store(saleId: number, offer: SaleOffer): Observable<SaleOffer> {
    return this.http.post('v1/sales/' + saleId + '/offers ', offer).pipe(
      map((response: HttpResponse) => {
        return new SaleOffer().deserialize(response.data);
      })
    );
  }

  update(saleId: number, offer: SaleOffer): Observable<SaleOffer> {
    return this.http.put('v1/sales/' + saleId + '/offers/' + offer.id, offer).pipe(
      map((response: HttpResponse) => {
        return new SaleOffer().deserialize(response.data);
      })
    );
  }

  show(saleId: number, offerId: number, query: ApiQuery = new ApiQuery()): Observable<Model> {
    return this.http.get('v1/sales/' + saleId + '/offers/' + offerId, query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return new SaleOffer().deserialize(response.data);
      })
    );
  }

  order(saleId: number, order: object): Observable<any> {
    return this.http.post('v1/sales/' + saleId + '/offers/order', order).pipe(
      map((response: HttpResponse) => {
        return response;
      })
    );
  }

  destroy(saleId: number, offerId: number): Observable<any> {
    return this.http.delete('v1/sales/' + saleId + '/offers/' + offerId);
  }
}
