import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AuthenticationService, Logger } from '@app/core';
import { Reminder } from '@app/reminders/reminders/reminder.model';
import { ApiQuery } from '@app/core/http/api-query';
import { RemindersService } from '@app/reminders/reminders/reminders.service';
import { environment } from '@env/environment';
import { User } from '@app/settings/users/user.model';

const log = new Logger('RemindersFormComponent');

@Component({
  selector: 'app-reminders-form',
  templateUrl: './reminders-form.component.html',
  styleUrls: ['./reminders-form.component.scss'],
})
export class RemindersFormComponent implements OnInit {
  @Input() reminder: Reminder;
  creator: User;
  form: FormGroup;
  env = environment;
  minDate = moment().format();
  defaultDate = moment().format();

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private reminderService: RemindersService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    log.debug('init', this.reminder);

    this.createForm();
    this.patchForm();
  }

  /**
   * Submit form
   */
  save() {
    if (this.reminder && this.reminder.id) {
      const reminder: Reminder = this.reminder.deserialize(this.form.value);
      this.reminderService.update(reminder).subscribe((res: Reminder) => this.modal.close('Save click'));
    } else {
      const reminder: Reminder = new Reminder().deserialize(this.form.value);
      this.reminderService.store(reminder).subscribe((res) => this.modal.close(res));
    }
  }

  /**
   * Set date to input
   *
   * @param value value
   * @param periodType periodType
   * @param popover popover
   */
  fillReminderDate(value: any, periodType: string, popover: any): void {
    log.debug('fill', value, periodType);

    // @ts-ignore
    this.form
      .get('remind_at')
      .setValue(moment().add(value, periodType).set('seconds', 0).format(this.env.apiPipeDateTimeFormat));
    popover.close();
  }

  /**
   * Test
   *
   * @param form any
   */
  test(form: any) {
    log.debug(form);
  }

  complete() {
    this.form.get('completed').patchValue(true);
    this.form.get('completed').updateValueAndValidity();
    this.save();
  }

  inComplete() {
    this.form.get('completed').patchValue(false);
    this.form.get('completed').updateValueAndValidity();
    this.save();
  }

  private createForm(): void {
    //  rooms.push(this.getEmptyRoom());
    this.form = this.formBuilder.group({
      id: [null],
      user_id: [null, [Validators.required]],
      user: [null],
      note: [null, [Validators.required]],
      remind_at: [null, [Validators.required]],
      reminderable_id: [null, [Validators.required]],
      reminderable_type: [null, [Validators.required]],
      clean_reminderable_type: [null],
      completed: [false],
    });
  }

  private patchForm() {
    log.debug('patchForm?', !this.reminder);
    if (this.reminder && this.reminder.id) {
      const apiQuery: ApiQuery = new ApiQuery().addIncludes('user', 'creator.avatar', 'reminderable');
      this.reminderService.show(this.reminder.id, apiQuery).subscribe((reminder: Reminder) => {
        // Това се налага, за да могат да се викат get-ерите от модела... Ако има друг начин да се махне
        // reminder.remind_at = moment(reminder.remind_at).format(this.env.apiPipeDateFormat);
        this.creator = reminder.creator;
        reminder['clean_reminderable_type'] = reminder.cleanReminderableType;
        this.form.patchValue(reminder);
      });
    } else if (this.reminder && !this.reminder.id) {
      this.form.get('reminderable_type').patchValue(this.reminder.reminderable_type);
      this.form.get('reminderable_id').patchValue(this.reminder.reminderable_id);
      this.pathDefaultUser();
    }
  }

  /**
   * Попълва user с логнатия user
   */
  private pathDefaultUser() {
    this.authenticationService.loadProfile().subscribe((profile: any) => {
      this.form.get('user_id').patchValue(profile.profile.id);
      this.form.get('user').patchValue(profile.profile);
    });
  }
}
