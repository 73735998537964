import { Component, Input, OnInit } from '@angular/core';
import { EmailTemplateSenderService } from '@app/shared/components/email-template-sender/email-template-sender.service';
import { Logger } from '@app/core';
import { Templates } from '@app/settings/types/templates.enum';

const log = new Logger('EmailTemplatesButtonComponent');

@Component({
  selector: 'app-email-templates-button',
  templateUrl: './email-templates-button.component.html',
  styleUrls: ['./email-templates-button.component.scss'],
})
export class EmailTemplatesButtonComponent implements OnInit {
  @Input() saleId: number;
  templatesTypes = Templates;

  constructor(private emailTemplateSenderService: EmailTemplateSenderService) {}

  ngOnInit() {
    if (!this.saleId) {
      log.error('saleId not provided!!!');
    }
  }

  openEmailTemplateSender(typeId: Templates) {
    this.emailTemplateSenderService.open(this.saleId, typeId).then(
      () => {},
      () => {}
    );
  }
}
