import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Logger} from '@app/core';
import {SaleGuestNamesService} from '@app/sales/sales/sale-guest-names.service';
import * as moment from 'moment';
import {environment} from '@env/environment';

const log = new Logger('SaleGuestNamesFormComponent');

@Component({
  selector: 'app-sale-guest-names-form',
  templateUrl: './sale-guest-names-form.component.html',
  styleUrls: ['./sale-guest-names-form.component.scss'],
})
export class SaleGuestNamesFormComponent implements OnInit {
  items: Array<any>;
  @Input() saleId: number = null;
  @Input() finalId: number = null;
  env = environment;

  constructor(public modal: NgbActiveModal, private saleGuestNamesService: SaleGuestNamesService) {
  }

  ngOnInit() {
    log.debug(this.saleId);
    log.debug(this.finalId);
    this.loadData();
  }

  save() {
    this.saleGuestNamesService.store(this.saleId, this.finalId, this.formatPost()).subscribe((res: any) => {
      return this.modal.close(res);
    });
  }

  test() {
    log.debug(this.items);
  }

  getNightsCount(date1: string | Date, date2: string | Date) {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private formatPost() {
    return {
      items: this.items.map((item: any) => {
        return {
          guest_names: item.guest_names.map((n: any) => n.name),
          hotel_booking_room_id: item.hotel_booking_room_id,
          sale_final_item_id: item.sale_final_item_id,
          hotel_booking_room_confirmation_number: item.hotel_booking_room_confirmation_number,
        };
      }),
    };
  }

  private loadData(): void {
    this.saleGuestNamesService.index(this.saleId, this.finalId).subscribe((finalGuestNames: any) => {
      const roomIds = {};
      this.items = finalGuestNames.map((item: any) => {
        const guestNames = new Array();
        for (let i = 0; i < Number(item.sale_final_item.sale_offer_item.people); i++) {
          guestNames.push({name: item.guest_names && item.guest_names[i] ? item.guest_names[i] : null});
        }


        let isRoomIdDisabled = false;
        if (roomIds[item.room.id]) {
          isRoomIdDisabled = true;
        }

        roomIds[item.room.id] = true;

        return {
          guest_names: guestNames,
          booking: item.booking,
          room: item.room,
          hotel_booking_room_id: item.room.id,
          // позволява да се пише само един hotel_booking_room_confirmation_number за стая
          hotel_booking_room_id_disabled: isRoomIdDisabled,
          sale_final_item_id: item.sale_final_item.id,
          sale_final_item: item.sale_final_item,
          hotel_booking_room_confirmation_number: item.hotel_booking_room_confirmation_number,
        };
      });
      log.debug('this.items', this.items);
    });
  }
}
