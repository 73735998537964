import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileUpload } from 'primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() newControlName: string;

  /**
   * Set a specific placeholder
   * Placeholder keys: ['user']
   */
  @Input() placeholder: string = null;

  @ViewChild('fileUpload', { static: true }) fileUpload: FileUpload;

  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  /**
   * Add image
   *
   * @param event any
   */
  addImage(event: any): void {
    const reader = new FileReader();
    const [file] = event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      const tempObj = {};
      tempObj[this.newControlName] = reader.result;
      this.form.patchValue(tempObj);
      this.fileUpload.files = [];
    };
  }

  /**
   * Clear avatar
   */
  clearImage(): void {
    this.fileUpload.files = [];
    this.form.get(this.controlName).setValue(null);
    this.form.get(this.newControlName).setValue(false);
  }

  /**
   * чупи адреса без това
   * https://stackoverflow.com/questions/46041210/angular-2-ngstyle-and-background-image
   *
   * @param imgStr img
   */
  getBackgroundImg(imgStr: string) {
    const imageString = JSON.stringify(imgStr).replace(/\\n/g, '');
    const style = 'url(' + imageString + ')';
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
