import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { Model } from '@app/shared/models/model.model';
import { ResourceInterface } from '@app/shared/models/resource.interface';
import { Sale } from '@app/sales/sales/sale.model';
import { HttpParamsVouchers } from '@app/core/http/http-params-vouchers';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SalesService implements ResourceInterface {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery(), keywords: string = null): Observable<PaginatedResponse> {
    const options = query.buildGetOptions();
    if (keywords) {
      options.params = options.params.set('keywords', keywords);
    }

    return this.http.get('v1/sales', options).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((request: any) => {
          return new Sale().deserialize(request);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  update(request: Sale): Observable<Sale> {
    return this.http.put('v1/sales/' + request.id, request).pipe(
      map((response: HttpResponse) => {
        return new Sale().deserialize(response.data);
      })
    );
  }

  store(request: Sale): Observable<Sale> {
    return this.http.post('v1/sales', request).pipe(
      map((response: HttpResponse) => {
        return new Sale().deserialize(response.data);
      })
    );
  }

  show(id: number, query: ApiQuery = new ApiQuery()): Observable<Model> {
    return this.http.get('v1/sales/' + id, query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return new Sale().deserialize(response.data);
      })
    );
  }

  destroy(id: number): Observable<any> {
    return this.http.delete('v1/sales/' + id);
  }

  requests(saleId: number, requests: any): Observable<any> {
    return this.http.post('v1/sales/' + saleId + '/requests', requests).pipe(
      map((response: HttpResponse) => {
        return response;
        // const arr: Array<SaleRequest> = [];
        // response.data.forEach((req: any) => {
        //   arr.push(new SaleRequest().deserialize(req));
        // });
        // return arr;
      })
    );
  }

  downloadVouchers(httpParamsVouchers: HttpParamsVouchers): any {
    return this.http.download('v1/sales/vouchers', httpParamsVouchers.get()).pipe(
      map((response: any) => {
        const blob = new Blob([response], {
          type: 'application/zip;charset=utf-8',
        });
        FileSaver.saveAs(blob, 'vouchers-' + moment().format('DD_MM_YYYY_HH_mm_ss') + '.zip');
      })
    );
  }

  mass(data: any) {
    return this.http.post('v1/sales/mass', data).pipe(
      map((response: HttpResponse) => {
        return response;
      })
    );
  }

  exportClientEmails(data: any): any {
    return this.http.post('v1/sales/exports/client_emails', data).pipe(
      map((response: HttpResponse) => {
        return response;
      })
    );
  }

  massReassign(fields: any): Observable<any> {
    return this.http.post('v1/sales/mass_reassign_agents', fields).pipe(
      map((response: HttpResponse) => {
        return response.data;
      })
    );
  }
}
