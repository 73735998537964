import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapModalComponent } from '@app/shared/components/map-modal/map-modal.component';

@Injectable({
  providedIn: 'root',
})
export class MapModalService {
  constructor(private modalService: NgbModal) {}

  /**
   * Open map form
   * @param hotelsIds масив с ID-та на hotels
   * @param venuesIds масив с ID-та на venues
   */
  public open(hotelsIds: Array<number>, venuesIds: Array<number> = []) {
    const modal = this.modalService.open(MapModalComponent, { size: 'full' as 'lg' });
    modal.componentInstance.hotelsIds = hotelsIds;
    modal.componentInstance.venuesIds = venuesIds;
    return modal.result;
  }
}
