<div class="d-inline-block">
  <ng-template #popContent>
    <ng-container *ngIf="!mediaData.data">
      <div class="spinner-border text-primary p-5 m-5"></div>
    </ng-container>

    <div class="text-danger font-weight-bold p-3" *ngIf="mediaData.data && mediaData.data.length == 0">
      {{ 'DOWNLOAD_VOUCHERS_BUTTON.NO_MEDIA_MSG' | translate }}
    </div>

    <ng-container *ngIf="mediaData.data">
      <div ngbDropdown placement="left" *ngFor="let media of mediaData.data; let i = index">
        <button ngbDropdownToggle type="button" class="btn menu-item">
          {{ media.name || media.file_name }}
        </button>

        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="downloadVouchers(VouchersTypes.pdf, media.id, p)">
            {{ 'DOWNLOAD_VOUCHERS_BUTTON.DOWNLOAD_PDF' | translate }}
          </button>
          <button ngbDropdownItem (click)="downloadVouchers(VouchersTypes.word, media.id, p)">
            {{ 'DOWNLOAD_VOUCHERS_BUTTON.DOWNLOAD_WORD' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <button
    (click)="loadVouchersMedia(p)"
    #p="ngbPopover"
    popoverClass="download-vouchers-popover"
    [ngbPopover]="popContent"
    type="button"
    class="btn btn-warning btn-download-voucher"
    [ngbTooltip]="'DOWNLOAD_VOUCHERS_BUTTON.DOWNLOAD_VOUCHERS' | translate"
    container="body"
    [autoClose]="false"
  >
    <i class="fal fa-file-download"></i> <i class="fas fa-caret-down"></i>
  </button>
</div>
