import { Component, OnInit } from '@angular/core';
import { AuthenticationService, Logger } from '@app/core';
import { Venue } from '@app/settings/venues/venue.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiQuery } from '@app/core/http/api-query';
import { VenuesService } from '@app/settings/venues/venues.service';
import { VenuesFormService } from '@app/settings/venues/venues-form.service';
import { environment } from '@env/environment';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { EventsService } from '@app/events/events/events.service';
import { Events } from '@app/events/events/events.model';
import { EventsFormService } from '@app/events/events/events-form.service';
import { EventsSeoFormService } from '@app/events/events/events-seo-form.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';

const log = new Logger('VenuesViewsComponent');

@Component({
  selector: 'app-venues-views',
  templateUrl: './venues-views.component.html',
  styleUrls: ['./venues-views.component.scss'],
})
export class VenuesViewsComponent implements OnInit {
  venueId: number;
  venue: Venue;
  env = environment;

  eventsRequestsResponse: PaginatedResponse;
  eventsData: GridDataResult;
  eventsState: DataStateChangeEvent;
  eventsApiQuery: ApiQuery = new ApiQuery();

  AuthenticationService = AuthenticationService;

  UserRoleType = UserRoleType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private venuesService: VenuesService,
    private venuesFormService: VenuesFormService,
    private eventsService: EventsService,
    private eventsFormService: EventsFormService,
    private eventSeoFormService: EventsSeoFormService,
    public accessService: AccessService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.venueId = Number(params.get('id'));
        this.loadData(this.venueId);
        this.loadEventsDataTable();
        // this.openActivities();
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  /**
   *  Парсва стрингосани числа
   * @param number Подава се стрингосано число или число
   */
  number(number: any): number {
    return Number(number);
  }

  loadData(id: number): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes('city.country');
    this.venuesService.show(id, apiQuery).subscribe((venue: Venue) => {
      log.debug(venue);
      this.venue = venue;
    });
  }

  edit(): void {
    this.venuesFormService.open(this.venue).then(
      (response) => {
        log.debug('res', response);
        this.loadData(this.venueId);
      },
      (res) => {
        log.info(res);
      }
    );
  }

  add() {
    this.venuesFormService.open().then(
      (response) => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { id: response.id },
          queryParamsHandling: 'merge',
        });
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  loadEventsDataTable(state: DataStateChangeEvent = null) {
    log.debug('employeesLoadDataTable');
    this.eventsState = state;
    this.eventsApiQuery
      .addFilters({ venue_id: this.venueId })
      .setDataSetFilters(state)
      .addIncludes('type', 'city.country');

    this.eventsService.index(this.eventsApiQuery).subscribe((response: PaginatedResponse) => {
      this.eventsRequestsResponse = response;
      this.eventsData = response.getGridData();
    });
  }

  editEvent(event: Events) {
    this.eventsFormService.open(event).then(
      () => {
        this.loadEventsDataTable();
      },
      () => {}
    );
  }

  deleteEvent(event: Events) {
    this.eventsService.destroy(event.id).subscribe(() => {
      this.loadEventsDataTable(this.eventsState);
    });
  }

  /**
   *  Отваря SEO формата
   * @param event Events
   */
  editEventSeo(event: Events): void {
    this.eventSeoFormService.open(event).then(
      (res) => {
        log.debug(res);
      },
      (err) => {
        log.debug(err);
      }
    );
  }
}
