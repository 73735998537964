import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, Logger } from '@app/core';
import { FileUpload } from 'primeng';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { ProfileService } from '@app/profile/profile.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/settings/users/user.model';

const log = new Logger('ProfileFormComponent');

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {
  @ViewChild('fileUpload', { static: true }) fileUpload: FileUpload;
  form: FormGroup;
  formPass: FormGroup;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;

  /**
   * Confirm new passwords
   *
   * @param group FormGroup
   * @return boolean
   */
  static confirmPassword(group: FormGroup) {
    const pass = group.get('new_password').value;
    const confirmPass = group.get('new_password_confirmation').value;
    const isValid = pass === confirmPass;

    group.get('new_password_confirmation').setErrors(!(isValid && pass) ? { invalid: true } : null);
    return isValid ? null : { notSame: true };
  }

  constructor(
    public modal: NgbActiveModal,
    public authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    this.pathForm();
    this.createChangePasswordForm();
  }

  /**
   * Log helper
   * @param form Form
   */
  log(form: any) {
    log.debug(form);
  }

  /**
   * Save action
   */
  save(): void {
    log.debug('save init!');
    const user: User = new User().deserialize(this.form.value);
    this.profileService.update(user).subscribe((res) => {
      this.translate.get('PROFILE.PROFILE_SUCCESSFULLY_UPDATED').subscribe((trans: string) => {
        this.toastr.success(trans);
        this.authenticationService.loadProfile().subscribe(() => {
          this.modal.close('Save click');
        });
      });
    });
  }

  /**
   * Add avatar image
   *
   * @param event any
   */
  addAvatar(event: any) {
    const reader = new FileReader();
    const [file] = event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.form.patchValue({
        new_avatar: reader.result,
      });

      this.fileUpload.files = [];
    };
  }

  /**
   * Clear avatar
   */
  clearAvatar() {
    this.fileUpload.files = [];
    this.form.get('avatar').setValue(null);
    this.form.get('new_avatar').setValue(null);
  }

  /**
   * чупи адреса без това
   * https://stackoverflow.com/questions/46041210/angular-2-ngstyle-and-background-image
   *
   * @param imgStr img
   */
  getBackgroundImg(imgStr: string) {
    const imageString = JSON.stringify(imgStr).replace(/\\n/g, '');
    const style = 'url(' + imageString + ')';
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  /**
   * Submit "change password" form
   */
  saveChangePassword() {
    log.debug('changePassword() init');

    this.profileService.changePassword(this.formPass.value).subscribe(
      (res) => {
        log.debug('saveChangePassword()', res);
        this.translate.get('PROFILE.PASSWORD_SUCCESSFULLY_CHANGED').subscribe((trans: string) => {
          this.toastr.success(trans);
        });
      },
      (err) => {
        log.debug('saveChangePassword()', err);
        this.toastr.error(err);
      }
    );
  }

  /**
   * Създава формата
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      title_id: [null],
      first_name: [null],
      middle_name: [null],
      last_name: [null],
      email: [null],
      company_id: [null],
      fake_first_name: [null],
      fake_last_name: [null],
      fake_email: [null],
      gender: [null],
      avatar: [null],
      new_avatar: [null],
      locales: [[]],
      companies: [[]],
      active: [true],
    });
  }

  /**
   * Create "change password" form
   */
  private createChangePasswordForm(): void {
    this.formPass = this.formBuilder.group(
      {
        current_password: [null, [Validators.required, Validators.minLength(6)]],
        new_password: [null, [Validators.required, Validators.minLength(6)]],
        new_password_confirmation: [null, [Validators.required]],
      },
      { validator: ProfileFormComponent.confirmPassword }
    );
  }

  /**
   * Попълва user с логнатия user
   */
  private pathForm() {
    this.authenticationService.loadProfile().subscribe((profile: any) => {
      this.form.patchValue(profile.profile);
    });
  }
}
