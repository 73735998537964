<div class="container-fluid">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'SALES.GUEST_NAMES_LIST.TITLE' | translate }}
      </h5>
    </div>
    <form class="d-flex" [formGroup]="form" (ngSubmit)="loadData()">
      <div class="align-self-end">
        <button type="button" class="btn btn-link" (click)="clearFilters()">
          {{ 'SALES.GUEST_NAMES_LIST.CLEAR_FILTERS' | translate }}
        </button>
      </div>

      <div class="custom-control custom-checkbox checkbox-margin mr-3">
        <input type="checkbox" class="custom-control-input" formControlName="exact" id="guest-names-list-exact" />
        <label class="custom-control-label" for="guest-names-list-exact">
          {{ 'SALES.GUEST_NAMES_LIST.EXACT' | translate }}
        </label>
      </div>

      <div>
        <label>{{ 'SALES.GUEST_NAMES_LIST.KEYWORD' | translate }}</label>
        <input type="text" class="form-control" formControlName="keywords" />
      </div>

      <div class="d-flex">
        <button type="submit" class="btn btn-success btn-input align-self-end">
          {{ 'SALES.GUEST_NAMES_LIST.SEARCH' | translate }}
        </button>
      </div>
    </form>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="requestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Guest_names', grid)"
    [loading]="loader"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'SALES.GUEST_NAMES_LIST.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'SALES.GUEST_NAMES_LIST.ID' | translate }}"
      filter="numeric"
      [width]="120"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="guest_names"
      title="{{ 'SALES.GUEST_NAMES_LIST.GUEST_NAMES' | translate }}"
      [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let guestName of dataItem?.soldRoomsGuestUniqueNames; let i = index">
          <ng-container *ngIf="guestName">
            {{ guestName }}<span *ngIf="i !== dataItem?.soldRoomsGuestUniqueNames?.length - 1">, </span>
          </ng-container>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="client.name"
      title="{{ 'SALES.GUEST_NAMES_LIST.CLIENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('client.name')"
      [width]="200"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter" [field]="'client_id'"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-client-box [client]="dataItem.client" [flag]="true"></app-client-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.formattedName"
      title="{{ 'SALES.GUEST_NAMES_LIST.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.formattedName')"
      [width]="200"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter" [field]="'event_id'"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.event?.id }" target="_blank">
          {{ dataItem?.event?.name }} - {{ dataItem?.event?.date_from | amDateFormat: 'YYYY' }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="status.value" title="{{ 'SALES.GUEST_NAMES_LIST.STATUS' | translate }}" [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_status'"
          [filter]="filter"
          [field]="'status_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.status"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="company.name"
      title="{{ 'SALES.GUEST_NAMES_LIST.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('company.name', true)"
      [width]="150"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter" [field]="'company_id'"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/settings/companies/view']" [queryParams]="{ id: dataItem?.company_id }" target="_blank">
          {{ dataItem?.company?.abbreviation }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'SALES.GUEST_NAMES_LIST.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-sale-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER_LEAD
              ],
              ['sales.show']
            )
          "
          [link]="['/sales/view']"
          [params]="{ id: dataItem.id }"
        >
        </app-sale-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
