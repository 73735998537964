import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { Logger } from '@app/core';
import * as moment from 'moment';

const log = new Logger('MinNightsPickerComponent');

@Component({
  selector: 'app-min-nights-picker',
  templateUrl: './min-nights-picker.component.html',
  styleUrls: ['./min-nights-picker.component.scss'],
})
export class MinNightsPickerComponent implements OnInit {
  form: FormGroup;
  env = environment;
  @Input() FormControlMinNightsFrom: AbstractControl;
  @Input() FormControlMinNightsTo: AbstractControl;
  minNightsCount: number = null;

  /**
   *  На кой месец да е календара като се отвори
   *  Не следи за промени(ако е нужно да се направи да следи за промени)
   */
  _defaultDate: string | Date = null;
  @Input() set defaultDate(value: string | Date) {
    log.debug('set defaultDate');
    this._defaultDate = moment(value, this.env.apiPipeDateFormat).toDate() || null;
  }

  get defaultDate(): string | Date {
    return this._defaultDate;
  }

  _minDate: string | Date = null;
  @Input() set minDate(value: string | Date) {
    this._minDate = moment(value, this.env.apiPipeDateFormat).toDate() || null;
  }

  get minDate(): string | Date {
    return this._minDate;
  }

  _maxDate: string | Date = null;
  @Input() set maxDate(value: string | Date) {
    this._maxDate = moment(value, this.env.apiPipeDateFormat).toDate() || null;
  }

  get maxDate(): string | Date {
    return this._maxDate;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      min_nights_fake: [null],
    });

    if (
      this.FormControlMinNightsFrom &&
      this.FormControlMinNightsFrom.value &&
      this.FormControlMinNightsFrom.value.length > 0 &&
      this.FormControlMinNightsTo &&
      this.FormControlMinNightsTo.value &&
      this.FormControlMinNightsTo.value.length > 0
    ) {
      const dateFromMoment = moment(this.FormControlMinNightsFrom.value);
      const dateToMoment = moment(this.FormControlMinNightsTo.value);
      const formatted = [dateFromMoment.toDate(), dateToMoment.toDate()];
      this.form.get('min_nights_fake').setValue(formatted);
      this.minNightsCount = dateToMoment.diff(dateFromMoment, 'days');
    }
  }

  onSelect() {
    log.debug('select');
    if (
      this.form.get('min_nights_fake').value &&
      this.form.get('min_nights_fake').value[0] &&
      this.form.get('min_nights_fake').value[1]
    ) {
      const dateFromMoment = moment(this.form.get('min_nights_fake').value[0]);
      const dateToMoment = moment(this.form.get('min_nights_fake').value[1]);
      const dateFrom = dateFromMoment.format(this.env.apiPipeDateFormat);
      const dateTo = dateToMoment.format(this.env.apiPipeDateFormat);
      this.FormControlMinNightsFrom.setValue(dateFrom);
      this.FormControlMinNightsTo.setValue(dateTo);
      this.minNightsCount = dateToMoment.diff(dateFromMoment, 'days');
    } else {
      this.FormControlMinNightsFrom.setValue(null);
      this.FormControlMinNightsTo.setValue(null);
      this.minNightsCount = null;
    }
    this.FormControlMinNightsFrom.markAsDirty();
    this.FormControlMinNightsTo.markAsDirty();
  }
}
