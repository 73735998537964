import { environment } from '@env/environment';

export enum UserRoleType {
  ADMINISTRATOR = environment.enums.roles.administrator,

  // managers
  SALES_MANAGER = environment.enums.roles.salesManager,
  RESERVATION_MANAGER = environment.enums.roles.reservationManager,
  CUSTOMER_CARE_MANAGER = environment.enums.roles.customerCareManager,
  FINANCE_MANAGER = environment.enums.roles.financeManager,
  CONTRACTING_MANAGER = environment.enums.roles.contractingManager,

  // employees
  SALES_AGENT = environment.enums.roles.salesAgent,
  RESERVATION_AGENT = environment.enums.roles.reservationAgent,
  CUSTOMER_CARE_AGENT = environment.enums.roles.customerCareAgent,
  FINANCE_AGENT = environment.enums.roles.financeAgent,
  CONTRACTING_AGENT = environment.enums.roles.contractingAgent,

  // other
  LEAD_GENERATION = environment.enums.roles.leadGeneration,
  ACCOUNTANT = environment.enums.roles.accountant,
  MARKETING = environment.enums.roles.marketing,
  IT = environment.enums.roles.it,

  EXTERNAL_PARTNER = environment.enums.roles.externalPartner,
  EXTERNAL_PARTNER_LEAD = environment.enums.roles.externalPartnerLead,
}
