import { Component, OnInit } from '@angular/core';
import { ApiQuery, ApiQuerySortTypes } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { ProfileService } from '@app/profile/profile.service';
import { NotificationsService } from '@app/notifications/notifications/notifications.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { Notification } from '@app/notifications/notifications/notification.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  apiQuery: ApiQuery = new ApiQuery();
  data: Array<Notification>;
  env = environment;
  collapsed = this.profileService.getSetting('NotificationsListComponent', 'dashboardBoxes').collapsed;

  constructor(
    private profileService: ProfileService,
    private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (!this.collapsed) {
      this.loadData();
    }
  }

  /**
   * Load data
   */
  loadData() {
    this.apiQuery
      .addFilters({
        unread: true,
      })
      .setSort('read_at', ApiQuerySortTypes.ASC)
      .setLimit(15);

    this.notificationsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.data = response.data;
    });
  }

  /**
   * Mark notification as 'read'
   *
   * @param notification Notification
   */
  markAsRead(notification: Notification) {
    this.notificationsService.update(notification).subscribe(() => {
      this.translate.get('NOTIFICATIONS.NOTIFICATION_MARKED_AS_READ_SUCCESSFULLY').subscribe((trans: string) => {
        this.toastr.success(trans);
        this.loadData();
      });
    });
  }

  /**
   * Go to notification's target
   *
   * @param url string
   */

  /*goToTarget(url: string): void {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }*/

  /**
   * Toggle
   */
  toggle() {
    this.collapsed = !this.collapsed;
    this.profileService
      .setSetting('NotificationsListComponent', { collapsed: this.collapsed }, 'dashboardBoxes')
      .subscribe();
    if (!this.collapsed && !this.data) {
      this.loadData();
    }
  }
}
