import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { Logger } from '@app/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

const log = new Logger('HotelBookingsMassReassignComponent');

@Component({
  selector: 'app-hotel-bookings-mass-reassign',
  templateUrl: './hotel-bookings-mass-reassign.component.html',
  styleUrls: ['./hotel-bookings-mass-reassign.component.scss'],
})
export class HotelBookingsMassReassignComponent implements OnInit {
  form: FormGroup;
  UserRoleType = UserRoleType;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private hotelBookingService: HotelBookingsService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  fieldChange() {
    this.form.get('from_id').patchValue(null);
    this.form.get('to_id').patchValue(null);
  }

  reassign(HotelBookingsMassReassignPopOver: NgbPopover) {
    this.hotelBookingService.massReassignAgents(this.form.value).subscribe((response: any) => {
      if (response) {
        this.translate.get('HOTELS_BOOKINGS_MASS_REASSIGN.AFFECTED_ROWS').subscribe((trans: string) => {
          this.toastr.success(trans + ' ' + response.affected_rows);
          this.close(HotelBookingsMassReassignPopOver);
        });
      }
    });
  }

  close(HotelBookingsMassReassignPopOver: NgbPopover) {
    this.form.reset();
    HotelBookingsMassReassignPopOver.close();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      field: [null, [Validators.required]],
      from_id: [null, [Validators.required]],
      to_id: [null, [Validators.required]],
    });
  }
}
