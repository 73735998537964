<button
  (click)="createPayment()"
  type="button"
  class="btn btn-primary"
  [ngClass]="{ 'btn-ico': iconBtn, 'btn-xs': !iconBtn }"
  [ngbTooltip]="'PAYMENT_BUTTON.CREATE_PAYMENT' | translate"
  container="body"
>
  <span *ngIf="iconBtn">
    <i class="fal fa-usd-square"></i>
  </span>
  <span *ngIf="!iconBtn">
    {{ 'PAYMENT_BUTTON.CREATE_PAYMENT' | translate }}
  </span>
</button>
