import { Component, Input, OnInit } from '@angular/core';
import { ApiQuery } from '@app/core/http/api-query';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Company } from '@app/settings/companies/company.model';
import { CompaniesService } from '@app/settings/companies/companies.service';
import { CompaniesFormService } from '@app/settings/companies/companies-form.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { Logger } from '@app/core';

const log = new Logger('CompaniesSelectComponent');

@Component({
  selector: 'app-companies-select',
  templateUrl: './companies-select.component.html',
  styleUrls: ['./companies-select.component.scss'],
})
export class CompaniesSelectComponent implements OnInit {
  data: Array<Company>;
  apiQuery: ApiQuery = new ApiQuery();
  loader: boolean;
  @Input() FormControl: FormControl;
  form: FormGroup;
  input$ = new Subject<string>();
  @Input() required = false;

  constructor(
    private companiesService: CompaniesService,
    private formBuilder: FormBuilder,
    private companiesFormService: CompaniesFormService
  ) {}

  ngOnInit() {
    this.loadCompanies();
    /*
     *  създава fake formGroup, за да може да пази временно стойностите някъде
     *  после чрез евентите add, remove и clear ги прехвърля към подадения на компонента контрол
     */
    if (this.required) {
      this.form = this.formBuilder.group({
        companies_fake: [[], [Validators.required]],
      });
    } else {
      this.form = this.formBuilder.group({
        companies_fake: [[]],
      });
    }

    /*
     * това помага за patch-a на parent формата
     * ако се пали в някой друг случай трябва да се измисли нещо по-добро
     */
    this.FormControl.valueChanges.subscribe((values: []) => {
      setTimeout(() => {
        const preSelectedCompanies: Array<any> = [];
        values.forEach((company: any) => {
          preSelectedCompanies.push(company.id);
        });
        this.form.get('companies_fake').setValue(preSelectedCompanies);
      });
    });
  }

  loadCompanyById(id: number) {
    return this.companiesService.show(id);
  }

  loadCompaniesByIds(companies: any[]) {
    this.loader = true;
    for (let i = 0; i < companies.length; i++) {
      this.companiesService.show(companies[i]['id']).subscribe((company: Company) => {
        this.data = [company]; // не знам защо работи
      });
    }
    this.loader = false;
    // return result;
  }

  loadCompanies(keyword?: string) {
    this.loader = true;
    this.companiesService
      .index(new ApiQuery().addFilters({ active: true }).setLimit(9999))
      .subscribe((response: PaginatedResponse) => {
        log.debug('loadCompaniesresponse.data', response.data);
        this.data = response.data;
        this.loader = false;
      });
  }

  add(id: number) {
    this.FormControl.value.push({ id: id });
  }

  remove(id: any) {
    let index: number;
    this.FormControl.value.forEach((value: any, ind: number) => {
      if (value.id === id) {
        index = ind;
      }
    });
    this.FormControl.value.splice(index, 1);
  }

  clear() {
    this.FormControl.setValue([]);
  }

  addNew(searchTerm: string) {
    this.companiesFormService.open(new Company().deserialize({ name: searchTerm })).then(
      (response) => {
        const newValues = [...this.form.get('companies_fake').value, response.id];

        const newValuesObject = newValues.map((id: number) => {
          return new Company().deserialize({ id: id });
        });
        this.loadCompaniesByIds(newValuesObject);
        this.form.get('companies_fake').setValue(newValues);
        this.add(response.id);
      },
      (err) => {
        log.error(err);
      }
    );
  }
}
