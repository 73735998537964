import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { InvoiceType } from '@app/finance/invoices/invoice-type.enum';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { InvoicesFormService } from '@app/finance/invoices/invoices-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { Invoice } from '@app/finance/invoices/invoice.model';
import * as FileSaver from 'file-saver';
import { AuthenticationService, Logger } from '@app/core';
import { DataSet } from '@app/shared/kendo/data-set';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { AccessService } from '@app/core/access/access.service';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';

const log = new Logger('ClientInvoicesListComponent');

@Component({
  selector: 'app-client-invoices-list',
  templateUrl: './client-invoices-list.component.html',
  styleUrls: ['./client-invoices-list.component.scss'],
})
export class ClientInvoicesListComponent extends KendoGridComponent implements OnInit {
  invoicesResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  LaravelModelsEnum = LaravelModelsEnum;
  InvoiceType = InvoiceType;

  constructor(
    private invoicesService: InvoicesService,
    private invoicesFormService: InvoicesFormService,
    protected kendoGridService: KendoGridService,
    public accessService: AccessService,
    private auth: AuthenticationService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.state = new DataSet().addFilter('paid', false);

    const stateFromStorage = CustomKendoFilter.getStateFromStorage('ClientInvoicesListComponent');
    if (stateFromStorage) {
      this.state = stateFromStorage;
    }

    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes('company', 'clientable.country', 'type', 'payment_method', 'invoiceable')
      .addFilter('invoiceable_type', this.LaravelModelsEnum.SALE_FINAL);

    // проверява дали юзъра има определени роли, но не админ
    if (!this.accessService.hasAccess([], [])) {
      this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
        if (
          role.id === UserRoleType.RESERVATION_AGENT ||
          role.id === UserRoleType.SALES_AGENT ||
          role.id === UserRoleType.CUSTOMER_CARE_AGENT
        ) {
          this.apiQuery.addFilter('my_sales', this.auth.accessToken.profile.id);
        }
      });
    }

    this.apiQuery.addFilter('hide_linked', true);

    this.invoicesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((invoice: Invoice) => {
        // Събира хотелите от всички редове във файнъла
        invoice.hotels = [];
        if (invoice.invoiceable.items) {
          invoice.invoiceable.items.forEach((saleFinalItem: any) => {
            if (
              saleFinalItem.hotel_booking_room &&
              saleFinalItem.hotel_booking_room.hotel &&
              invoice.hotels.indexOf(saleFinalItem.hotel_booking_room.hotel.name) < 0
            ) {
              invoice.hotels.push(saleFinalItem.hotel_booking_room.hotel.name);
            }
          });
        }
      });
      this.invoicesResponse = response;
      this.data = response.getGridData();
    });
    CustomKendoFilter.pushStateInStorage('ClientInvoicesListComponent', this.state);
  }

  edit(invoice: Invoice): void {
    this.invoicesFormService.open(invoice).then(
      (res) => {
        this.loadData(this.state);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  delete(invoice: Invoice): void {
    this.invoicesService.destroy(invoice.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  invoiceRowCallback(context: RowClassArgs) {
    let rowOpacity = false;

    if (context.dataItem.linked_id && context.dataItem.type_id === InvoiceType.PROFORMA) {
      rowOpacity = true;
    }
    return {
      'kendo-row-opacity': rowOpacity,
    };
  }

  clearFilters() {
    this.apiQuery = new ApiQuery();
    this.loadData();
  }
}
