<div class="companies-view-wrapper relative">
  <div class="top">
    <div class="row">
      <div class="col-sm-1">
        <div class="company-logo-wrapper">
          <img *ngIf="company?.logo" [src]="company.logo" alt="logo" class="img-fluid logo" />
        </div>
      </div>
      <div class="col-sm-11">
        <div class="preview-header d-flex justify-content-between">
          <div class="preview-title-wrapper d-flex flex-column">
            <div class="d-flex align-items-center">
              <div class="title">
                {{ company?.name }}
              </div>

              <div
                *ngIf="company?.color"
                [ngStyle]="{ 'background-color': company?.color, color: company?.invertColor }"
                class="color ml-2"
              >
                {{ 'COMPANIES.COMPANIES_VIEW.COLOR' | translate }}
              </div>

              <span class="sub-title ml-2">
                {{ 'COMPANIES.COMPANIES_VIEW.ACTIVE' | translate }}
              </span>

              <span class="value">
                <app-active-indicator [active]="company?.active"></app-active-indicator>
              </span>
            </div>

            <div>
              <span [ngStyle]="{ color: company?.color }" class="company-abbreviation" *ngIf="company?.abbreviation">
                {{ company?.abbreviation }}
              </span>
            </div>
          </div>
          <div class="preview-btns-wrapper">
            <button
              *ngIf="
                accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], ['companies.show'])
              "
              type="button"
              class="btn btn-sm btn-success"
              (click)="edit()"
            >
              {{ 'COMPANIES.COMPANIES_VIEW.EDIT_COMPANY_BTN' | translate }}
            </button>

            <button
              *ngIf="
                accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], ['companies.store'])
              "
              type="button"
              class="btn btn-sm btn-info"
              (click)="add()"
            >
              {{ 'COMPANIES.COMPANIES_VIEW.NEW_COMPANY_BTN' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-0 mb-0" />

    <div class="d-flex">
      <div class="w-50 border-right">
        <div class="pl-3">
          <div class="row">
            <div class="col-sm-3 mb-3 mt-3">
              <div class="sub-title" *ngIf="company?.city.country">
                <i class="far fa-globe-europe"></i> {{ 'COMPANIES.COMPANIES_VIEW.COUNTRY' | translate }}
              </div>

              <div class="value" *ngIf="company?.city.country">
                {{ company?.city?.country?.name }}
              </div>
            </div>

            <div class="col-sm-3 mb-3 mt-3">
              <div class="sub-title" *ngIf="company?.city">
                <i class="fal fa-location-circle"></i> {{ 'COMPANIES.COMPANIES_VIEW.CITY' | translate }}
              </div>

              <div class="value" *ngIf="company?.city?.name">
                {{ company?.city?.name }}
              </div>
            </div>

            <div class="col-sm-6 mb-3 mt-3">
              <div class="sub-title" *ngIf="company?.address">
                <i class="fas fa-sort-numeric-down"></i> {{ 'COMPANIES.COMPANIES_VIEW.ADDRESS' | translate }}
              </div>

              <div class="value" *ngIf="company?.address">
                {{ company?.address }}
              </div>
            </div>

            <div class="col-sm-3 mb-3">
              <div class="sub-title" *ngIf="company?.tax_number">
                <i class="far fa-user"></i> {{ 'COMPANIES.COMPANIES_VIEW.TAX_NUMBER' | translate }}
              </div>

              <div class="value" *ngIf="company?.tax_number">
                {{ company?.tax_number }}
              </div>
            </div>

            <div class="col-sm-3 mb-3">
              <div class="sub-title" *ngIf="company?.registration_number">
                <i class="far fa-map-marker-check"></i> {{ 'COMPANIES.COMPANIES_VIEW.REGISTRATION_NUMBER' | translate }}
              </div>

              <div class="value" *ngIf="company?.registration_number">
                {{ company?.registration_number }}
              </div>
            </div>

            <div class="col-sm-3 mb-3">
              <div class="sub-title" *ngIf="company?.iata_tids_number">
                <i class="far fa-map-marker-check"></i> {{ 'COMPANIES.COMPANIES_VIEW.IATA_TIDS_NUMBER' | translate }}
              </div>

              <div class="value" *ngIf="company?.iata_tids_number">
                {{ company?.iata_tids_number }}
              </div>
            </div>

            <div class="col-sm-3 mb-3">
              <div class="sub-title" *ngIf="company?.vat_number">
                <i class="far fa-map-marker-check"></i> {{ 'COMPANIES.COMPANIES_VIEW.VAT_NUMBER' | translate }}
              </div>

              <div class="value" *ngIf="company?.vat_number">
                {{ company?.vat_number }}
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3 border-top">
          <div class="pl-3">
            <app-bank-accounts-box [bankAccounts]="company?.bank_accounts"></app-bank-accounts-box>
          </div>
        </div>
      </div>

      <div class="w-50 pl-3 pt-2">
        <div *ngIf="company?.contacts?.length > 0">
          <div class="section-title mt-2 mb-1">
            <i class="far fa-address-book"></i>
            {{ 'COMPANIES.COMPANIES_VIEW.CONTACTS' | translate }}
          </div>

          <app-contacts-box [contacts]="company?.contacts"></app-contacts-box>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <!--      <div class="section" *ngIf="company?.properties?.length > 0">-->
      <!--        <div class="section-title mt-2 mb-1">{{ 'COMPANIES.COMPANIES_VIEW.PROPERTIES' | translate }}</div>-->

      <!--        <div *ngFor="let property of company?.properties; let i = index">-->
      <!--          <div class="sub-title">{{ property?.type?.value }}</div>-->
      <!--          <div class="value">-->
      <!--            {{ property?.value }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>

  <div class="title2 mt-2">
    {{ 'COMPANIES.COMPANIES_VIEW.EMPLOYEES' | translate }}
  </div>

  <kendo-grid
    [data]="employeesData"
    [pageSize]="employeesRequestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [filter]="employeesState?.filter"
    [sort]="employeesState?.sort"
    [skip]="employeesState?.skip"
    [pageable]="true"
    (dataStateChange)="employeesLoadDataTable($event)"
    #employeesGrid
    (excelExport)="onExcelExport($event, 'Employees', employeesGrid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'COMPANIES.COMPANIES_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'COMPANIES.COMPANIES_VIEW.ID' | translate }}"
      filter="numeric"
      [width]="150"
    ></kendo-grid-column>

    <kendo-grid-column
      field="names"
      title="{{ 'COMPANIES.COMPANIES_VIEW.NAMES' | translate }}"
      filter="text"
      [sortable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-user-box [user]="dataItem"></app-user-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="fake_names"
      title="{{ 'COMPANIES.COMPANIES_VIEW.FAKE_NAMES' | translate }}"
      filter="text"
      [sortable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.fake_first_name }} {{ dataItem.fake_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="roles" title="{{ 'COMPANIES.COMPANIES_VIEW.ROLES' | translate }}" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.getRolesNames() }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="contacts" title="{{ 'COMPANIES.COMPANIES_VIEW.CONTACTS' | translate }}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let contact of dataItem.contacts; let i = index">
          <span class="contact-key">{{ contact?.type?.value }}:</span>
          <span class="contact-value">{{ contact?.value }}</span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="email" title="{{ 'COMPANIES.COMPANIES_VIEW.EMAIL' | translate }}" filter="text">
    </kendo-grid-column>

    <kendo-grid-column
      field="active"
      title="{{ 'COMPANIES.COMPANIES_VIEW.ACTIVE' | translate }}"
      filter="boolean"
      [width]="150"
      [class]="'text-center'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-active-indicator [active]="dataItem.active"></app-active-indicator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'COMPANIES.COMPANIES_VIEW.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button
          *ngIf="accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], ['users.show'])"
          (click)="editUser(dataItem)"
        >
        </app-edit-button>

        <app-delete-button
          *ngIf="accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], ['users.destroy'])"
          [swal]="{}"
          (confirm)="delete(dataItem)"
        >
        </app-delete-button>

        <!--        <app-activities [subjectId]="dataItem.id" [subjectType]="LaravelModels.USER"></app-activities>-->
        <app-media-library-button
          *ngIf="accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], ['media.index'])"
          [ngbTooltip]="'COMPANIES.COMPANIES_VIEW.UPLOAD_DOCUMENTS' | translate"
          container="body"
          placement="left"
          [icon]="'fal fa-file'"
          [modelId]="dataItem.id"
          [modelType]="LaravelModels.USER"
          [acceptType]="MediaLibraryAcceptTypes.ALL"
        ></app-media-library-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="employeesLoadDataTable(employeesState)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="employeesGrid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>

  <div class="title2 mt-2">
    {{ 'COMPANIES.COMPANIES_VIEW.SUPPLIERS' | translate }}
  </div>

  <kendo-grid
    [data]="suppliersData"
    [pageSize]="suppliersRequestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [filter]="suppliersState?.filter"
    [sort]="suppliersState?.sort"
    [skip]="suppliersState?.skip"
    [pageable]="true"
    (dataStateChange)="suppliersLoadDataTable($event)"
    #suppliersGrid
    (excelExport)="onExcelExport($event, 'Suppliers', suppliersGrid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'COMPANIES.COMPANIES_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'COMPANIES.COMPANIES_VIEW.ID' | translate }}"
      filter="numeric"
      [width]="150"
    ></kendo-grid-column>

    <kendo-grid-column
      field="name"
      title="{{ 'COMPANIES.COMPANIES_VIEW.NAME' | translate }}"
      filter="text"
    ></kendo-grid-column>

    <kendo-grid-column field="type.value" title="{{ 'COMPANIES.COMPANIES_VIEW.TYPE' | translate }}" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.type"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'COMPANIES.COMPANIES_VIEW.CONTACTS' | translate }}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let contact of dataItem.contacts; let i = index">
          <span class="contact-key">{{ contact?.type?.value }}:</span>
          <span class="contact-value">{{ contact?.value }}</span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="active"
      title="{{ 'COMPANIES.COMPANIES_VIEW.ACTIVE' | translate }}"
      filter="boolean"
      [width]="150"
      [class]="'text-center'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-active-indicator [active]="dataItem.active"></app-active-indicator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'COMPANIES.COMPANIES_VIEW.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['suppliers.show']
            )
          "
          (click)="editSupplier(dataItem)"
        >
        </app-edit-button>

        <app-delete-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['suppliers.destroy']
            )
          "
          [swal]="{}"
          (confirm)="deleteSupplier(dataItem)"
        >
        </app-delete-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="suppliersLoadDataTable(suppliersState)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="suppliersGrid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>

    <ng-template kendoGridDetailTemplate let-dataItem>
      <kendo-grid [data]="dataItem.companies">
        <kendo-grid-column
          headerClass="pt-1 pb-1"
          field="abbreviation"
          title="{{ 'COMPANIES.COMPANIES_VIEW.COMPANY' | translate }}"
        ></kendo-grid-column>

        <kendo-grid-column headerClass="pt-1 pb-1" title="{{ 'COMPANIES.COMPANIES_VIEW.PROPERTIES' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div *ngFor="let property of dataItem.properties; let i = index" class="d-inline-block">
              <span class="property-key">{{ property?.type?.value }}:</span>
              <span class="property-value">{{ property?.value }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </ng-template>
  </kendo-grid>
</div>
