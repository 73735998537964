import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BankAccount } from '@app/shared/components/bank-accounts/bank-account.model';
import { Logger } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountsService } from '@app/banks/bank-accounts/bank-accounts.service';
import { ApiQuery } from '@app/core/http/api-query';
import { BankaccountableType } from '@app/banks/bankaccountable-type.enum';

const log = new Logger('BankAccountsFormComponent');

@Component({
  selector: 'app-bank-accounts-form',
  templateUrl: './bank-accounts-form.component.html',
  styleUrls: ['./bank-accounts-form.component.scss'],
})
export class BankAccountsFormComponent implements OnInit {
  @Input() bankAccount: BankAccount = null;
  form: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private bankAccountsService: BankAccountsService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.patchForm();
  }

  save() {
    if (this.bankAccount && this.bankAccount.id) {
      const bankAccount: BankAccount = this.bankAccount.deserialize(this.form.value);
      this.bankAccountsService.update(bankAccount).subscribe((res) => this.modal.close(res));
    } else {
      const bankAccount: BankAccount = new BankAccount().deserialize(this.form.value);
      this.bankAccountsService.store(bankAccount).subscribe((res) => this.modal.close(res));
    }
  }

  /**
   * Създава формата
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required]],
      iban: [null],
      bic: [null, [Validators.required]],
      abbreviation: [null],
      beneficiary_name: [null],
      address: [null],
      sort_code: [null],
      account_number: [null],
      city: [null],
      currency_id: [null],
      currency: [null],
      bankaccountable_id: [null, [Validators.required]],
      bankaccountable: [null],
      bankaccountable_type: [BankaccountableType.COMPANY, [Validators.required]],

      balance: [null, [Validators.required]],
      last_balance: [null],
      payment_calculation_date: [null],
    });
  }

  private patchForm(): void {
    if (this.bankAccount && this.bankAccount.id) {
      const query = new ApiQuery().addIncludes('currency');
      this.bankAccountsService.show(this.bankAccount.id, query).subscribe((bankAccount: BankAccount) => {
        this.form.patchValue(bankAccount);
      });
    } else if (this.bankAccount) {
      this.form.patchValue(this.bankAccount);
    }
  }
}
