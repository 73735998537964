import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { TemplatesService } from '@app/settings/templates/templates.service';
import { TemplatesFormService } from '@app/settings/templates/templates-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { HttpResponse, Logger } from '@app/core';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { EmailTemplate } from '@app/settings/templates/email-template.model';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { FileUpload } from 'primeng';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';

const log = new Logger('TemplatesListComponent');

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss'],
})
export class TemplatesListComponent extends KendoGridComponent implements OnInit {
  templatesResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;

  UserRoleType = UserRoleType;

  constructor(
    private templatesService: TemplatesService,
    private templatesFormService: TemplatesFormService,
    protected kendoGridService: KendoGridService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.state = CustomKendoFilter.getStateFromStorage('TemplatesListComponent');
    this.loadData(this.state);
  }

  add() {
    this.templatesFormService.open().then(() => {
      this.loadData();
    });
  }

  edit(template: EmailTemplate) {
    this.templatesFormService.open(template).then(() => {
      this.loadData();
    });
  }

  delete(template: EmailTemplate) {
    this.templatesService.destroy(template.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery.setDataSetFilters(state).addIncludes('company', 'locale', 'type');

    this.templatesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.templatesResponse = response;
      log.debug(response.data);
      this.data = response.getGridData();
    });
    CustomKendoFilter.pushStateInStorage('TemplatesListComponent', this.state);
  }

  export() {
    this.templatesService.export().subscribe((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.ms-excel;charset=utf-8',
      });
      FileSaver.saveAs(blob, 'templates-export-' + moment().format('DD_MM_YYYY_HH_mm_ss') + '.xlsx');
    });
  }

  /**
   * Качва файл на сървъра
   * @param event Event
   * @param fileUploader fileUploader
   */
  import(event: any, fileUploader: FileUpload): void {
    const promises: Array<any> = [];

    event.files.forEach((file: File) => {
      promises.push(this.readFile(file));
    });

    Promise.all(promises)
      .then((files: Array<{}>) => {
        const body = {
          file: files[0]['base64'],
        };

        this.http.post('v1/templates/import', body).subscribe(
          (response: HttpResponse) => {
            this.translate.get('TEMPLATES.TEMPLATES_LIST.IMPORT_SUCCESS_MSG').subscribe((trans: string) => {
              this.toastr.success(trans);
              this.loadData(this.state);
            });
          },
          (error: any) => {
            this.translate.get(error).subscribe((trans: string) => {
              this.toastr.error(trans);
            });
          }
        );
      })
      .finally(() => {
        fileUploader.clear();
      });
  }

  clearFilters() {
    this.apiQuery = new ApiQuery();
    this.loadData();
  }

  private readFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.cd.markForCheck();
        resolve({ base64: reader.result, file_name: file.name });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
}
