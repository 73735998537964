import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Invoice } from '@app/finance/invoices/invoice.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { ApiQuery } from '@app/core/http/api-query';
import { Logger } from '@app/core';
import { InvoiceItem } from '@app/finance/invoices/invoice-item.model';
import { PaymentMethodType } from '@app/settings/types/payment-method-type.enum';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { InvoiceType } from '@app/finance/invoices/invoice-type.enum';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { ClientableSelectComponent } from '@app/shared/components/clientable-select/clientable-select.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Companies } from '@app/settings/companies/companies.enum';

const log = new Logger('InvoicesFormComponent');

@Component({
  selector: 'app-invoices-form',
  templateUrl: './invoices-form.component.html',
  styleUrls: ['./invoices-form.component.scss'],
})
export class InvoicesFormComponent implements OnInit {
  @Input() invoice: Invoice = null;
  form: FormGroup;
  totals = {
    price: 0,
  };
  PaymentMethods = PaymentMethodType;
  InvoiceType = InvoiceType;
  nextNumber: number = null;
  LaravelModelsEnum = LaravelModelsEnum;
  @ViewChild('clientableSelectComponent', { static: true }) clientableSelectComponent: ClientableSelectComponent;

  CompaniesEnum = Companies;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private invoiceService: InvoicesService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();
    if (this.invoice && !this.invoice.id && this.invoice.invoiceable_id && this.invoice.invoiceable_type) {
      setTimeout(() => {
        this.form.patchValue(this.invoice);
      });

      this.setAutoNumberTrue();
    }

    if (!this.invoice) {
      this.setAutoNumberTrue();
    }
  }

  save() {
    if (this.invoice && this.invoice.id) {
      const invoice: Invoice = this.invoice.deserialize(this.formatPost());
      this.invoiceService.update(invoice).subscribe((res) => this.modal.close('Save click'));
    } else {
      const invoice: Invoice = new Invoice().deserialize(this.formatPost());
      this.invoiceService.store(invoice).subscribe((res) => this.modal.close(res));
    }
  }

  setAutoNumberTrue() {
    this.form.get('auto_number').patchValue(true);
  }

  test() {
    log.debug(this.form);
  }

  /**
   * add new Line
   */
  addLine() {
    // @ts-ignore
    this.form.controls.items.push(this.getEmptyRow());
  }

  /**
   * remove Line
   * @param index Line
   */
  removeLine(index: number) {
    this.form.get('items')['controls'].splice(index, 1);
    this.form.get('items').updateValueAndValidity();
    this.calculateTotals();
  }

  /**
   * Пали когато се смени company
   */
  companyChange() {
    this.form.get('bank_account_id').patchValue(null);
    this.form.get('bank_account').patchValue(null);
    this.getNextNumber();
  }

  /**
   * Пали когато се смени payment method
   */
  paymentMethodChange() {
    this.form.get('bank_account_id').patchValue(null);
    this.form.get('bank_account').patchValue(null);
  }

  moveUp(index: number) {
    this.arrayMove(this.form.get('items')['controls'], index, index - 1);
  }

  moveDown(index: number) {
    this.arrayMove(this.form.get('items')['controls'], index, index + 1);
  }

  arrayMove(arr: Array<any>, old_index: number, new_index: number) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  /**
   * Връща разликата в дни между две дати
   * @param date1 Дата 1
   * @param date2 Дата 2
   */
  getNightsCount(date1: string | Date, date2: string | Date): number {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }

  download(locale: string = null) {
    const invoice: Invoice = this.invoice.deserialize(this.formatPost());
    this.invoiceService.update(invoice).subscribe((res: any) => {
      this.invoiceService.download(this.invoice.id, locale).subscribe((data) => {
        const blob = new Blob([data], {
          type: 'application/pdf;charset=utf-8',
        });
        FileSaver.saveAs(blob, 'invoice-' + this.invoice.id + '.pdf');
      });
    });
  }

  createInvoice() {
    this.getNextNumber();
    const invoice = this.form.value;
    invoice.type_id = InvoiceType.ORIGINAL;
    invoice.linked_id = invoice.id;
    invoice.id = null;
    invoice.items.map((item: InvoiceItem) => {
      item.id = null;
      return item;
    });
    invoice.auto_number = true;
    this.invoice = new Invoice().deserialize(invoice);
    this.form.patchValue(invoice);

    this.translate.get('INVOICES.INVOICES_FORM.CLICK_SAVE_TO_CREATE_INVOICE_MSG').subscribe((trans: string) => {
      this.toastr.info(trans);
    });
  }

  getNextNumber() {
    const typeID = this.form.get('type_id').value;
    const companyID = this.form.get('company_id').value;
    if (!typeID || !companyID) {
      this.nextNumber = null;
      return log.error('Invalid typeID or companyID. Fail to generate next number!');
    }
    this.invoiceService.getNextNumber(typeID, companyID).subscribe((res) => {
      this.nextNumber = res.next_number;
    });
  }

  saveAndShowItems() {
    const invoice: Invoice = new Invoice().deserialize(this.formatPost());
    this.invoiceService.store(invoice).subscribe((res: Invoice) => {
      this.invoice = res;
      this.patchForm();
    });
  }

  // дали трябва да се save, за да се покажат item-ите
  saveToShowBool(): boolean {
    return (
      !this.form.get('id').value &&
      this.form.get('invoiceable_id').value &&
      this.form.get('invoiceable_type').value &&
      this.form.get('items')['controls'].length <= 0
    );
  }

  /**
   * Връща празен ред
   */
  private getEmptyRow() {
    const quantity = new FormControl(null, [Validators.required]);
    const unitPrice = new FormControl(null, [Validators.required]);

    quantity.valueChanges.subscribe((val) => {
      this.calculateTotals();
    });

    unitPrice.valueChanges.subscribe((val) => {
      this.calculateTotals();
    });

    return new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      quantity: quantity,
      unit_price: unitPrice,
      invoiceable_item: new FormControl(null),
      order_index: new FormControl(null),
      account_id: new FormControl(null),
      apply_to_net_amount: new FormControl(false),
    });
  }

  private createForm() {
    const paymentMethodId = new FormControl(null, [Validators.required]);
    const bankAccountId = new FormControl(null);
    const autoNumber = new FormControl(false);
    const number = new FormControl(null, [Validators.required]);
    const depositPercent = new FormControl(null);
    const balanceDueDate = new FormControl(null);

    paymentMethodId.valueChanges.subscribe((val) => {
      log.debug(val === this.PaymentMethods.BANK_TRANSFER);
      if (val === this.PaymentMethods.BANK_TRANSFER) {
        bankAccountId.setValidators([Validators.required]);
      } else {
        bankAccountId.clearValidators();
      }
      bankAccountId.updateValueAndValidity();
    });

    autoNumber.valueChanges.subscribe((val) => {
      log.debug('autoNumber change');
      if (val) {
        number.clearValidators();
        number.patchValue(null);
      } else {
        number.setValidators([Validators.required]);
      }
      number.updateValueAndValidity();
    });

    depositPercent.valueChanges.subscribe((val) => {
      log.debug('depositPercent change');
      if (!val) {
        balanceDueDate.patchValue(null);
      }
      balanceDueDate.updateValueAndValidity();
    });

    this.form = this.formBuilder.group({
      id: [null],
      clientable_type: [null, [Validators.required]],
      clientable_id: [null, [Validators.required]],
      clientable: [null],
      company_id: [null, [Validators.required]],
      company: [null],
      date: [null, [Validators.required]],
      due_date: [null],
      number: number,
      tax_id: [null],
      tax: [null],
      items: new FormArray([]),
      public_note: [null],
      private_note: [null],
      payment_method_id: paymentMethodId,
      payment_method: [null],
      bank_account_id: bankAccountId,
      bank_account: [null],
      reference: [null],
      type_id: [null, [Validators.required]],
      type: [null],
      currency_id: [null, [Validators.required]],
      currency: [null],
      auto_number: autoNumber,
      invoiceable_id: [null],
      invoiceable_type: [null],
      linked_id: [null],

      deposit_percent: depositPercent,
      vat_number: [null],
      client_address: [null],
      client_name: [null],
      deposit_invoices: [[]],

      paid: [false],

      balance_due_date: balanceDueDate,

      registration_number: [null],
      responsible_person: [null],
    });
  }

  private patchForm() {
    if (this.invoice && this.invoice.id) {
      const apiQuery: ApiQuery = new ApiQuery().addIncludes(
        'clientable',
        'type',
        'currency',
        'items.invoiceable_item.sale_offer_item.type',
        'company.bank_accounts.currency',
        'bank_account',
        'tax',
        'deposit_invoices',
        'invoiceable'
      );
      this.invoiceService.show(this.invoice.id, apiQuery).subscribe((invoice: Invoice) => {
        invoice.items.forEach((item: InvoiceItem) => {
          this.addLine();
        });

        this.form.patchValue(invoice);
        this.clientableSelectComponent.setValues(invoice.clientable_type, invoice.clientable_id, invoice.clientable);

        /**
         * за да може да се показва допълнителна информация
         * ако нещо не е добре да се направи в отделна от @input променливая
         */
        this.invoice = invoice;
      });
    } else if (this.invoice) {
      this.form.patchValue(this.invoice);
      this.clientableSelectComponent.setValues(
        this.invoice.clientable_type,
        this.invoice.clientable_id,
        this.invoice.clientable
      );
    }
  }

  private calculateTotals() {
    let totalPrice = 0;
    // @ts-ignore
    this.form.get('items').controls.forEach((itemControl: any) => {
      totalPrice += (itemControl.get('unit_price').value || 0) * (itemControl.get('quantity').value || 0);
    });
    this.totals.price = totalPrice;
  }

  private formatPost() {
    this.form.get('items')['controls'].forEach((item: any, index: number) => {
      item.get('order_index').patchValue(index + 1);
    });
    this.form.updateValueAndValidity();
    return this.form.value;
  }
}
