import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemindersFormComponent } from '@app/reminders/reminders/reminders-form/reminders-form.component';
import { Logger } from '@app/core';
import { Reminder } from '@app/reminders/reminders/reminder.model';

const log = new Logger('RemindersFormService');

@Injectable({
  providedIn: 'root',
})
export class RemindersFormService {
  constructor(private modalService: NgbModal) {}

  /**
   * Open reminder's form
   * @param reminder Reminder
   * @param size Reminder's type
   */
  open(reminder: null | Reminder = null, size?: string): Promise<any> {
    log.debug('open');
    const modal = this.modalService.open(RemindersFormComponent, { size: size ? size : 'lg' });
    modal.componentInstance.reminder = reminder;
    return modal.result;
  }
}
