import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sale-button',
  templateUrl: './sale-button.component.html',
  styleUrls: ['./sale-button.component.scss'],
})
export class SaleButtonComponent {
  @Input() link: Array<string>;
  @Input() params: object;
  @Input() targetBlank = false;

  constructor() {}
}
