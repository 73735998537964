<div class="container-fluid" *ngIf="accessService.hasAccess([], ['sales.menu'])">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'SALES.SALES_LIST.TITLE' | translate }}
      </h5>
    </div>

    <div class="d-flex align-items-center">
      <div>
        <app-client-emails></app-client-emails>
      </div>

      <div>
        <button type="button" class="btn btn-link" (click)="clearFilters()">
          {{ 'SALES.SALES_LIST.CLEAR_FILTERS' | translate }}
        </button>
      </div>

      <div *ngIf="accessService.hasAccess([], ['sales.mass_reassign'])">
        <app-sale-mass-reassign class="mr-2"> </app-sale-mass-reassign>
      </div>

      <div>
        <button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['sales.store']
            )
          "
          type="button"
          class="btn btn-secondary"
          (click)="add()"
        >
          {{ 'SALES.SALES_LIST.ADD' | translate }}
        </button>
      </div>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="requestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Clients_requests', grid)"
    [loading]="loader"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'SALES.SALES_LIST.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'SALES.SALES_LIST.ID' | translate }}"
      filter="numeric"
      [width]="120"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="client.name"
      title="{{ 'SALES.SALES_LIST.CLIENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('client.name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-client-box [client]="dataItem.client" [flag]="true"></app-client-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="client.city.timezone_diff"
      title="{{ 'SALES.SALES_LIST.CLIENT_TIME' | translate }}"
      [filterable]="false"
      [hidden]="isHiddenColumn('time')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div
          class="active-time-wrapper"
          *ngIf="dataItem.meta_front?.clientTime"
          [ngbTooltip]="'SALES.SALES_LIST.CLIENT_TIME' | translate"
          container="body"
        >
          <i class="fal fa-clock"></i> {{ dataItem.meta_front?.clientTime }}
        </div>
        <div class="active-time-wrapper" *ngIf="dataItem.meta_front?.clientTime === 'false'">
          <i class="fas fa-exclamation-triangle"></i>
          {{ 'SALES.SALES_LIST.CLIENT_TIMEZONE_MISSING' | translate }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="client.country.name"
      title="{{ 'SALES.SALES_LIST.CLIENT_COUNTRY' | translate }}"
      [hidden]="isHiddenColumn('client.country.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-country-select [filter]="filter" [field]="'client.country_id'"></app-kendo-country-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-country-box [country]="dataItem?.client?.country" [flag]="false"></app-country-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="clientTags"
      title="{{ 'SALES.SALES_LIST.CLIENT_TAGS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('clientTags', true)"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-kendo-tag-select [filter]="filter" [model]="LaravelModelsEnum.CLIENT" field="clientTags">
        </app-kendo-tag-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let tag of dataItem.client?.tags; let i = index">
          {{ tag }}<span *ngIf="i !== dataItem?.client?.tags?.length - 1">, </span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.formattedName"
      title="{{ 'SALES.SALES_LIST.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.formattedName')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.event?.id }" target="_blank">
          {{ dataItem?.event?.formattedName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.event_group.name"
      title="{{ 'SALES.SALES_LIST.GROUP_NAME' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.event_group.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-group-select [filter]="filter" [field]="'event.event_group_id'"></app-kendo-event-group-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/event-groups/view']"
          [queryParams]="{ id: dataItem?.event?.event_group?.id }"
          target="_blank"
        >
          {{ dataItem?.event?.event_group?.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="source.value"
      title="{{ 'SALES.SALES_LIST.SOURCE' | translate }}"
      filter="text"
      [hidden]="
        isHiddenColumn(
          'source.value',
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.SALES_MANAGER,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_source'"
          [filter]="filter"
          [field]="'source_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.source"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="status.value"
      title="{{ 'SALES.SALES_LIST.STATUS' | translate }}"
      filter="text"
      [hidden]="
        isHiddenColumn(
          'status.value',
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.SALES_MANAGER,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_status'"
          [filter]="filter"
          [field]="'status_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.status"></app-type-badge>
        <div class="text-truncate" [ngbTooltip]="dataItem.status_reason" container="body">
          {{ dataItem.status_reason }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="company.name"
      title="{{ 'SALES.SALES_LIST.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('company.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/settings/companies/view']" [queryParams]="{ id: dataItem?.company_id }" target="_blank">
          {{ dataItem?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sales_agent.fake_first_name"
      title="{{ 'SALES.SALES_LIST.SALES_AGENT_AND_SALES_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sales_agent.fake_first_name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
          [field]="'sales_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sales_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="reservation_agent.fake_first_name"
      title="{{ 'SALES.SALES_LIST.RESERVATION_AGENT_AND_RESERVATION_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('reservation_agent.fake_first_name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
          [field]="'reservation_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.reservation_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="customer_care_agent.fake_first_name"
      title="{{ 'SALES.SALES_LIST.CUSTOMER_CARE_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('customer_care_agent.fake_first_name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.CUSTOMER_CARE_AGENT"
          [field]="'customer_care_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.customer_care_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      width="90"
      field="rooms_count"
      title="{{ 'SALES.SALES_LIST.REQUESTED_ROOMS_COUNT' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('rooms_count')"
      format="0"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="count-column-inner-custom">
          <div>
            <small>{{ 'SALES.SALES_LIST.ALL' | translate }}</small>
          </div>
          {{ dataItem.rooms_count }}
        </div>
        <div class="count-column-inner-custom center border-left">
          <div>
            <small>{{ 'SALES.SALES_LIST.CLOSED' | translate }}</small>
          </div>
          {{ dataItem.requestsClosedRoomsCount }}
        </div>
        <div class="count-column-inner-custom border-left">
          <div>
            <small>{{ 'SALES.SALES_LIST.REST' | translate }}</small>
          </div>
          {{ dataItem.rooms_count - dataItem.requestsClosedRoomsCount }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      field="arrival_request.check_in"
      title="{{ 'SALES.SALES_LIST.FIRST_ARRIVAL_DATE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="
        isHiddenColumn(
          'arrival_request.check_in',
          accessService.hasAccess(
            [
              UserRoleType.IT,
              UserRoleType.MARKETING,
              UserRoleType.ACCOUNTANT,
              UserRoleType.LEAD_GENERATION,
              UserRoleType.CUSTOMER_CARE_AGENT
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.arrival_request?.check_in | date: env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="tags"
      title="{{ 'SALES.SALES_LIST.TAGS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('tags')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-kendo-tag-select [filter]="filter" [model]="LaravelModelsEnum.SALE"></app-kendo-tag-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let tag of dataItem?.tags; let i = index">
          {{ tag }}<span *ngIf="i !== dataItem?.tags?.length - 1">, </span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="channel.value"
      title="{{ 'SALES.SALES_LIST.CHANNEL' | translate }}"
      filter="text"
      [hidden]="
        isHiddenColumn(
          'channel.value',
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.SALES_MANAGER,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_channel'"
          [filter]="filter"
          [field]="'channel_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.channel"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="campaign.value"
      title="{{ 'SALES.SALES_LIST.CAMPAIGN' | translate }}"
      filter="text"
      [hidden]="
        isHiddenColumn(
          'campaign.value',
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.SALES_MANAGER,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_campaign'"
          [filter]="filter"
          [field]="'campaign_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.campaign"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="created_at"
      title="{{ 'SALES.SALES_LIST.CREATED_AT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="
        isHiddenColumn(
          'created_at',
          accessService.hasAccess(
            [
              UserRoleType.CUSTOMER_CARE_AGENT,
              UserRoleType.LEAD_GENERATION,
              UserRoleType.IT,
              UserRoleType.MARKETING,
              UserRoleType.ACCOUNTANT
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.created_at.getDate() }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      field="total_amount"
      title="{{ 'SALES.SALES_LIST.TOTAL_AMOUNT' | translate }}"
      filter="numeric"
      [hidden]="
        isHiddenColumn(
          'total_amount',
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.CUSTOMER_CARE_AGENT,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.LEAD_GENERATION,
              UserRoleType.FINANCE_MANAGER,
              UserRoleType.IT,
              UserRoleType.MARKETING,
              UserRoleType.ACCOUNTANT
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.total_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      field="balance"
      title="{{ 'SALES.SALES_LIST.BALANCE' | translate }}"
      filter="numeric"
      [hidden]="
        isHiddenColumn(
          'balance',
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.CUSTOMER_CARE_AGENT,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.LEAD_GENERATION,
              UserRoleType.FINANCE_MANAGER,
              UserRoleType.IT,
              UserRoleType.MARKETING,
              UserRoleType.ACCOUNTANT
            ],
            []
          )
        )
      "
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span
          class="font-weight-bold"
          [ngClass]="{ 'text-danger': dataItem?.balance < 0, 'text-success': dataItem?.balance >= 0 }"
        >
          {{ dataItem.balance | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="requests.status.value"
      title="{{ 'SALES.SALES_LIST.CLIENT_REQUESTS_STATUS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('requests.status.value')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-types-select
          [groupType]="'sale_request'"
          [filter]="filter"
          [field]="'requests.status_id'"
        ></app-kendo-types-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge
          *ngFor="let status of dataItem?.requestsUniqueStatuses; let i = index"
          [type]="status"
          class="d-block"
        ></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      field="assign_to_user.fake_first_name"
      title="{{ 'SALES.SALES_LIST.ASSIGN_TO_USER' | translate }}"
      [hidden]="isHiddenColumn('assign_to_user.fake_first_name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select [filter]="filter" [field]="'assign_to_user_id'"></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div>{{ dataItem?.assign_to_role?.name }}</div>
        <div>{{ dataItem?.assign_to_user?.names }}</div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      field="assign_date"
      title="{{ 'SALES.SALES_LIST.ASSIGN_DATE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('assign_date', true)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.assign_date | date: env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'SALES.SALES_LIST.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['sales.show']
            )
          "
          (click)="edit(dataItem)"
        >
        </app-edit-button>

        <app-sale-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER_LEAD
              ],
              ['sales.show']
            )
          "
          [link]="['/sales/view']"
          [params]="{ id: dataItem.id }"
        >
        </app-sale-button>

        <app-delete-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['sales.destroy']
            )
          "
          [swal]="{}"
          (confirm)="delete(dataItem)"
        >
        </app-delete-button>

        <app-download-vouchers-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['sales.vouchers.download']
            )
          "
          [type]="'sales'"
          [id]="dataItem.id"
          [companyId]="dataItem.company_id"
        ></app-download-vouchers-button>

        <app-media-library-button
          *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
          [ngbTooltip]="'SALES.SALES_LIST.UPLOAD_DOCUMENTS' | translate"
          container="body"
          [icon]="'fal fa-file'"
          [acceptType]="MediaLibraryAcceptTypes.ALL"
          [collectionName]="'documents'"
          [modelId]="dataItem.id"
          [modelType]="LaravelModelsEnum.SALE"
        >
        </app-media-library-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
