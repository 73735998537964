import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LinksComponent } from '@app/shared/components/links/links.component';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  constructor(private modalService: NgbModal) {}

  open(data: any): Promise<any> {
    const modal = this.modalService.open(LinksComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.data = data;
    return modal.result;
  }
}
