import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AllotmentFinderComponent } from '@app/shared/components/allotment-finder/allotment-finder.component';

@Injectable({
  providedIn: 'root',
})
export class AllotmentFinderService {
  constructor(private modalService: NgbModal) {}

  public open(
    returnValue: string = 'hotel',
    eventId: number = null,
    additionalInfo: any = null,
    hotelName: string = null
  ): Promise<any> {
    const modal = this.modalService.open(AllotmentFinderComponent, { size: 'full' as 'lg' });
    modal.componentInstance.returnValue = returnValue;
    modal.componentInstance.eventId = eventId;
    modal.componentInstance.additionalInfo = additionalInfo;
    modal.componentInstance.hotelName = hotelName;
    return modal.result;
  }
}
