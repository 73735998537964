import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sale } from '@app/sales/sales/sale.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesService } from '@app/sales/sales/sales.service';
import { ApiQuery } from '@app/core/http/api-query';
import { Router } from '@angular/router';
import { RequestsService } from '@app/sales/requests/requests.service';
import { RequestModel } from '@app/sales/requests/request.model';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { RequestsFormService } from '@app/sales/requests/requests-form.service';
import * as moment from 'moment';
import { environment } from '@env/environment';

const log = new Logger('SalesFormComponent');

@Component({
  selector: 'app-sales-form',
  templateUrl: './sales-form.component.html',
  styleUrls: ['./sales-form.component.scss'],
})
export class SalesFormComponent implements OnInit {
  @Input() sale: Sale = null;
  @Input() enquiry_id: number = null;
  @Input() redirectAfterSave: boolean;
  form: FormGroup;
  LaravelModelsEnum = LaravelModelsEnum;
  UserRoleType = UserRoleType;
  dateNow = moment().format(environment.apiPipeDateFormat);

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private salesService: SalesService,
    private router: Router,
    private requestsService: RequestsService,
    private requestsFormService: RequestsFormService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  save() {
    if (this.sale && this.sale.id) {
      const sale: Sale = this.sale.deserialize(this.form.value);
      this.salesService.update(sale).subscribe((res: any) => {
        this.modal.close(res);
        if (this.redirectAfterSave) {
          this.router.navigate(['/sales/view'], { queryParams: { id: res.id }, replaceUrl: true });
        }
      });
    } else {
      const sale: Sale = new Sale().deserialize(this.form.value);
      this.salesService.store(sale).subscribe((res) => {
        this.modal.close(res);
        if (this.redirectAfterSave) {
          this.router.navigate(['/sales/view'], { queryParams: { id: res.id }, replaceUrl: true });
        }
      });
    }
  }

  test(test: any) {
    log.debug(test);
  }

  editEnquiry(enquiryId: number) {
    this.requestsFormService.open(new RequestModel().deserialize({ id: enquiryId })).then(
      (res) => {
        log.debug(res);
      },
      (err: any) => {
        log.debug(err);
      }
    );
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      company_id: [null, [Validators.required]],
      company: [null],
      client_id: [null, [Validators.required]],
      client: [null],
      event_id: [null, [Validators.required]],
      event: [null],
      sales_agent_id: [null],
      sales_agent: [null],
      reservation_agent_id: [null],
      reservation_agent: [null],
      customer_care_agent_id: [null],
      customer_care_agent: [null],
      enquiry_id: [null],
      note: [null],
      status_id: [null],
      status_reason: [null],
      status: [null],
      channel_id: [null],
      channel: [null],
      source_id: [null],
      source: [null],
      campaign_id: [null],
      campaign: [null],
      from_advertising: [false],
      tags: [],
      assign_to_user_id: [null],
      assign_to_role_id: [null],
      assign_date: [this.sale && this.sale.id ? null : moment().format(environment.apiPipeDateFormat)],
    });
  }

  private patchForm() {
    /*
     * гетва sale-а и го пълни
     */
    if (this.sale && this.sale.id) {
      /*
       * Гетва hotel-a от сървиса по ID
       * Тук се подават като параметри всички includes(relations), които са нужни
       */
      const apiQuery: ApiQuery = new ApiQuery()
        .setLimit(0)
        .addIncludes('company', 'event', 'client', 'sales_agent', 'customer_care_agent', 'reservation_agent', 'tags');
      this.salesService.show(this.sale.id, apiQuery).subscribe((sale) => {
        setTimeout(() => {
          this.form.patchValue(sale);
        });
      });
    } else if (this.enquiry_id) {
      const query = new ApiQuery().addIncludes('company', 'client', 'event');
      this.requestsService.show(this.enquiry_id, query).subscribe((enquiry: RequestModel) => {
        const patchValue = {
          enquiry_id: this.enquiry_id,
          company_id: enquiry.company_id,
          company: enquiry.company,
          client_id: enquiry.client_id,
          client: enquiry.client,
          event_id: enquiry.event_id,
          event: enquiry.event,
        };
        this.form.patchValue(patchValue);
        this.form.updateValueAndValidity(); // за всеки случай може да се махне при оптимизация
      });
    } else if (this.sale) {
      setTimeout(() => {
        this.form.patchValue(this.sale);
      });
    }
  }
}
