<div class="dashboard-box1-wrapper mb-1">
  <div class="dashboard-box1-header d-flex justify-content-between">
    <div class="d-flex justify-content-between">
      <div class="dashboard-box1-title">
        {{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.LAST_BOOKINGS_LIST_TITLE' | translate }}
      </div>

      <div class="collapse-trigger ml-2" (click)="toggle()">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-chevron-down" *ngIf="collapsed"></i>
          <i class="fal fa-chevron-up" *ngIf="!collapsed"></i>
        </button>
      </div>

      <div class="refresh-trigger ml-2" (click)="loadData()" *ngIf="!collapsed">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-sync"></i>
        </button>
      </div>
    </div>

    <div>
      <small class="pointer btn-link" routerLink="/hotel-bookings-rooms">
        {{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.VIEW_ALL' | translate }}
      </small>
    </div>
  </div>

  <div class="dashboard-box1-body" [perfectScrollbar] *ngIf="!collapsed">
    <div *ngIf="!data">
      {{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.NO_LAST_BOOKINGS_MSG' | translate }}
    </div>

    <div class="table" *ngIf="data && data.length > 0">
      <thead>
        <tr>
          <th>{{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.NUMBER' | translate }}</th>
          <th>{{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.HOTEL' | translate }}</th>
          <th style="width: 50%">{{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.EVENTS' | translate }}</th>
          <th>{{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.COUNT' | translate }}</th>
          <th class="no-wrap">{{ 'DASHBOARD.DASHBOARD_LAST_BOOKINGS.NET_PRICE_AVG' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let allotment of data; let I = index">
          <td>{{ I + 1 }}</td>
          <td>
            <a [routerLink]="'/hotels/view'" [queryParams]="{ id: allotment.hotel_id }" target="_blank">
              {{ allotment.hotel?.name }}
            </a>
          </td>
          <td>
            <span *ngFor="let event of allotment.booking.events; let i = index">
              <a [routerLink]="['/events/view']" [queryParams]="{ id: event.id }" target="_blank">
                {{ event.formattedName }}
              </a>
              <span *ngIf="i !== allotment?.booking?.events?.length - 1">, </span>
            </span>
          </td>
          <td>
            {{ allotment.total_rooms }}
          </td>
          <td>{{ allotment.net_price_eur_avg | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}</td>
        </tr>
      </tbody>
    </div>
  </div>
</div>
