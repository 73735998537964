import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplatesPreviewFormComponent } from '@app/settings/templates/templates-preview-form/templates-preview-form.component';

@Injectable({
  providedIn: 'root',
})
export class TemplatesPreviewFormService {
  constructor(private modalService: NgbModal) {}

  public open(template: string): Promise<any> {
    const modal = this.modalService.open(TemplatesPreviewFormComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.template = template;
    return modal.result;
  }
}
