import { Injectable } from '@angular/core';
import { ResourceInterface } from '@app/shared/models/resource.interface';
import { HttpClient } from '@angular/common/http';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { SaleBonus } from '@app/bonuses/sale-bonuses/sale-bonus.model';
import { Model } from '@app/shared/models/model.model';
import { Recipient } from '@app/bonuses/sale-bonuses/recipient.model';

@Injectable({
  providedIn: 'root',
})
export class SaleBonusesService implements ResourceInterface {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/sales/bonuses', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((saleBonus: any) => {
          return new SaleBonus().deserialize(saleBonus);
        });
        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  indexRecipients(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/sales/bonus-recipients', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((recipient: any) => {
          return new Recipient().deserialize(recipient);
        });
        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  update(saleBonus: SaleBonus): Observable<SaleBonus> {
    return this.http.put('v1/sales/bonuses/' + saleBonus.id, saleBonus).pipe(
      map((response: HttpResponse) => {
        return new SaleBonus().deserialize(response.data);
      })
    );
  }

  store(saleBonus: SaleBonus): Observable<SaleBonus> {
    return this.http.post('v1/sales/bonuses', saleBonus).pipe(
      map((response: HttpResponse) => {
        return new SaleBonus().deserialize(response.data);
      })
    );
  }

  show(id: number, query: ApiQuery = new ApiQuery()): Observable<Model> {
    return this.http.get('v1/sales/bonuses/' + id, query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return new SaleBonus().deserialize(response.data);
      })
    );
  }

  destroy(id: number): Observable<any> {
    return this.http.delete('v1/sales/bonuses/' + id);
  }
}
