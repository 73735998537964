import { Injectable } from '@angular/core';
import { Currency } from '@app/settings/currencies/currency.model';
import { Logger } from '@app/core';

const log = new Logger('CurrenciesAdditionsService');

@Injectable({
  providedIn: 'root',
})
export class CurrenciesAdditionsService {
  /**
   * Обръща всяка валута в евро
   * @param num Числова стойност
   * @param currency Валута, от която обръща в евро
   * @param exchangeRate  exchangeRate по който да смята(не задължително)
   */
  static toEUR(num: number, currency: Currency, exchangeRate: number = null): number | null {
    if (!currency.exchange_rate) {
      log.warn('toEUR: INVALID CURRENCY PASSED');
      return null;
    }

    if (exchangeRate) {
      return Number((num / exchangeRate).toFixed(2));
    }
    return Number((num / currency.exchange_rate).toFixed(2));
  }

  constructor() {}
}
