import { Component, Input, OnInit } from '@angular/core';
import { Client } from '@app/clients/clients/client.model';

@Component({
  selector: 'app-client-box',
  templateUrl: './client-box.component.html',
  styleUrls: ['./client-box.component.scss'],
})
export class ClientBoxComponent implements OnInit {
  @Input() client: Client;
  @Input() targetBlank = true;
  @Input() flag = false;

  constructor() {}

  ngOnInit() {}
}
