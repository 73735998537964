<button
  [ngbTooltip]="'DOWNLOAD_ROOMING_LIST_BUTTON.DOWNLOAD_ROOMING_LIST' | translate"
  container="body"
  (click)="downloadRoomingList()"
  class="btn btn-primary"
  [ngClass]="{ 'btn-ico': iconButton, 'btn-sm': !iconButton }"
>
  <i class="fas fa-download"></i>

  <span *ngIf="!iconButton">
    {{ 'DOWNLOAD_ROOMING_LIST_BUTTON.DOWNLOAD_ROOMING_LIST' | translate }}
  </span>
</button>
