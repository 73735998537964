import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, Logger } from '@app/core';
import { Payment } from '@app/finance/payments/payment.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentsService } from '@app/finance/payments/payments.service';
import { ApiQuery } from '@app/core/http/api-query';
import { PaymentMethodType } from '@app/settings/types/payment-method-type.enum';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { PaymentsTypes } from '@app/finance/payments/payments-types.enum';
import { Invoice } from '@app/finance/invoices/invoice.model';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import * as moment from 'moment';
import { ClientableSelectComponent } from '@app/shared/components/clientable-select/clientable-select.component';
import { ClientabeType } from '@app/shared/components/clientable-select/clientabe-type.enum';
import { environment } from '@env/environment';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';

const log = new Logger('PaymentsFormComponent');

@Component({
  selector: 'app-payments-form',
  templateUrl: './payments-form.component.html',
  styleUrls: ['./payments-form.component.scss'],
})
export class PaymentsFormComponent implements OnInit {
  @Input() payment: Payment = null;
  form: FormGroup;
  PaymentMethods = PaymentMethodType;
  LaravelModels = LaravelModelsEnum;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;
  @ViewChild('clientableSelectComponent', { static: true }) clientableSelectComponent: ClientableSelectComponent;
  env = environment;
  isCurrentUserAdministratorOrFinance = false;
  isCurrentUserFinanceManager = false;
  paymentTypes = PaymentsTypes;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private paymentsService: PaymentsService,
    private invoicesService: InvoicesService,
    private hotelBookingService: HotelBookingsService,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
      if (
        role.id === UserRoleType.ADMINISTRATOR ||
        role.id === UserRoleType.FINANCE_MANAGER ||
        role.id === UserRoleType.FINANCE_AGENT
      ) {
        this.isCurrentUserAdministratorOrFinance = true;
      }

      if (role.id === UserRoleType.FINANCE_MANAGER) {
        this.isCurrentUserFinanceManager = true;
      }
    });

    this.createForm();
    this.patchForm();
  }

  save() {
    if (this.form.get('type_id').value === PaymentsTypes.INCOME) {
      // Венко каза така на 04.05.2020 12:20
      this.form.get('is_paid').patchValue(true);
      this.form.get('is_approved').patchValue(true);
      this.form.get('has_documents').patchValue(false);
    }
    if (this.payment && this.payment.id) {
      // const payment: Payment = this.payment.deserialize(this.form.value);
      const payment: Payment = new Payment().deserialize(this.form.value);
      this.paymentsService.update(payment).subscribe((res: Payment) => this.modal.close('Save click'));
    } else {
      const payment: Payment = new Payment().deserialize(this.form.value);
      this.paymentsService.store(payment).subscribe((res) => this.modal.close(res));
    }
  }

  test() {
    log.debug(this.form);
  }

  /**
   * Пали когато се смени company
   */
  companyChange() {
    this.form.get('bank_account_id').patchValue(null);
    this.form.get('bank_account').patchValue(null);
  }

  /**
   * Пали когато се смени is_paid
   */
  isPaidChange() {
    if (!this.form.get('is_approved').value) {
      this.form.get('is_paid').patchValue(false);
    }
  }

  private createForm() {
    const paymentMethodId = new FormControl(null, [Validators.required]);
    const bankAccountId = new FormControl(null);
    const feeAmount = new FormControl(null, [Validators.required]);
    const feeCurrencyId = new FormControl(null, [Validators.required]);

    if (!this.isCurrentUserAdministratorOrFinance) {
      feeAmount.clearValidators();
      feeCurrencyId.clearValidators();
    }

    // ако payment-a се създава от booking тези валидация трябва да я няма
    if (this.isCurrentUserAdministratorOrFinance) {
      paymentMethodId.valueChanges.subscribe((val) => {
        if (val === this.PaymentMethods.BANK_TRANSFER) {
          bankAccountId.setValidators([Validators.required]);
        } else {
          bankAccountId.clearValidators();
        }
        bankAccountId.updateValueAndValidity();
      });
    }

    this.form = this.formBuilder.group({
      id: [null],
      company_id: [null, [Validators.required]],
      company: [null],
      clientable_type: [null, [Validators.required]],
      clientable_id: [null, [Validators.required]],
      clientable: [null],
      type_id: [null, [Validators.required]],
      payment_method_id: paymentMethodId,
      bank_account_id: bankAccountId,
      bank_account: [null],
      amount: [null, [Validators.required]],
      date: [null, [Validators.required]],
      currency_id: [null, [Validators.required]],
      private_note: [null],
      reference: [null],
      paymentable_id: [null],
      paymentable_type: [null],
      paymentable: [null],
      clean_paymentable_type: [null],
      fee_amount: feeAmount,
      fee_currency_id: feeCurrencyId,
      fee_currency: [null],
      is_paid: [{ value: false, disabled: !this.isCurrentUserAdministratorOrFinance }],
      is_approved: [{ value: false, disabled: !this.isCurrentUserFinanceManager }],
      has_documents: [{ value: false, disabled: !this.isCurrentUserAdministratorOrFinance }],

      refundable_id: [null],
      refundable_percent: [null],
      refundable_date: [null],

      sale_id: [null],
    });
  }

  private patchForm() {
    if (this.payment && this.payment.id) {
      const apiQuery: ApiQuery = new ApiQuery().addIncludes(
        'company.bank_accounts.currency',
        'clientable.bank_accounts'
      );
      this.paymentsService.show(this.payment.id, apiQuery).subscribe((payment: Payment) => {
        // Това се налага, за да могат да се викат get-ерите от модела... Ако има друг начин да се махне
        payment['clean_paymentable_type'] = payment.cleanPaymentableType;

        this.form.patchValue(payment);
        this.clientableSelectComponent.setValues(payment.clientable_type, payment.clientable_id, payment.clientable);
      });
    } else if (this.payment && !this.payment.id && this.payment.paymentable_id && this.payment.paymentable_type) {
      if (this.payment.paymentable_type === LaravelModelsEnum.INVOICE) {
        const query = new ApiQuery().addIncludes('company.bank_accounts.currency', 'clientable');
        this.invoicesService.show(this.payment.paymentable_id, query).subscribe((invoice: Invoice) => {
          const payment = new Payment().deserialize({
            company_id: invoice.company_id,
            company: invoice.company,
            clientable_id: invoice.clientable_id,
            clientable: invoice.clientable,
            clientable_type: invoice.clientable_type,
            payment_method_id: invoice.payment_method_id,
            bank_account_id: invoice.bank_account_id,
            type_id: PaymentsTypes.INCOME,
            reference: invoice.reference,
            private_note: invoice.private_note,
            amount: Math.abs(invoice.balance),
            date: invoice.date,
            //  currency_id: invoice.currency_id,
            paymentable_id: invoice.id,
            paymentable_type: this.payment.paymentable_type,
          });

          // Това се налага, за да могат да се викат get-ерите от модела... Ако има друг начин да се махне
          payment['clean_paymentable_type'] = payment.cleanPaymentableType;

          this.form.patchValue(payment);
          this.clientableSelectComponent.setValues(payment.clientable_type, payment.clientable_id, payment.clientable);
        });
      } else if (this.payment.paymentable_type === LaravelModelsEnum.BOOKING) {
        const query = new ApiQuery().addIncludes(
          'company.bank_accounts.currency',
          'hotel.bank_accounts',
          'supplier.bank_accounts'
        );
        this.hotelBookingService.show(this.payment.paymentable_id, query).subscribe((booking: HotelBooking) => {
          const payment = new Payment().deserialize({
            clientable_id: booking.supplier_id ? booking.supplier_id : booking.hotel_id, //  Task #277: Suppliers - Add Bank Details field
            clientable_type: booking.supplier_id ? ClientabeType.SUPPLIER : ClientabeType.HOTEL, //  Task #277: Suppliers - Add Bank Details field
            clientable: booking.supplier_id ? booking.supplier : booking.hotel, //  Task #277: Suppliers - Add Bank Details field
            company_id: booking.company_id,
            company: booking.company,
            type_id: PaymentsTypes.EXPENSE, // #277: Suppliers - Add Bank Details field
            private_note: booking.note,
            amount: Math.abs(booking.balance),
            date: moment().format(this.env.apiPipeDateFormat),
            // currency_id: booking.currency_id,
            paymentable_id: booking.id,
            paymentable_type: this.payment.paymentable_type,
            paymentable: booking,
            sale_id: this.payment.sale_id,
          });

          // Това се налага, за да могат да се викат get-ерите от модела... Ако има друг начин да се махне
          payment['clean_paymentable_type'] = payment.cleanPaymentableType;

          this.clientableSelectComponent.setValues(payment.clientable_type, payment.clientable_id, payment.clientable);
          this.form.patchValue(payment);
        });
      } else if (this.payment.paymentable_type === LaravelModelsEnum.SALE_FINAL_ITEM) {
        const payment = new Payment().deserialize({
          ...this.payment,
          clientable_type: ClientabeType.HOTEL,
          date: moment().format(this.env.apiPipeDateFormat),
          type_id: PaymentsTypes.EXPENSE,
        });

        this.clientableSelectComponent.setValues(payment.clientable_type, payment.clientable_id, payment.clientable);
        this.form.patchValue(payment);
      }
    }
  }
}
