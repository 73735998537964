import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { InvoicesFormService } from '@app/finance/invoices/invoices-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { AuthenticationService, Logger } from '@app/core';
import { Invoice } from '@app/finance/invoices/invoice.model';
import * as FileSaver from 'file-saver';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { PaymentsTypes } from '@app/finance/payments/payments-types.enum';
import { InvoiceType } from '@app/finance/invoices/invoice-type.enum';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { AccessService } from '@app/core/access/access.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSet } from '@app/shared/kendo/data-set';

const log = new Logger('InvoicesListComponent');

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss'],
})
export class InvoicesListComponent extends KendoGridComponent implements OnInit {
  invoicesResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  LaravelModelsEnum = LaravelModelsEnum;
  PaymentsTypes = PaymentsTypes;
  InvoiceType = InvoiceType;

  UserRoleType = UserRoleType;

  constructor(
    private invoicesService: InvoicesService,
    private invoicesFormService: InvoicesFormService,
    protected kendoGridService: KendoGridService,
    public accessService: AccessService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.state = CustomKendoFilter.getStateFromStorage('InvoicesListComponent');

      if (params.get('id')) {
        this.state = new DataSet().addFilter('id', Number(params.get('id')));
      }

      this.loadData(this.state);
    });
  }

  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes('company', 'clientable', 'type', 'payment_method', 'invoiceable');

    // проверява дали юзъра има определени роли, но не админ
    if (!this.accessService.hasAccess([], [])) {
      this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
        if (role.id === UserRoleType.RESERVATION_AGENT || role.id === UserRoleType.SALES_AGENT) {
          this.apiQuery.addFilter('user_id', this.auth.accessToken.profile.id);
        }
      });
    }

    this.invoicesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.invoicesResponse = response;
      this.data = response.getGridData();
    });

    CustomKendoFilter.pushStateInStorage('InvoicesListComponent', this.state);
  }

  add() {
    this.invoicesFormService.open().then(
      (response) => {
        this.loadData(this.state);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  edit(invoice: Invoice): void {
    this.invoicesFormService.open(invoice).then(
      (res) => {
        this.loadData(this.state);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  delete(invoice: Invoice): void {
    this.invoicesService.destroy(invoice.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  invoiceRowCallback(context: RowClassArgs) {
    let rowOpacity = false;

    if (context.dataItem.linked_id && context.dataItem.type_id === InvoiceType.PROFORMA) {
      rowOpacity = true;
    }
    return {
      'kendo-row-opacity': rowOpacity,
    };
  }

  clearFilters() {
    this.router.navigate(['/invoices']);
    this.apiQuery = new ApiQuery();
    this.loadData();
  }
}
