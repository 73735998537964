import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Logger } from '@app/core';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { FilterService } from '@progress/kendo-angular-grid';

const log = new Logger('KendoUniversalInputComponent');

@Component({
  selector: 'app-kendo-universal-input',
  templateUrl: './kendo-universal-input.component.html',
  styleUrls: ['./kendo-universal-input.component.scss'],
})
export class KendoUniversalInputComponent extends CustomKendoFilter implements OnInit, OnChanges {
  @Input() field: string;
  @Input() type = 'text';
  model: any = null;

  debounceTime = 1000;
  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  ngOnInit(): void {
    if (!this.field) {
      log.error('Provide field as string!!!');
    }

    const preSelected = this.getObjectId(this.field);
    if (preSelected) {
      this.model = preSelected;
    }

    this.subscription = this.modelChanged.pipe(debounceTime(this.debounceTime)).subscribe(() => {
      this.keyUp();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Изчиства формата
    // Използва се, за да чисти филтрите, когато се сетне null state на таблицата
    if (changes && changes.filter && !changes.filter.currentValue) {
      this.model = null;
    }
  }

  inputChanged() {
    this.modelChanged.next();
  }

  keyUp() {
    this.filter = this.removeFilter(this.field);

    const filters = [];
    if (this.model) {
      filters.push({
        field: this.field,
        operator: 'eq',
        value: this.model,
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: [],
    };

    if (filters.length) {
      root.filters.push(...filters);
    }
    this.filterService.filter(root);
    log.debug('filters', filters);
  }
}
