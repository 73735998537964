import { Model } from '@app/shared/models/model.model';

export class Email extends Model {
  id: number;
  from: string;
  to: string;
  subject: string;
  body: string;
  attachments: Array<any>;
}
