import { Component, Input, OnInit } from '@angular/core';
import { PhoneCallsFinderService } from '@app/shared/components/phone-calls-finder/phone-calls-finder.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';

@Component({
  selector: 'app-phone-calls-finder-button',
  templateUrl: './phone-calls-finder-button.component.html',
  styleUrls: ['./phone-calls-finder-button.component.scss'],
})
export class PhoneCallsFinderButtonComponent {
  @Input() id: number;
  @Input() type: LaravelModelsEnum;

  constructor(private phoneCallsFinderService: PhoneCallsFinderService) {}

  open() {
    this.phoneCallsFinderService.open(this.id, this.type);
  }
}
