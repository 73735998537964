<div class="dashboard-box1-wrapper mb-1">
  <div class="dashboard-box1-header d-flex- justify-content-between">
    <div class="d-flex">
      <div class="dashboard-box1-title">
        {{ 'DASHBOARD.DASHBOARD_NOTIFICATIONS.NOTIFICATIONS_LIST_TITLE' | translate }}
      </div>

      <div class="collapse-trigger ml-2" (click)="toggle()">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-chevron-down" *ngIf="collapsed"></i>
          <i class="fal fa-chevron-up" *ngIf="!collapsed"></i>
        </button>
      </div>

      <div class="refresh-trigger ml-2" (click)="loadData()" *ngIf="!collapsed">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-sync"></i>
        </button>
      </div>

      <div class="ml-auto">
        <small class="pointer btn-link" routerLink="/notifications">
          {{ 'DASHBOARD.DASHBOARD_NOTIFICATIONS.VIEW_ALL' | translate }}
        </small>
      </div>
    </div>
  </div>

  <div class="dashboard-box1-body" [perfectScrollbar] *ngIf="!collapsed">
    <div *ngIf="data && !data.length">
      {{ 'DASHBOARD.DASHBOARD_NOTIFICATIONS.NO_NOTIFICATIONS_MSG' | translate }}
    </div>
    <div class="dashboard-box1-item" *ngFor="let notification of data; let I = index">
      <div class="row align-items-center">
        <div class="col-sm-9">
          <div class="note">
            {{ notification?.text }}
          </div>
        </div>

        <div class="col-sm-3 text-right">
          <a
            class="btn btn-warning btn-ico"
            container="body"
            target="_blank"
            [href]="notification?.link"
            *ngIf="notification?.link"
            [ngbTooltip]="'NOTIFICATIONS.GO_TO_TARGET' | translate"
          >
            <i class="fal fa-external-link"></i>
          </a>

          <button type="button" class="btn btn-sm btn-outline-success btn-complete" (click)="markAsRead(notification)">
            <i class="far fa-check"></i>
            {{ 'NOTIFICATIONS.NOTIFICATIONS_FORM.MARK_AS_READ' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
