import {Component, Input, OnInit} from '@angular/core';
import {Logger} from '@app/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SaleBonus} from '@app/bonuses/sale-bonuses/sale-bonus.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiQuery} from '@app/core/http/api-query';
import {SaleBonusesService} from '@app/bonuses/sale-bonuses/sale-bonuses.service';
import {Recipient} from '@app/bonuses/sale-bonuses/recipient.model';
import {PaginatedResponse} from '@app/shared/models/paginated-response.model';
import {InvoicesService} from '@app/finance/invoices/invoices.service';
import {Invoice} from '@app/finance/invoices/invoice.model';
import {environment} from '@env/environment';
import {cloneDeep, sumBy} from 'lodash';
import moment from 'moment';

const log = new Logger('SaleBonusesFormComponent');

@Component({
  selector: 'app-sale-bonuses-form',
  templateUrl: './sale-bonuses-form.component.html',
  styleUrls: ['./sale-bonuses-form.component.scss'],
})
export class SaleBonusesFormComponent implements OnInit {
  @Input() saleBonus: SaleBonus = null;
  form: FormGroup;
  env = environment;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private saleBonusService: SaleBonusesService,
    private invoicesService: InvoicesService
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.loadInvoices();
  }

  save() {
    if (this.saleBonus && this.saleBonus.id) {
      this.saleBonusService.update(this.formatPost()).subscribe((res) => this.modal.close(res));
    } else {
      this.saleBonusService.store(this.formatPost()).subscribe((res) => this.modal.close(res));
    }
  }

  formatPost(): SaleBonus {
    const post = cloneDeep(this.form.value);
    const invoices: Array<any> = [];

    post.invoices.forEach((invoiceControl: any) => {
      if (invoiceControl.checked) {
        invoices.push({id: invoiceControl.invoice.id});
      }
    });

    post.invoices = invoices;

    if (this.saleBonus && this.saleBonus.id) {
      return this.saleBonus.deserialize(post);
    } else {
      const saleBonus: SaleBonus = new SaleBonus().deserialize(post);
      if (this.saleBonus.sale_id) {
        saleBonus.sale_id = this.saleBonus.sale_id;
      }
      return saleBonus;
    }
  }

  addRecipient() {
    log.debug('addRecipient');
    // @ts-ignore
    this.form.controls.recipients.push(this.getEmptyRecipient());
  }

  removeRecipient(index: number) {
    this.form.get('recipients')['controls'].splice(index, 1);
    this.form.get('recipients').updateValueAndValidity();
  }

  loadInvoices() {
    const invoiceApiQuery = new ApiQuery()
      .addIncludes('company', 'clientable', 'invoiceable.sale_offer', 'bonuses')
      .addFilters({
        sale_id: this.saleBonus.sale_id,
        hide_linked: true,
      })
      .setLimit(99999);

    this.invoicesService.index(invoiceApiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((invoice: Invoice) => {

        if (invoice?.bonuses.length > 0) {
          invoice['meta'] = {};
          invoice['meta']['bonuses'] = {};
          invoice['meta']['bonuses']['sum_amount'] = sumBy(invoice.bonuses, 'amount');
          invoice['meta']['bonuses']['last_date'] = moment.max(invoice.bonuses.map((bns: SaleBonus) => {
            return moment(bns.date);
          }));
        }
        log.debug('invoice', invoice);


        this.addInvoice(invoice);
      });
      this.patchForm();
    });
  }

  test(item: any = null) {
    log.debug(this.form, item);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      amount: [null, [Validators.required]],
      date: [null, [Validators.required]],
      refundable: [false],
      active: [true],
      recipients: new FormArray([]),
      invoices: new FormArray([]),
    });
  }

  private getEmptyInvoiceControl(invoice: Invoice = null): FormGroup {
    return new FormGroup({
      invoice: new FormControl(invoice ? invoice : null),
      checked: new FormControl(false),
    });
  }

  private addInvoice(invoice: Invoice = null) {
    // @ts-ignore
    this.form.get('invoices').push(this.getEmptyInvoiceControl(invoice ? invoice : null));
    this.form.get('invoices').updateValueAndValidity();
  }

  private patchForm(): void {
    if (this.saleBonus && this.saleBonus.id) {
      const query = new ApiQuery().addIncludes('recipients.user', 'invoices');
      this.saleBonusService.show(this.saleBonus.id, query).subscribe((saleBonus: SaleBonus) => {
        if (saleBonus['recipients'].length > 0) {
          this.form.setControl('recipients', this.createMany(saleBonus['recipients']));
        }

        if (saleBonus['invoices'].length > 0) {
          saleBonus['invoices'].forEach((invoice: Invoice) => {
            this.form.get('invoices')['controls'].forEach((invoiceFromForm: any) => {
              if (invoice.id === invoiceFromForm.get('invoice').value.id) {
                invoiceFromForm.get('checked').patchValue(true);
              }
            });
          });
        }

        setTimeout(() => {
          this.form.patchValue(saleBonus);
          this.form.updateValueAndValidity();
        });
      });
    } else if (this.saleBonus) {
      this.form.patchValue(this.saleBonus);
    }
  }

  private getEmptyRecipient(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      percent: new FormControl(null),
      user_type: new FormControl(null),
      user_id: new FormControl(null),
      user: new FormControl(null),
    });
  }

  private createMany(recipients: Array<Recipient>): FormArray {
    const formArray: FormArray = new FormArray([]);
    recipients.forEach((recipient: Recipient) => {
      formArray.push(this.getEmptyRecipient());
    });
    return formArray;
  }
}
