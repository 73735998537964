<div class="modal-header">
  <h4 class="modal-title">
    <span *ngIf="template && template.id">{{ 'TEMPLATES.TEMPLATES_FORM.EDIT_TEMPLATE' | translate }}</span>
    <span *ngIf="!template || !template.id">{{ 'TEMPLATES.TEMPLATES_FORM.ADD_TEMPLATE' | translate }}</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ps" [perfectScrollbar]>
  <div class="row">
    <!--    <div class="col-sm-12" [ngClass]="form.valid ? 'col-sm-10' : 'col-sm-12'">-->
    <div class="col-sm-12">
      <form id="templates-form" (ngSubmit)="save()" [formGroup]="form" novalidate>
        <div class="row">
          <div class="col-sm-2">
            <app-company-select [parentFormGroup]="form"></app-company-select>
          </div>

          <div class="col-sm-2">
            <label>{{ 'TEMPLATES.TEMPLATES_FORM.LOCALE' | translate }}</label>
            <app-locale-select [parentFormGroup]="form"></app-locale-select>
          </div>

          <div class="col-sm-2">
            <label>{{ 'TEMPLATES.TEMPLATES_FORM.TYPE' | translate }}</label>
            <app-type-select
              [groupType]="'template'"
              [passFormControlName]="'type_id'"
              [parentFormGroup]="form"
            ></app-type-select>
          </div>

          <div class="col-sm-1 offset-sm-5">
            <div class="form-group">
              <label>{{ 'TEMPLATES.TEMPLATES_FORM.OBJECT_ID' | translate }}</label>
              <input type="number" class="form-control" formControlName="object_id" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label>{{ 'TEMPLATES.TEMPLATES_FORM.NAME' | translate }}</label>
              <input type="text" class="form-control" formControlName="name" />
            </div>
          </div>

          <div class="col-sm-9">
            <div class="form-group">
              <label>{{ 'TEMPLATES.TEMPLATES_FORM.SUBJECT' | translate }}</label>
              <input type="text" class="form-control" formControlName="subject" />
            </div>
          </div>
        </div>
        <div>
          <div
            class="btn-group btn-group-toggle"
            ngbRadioGroup
            name="editorMode"
            [(ngModel)]="editorMode"
            [ngModelOptions]="{ standalone: true }"
          >
            <!--            <label ngbButtonLabel class="btn-info">-->
            <!--              <input ngbButton type="radio" [value]="true" [disabled]="true" />-->
            <!--              {{ 'TEMPLATES.TEMPLATES_FORM.EDITOR_MODE' | translate }}-->
            <!--            </label>-->
            <label ngbButtonLabel class="btn-info">
              <input ngbButton type="radio" [value]="false" />
              {{ 'TEMPLATES.TEMPLATES_FORM.CODE_MODE' | translate }}
            </label>
          </div>

          <ngx-codemirror
            *ngIf="!editorMode"
            formControlName="html_template"
            [options]="{
              lineNumbers: true,
              theme: 'material',
              mode: 'htmlmixed'
            }"
          >
          </ngx-codemirror>

          <!--          <p-editor *ngIf="editorMode" formControlName="html_template" [style]="{ height: '520px' }"></p-editor>-->
        </div>
      </form>
    </div>

    <!--    <div class="col-sm-2">-->
    <!--      <div class="variables-wrapper">-->
    <!--        <div class="modal-section-title">{{ 'TEMPLATES.TEMPLATES_FORM.VARIABLES' | translate }}</div>-->
    <!--        <ngx-json-viewer [json]="variables"></ngx-json-viewer>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>

<div class="modal-footer d-flex">
  <div>
    <span *ngIf="!form.get('object_id').value" class="d-inline-block mr-3 text-danger">
      {{ 'TEMPLATES.TEMPLATES_FORM.OBJECT_ID_EMPTY_MSG' | translate }}
    </span>

    <button
      type="submit"
      class="btn btn-outline-dark btn-xs"
      (click)="saveAndPreview()"
      [disabled]="!form.get('object_id').value"
    >
      {{ 'TEMPLATES.TEMPLATES_FORM.PREVIEW' | translate }}
    </button>

    <button type="submit" class="btn btn-outline-dark btn-xs" form="templates-form" [disabled]="form.invalid">
      {{ 'TEMPLATES.TEMPLATES_FORM.SAVE' | translate }}
    </button>
  </div>
</div>
