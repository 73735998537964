import { Component, Input, OnInit } from '@angular/core';
import { Type } from '@app/settings/types/types.model';

@Component({
  selector: 'app-type-badge',
  templateUrl: './type-badge.component.html',
  styleUrls: ['./type-badge.component.scss'],
})
export class TypeBadgeComponent implements OnInit {
  @Input() type: Type | object;

  constructor() {}

  ngOnInit() {
    if (this.type && !(this.type instanceof Type)) {
      this.type = new Type().deserialize(this.type);
    }
  }
}
