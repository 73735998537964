import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Logger } from '@app/core';
import { WebOffer } from '@app/sales/web-offers/web-offer.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebOffersService } from '@app/sales/web-offers/web-offers.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { ApiQuery } from '@app/core/http/api-query';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';
import { SalesOffersService } from '@app/sales/sales/sales-offers.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { WebOffersItem } from '@app/sales/web-offers/web-offers-item.model';
import { environment } from '@env/environment';
import { CurrenciesAdditionsService } from '@app/shared/services/currencies-additions.service';
import { TemplatesService } from '@app/settings/templates/templates.service';
import { EmailTemplateCompiler } from '@app/settings/templates/email-template-compiler.model';
import { Templates } from '@app/settings/types/templates.enum';
import { SalesService } from '@app/sales/sales/sales.service';
import { Sale } from '@app/sales/sales/sale.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { UsersService } from '@app/settings/users/users.service';
import { User } from '@app/settings/users/user.model';

const log = new Logger('WebOffersFormComponent');

@Component({
  selector: 'app-web-offers-form',
  templateUrl: './web-offers-form.component.html',
  styleUrls: ['./web-offers-form.component.scss'],
})
export class WebOffersFormComponent implements OnInit {
  @Input() webOffer: WebOffer;
  @Input() saleAgentId: number;
  form: FormGroup;
  saleOffers: Array<SaleOffer>;
  selectedSaleOffersIds: Array<number> = [];

  selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
  };

  env = environment;
  CurrenciesAdditionsService = CurrenciesAdditionsService;
  @ViewChild('grid', { static: true }) grid: any;

  templatesTypes = Templates;
  UserRoleType = UserRoleType;

  saleAgent: User = null;
  selectedSmtp: any = null;
  saleAgentLoader = false;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private webOffersService: WebOffersService,
    private saleOffersService: SalesOffersService,
    private templatesService: TemplatesService,
    private salesService: SalesService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();

    if (this.webOffer && this.webOffer.web_offerable_type === LaravelModelsEnum.SALE) {
      this.getSaleOffers();
    }
  }

  getSalesAgent() {
    if (!this.saleAgentId) {
      return false;
    }
    this.saleAgentLoader = true;
    this.usersService.show(this.saleAgentId).subscribe(
      (salesAgent: User) => {
        this.saleAgent = salesAgent;
        this.saleAgentLoader = false;
      },
      () => {
        this.saleAgentLoader = false;
      }
    );
  }

  smtpPopOverOpen(smtpPopOver: any) {
    this.selectedSmtp = null;
    this.getSalesAgent();
    smtpPopOver.open();
  }

  setSmtp(smtpPopOver: any) {
    this.form.get('sales_agent_names').patchValue(this.selectedSmtp.from_name);
    this.form.get('sales_agent_email').patchValue(this.selectedSmtp.from_email);
    smtpPopOver.close();
  }

  getSaleOffers() {
    if (this.webOffer.web_offerable_type !== LaravelModelsEnum.SALE) {
      return log.warn('Abort get sale offers');
    }

    const query = new ApiQuery()
      .addFilter('sale_id', this.webOffer.web_offerable_id)
      .addIncludes(
        'items.net_price_currency',
        'items.hotel.city.country',
        'items.supplier',
        'items.allotment.booking.property',
        'items.type',
        'items.meal_plan'
      )
      .setLimit(99999);
    this.saleOffersService.index(query).subscribe((response: PaginatedResponse) => {
      this.saleOffers = response.data;

      // expand all grid rows
      response.data.forEach((item, idx) => {
        this.grid.expandRow(idx);
      });
    });
  }

  test(test: any) {
    log.debug(test);
    log.debug(this.saleOffers);
    log.debug(this.selectedSaleOffersIds);
  }

  save() {
    if (this.webOffer && this.webOffer.id) {
      const webOffer: WebOffer = this.webOffer.deserialize(this.form.value);
      this.webOffersService.update(webOffer).subscribe((res: any) => {
        this.modal.close(res);
      });
    } else {
      const webOffer: WebOffer = new WebOffer().deserialize(this.form.value);
      this.webOffersService.store(webOffer).subscribe((res) => {
        this.modal.close(res);
      });
    }
  }

  offersSelectionChange() {
    setTimeout(() => {
      this.form.get('items').setValue([]);

      this.saleOffers.forEach((saleOffer: SaleOffer) => {
        if (this.selectedSaleOffersIds.indexOf(saleOffer.id) > -1) {
          const webOfferItem = new WebOffersItem().deserialize({
            web_offerable_item: saleOffer,
            web_offerable_item_id: saleOffer.id,
            web_offerable_item_type: LaravelModelsEnum.SALE_OFFER,
          });
          this.form.get('items').value.push(webOfferItem);
        }
      });
    });
  }

  moveUp(index: number) {
    this.arrayMove(this.form.get('items').value, index, index - 1);
  }

  moveDown(index: number) {
    this.arrayMove(this.form.get('items').value, index, index + 1);
  }

  arrayMove(arr: Array<any>, old_index: number, new_index: number) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      web_offerable_id: [null, [Validators.required]],
      web_offerable_type: [null, [Validators.required]],
      title: [null],
      description: [null],
      active: [true, [Validators.required]],
      items: [[]],
      sales_agent_names: [null],
      sales_agent_email: [null],
    });
  }

  private patchForm() {
    if (this.webOffer && this.webOffer.id) {
      const query = new ApiQuery();
      this.webOffersService.show(this.webOffer.id, query).subscribe((webOffer: WebOffer) => {
        webOffer.items.forEach((item: WebOffersItem) => {
          this.selectedSaleOffersIds.push(item.web_offerable_item_id);
        });
        this.form.patchValue(webOffer);
      });
    } else if (this.webOffer) {
      log.debug('this.webOffer.web_offerable', this.webOffer);

      if (this.webOffer.web_offerable_type === LaravelModelsEnum.SALE) {
        this.salesService.show(this.webOffer.web_offerable_id).subscribe((sale: Sale) => {
          const templateServiceQuery = new ApiQuery().addFilters({
            type_id: this.templatesTypes.SALEWEBOFFERLINKCREATE,
            company_id: sale.company_id,
          });

          this.templatesService.index(templateServiceQuery).subscribe((templates: PaginatedResponse) => {
            if (templates.data.length > 0) {
              const formattedFilterData = new EmailTemplateCompiler().deserialize({
                object_id: this.webOffer.web_offerable_id,
                template_id: templates.data[0].id,
              });
              this.templatesService.compile(formattedFilterData).subscribe((res: any) => {
                this.form.get('title').patchValue(res.compiled.subject);
                this.form.get('description').patchValue(res.compiled.html_template);
              });
            } else {
              this.translate.get('SALES.WEB_OFFERS_FORM.TEMPLATE_NOT_FOUND_MSG').subscribe((trans: string) => {
                this.toastr.warning(trans);
              });
            }
          });
        });
      }

      this.form.patchValue(this.webOffer);
    }
  }
}
