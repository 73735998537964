import { Model } from '@app/shared/models/model.model';
import { RequestModel } from '@app/sales/requests/request.model';
import { Hotel } from '@app/hotels/hotels/hotel.model';
import { Type } from '@app/settings/types/types.model';
import * as moment from 'moment';
import { environment } from '@env/environment';

export class SaleRequest extends Model {
  id: number;
  budget: string;
  check_in: string; // date
  check_out: string; // date
  count: number;
  enquiry_id: number;
  enquiry?: RequestModel;
  hotel_id: number;
  hotel?: Hotel;
  hotel_stars: number;
  meal_plan_id: number;
  meal_plan?: Type;
  note: string;
  sale_id: number;
  type_id: number;
  type?: Type;
  status_id: number;
  status?: Type;
  people: number;
  guest_names: Array<string>;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.type) {
      this.type = new Type().deserialize(input.type);
    }

    if (input.status) {
      this.status = new Type().deserialize(input.status);
    }

    return this;
  }

  get formattedName(): String {
    let str = '';

    if (this.status) {
      str += this.status.value + '/';
    }

    if (this.count) {
      str += this.count + '/';
    }

    if (this.type) {
      str += this.type.value + '/';
    }

    if (this.check_in && this.check_out) {
      str +=
        moment(this.check_in).format(environment.momentDateFormat) +
        '-' +
        moment(this.check_out).format(environment.momentDateFormat) +
        '/';
    }

    if (this.hotel) {
      str += this.hotel.name;
    }
    return str;
  }
}
