import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Industry } from '@app/settings/industries/industries.model';
import { IndustriesSeoFormComponent } from '@app/settings/industries/industries-seo-form/industries-seo-form.component';

@Injectable({
  providedIn: 'root',
})
export class IndustriesSeoFormService {
  constructor(private modalService: NgbModal) {}

  public open(industry: null | Industry = null): Promise<any> {
    const modal = this.modalService.open(IndustriesSeoFormComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.industrySeo = industry;
    return modal.result;
  }
}
