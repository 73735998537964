<div class="dashboard-box1-wrapper mb-1">
  <div class="dashboard-box1-header d-flex justify-content-between">
    <div class="d-flex justify-content-between">
      <div class="dashboard-box1-title">
        {{ 'DASHBOARD.DASHBOARD_REMINDERS.REMINDERS_LIST_TITLE' | translate }}
      </div>

      <div class="collapse-trigger ml-2" (click)="toggle()">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-chevron-down" *ngIf="collapsed"></i>
          <i class="fal fa-chevron-up" *ngIf="!collapsed"></i>
        </button>
      </div>

      <div class="refresh-trigger ml-2" (click)="loadData()" *ngIf="!collapsed">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-sync"></i>
        </button>
      </div>
    </div>

    <div>
      <small class="pointer btn-link" routerLink="/reminders">
        {{ 'DASHBOARD.DASHBOARD_REMINDERS.VIEW_ALL' | translate }}
      </small>
    </div>
  </div>

  <div class="dashboard-box1-body" [perfectScrollbar] *ngIf="!collapsed">
    <div *ngIf="data && !data.length">
      {{ 'DASHBOARD.DASHBOARD_REMINDERS.NO_REMINDERS_MSG' | translate }}
    </div>
    <div class="dashboard-box1-item" *ngFor="let reminder of data; let I = index">
      <div class="row">
        <div class="col-sm-10 d-flex">
          <div class="user-names no-wrap">
            <span class="key">{{ 'DASHBOARD.DASHBOARD_REMINDERS.CREATOR_NAMES' | translate }}:</span>
            <span class="val">{{ reminder?.creator?.names }}</span>
          </div>
          <div class="reminderable-type no-wrap ml-3">
            <span class="key">{{ 'DASHBOARD.DASHBOARD_REMINDERS.TYPE' | translate }}:</span>
            <span class="val">{{ reminder?.cleanReminderableType }}</span>
          </div>
          <div class="reminderable-about ml-3" *ngIf="reminder?.about">
            <span class="key">{{ 'DASHBOARD.DASHBOARD_REMINDERS.ABOUT' | translate }}:</span>
            <span class="val"> {{ reminder?.about }}</span>
          </div>
        </div>

        <div class="col-sm-2 text-right">
          <div class="datetime">{{ reminder.remind_at | date: env.pipeDateTimeFormat }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-9">
          <div class="note">
            {{ reminder.note }}
          </div>
        </div>

        <div class="col-sm-3 text-right">
          <app-reminder-view-button [reminder]="reminder"></app-reminder-view-button>

          <button
            type="button"
            class="btn btn-sm btn-outline-success btn-complete"
            [swal]="{
              titleText: 'REMINDERS.REMINDERS_FORM.COMPLETED.SWAL.TITLE' | translate,
              text: 'REMINDERS.REMINDERS_FORM.COMPLETED.SWAL.TEXT' | translate
            }"
            (confirm)="complete(reminder)"
          >
            <i class="far fa-check"></i>
            {{ 'REMINDERS.REMINDERS_FORM.COMPLETED.BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
