import { environment } from '@env/environment';

export enum Templates {
  SALECLIENTOFFER = environment.enums.types.saleClientOffer,
  SALEGENERAL = environment.enums.types.saleGeneral,
  SALEWEBOFFERS = environment.enums.types.saleWebOffers,
  SALEINVOICES = environment.enums.types.saleInvoices,
  SALEVOUCHERS = environment.enums.types.saleVouchers,
  SALEFEEDBACK = environment.enums.types.saleFeedback,
  SALESENDTERMS = environment.enums.types.saleSendTerms,
  SALEWEBOFFERLINKCREATE = environment.enums.types.saleWebOfferLinkCreate,
  ONLINEBOOKINGTERMS = environment.enums.types.onlineBookingTerms,
}
