<div class="reminder-view-wrapper relative ps" [perfectScrollbar]>
  <div class="top">
    <div class="preview-header d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="title">
          {{ 'REMINDERS.REMINDERS_VIEW.REMINDER_ID' | translate }} #{{ reminder?.id }}

          <app-reminder-view-button
            [reminder]="reminder"
            class="ml-1 align-text-top"
            *ngIf="reminder"
          ></app-reminder-view-button>
        </div>
      </div>
      <div class="preview-btns-wrapper d-flex align-items-stretch">
        <div>
          <button
            *ngIf="!reminder?.completed"
            type="button"
            class="btn btn-sm btn-outline-success btn-complete mr-2"
            [swal]="{
              titleText: 'REMINDERS.REMINDERS_FORM.COMPLETED.SWAL.TITLE' | translate,
              text: 'REMINDERS.REMINDERS_FORM.COMPLETED.SWAL.TEXT' | translate
            }"
            (confirm)="complete()"
            [ngbTooltip]="'REMINDERS.REMINDERS_FORM.COMPLETED.TOOLTIP' | translate"
            container="body"
          >
            <i class="far fa-check"></i>
            {{ 'REMINDERS.REMINDERS_FORM.COMPLETED.BUTTON' | translate }}
          </button>

          <button
            *ngIf="reminder?.completed"
            type="button"
            class="btn btn-sm btn-outline-danger btn-complete mr-2"
            [swal]="{
              titleText: 'REMINDERS.REMINDERS_FORM.INCOMPLETE.SWAL.TITLE' | translate,
              text: 'REMINDERS.REMINDERS_FORM.INCOMPLETE.SWAL.TEXT' | translate
            }"
            (confirm)="inComplete()"
            [ngbTooltip]="'REMINDERS.REMINDERS_FORM.INCOMPLETE.TOOLTIP' | translate"
            container="body"
          >
            <i class="far fa-check"></i>
            {{ 'REMINDERS.REMINDERS_FORM.INCOMPLETE.BUTTON' | translate }}
          </button>
        </div>

        <div>
          <button type="button" class="btn btn-sm btn-success" (click)="edit()">
            {{ 'REMINDERS.REMINDERS_VIEW.EDIT_CLIENT_BTN' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div class="section-title mt-2 mb-1">
          <i class="fas fa-info"></i>
          {{ 'REMINDERS.REMINDERS_VIEW.INFO' | translate }}
        </div>

        <div>
          <div class="sub-title">
            {{ 'REMINDERS.REMINDERS_VIEW.COMPLETED' | translate }}
          </div>
          <div class="value">
            <app-active-indicator [active]="reminder?.completed"></app-active-indicator>
          </div>
        </div>

        <div *ngIf="reminder?.creator">
          <div class="sub-title">
            {{ 'REMINDERS.REMINDERS_VIEW.CREATOR' | translate }}
          </div>
          <div class="value" *ngIf="reminder?.creator?.names">
            {{ reminder?.creator?.names }}
          </div>
        </div>

        <div *ngIf="reminder?.created_at">
          <div class="sub-title">
            {{ 'REMINDERS.REMINDERS_VIEW.CREATED_AT' | translate }}
          </div>
          <div class="value">
            {{ reminder?.created_at.getDateTime() }}
          </div>
        </div>

        <div *ngIf="reminder?.deleted_at">
          <div class="sub-title">
            {{ 'REMINDERS.REMINDERS_VIEW.DELETED_AT' | translate }}
          </div>
          <div class="value">
            {{ reminder?.deleted_at.getDateTime() }}
          </div>
        </div>

        <div *ngIf="reminder?.cleanReminderableType">
          <div class="sub-title">
            {{ 'REMINDERS.REMINDERS_VIEW.TYPE' | translate }}
          </div>
          <div class="value">
            {{ reminder?.cleanReminderableType }}
          </div>
        </div>

        <div>
          <div class="sub-title" *ngIf="reminder?.about">
            {{ 'REMINDERS.REMINDERS_VIEW.ABOUT' | translate }}
          </div>
          <div class="value">
            {{ reminder?.about }}
          </div>
        </div>

        <div>
          <div class="sub-title" *ngIf="reminder?.user">
            {{ 'REMINDERS.REMINDERS_VIEW.USER' | translate }}
          </div>
          <div class="value">
            {{ reminder?.user?.names }}
          </div>
        </div>

        <div>
          <div class="sub-title" *ngIf="reminder?.remind_at">
            {{ 'REMINDERS.REMINDERS_VIEW.REMIND_AT' | translate }}
          </div>
          <div class="value">
            {{ reminder?.remind_at | date: env.pipeDateTimeFormat }}
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="note-box mt-3" *ngIf="reminder?.note">
          <div class="sub-title">
            <i class="far fa-sticky-note"></i>
            {{ 'REMINDERS.REMINDERS_VIEW.NOTE' | translate }}
          </div>
          <div class="value m-0">
            {{ reminder?.note }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
