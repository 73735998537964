import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { Hotel } from '@app/hotels/hotels/hotel.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelsService } from '@app/hotels/hotels/hotels.service';
import { HotelsFormService } from '@app/hotels/hotels/hotels-form.service';
import { Logger } from '@app/core';

const log = new Logger();

@Component({
  selector: 'app-hotel-finder',
  templateUrl: './hotel-finder.component.html',
  styleUrls: ['./hotel-finder.component.scss'],
})
export class HotelFinderComponent implements OnInit {
  form: FormGroup;
  @Input() data: object = {};
  @Input() multiple: boolean;
  hotelsResponse: PaginatedResponse;
  gridData: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  public selectedHotels: Array<number> = [];
  env = environment;

  selectableSettings: SelectableSettings = {};

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private hotelsService: HotelsService,
    private hotelsFormService: HotelsFormService
  ) {}

  ngOnInit() {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: this.multiple ? 'multiple' : 'single',
    };

    log.debug('data', this.data);
    this.form = this.formBuilder.group({
      venue_id: [null],
      venue: [null],
    });
    this.form.patchValue(this.data);
    if (Object.keys(this.data).length > 0 && !Object.values(this.data).every((x) => x === null)) {
      this.loadData();
    }
  }

  test(logg: any) {
    log.debug(logg);
    log.debug('selected', this.selectedHotels);
  }

  /**
   * Затваря модала и праща обратно данните от резултата (всичките селектнати hotels)
   */
  save(): void {
    this.modal.close(this.selectedHotels);
  }

  selectHotel(hotel: Hotel) {
    this.modal.close([hotel.id]);
  }

  /**
   *
   * @param hotel Hotel object
   */
  edit(hotel: Hotel): void {
    this.hotelsFormService.open(hotel).then(
      (res) => {
        log.debug(res);
      },
      (err) => {
        log.debug(err);
      }
    );
  }

  loadData(state: DataStateChangeEvent = null): void {
    log.debug('find ', this.form.value);

    this.apiQuery.removeFilter('venue_id');

    if (this.form.get('venue_id').value) {
      this.apiQuery.addFilter('venue_id', this.form.get('venue_id').value);
    }
    if (state) {
      this.state = state;
    }

    this.apiQuery.setDataSetFilters(this.state).addIncludes('city.country', 'venues').addFilters({ active: true });

    this.hotelsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      if (this.form.get('venue_id').value) {
        response.data.forEach((hotel: Hotel) => {
          hotel.venues.forEach((venue: any) => {
            if (venue.id === this.form.get('venue_id').value) {
              log.debug('venue', venue);
              hotel['distance_to_fair'] = venue.pivot.distance;
            }
          });
        });
      }

      this.hotelsResponse = response;
      this.gridData = response.getGridData();
    });
  }
}
