<div class="mb-3" *ngIf="userAccess">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.BOOKINGS_ASSIGNMENTS_TABLE_TITLE' | translate }}
      </h5>
    </div>
    <div>
      <button type="button" class="btn btn-link" (click)="clearFilters()">
        {{ 'CLEAR_FILTERS' | translate }}
      </button>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="hotelBookingsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Bookings_assignments', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.ID' | translate }}"
      filter="numeric"
      [width]="150"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="hotel.name"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.HOTEL' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('hotel.name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-hotel-select [filter]="filter"></app-kendo-hotel-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="'/hotel-bookings/view'" [queryParams]="{ id: dataItem.id }" target="_blank">
          {{ dataItem?.hotel?.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="events"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.EVENTS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('events')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter" [field]="'events.id'"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngFor="let event of dataItem.events; let i = index">
          <a [routerLink]="['/events/view']" [queryParams]="{ id: event.id }" target="_blank">
            {{ event.formattedName }}
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="rooms.check_in"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.CHECK_IN' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('rooms.check_in')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngFor="let room of dataItem.roomsUnitedDates; let i = index">
          {{ room.check_in | date: env.pipeDateFormat }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="rooms.check_out"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.CHECK_OUT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('rooms.check_out')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngFor="let room of dataItem.roomsUnitedDates; let i = index">
          {{ room.check_out | date: env.pipeDateFormat }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="rooms.net_price_eur_avg"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.NET_PRICE_AVG' | translate }}"
      [filterable]="false"
      [sortable]="false"
      [hidden]="isHiddenColumn('rooms.net_price_eur_avg')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.avgNetPriceEur | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="property.value"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.PROPERTY' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('property.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'booking_properties'"
          [field]="'property_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.property"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="roomsCount"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.ROOMS_COUNT' | translate }}"
      [filterable]="false"
      [sortable]="false"
      [hidden]="isHiddenColumn('roomsCount')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="rooms.type.value"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.ROOMS_TYPE' | translate }}"
      [hidden]="isHiddenColumn('rooms.type.value')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select [groupType]="'rooms'" [filter]="filter" [field]="'rooms.type_id'">
        </app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge
          *ngFor="let type of dataItem?.roomsUniqueTypes; let i = index"
          [type]="type"
          class="d-block"
        ></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="assign_date"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.ASSIGN_DATE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('assign_date', false)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.assign_date | date: env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="book"
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.BOOK' | translate }}"
      filter="boolean"
      [hidden]="isHiddenColumn('book')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem.book">
          {{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.YES' | translate }}
        </ng-container>

        <ng-container *ngIf="!dataItem.book">
          {{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.NO' | translate }}
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'DASHBOARD.BOOKINGS_ASSIGNMENTS_TABLE.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button (click)="edit(dataItem)"></app-edit-button>

        <app-preview-button [link]="['/hotel-bookings/view']" [params]="{ id: dataItem.id }"></app-preview-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
