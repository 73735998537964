<div class="container-fluid" *ngIf="accessService.hasAccess([], ['sales.finals.items.menu'])">
  <div class="row align-items-center">
    <div class="col-auto">
      <h5 class="title-secondary">
        {{ 'INVOICES.INTER_COMPANY_SALES_LIST.TITLE' | translate }}
      </h5>
    </div>
    <div class="col-auto ml-auto">
      <button type="button" class="btn btn-link" (click)="clearFilters()">
        {{ 'CLEAR_FILTERS' | translate }}
      </button>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="requestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Inter_company_sales', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'INVOICES.INTER_COMPANY_SALES_LIST.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_final.sale.company.abbreviation"
      title="{{ 'INVOICES.INTER_COMPANY_SALES_LIST.SALES_OFFICE' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_final.sale.company.abbreviation')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter" [field]="'sale_final.sale.company_id'"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/settings/companies/view']"
          [queryParams]="{ id: dataItem?.sale_final?.sale?.company_id }"
          target="_blank"
        >
          {{ dataItem?.sale_final?.sale?.company?.abbreviation }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel_booking_room.booking.company.abbreviation"
      title="{{ 'INVOICES.INTER_COMPANY_SALES_LIST.BOOKING_OFFICE' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('hotel_booking_room.booking.company.abbreviation')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select
          [filter]="filter"
          [field]="'hotel_booking_room.booking.company_id'"
        ></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/settings/companies/view']"
          [queryParams]="{ id: dataItem?.hotel_booking_room?.booking?.company_id }"
          target="_blank"
        >
          {{ dataItem?.hotel_booking_room?.booking?.company?.abbreviation }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'INVOICES.INTER_COMPANY_SALES_LIST.ID' | translate }}"
      filter="numeric"
      [width]="150"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      title="{{ 'INVOICES.INTER_COMPANY_SALES_LIST.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"> </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
