import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '@app/settings/companies/company.model';
import { ApiQuery } from '@app/core/http/api-query';
import { CompaniesService } from '@app/settings/companies/companies.service';
import { CompaniesFormService } from '@app/settings/companies/companies-form.service';
import { environment } from '@env/environment';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { UsersService } from '@app/settings/users/users.service';
import { User } from '@app/settings/users/user.model';
import { UsersFormService } from '@app/settings/users/users-form.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { SuppliersService } from '@app/suppliers/suppliers/suppliers.service';
import { Supplier } from '@app/suppliers/suppliers/suppliers.model';
import { SuppliersFormService } from '@app/suppliers/suppliers/suppliers-form.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';

const log = new Logger('CompaniesViewComponent');

@Component({
  selector: 'app-companies-view',
  templateUrl: './companies-view.component.html',
  styleUrls: ['./companies-view.component.scss'],
})
export class CompaniesViewComponent extends KendoGridComponent implements OnInit {
  companyId: number;
  company: Company;
  env = environment;
  LaravelModels = LaravelModelsEnum;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;

  employeesRequestsResponse: PaginatedResponse;
  employeesData: GridDataResult;
  employeesState: DataStateChangeEvent;
  employeesApiQuery: ApiQuery = new ApiQuery();

  suppliersRequestsResponse: PaginatedResponse;
  suppliersData: GridDataResult;
  suppliersState: DataStateChangeEvent;
  suppliersApiQuery: ApiQuery = new ApiQuery();

  UserRoleType = UserRoleType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companiesService: CompaniesService,
    private companiesFormService: CompaniesFormService,
    private usersService: UsersService,
    private usersFormService: UsersFormService,
    private supplierService: SuppliersService,
    private suppliersFormService: SuppliersFormService,
    public accessService: AccessService,
    protected kendoGridService: KendoGridService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.companyId = Number(params.get('id'));
        this.loadData(this.companyId);
        this.employeesLoadDataTable();
        this.suppliersLoadDataTable();
        // this.openActivities();
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  loadData(id: number): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes(
      'bank_accounts',
      //   'properties.type',
      'city.country',
      'contacts.type',
      'websites',
      'logo'
    );
    this.companiesService.show(id, apiQuery).subscribe((company: Company) => {
      log.debug(company);
      this.company = company;
    });
  }

  /**
   * Edit Company modal
   */
  edit(): void {
    this.companiesFormService.open(this.company).then(
      (response) => {
        log.debug('res', response);
        this.loadData(this.companyId);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  add() {
    this.companiesFormService.open().then(
      (response) => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { id: response.id },
          queryParamsHandling: 'merge',
        });
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  employeesLoadDataTable(state: DataStateChangeEvent = null) {
    log.debug('employeesLoadDataTable');
    this.employeesState = state;
    this.employeesApiQuery
      .addFilters({ employee: true, 'companies.id': this.companyId })
      .setDataSetFilters(state)
      .addIncludes('roles', 'contacts.type', 'avatar');

    this.usersService.index(this.employeesApiQuery).subscribe((response: PaginatedResponse) => {
      this.employeesRequestsResponse = response;
      this.employeesData = response.getGridData();
    });
  }

  editUser(user: User) {
    this.usersFormService.open(user).then(
      () => {
        this.employeesLoadDataTable();
      },
      () => {}
    );
  }

  deleteUser(user: User) {
    this.usersService.destroy(user.id).subscribe(() => {
      this.employeesLoadDataTable(this.employeesState);
    });
  }

  suppliersLoadDataTable(state: DataStateChangeEvent = null) {
    log.debug('suppliersLoadDataTable');
    this.suppliersState = state;
    this.suppliersApiQuery
      .setDataSetFilters(state)
      .addIncludes('type', 'companies.pivot.properties.type', 'companies.pivot.contact_persons.title', 'contacts.type')
      .addFilter('companies.id', this.companyId);

    this.supplierService.index(this.suppliersApiQuery).subscribe((response: PaginatedResponse) => {
      this.suppliersRequestsResponse = response;
      this.suppliersData = response.getGridData();
    });
  }

  editSupplier(supplier: Supplier) {
    this.suppliersFormService.open(supplier).then(
      () => {
        this.suppliersLoadDataTable();
      },
      () => {}
    );
  }

  deleteSupplier(supplier: Supplier) {
    this.supplierService.destroy(supplier.id).subscribe(() => {
      this.suppliersLoadDataTable(this.suppliersState);
    });
  }
}
