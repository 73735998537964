import { Injectable } from '@angular/core';
import { MenuItem, MenuItemInterface } from '@app/shared/models/menu-item.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  items: Array<MenuItem> = [];

  getItems() {
    return this.items;
  }

  addItem(item: MenuItemInterface): this {
    const newItem = new MenuItem(item);

    let base: string | Array<string> = newItem.key.split(/\.(?=[^\.]+$)/);
    base = base[0];

    const parent = this.findNested(this.items, 'key', base);

    // решава дали да го сложи от първо ниво
    if (parent) {
      parent.childrens.push(newItem);
    } else {
      this.items.push(newItem);
    }

    return this;
  }

  // @todo:Не работи на повече от едно ниво навътре
  private findNested(obj: Array<any>, key: string, value: string) {
    if (obj[key] === value) {
      return obj;
    } else {
      for (let i = 0, len = Object.keys(obj).length; i < len; i++) {
        if (typeof obj[i] === 'object') {
          const found: any = this.findNested(obj[i], key, value);
          if (found) {
            return found;
          }
        }
      }
    }
  }
}
