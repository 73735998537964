import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { SalesService } from '@app/sales/sales/sales.service';
import { SalesFormService } from '@app/sales/sales/sales-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { AuthenticationService, Logger } from '@app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService } from '@app/core/access/access.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { SaleStatusType } from '@app/settings/types/sale-status-type.enum';

const log = new Logger('ClosedSalesComponent');

@Component({
  selector: 'app-closed-sales',
  templateUrl: './closed-sales.component.html',
  styleUrls: ['./closed-sales.component.scss'],
})
export class ClosedSalesComponent extends KendoGridComponent implements OnInit {
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  UserRoleType = UserRoleType;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;
  LaravelModelsEnum = LaravelModelsEnum;
  loader = false;

  constructor(
    private salesService: SalesService,
    private salesFormService: SalesFormService,
    protected kendoGridService: KendoGridService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit(): void {
    const storageFilter = CustomKendoFilter.getStateFromStorage('ClosedSalesListComponent');
    if (storageFilter) {
      this.state = storageFilter;
    }
    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData');
    this.loader = true;
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'company',
        'event',
        'client.country',
        'client.city',
        'sales_agent',
        'customer_care_agent',
        'reservation_agent',
        'notSoldActivity'
      )
      .addFilter('status_id', SaleStatusType.NOT_SOLD);

    this.salesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.requestsResponse = response;
      this.data = response.getGridData();
      this.loader = false;
    });

    CustomKendoFilter.pushStateInStorage('ClosedSalesListComponent', this.state);
  }

  clearFilters() {
    this.router.navigate(['/closed-sales']);

    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData();
    });
  }
}
