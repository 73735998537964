/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Logger } from '@app/core';

import TravelMode = google.maps.TravelMode;
import LatLng = google.maps.LatLng;

const log = new Logger('DistanceService');

@Injectable({
  providedIn: 'root',
})
export class DistanceService {
  constructor() {}

  getRoadDistance(startCoordinates: any, endCoordinates: any): Observable<number> {
    log.debug('getRoadDistance', startCoordinates, endCoordinates);

    if (!startCoordinates.lat || !startCoordinates.long || !endCoordinates.lat || !endCoordinates.long) {
      // @todo: some error
      return of();
    }
    return new Observable((observer) => {
      const dm = new google.maps.DistanceMatrixService();

      dm.getDistanceMatrix(
        {
          travelMode: TravelMode.DRIVING,
          origins: [new LatLng(startCoordinates.lat, startCoordinates.long)],
          destinations: [new LatLng(endCoordinates.lat, endCoordinates.long)],
        },
        (response: any, status: any) => {
          if (status !== google.maps.DirectionsStatus.OK || !response.rows[0].elements[0].distance) {
            observer.error('Cant get information from google');
          } else {
            observer.next(response.rows[0].elements[0].distance.value / 1000);
          }
        }
      );
    });
  }

  /**
   * Връща целия json(не обработен) както идва от гоогле
   * @param startCoordinates startCoordinates
   * @param endCoordinates endCoordinates
   * @param travelMode travelMode default is DRIVING
   */
  getDistance(startCoordinates: any, endCoordinates: any, travelMode: string = 'DRIVING'): Observable<number> {
    log.debug('getDistance', startCoordinates, endCoordinates);

    if (!startCoordinates.lat || !startCoordinates.long || !endCoordinates.lat || !endCoordinates.long) {
      // @todo: some error
      return of();
    }
    return new Observable((observer) => {
      const dm = new google.maps.DistanceMatrixService();

      dm.getDistanceMatrix(
        {
          travelMode: TravelMode[travelMode],
          origins: [new LatLng(startCoordinates.lat, startCoordinates.long)],
          destinations: [new LatLng(endCoordinates.lat, endCoordinates.long)],
        },
        (response: any, status: any) => {
          log.debug('response', response, 'status', status);
          if (status !== google.maps.DirectionsStatus.OK || !response.rows[0].elements[0].distance) {
            observer.error('Cant get information from google');
          } else {
            observer.next(response);
          }
        }
      );
    });
  }

  getMultipleRoadDistances(startCoordinates: Array<any>, endCoordinates: any): Observable<number> {
    log.debug('getMultipleRoadDistances', startCoordinates, endCoordinates);

    return new Observable((observer) => {
      const dm = new google.maps.DistanceMatrixService();

      const origins: Array<LatLng> = [];

      startCoordinates.forEach((coordinate) => {
        origins.push(new LatLng(coordinate.lat, coordinate.long));
      });
      dm.getDistanceMatrix(
        {
          travelMode: TravelMode.DRIVING,
          origins: origins,
          destinations: [new LatLng(endCoordinates.lat, endCoordinates.long)],
        },
        (response: any, status: any) => {
          if (status !== google.maps.DirectionsStatus.OK) {
            observer.error('Cant get information from google');
          } else {
            observer.next(
              response.rows.map((rows: any) => {
                return rows.elements[0].distance ? rows.elements[0].distance.value / 1000 : 'error';
              })
            );
          }
        }
      );
    });
  }
}
