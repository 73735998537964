import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Logger } from '@app/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { FormBuilder } from '@angular/forms';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';

const log = new Logger('KendoUserSelectComponent');

@Component({
  selector: 'app-kendo-user-select',
  templateUrl: './kendo-user-select.component.html',
  styleUrls: ['./kendo-user-select.component.scss'],
})
export class KendoUserSelectComponent extends CustomKendoFilter implements OnInit, OnChanges {
  @Input() roleType: UserRoleType | Array<UserRoleType>;
  @Input() field = 'user_id';

  constructor(private formBuilder: FormBuilder, filterService: FilterService) {
    super(filterService);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      user_id: [null],
      user: [null],
    });

    const user = this.getObjectFromStorage(this.field, 'users');

    if (user) {
      setTimeout(() => {
        this.form.patchValue({
          user: user,
          user_id: user.id,
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Изчиства формата
    // Използва се, за да чисти филтрите, когато се сетне null state на таблицата
    if (this.form && changes && changes.filter && !changes.filter.currentValue) {
      this.form.reset();
    }
  }

  onSelect(event: any) {
    CustomKendoFilter.pushObjectInStorage(event, 'users');

    this.filter = this.removeFilter(this.field);

    const filters = [];
    if (event && event.id) {
      filters.push({
        field: this.field,
        operator: 'eq',
        value: event.id,
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: [],
    };

    if (filters.length) {
      root.filters.push(...filters);
    }
    this.filterService.filter(root);
    log.debug('filters', filters);
  }
}
