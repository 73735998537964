<div class="modal-header">
  <h4 class="modal-title">
    <span><i class="fas fa-phone-volume"></i> {{ 'PHONE_CALLS_FINDER.PHONE_CALLS' | translate }} </span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ps" [perfectScrollbar]>
  <kendo-grid
    [data]="data"
    [pageSize]="phoneCallsResponse?.meta.per_page"
    [sortable]="false"
    [filterable]="false"
    [columnMenu]="false"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    #grid
    (excelExport)="onExcelExport($event, 'PhoneCalls', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'PHONE_CALS_FINDER.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'PHONE_CALS_FINDER.ID' | translate }}"
      filter="numeric"
      [width]="70"
    ></kendo-grid-column>

    <kendo-grid-column field="datetime" title="{{ 'PHONE_CALS_FINDER.DATETIME' | translate }}" filter="datetime">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.datetime | date: env.pipeDateTimeFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="from_number" title="{{ 'PHONE_CALS_FINDER.FROM_NUMBER' | translate }}" filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.from_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="to_number" title="{{ 'PHONE_CALS_FINDER.TO_NUMBER' | translate }}" filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.to_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="type" title="{{ 'PHONE_CALS_FINDER.TYPE' | translate }}" filter="text">
    </kendo-grid-column>

    <kendo-grid-column field="play" title="{{ 'PHONE_CALS_FINDER.PLAY' | translate }}" filter="numeric" width="320">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div (click)="loadBlob(dataItem)" *ngIf="!dataItem?.meta_front?.blob" class="btn btn-link">
          {{ 'PHONE_CALS_FINDER.LOAD_AUDIO' | translate }}

          <div class="spinner-border spinner-border-sm" *ngIf="dataItem?.meta_front?.isLoading"></div>
        </div>

        <audio controls [src]="dataItem?.meta_front?.blob" *ngIf="dataItem?.meta_front?.blob">
          Your browser does not support the audio element.
        </audio>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
