import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { Events } from '@app/events/events/events.model';
import { EventGroup } from '@app/events/events-groups/event-group.model';
import { Hotel } from '@app/hotels/hotels/hotel.model';
import { WebsitesService } from '@app/settings/websites/websites.service';
import { HotelsService } from '@app/hotels/hotels/hotels.service';
import { ApiQuery } from '@app/core/http/api-query';
import { Companies } from '@app/settings/companies/companies.enum';
import { EventsService } from '@app/events/events/events.service';
import { EventGroupsService } from '@app/events/events-groups/event-groups.service';

const log = new Logger('LinksCompactTranslatesComponent');

/**
 * @todo: Временен компонент, който сглабя линковете ръчно. Трябва да се премахне и замени с links-compact,
 * когато Венко пусне линковете
 */

@Component({
  selector: 'app-links-compact-translates',
  templateUrl: './links-compact-translates.component.html',
  styleUrls: ['./links-compact-translates.component.scss'],
})
export class LinksCompactTranslatesComponent implements OnInit {
  @Input() data: Events | EventGroup | Hotel;
  companyWebsiteLocales: Array<any>;
  currentWebsiteLocaleId: number;

  constructor(
    private websitesService: WebsitesService,
    private hotelService: HotelsService,
    private eventService: EventsService,
    private eventGroupsService: EventGroupsService
  ) {}

  ngOnInit() {}

  shown() {
    if (!this.data) {
      return log.error('No data passed to component!');
    }

    log.debug(this.data);
    log.debug(this.data instanceof Hotel);
    log.debug(this.data instanceof Events);
    this.getData();
  }

  selectCompanyWebSiteLocale(id: number): void {
    this.currentWebsiteLocaleId = id;
  }

  getWebsiteLocaleName(id: number): string {
    log.debug('getWebsiteLocaleName id', id);
    const localeObj = this.companyWebsiteLocales.find((x) => x.id === id);
    return localeObj.website.name + ' ' + localeObj.website.url + ' ' + localeObj.locale.locale;
  }

  copyInputMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private getData() {
    this.websitesService.getLocales().subscribe((websiteLocales: []) => {
      this.companyWebsiteLocales = websiteLocales;

      const query = new ApiQuery().addIncludes('translations');
      if (this.data instanceof Hotel) {
        this.hotelService.show(this.data.id, query).subscribe((hotel: Hotel) => {
          this.companyWebsiteLocales.forEach((webSiteLocale: any, index: number) => {
            if (index === 0) {
              this.selectCompanyWebSiteLocale(webSiteLocale.id);
            }
            webSiteLocale['links'] = [];
            hotel.translations.forEach((translation: any) => {
              if (translation.company_website_locale_id === Number(webSiteLocale.id)) {
                let lnk = webSiteLocale.website.url;

                if (webSiteLocale.website.company_id === Companies.T2F) {
                  lnk += 'hotels/';
                } else if (webSiteLocale.website.company_id === Companies.GF) {
                  lnk += 'expo-hotels/';
                } else if (webSiteLocale.website.company_id === Companies.GETA) {
                  lnk += 'fair-hotels/';
                } else if (webSiteLocale.website.company_id === Companies.TFT) {
                  if (translation.locale.locale === 'en') {
                    lnk += 'fair-hotels/';
                  } else if (translation.locale.locale === 'bg') {
                    lnk += 'hoteli/';
                  } else if (translation.locale.locale === 'tr') {
                    lnk += 'oteller/';
                  }
                }

                lnk += translation.slug;

                webSiteLocale.links.push({ value: lnk });
              }
            });
          });
        });
      } else if (this.data instanceof Events) {
        query.addInclude('eventGroup.translations');
        this.eventService.show(this.data.id, query).subscribe((event: Events) => {
          this.companyWebsiteLocales.forEach((webSiteLocale: any, index: number) => {
            if (index === 0) {
              this.selectCompanyWebSiteLocale(webSiteLocale.id);
            }
            webSiteLocale['links'] = [];
            let lnk = webSiteLocale.website.url;

            if (webSiteLocale.website.company_id === Companies.T2F) {
              lnk += 'exhibitions';
            } else if (webSiteLocale.website.company_id === Companies.GF) {
              // за сега няма
            } else if (webSiteLocale.website.company_id === Companies.GETA) {
              lnk += 'exhibitions';
            } else if (webSiteLocale.website.company_id === Companies.TFT) {
              if (webSiteLocale.locale.locale === 'en') {
                lnk += 'exhibitions';
              } else if (webSiteLocale.locale.locale === 'bg') {
                lnk += 'izlozhenia';
              } else if (webSiteLocale.locale.locale === 'tr') {
                lnk += 'fuarlar';
              }
            }

            event.event_group.translations.forEach((groupTranslation: any) => {
              if (groupTranslation.company_website_locale_id === Number(webSiteLocale.id)) {
                lnk += '/' + groupTranslation.slug;
              }
            });

            event.translations.forEach((translation: any) => {
              if (translation.company_website_locale_id === Number(webSiteLocale.id)) {
                lnk += '/' + translation.slug;
              }
            });
            webSiteLocale.links.push({ value: lnk });
          });
        });
      } else if (this.data instanceof EventGroup) {
        query.addInclude('translations');
        this.eventGroupsService.show(this.data.id, query).subscribe((eventGroup: EventGroup) => {
          this.companyWebsiteLocales.forEach((webSiteLocale: any, index: number) => {
            if (index === 0) {
              this.selectCompanyWebSiteLocale(webSiteLocale.id);
            }
            webSiteLocale['links'] = [];
            let lnk = webSiteLocale.website.url;

            if (webSiteLocale.website.company_id === Companies.T2F) {
              lnk += 'exhibitions';
            } else if (webSiteLocale.website.company_id === Companies.GF) {
              // за сега няма
            } else if (webSiteLocale.website.company_id === Companies.GETA) {
              lnk += 'exhibitions';
            } else if (webSiteLocale.website.company_id === Companies.TFT) {
              if (webSiteLocale.locale.locale === 'en') {
                lnk += 'exhibitions';
              } else if (webSiteLocale.locale.locale === 'bg') {
                lnk += 'izlozhenia';
              } else if (webSiteLocale.locale.locale === 'tr') {
                lnk += 'fuarlar';
              }
            }
            eventGroup.translations.forEach((groupTranslation: any) => {
              if (groupTranslation.company_website_locale_id === Number(webSiteLocale.id)) {
                lnk += '/' + groupTranslation.slug;
              }
            });
            webSiteLocale.links.push({ value: lnk });
          });
        });
      }
    });
  }
}
