import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Client } from '@app/clients/clients/client.model';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@app/core';
import { ApiQuery, ApiQuerySortTypes } from '@app/core/http/api-query';
import { ClientsService } from '@app/clients/clients/clients.service';
import { ClientsFormService } from '@app/clients/clients/clients-form.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { SalesService } from '@app/sales/sales/sales.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { Sale } from '@app/sales/sales/sale.model';
import { SalesFormService } from '@app/sales/sales/sales-form.service';
import { environment } from '@env/environment';
import { SaleRequest } from '@app/sales/sales/sale-request.model';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { ActivitiesService } from '@app/shared/components/activities/activities.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { Title } from '@angular/platform-browser';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DataSet } from '@app/shared/kendo/data-set';

const log = new Logger('ClientsViewComponent');

@Component({
  selector: 'app-clients-view',
  templateUrl: './clients-view.component.html',
  styleUrls: ['./clients-view.component.scss'],
})
export class ClientsViewComponent extends KendoGridComponent implements OnInit, OnDestroy {
  clientId: number;
  client: Client;
  LaravelModelsEnum = LaravelModelsEnum;
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  UserRoleType = UserRoleType;

  @ViewChild('perfectScroll', { static: true }) perfectScroll: PerfectScrollbarDirective;

  constructor(
    public activitiesService: ActivitiesService,
    private salesFormService: SalesFormService,
    private salesService: SalesService,
    private route: ActivatedRoute,
    private clientService: ClientsService,
    private clientFormService: ClientsFormService,
    protected kendoGridService: KendoGridService,
    private titleService: Title,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.clientId = Number(params.get('id'));
        this.loadData(this.clientId);
        this.state = new DataSet().addSort('event.date_from', 'desc');
        this.loadDataTable(this.state);
        this.activitiesService.auto(LaravelModelsEnum.CLIENT, this.clientId, false);
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  ngOnDestroy(): void {
    // крие sidebar-a s activity-тата и съобщенията
    this.activitiesService.hide();
  }

  /**
   * Отваря sidebar-a s activity-тата и съобщенията
   */
  openActivities(): void {
    this.activitiesService.show(LaravelModelsEnum.CLIENT, this.clientId, false);
  }

  loadData(id: number): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes(
      'bank_accounts',
      'city',
      'country',
      'type',
      'locales',
      'contacts.type',
      'industry',
      'contact_persons.title',
      'contact_persons.contacts',
      'contact_persons.contacts.type',
      'contact_persons.event_group',
      'tags'
    );
    this.clientService.show(id, apiQuery).subscribe((client: Client) => {
      log.debug(client);
      this.client = client;

      if (this.client.name) {
        this.titleService.setTitle(this.client.name);
      }
    });
  }

  loadDataTable(state: DataStateChangeEvent = null) {
    log.debug('loadDataTable');
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'company',
        'event.venue.city.country',
        'client',
        'sales_agent',
        'customer_care_agent',
        'reservation_agent',
        'status',
        'channel',
        'source',
        'campaign',
        'tags',
        'requests.type'
      )
      .addFilter('client_id', this.clientId);

    this.salesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      log.debug('response', response);
      response.data.forEach((sale: Sale) => {
        sale['totalRequests'] = 0;

        if (sale.requests.length > 0) {
          sale.requests.forEach((request: SaleRequest) => {
            sale['totalRequests'] += Number(request.count);
          });
        }
      });
      this.requestsResponse = response;
      this.data = response.getGridData();
      this.perfectScroll.update();
    });
  }

  edit() {
    this.clientFormService.open(this.client).then(
      (response) => {
        log.debug('res', response);
        this.loadData(this.client.id);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  addSale() {
    const newSaleObj = new Sale().deserialize({ client_id: this.clientId, client: this.client });
    this.salesFormService.open(newSaleObj, null, false).then(
      () => {
        this.loadDataTable(this.state);
      },
      (res) => {
        log.debug(res);
      }
    );
  }

  editSale(sale: Sale) {
    this.salesFormService.open(sale, null, false).then(
      () => {
        this.loadDataTable(this.state);
      },
      (res) => {
        log.debug(res);
      }
    );
  }

  deleteSale(sale: Sale) {
    this.salesService.destroy(sale.id).subscribe(() => {
      this.loadDataTable(this.state);
    });
  }
}
