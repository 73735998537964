import { LaravelModelsEnum, Model } from '@app/shared/models/model.model';
import { Company } from '@app/settings/companies/company.model';
import { Type } from '@app/settings/types/types.model';
import { BankAccount } from '@app/shared/components/bank-accounts/bank-account.model';
import { Currency } from '@app/settings/currencies/currency.model';
import { ClientabeType } from '@app/shared/components/clientable-select/clientabe-type.enum';

export class Payment extends Model {
  id: number;
  company_id: number;
  company?: Company;
  clientable_type: ClientabeType;
  clientable_id: number;
  clientable?: any;
  type_id: number;
  type?: Type;
  payment_method_id: number;
  payment_method?: Type;
  bank_account_id: number;
  bank_account?: BankAccount;
  amount: number;
  date: string;
  currency_id: number;
  currency?: Currency;
  private_note: string;
  reference: string;
  paymentable_id: number;
  paymentable_type: LaravelModelsEnum;
  paymentable?: any;
  fee_amount: number;
  fee_currency_id: number;
  fee_currency?: Currency;
  is_paid?: boolean;
  is_approved?: boolean;
  has_documents?: boolean;
  refundable_id: number;
  refundable?: Type;
  refundable_percent: number;
  refundable_date: string;
  sale_id: number;
  exchange_rate?: number;
  meta_front?: any;
  deserialize(input: any): this {
    super.deserialize(input);

    if (input.company) {
      this.company = new Company().deserialize(input.company);
    }

    if (input.payment_method) {
      this.payment_method = new Type().deserialize(input.payment_method);
    }

    if (input.bank_account) {
      this.bank_account = new BankAccount().deserialize(input.bank_account);
    }

    if (input.currency) {
      this.currency = new Currency().deserialize(input.currency);
    }

    if (input.type) {
      this.type = new Type().deserialize(input.type);
    }

    if (input.fee_currency) {
      this.fee_currency = new Currency().deserialize(input.fee_currency);
    }

    if (input.refundable) {
      this.refundable = new Type().deserialize(input.refundable);
    }

    return this;
  }

  get cleanClientableType() {
    const type = this.clientable_type.split('\\');
    return type[type.length - 1];
  }

  get cleanPaymentableType() {
    if (this.paymentable_type) {
      const type = this.paymentable_type.split('\\');
      return type[type.length - 1];
    } else {
      return null;
    }
  }

  /**
   * Всички company-та от всички редове във файнъла(от booking-а), който се плащат(ако има)
   */
  get bookingCompanies(): Array<string> {
    const companies: Array<string> = [];

    if (this.paymentable && this.paymentable.invoiceable && this.paymentable.invoiceable.items) {
      this.paymentable.invoiceable.items.forEach((saleFinalItem: any) => {
        if (
          saleFinalItem.hotel_booking_room &&
          saleFinalItem.hotel_booking_room.booking &&
          saleFinalItem.hotel_booking_room.booking.company
        ) {
          if (companies.indexOf(saleFinalItem.hotel_booking_room.booking.company.abbreviation) < 0) {
            companies.push(saleFinalItem.hotel_booking_room.booking.company.abbreviation);
          }
        }
      });
    }

    return companies;
  }

  /**
   * Всички хотели от final-a ако има
   */
  get finalHotels(): Array<string> {
    const hotels: Array<string> = [];

    if (this.paymentable && this.paymentable.invoiceable && this.paymentable.invoiceable.items) {
      this.paymentable.invoiceable.items.forEach((saleFinalItem: any) => {
        if (saleFinalItem.hotel_booking_room && saleFinalItem.hotel_booking_room.hotel) {
          if (hotels.indexOf(saleFinalItem.hotel_booking_room.hotel.name) < 0) {
            hotels.push(saleFinalItem.hotel_booking_room.hotel.name);
          }
        }
      });
    }

    return hotels;
  }

  /**
   * Всички suppliers от всички редове във файнъла(от booking-а), който се плащат(ако има)
   */
  get bookingSuppliers(): Array<string> {
    const suppliers: Array<string> = [];

    if (this.paymentable && this.paymentable.invoiceable && this.paymentable.invoiceable.items) {
      this.paymentable.invoiceable.items.forEach((saleFinalItem: any) => {
        if (
          saleFinalItem.hotel_booking_room &&
          saleFinalItem.hotel_booking_room.booking &&
          saleFinalItem.hotel_booking_room.booking.supplier
        ) {
          if (suppliers.indexOf(saleFinalItem.hotel_booking_room.booking.supplier.name) < 0) {
            suppliers.push(saleFinalItem.hotel_booking_room.booking.supplier.name);
          }
        }
      });
    }

    return suppliers;
  }

  /**
   * Всички ref_numbers от всички редове във файнъла(от booking-а), който се плащат(ако има)
   */
  get bookingRefNumbers(): Array<string> {
    const refNumbers: Array<string> = [];

    if (this.paymentable && this.paymentable.invoiceable && this.paymentable.invoiceable.items) {
      this.paymentable.invoiceable.items.forEach((saleFinalItem: any) => {
        if (
          saleFinalItem.hotel_booking_room &&
          saleFinalItem.hotel_booking_room.booking &&
          saleFinalItem.hotel_booking_room.booking.ref_number
        ) {
          if (refNumbers.indexOf(saleFinalItem.hotel_booking_room.booking.ref_number) < 0) {
            refNumbers.push(saleFinalItem.hotel_booking_room.booking.ref_number);
          }
        }
      });
    }

    return refNumbers;
  }

  /**
   * Всички payment_deadline от всички редове във файнъла(от booking-а), който се плащат(ако има)
   */
  get bookingPaymentDeadline(): Array<string> {
    const deadLines: Array<string> = [];

    if (this.paymentable && this.paymentable.invoiceable && this.paymentable.invoiceable.items) {
      this.paymentable.invoiceable.items.forEach((saleFinalItem: any) => {
        if (
          saleFinalItem.hotel_booking_room &&
          saleFinalItem.hotel_booking_room.booking &&
          saleFinalItem.hotel_booking_room.booking.payment_deadline
        ) {
          if (deadLines.indexOf(saleFinalItem.hotel_booking_room.booking.payment_deadline) < 0) {
            deadLines.push(saleFinalItem.hotel_booking_room.booking.payment_deadline);
          }
        }
      });
    }

    return deadLines;
  }
}
