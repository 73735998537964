import { Injectable } from '@angular/core';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { Logger } from '@app/core';
import { HttpClient } from '@angular/common/http';

const log = new Logger('TimezonesService');

@Injectable({
  providedIn: 'root',
})
export class TimezonesService {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<any> {
    log.debug('index');
    return this.http.get('v1/time/zones', query.buildGetOptions());
  }
}
