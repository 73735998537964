<div class="modal-header">
  <h4 class="modal-title">
    {{ 'SALES.SALES_FINAL_FORM.FORM_TITLE' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form id="sale-final-form" (ngSubmit)="save()" [formGroup]="form" novalidate class="modal-body p-0 d-flex flex-column">
  <div class="d-flex overflow-hidden flex-grow-1">
    <div class="offer-lines-wrapper ps" [perfectScrollbar]>
      <div class="offer-lines-title mt-1 mb-2">
        {{ 'SALES.SALES_FINAL_FORM.OFFERED_ROOMS' | translate }}
      </div>
      <div
        class="single-offer-line"
        *ngFor="let offerLIne of offer?.items; let I = index"
        (click)="setCurrentOfferLine(offerLIne.id, offerLIne)"
        [ngClass]="{ active: offerLIne.id === currentOfferLineId }"
      >
        <div class="offer-line-name">{{ I + 1 }} {{ 'SALES.SALES_FINAL_FORM.ROOM_LINE' | translate }} {{ I + 1 }}</div>

        <div class="offer-line-hotel mb-1">
          {{ offerLIne.hotel.name }}

          <span *ngIf="offerLIne.hotel.stars">
            , {{ offerLIne.hotel.stars }}
            <span class="star-ico-wrapper"> <i class="fas fa-star"></i></span>
          </span>

          <div class="offer-line-hotel-city-wrapper" *ngIf="offerLIne?.hotel?.city">
            {{ offerLIne?.hotel?.city?.name }}
            <span *ngIf="offerLIne?.hotel?.city?.country">, {{ offerLIne?.hotel?.city?.country?.name }}</span>
          </div>
        </div>

        <div class="d-flex justify-content-between" *ngIf="offerLIne?.ref_number">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.REF_NUMBER' | translate }}
          </div>
          <div class="value">
            {{ offerLIne?.ref_number }}
          </div>
        </div>

        <div class="d-flex justify-content-between" *ngIf="offerLIne?.supplier_id">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.SUPPLIER' | translate }}
          </div>
          <div class="value text-right">
            {{ offerLIne?.supplier.name }}
          </div>
        </div>

        <div class="d-flex justify-content-between" *ngIf="offerLIne?.type_id">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.TYPE' | translate }}
          </div>
          <div class="value">
            {{ offerLIne?.type?.value }}
          </div>
        </div>

        <div class="d-flex justify-content-between" *ngIf="offerLIne?.meal_plan_id">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.MEAL_PLAN' | translate }}
          </div>
          <div class="value">
            {{ offerLIne?.meal_plan?.value }}
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.CHECK_IN' | translate }}
          </div>
          <div class="value">
            {{ offerLIne.check_in | date: env.pipeDateFormat }}
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.NIGHTS' | translate }}
          </div>
          <div class="value">
            {{ getNightsCount(offerLIne.check_in, offerLIne.check_out) }}
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.CHECK_OUT' | translate }}
          </div>
          <div class="value">
            {{ offerLIne.check_out | date: env.pipeDateFormat }}
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.PEOPLE' | translate }}
          </div>
          <div class="value">
            {{ offerLIne.people }}
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div class="title">
            {{ 'SALES.SALES_FINAL_FORM.ROOM_COUNT' | translate }}
          </div>
          <div class="value">
            {{ offerLIne.count }}
          </div>
        </div>

        <div class="price">
          {{ 'SALES.SALES_FINAL_FORM.PRICE' | translate }} - {{ offerLIne.price }} {{ 'DEFAULT_CURRENCY' | translate }}
        </div>
      </div>
    </div>

    <div class="content flex-grow-1">
      <div
        *ngFor="let offerLIne of offer?.items; let I = index"
        class="search-results-wrapper"
        [ngClass]="{ 'd-none': offerLIne.id !== currentOfferLineId }"
      >
        <div *ngIf="offerLIne.id === currentOfferLineId" class="h-100 d-flex flex-column">
          <div class="row filters-row">
            <div class="col-sm-1">
              <div class="form-group">
                <label>{{ 'SALES.SALES_FINAL_FORM.PROPERTY' | translate }}</label>

                <app-type-select
                  class="form-control-wrapper-sm"
                  [(model)]="offerLIne.filters.property_id"
                  [groupType]="'booking_properties'"
                ></app-type-select>
              </div>
            </div>

            <div class="col-sm-1">
              <div class="form-group">
                <label>{{ 'SALES.SALES_FINAL_FORM.REF_NUMBER' | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="offerLIne.filters.ref_number"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="col-sm-1">
              <div class="form-group">
                <label>{{ 'SALES.SALES_FINAL_FORM.CHECK_IN' | translate }}</label>
                <p-calendar
                  inputStyleClass="form-control"
                  class="prime-ng-datapicker"
                  styleClass="d-block"
                  monthNavigator="true"
                  yearNavigator="true"
                  dateFormat="yy-mm-dd"
                  appendTo="body"
                  baseZIndex="1"
                  [(ngModel)]="offerLIne.filters.check_in"
                  [ngModelOptions]="{ standalone: true }"
                ></p-calendar>
              </div>
            </div>

            <div class="col-sm-1">
              <div class="form-group">
                <label>{{ 'SALES.SALES_FINAL_FORM.CHECK_OUT' | translate }}</label>
                <p-calendar
                  inputStyleClass="form-control"
                  class="prime-ng-datapicker"
                  styleClass="d-block"
                  monthNavigator="true"
                  yearNavigator="true"
                  dateFormat="yy-mm-dd"
                  appendTo="body"
                  baseZIndex="1"
                  [(ngModel)]="offerLIne.filters.check_out"
                  [ngModelOptions]="{ standalone: true }"
                ></p-calendar>
              </div>
            </div>

            <div class="col-sm-2">
              <app-event-select class="form-control-wrapper-sm" [(model)]="offerLIne.filters.event"></app-event-select>
            </div>

            <div class="radius-holder holder">
              <div class="form-group">
                <label>{{ 'SALES.SALES_OFFERS_FORM.RADIUS' | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm text-left"
                  [(ngModel)]="offerLIne.filters.radius"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="col-sm-2">
              <app-supplier-select
                class="form-control-wrapper-sm"
                [(model)]="offerLIne.filters.supplier"
              ></app-supplier-select>
            </div>

            <div class="col-sm-1">
              <div class="form-group">
                <label>{{ 'SALES.SALES_FINAL_FORM.TYPE' | translate }}</label>
                <app-type-select
                  class="form-control-wrapper-sm"
                  [(model)]="offerLIne.filters.type_id"
                  [groupType]="'rooms'"
                ></app-type-select>
              </div>
            </div>

            <div class="col-sm-1">
              <div class="form-group">
                <label>{{ 'SALES.SALES_FINAL_FORM.MEAL_PLAN' | translate }}</label>
                <app-type-select
                  class="form-control-wrapper-sm"
                  [(model)]="offerLIne.filters.meal_plan_id"
                  [groupType]="'room_meal_plans'"
                ></app-type-select>
              </div>
            </div>

            <div class="col">
              <button type="button" class="btn btn-info btn-xs search-btn-margin" (click)="getHotelRooms(offerLIne)">
                {{ 'SALES.SALES_FINAL_FORM.SEARCH' | translate }}
              </button>

              <button
                type="button"
                class="btn btn-primary btn-xs search-btn-margin"
                (click)="addHotelBooking(offerLIne)"
              >
                {{ 'SALES.SALES_FINAL_FORM.NEW_BOOKING' | translate }}
              </button>
            </div>
          </div>

          <div class="results-kendo-grid-wrapper">
            <div *ngIf="offerLIne?.roomsResults?.data?.length <= 0">
              {{ 'SALES.SALES_FINAL_FORM.SEARCH_NO_RESULTS_MSG' | translate }}
            </div>

            <kendo-grid
              *ngIf="offerLIne?.roomsResults?.data?.length > 0"
              [style.height]="'100%'"
              [data]="offerLIne?.roomsResults?.getGridData()"
              [pageSize]="offerLIne?.roomsResults?.meta?.per_page"
              [pageable]="true"
              [sortable]="true"
              [skip]="offerLIne.state?.skip"
              [sort]="offerLIne.state?.sort"
              (dataStateChange)="getHotelRooms(offerLIne, $event)"
              [rowClass]="roomRowCallback"
            >
              <kendo-grid-column
                [width]="35"
                class="text-center p-1 relative"
                headerClass="text-center p-1"
                field="id"
                title="{{ 'SALES.SALES_FINAL_FORM.TABLE_NUMBER_COLUMN_TITLE' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span
                    [ngClass]="{
                      'bg-danger':
                        !isBefore(dataItem.check_in, offerLIne.check_in) ||
                        !isBefore(offerLIne.check_out, dataItem.check_out) ||
                        dataItem.available_count <= 0,
                      'bg-success':
                        isBefore(dataItem.check_in, offerLIne.check_in) ||
                        isBefore(offerLIne.check_out, dataItem.check_out) ||
                        dataItem.available_count > 1
                    }"
                    class="suitable-indicator"
                  ></span>

                  {{ rowIndex + 1 }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [width]="130"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="hotel.name"
                title="{{ 'SALES.SALES_FINAL_FORM.HOTEL' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem?.hotel?.name }}
                  <span *ngIf="dataItem?.hotel?.stars" class="text-nowrap">
                    ({{ dataItem.hotel.stars }}<span class="star-ico-wrapper"><i class="fas fa-star"></i></span>)
                  </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [width]="130"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="booking.supplier.name"
                title="{{ 'SALES.SALES_FINAL_FORM.SUPPLIER' | translate }}"
              >
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center p-1"
                headerClass="text-center p-1"
                field="booking.property.value"
                title="{{ 'SALES.SALES_FINAL_FORM.PROPERTY' | translate }}"
                [width]="60"
              >
              </kendo-grid-column>

              <kendo-grid-column
                [width]="75"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="booking.company.abbreviation"
                title="{{ 'SALES.SALES_FINAL_FORM.COMPANY' | translate }}"
              >
              </kendo-grid-column>

              <!--              <kendo-grid-column-->
              <!--                [width]="130"-->
              <!--                class="text-center p-1"-->
              <!--                headerClass="text-center p-1"-->
              <!--                field="hotel.city.name"-->
              <!--                title="{{ 'SALES.SALES_FINAL_FORM.CITY' | translate }}"-->
              <!--              >-->
              <!--                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
              <!--                  {{ dataItem.hotel.city.name }} ({{ dataItem.hotel.city.country.name }})-->
              <!--                </ng-template>-->
              <!--              </kendo-grid-column>-->

              <!--              <kendo-grid-column-->
              <!--                [width]="70"-->
              <!--                class="text-center p-1"-->
              <!--                headerClass="text-center p-1"-->
              <!--                title="{{ 'SALES.SALES_FINAL_FORM.DISTANCE_TO_FAIR' | translate }}"-->
              <!--              >-->
              <!--                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
              <!--                  {{ dataItem.distance_to_fair }}-->
              <!--                </ng-template>-->
              <!--              </kendo-grid-column>-->

              <!--              <kendo-grid-column-->
              <!--                [width]="70"-->
              <!--                class="text-center p-1"-->
              <!--                headerClass="text-center p-1"-->
              <!--                field="hotel.distance_to_center"-->
              <!--                title="{{ 'SALES.SALES_FINAL_FORM.DISTANCE_TO_CENTER' | translate }}"-->
              <!--              >-->
              <!--              </kendo-grid-column>-->

              <kendo-grid-column
                [width]="110"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="type.value"
                title="{{ 'SALES.SALES_FINAL_FORM.TYPE' | translate }}"
              >
              </kendo-grid-column>

              <kendo-grid-column
                [width]="35"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="meal_plan.value"
                title="{{ 'SALES.SALES_FINAL_FORM.MEAL_PLAN_SHORT' | translate }}"
              >
              </kendo-grid-column>

              <kendo-grid-column
                [width]="110"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="check_in"
                title="{{ 'SALES.SALES_FINAL_FORM.CHECK_IN' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span
                    class="font-weight-bold"
                    [ngClass]="{
                      'text-success': isBefore(dataItem.check_in, offerLIne.check_in),
                      'text-danger': !isBefore(dataItem.check_in, offerLIne.check_in)
                    }"
                  >
                    {{ dataItem.check_in | date: env.pipeDateFormat }}
                  </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [width]="55"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="nights"
                title="{{ 'SALES.SALES_FINAL_FORM.NIGHTS' | translate }}"
                [sortable]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ getNightsCount(dataItem.check_in, dataItem.check_out) }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [width]="110"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="check_out"
                title="{{ 'SALES.SALES_FINAL_FORM.CHECK_OUT' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span
                    class="font-weight-bold"
                    [ngClass]="{
                      'text-success': isBefore(offerLIne.check_out, dataItem.check_out),
                      'text-danger': !isBefore(offerLIne.check_out, dataItem.check_out)
                    }"
                  >
                    {{ dataItem.check_out | date: env.pipeDateFormat }}
                  </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [width]="100"
                class="text-center p-1"
                headerClass="text-center p-1"
                field="booking.ref_number"
                title="{{ 'SALES.SALES_FINAL_FORM.REF_NUMBER_SHORT' | translate }}"
              >
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center p-1"
                headerClass="text-center p-1"
                field="net_price_eur_avg"
                title="{{ 'SALES.SALES_FINAL_FORM.NET_PRICE_AVG' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  ~{{ dataItem.net_price | number: '1.2-2' }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center p-1"
                headerClass="text-center p-1"
                field="price_avg"
                title="{{ 'SALES.SALES_FINAL_FORM.PRICE_AVG' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  ~{{ dataItem.price | number: '1.2-2' }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center p-1"
                headerClass="text-center p-1"
                field="client_margin"
                title="{{ 'SALES.SALES_FINAL_FORM.CLIENT_MARGIN' | translate }}"
                [sortable]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div
                    class="font-weight-bold"
                    [ngClass]="{
                      'text-danger': dataItem.client_margin < 0,
                      'text-success': dataItem.client_margin >= 0
                    }"
                  >
                    ~{{ dataItem.client_margin | number: '1.2-2' }}
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center p-1"
                headerClass="text-center p-1"
                field="available_rooms"
                title="{{ 'SALES.SALES_FINAL_FORM.ROOMS' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div
                    class="font-weight-bold"
                    [ngClass]="{
                      'text-danger': dataItem.available_count <= 0,
                      'text-success': dataItem.available_count > 0
                    }"
                  >
                    <span [ngbTooltip]="'SALES.SALES_FINAL_FORM.ROOMS_FULL_AVAILABLE_TOOLTIP' | translate" container="body">
                      {{ dataItem.available_count }}
                    </span>
                    <span [ngbTooltip]="'SALES.SALES_FINAL_FORM.ROOMS_USED_TOOLTIP' | translate" container="body">
                      ({{ dataItem.count - dataItem.available_count }})
                    </span>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center p-1"
                headerClass="text-center p-1"
                field="room_count"
                title="{{ 'SALES.SALES_FINAL_FORM.ROOM_COUNT_SHORT' | translate }}"
                [sortable]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <input
                    type="text"
                    required
                    [ngClass]="{ invalid: dataItem.room_count > dataItem.available_count || dataItem.room_count < 1 }"
                    class="form-control table-form-control-sm form-control-room-count"
                    [(ngModel)]="dataItem.room_count"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center p-1"
                headerClass="text-center p-1"
                title="{{ 'SALES.SALES_FINAL_FORM.ACTIONS' | translate }}"
                [includeInChooser]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <button
                    [disabled]="
                      (dataItem.room_count < 1 ||
                      dataItem.room_count > dataItem.available_count ||
                      !dataItem.room_count)
                       && this.additionalForm.get('fullAvailable').value


                       // ||
                      // !isBefore(dataItem.check_in, offerLIne.check_in) ||
                      // !isBefore(offerLIne.check_out, dataItem.check_out)
                    "
                    type="button"
                    class="btn btn-success btn-ico align-top"
                    (click)="addRow(dataItem, offerLIne)"
                  >
                    <i class="fal fa-plus"></i>
                  </button>
                  <app-edit-button (click)="editHotelBooking(dataItem.booking, offerLIne, dataItem.allotment_id)">
                  </app-edit-button>
                </ng-template>
              </kendo-grid-column>

              <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
                <app-kendo-grid-refresh-button
                  (click)="getHotelRooms(offerLIne, offerLIne?.state)"
                ></app-kendo-grid-refresh-button>
              </ng-template>
            </kendo-grid>
          </div>
        </div>
      </div>

      <div *ngIf="!currentOfferLineId" class="select-offer-line-msg">
        <i class="fal fa-arrow-left"></i> {{ 'SALES.SALES_FINAL_FORM.SELECT_OFFER_LINE_MSG' | translate }}
      </div>

      <div class="selected-wrapper ps" [perfectScrollbar] *ngIf="currentOfferLineId">
        <div class="d-flex justify-content-between">
          <div class="modal-section-title">{{ 'SALES.SALES_FINAL_FORM.SELECTED_ROOMS' | translate }}</div>
          <div></div>
        </div>

        <div class="table" *ngIf="form.get('items').value.length > 0">
          <thead>
          <tr>
            <th>{{ 'SALES.SALES_FINAL_FORM.TABLE_NUMBER_COLUMN_TITLE' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.HOTEL' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.SUPPLIER' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.COMPANY' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.CITY' | translate }}</th>
            <!--            <th>{{ 'SALES.SALES_FINAL_FORM.DISTANCE_TO_FAIR' | translate }}</th>-->
            <!--            <th>{{ 'SALES.SALES_FINAL_FORM.DISTANCE_TO_CENTER' | translate }}</th>-->
            <th>{{ 'SALES.SALES_FINAL_FORM.TYPE' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.MEAL_PLAN_SHORT' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.CHECK_IN' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.NIGHTS' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.CHECK_OUT' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.REF_NUMBER_SHORT' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.PEOPLE' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.NET_PRICE' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.NET_PRICE_TOTAL' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.CLIENT_PRICE' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.CLIENT_PRICE_TOTAL' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.CLIENT_MARGIN' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.CLIENT_TOTAL_MARGIN' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.ROOM_COUNT_SHORT' | translate }}</th>
            <th>{{ 'SALES.SALES_FINAL_FORM.ACTIONS' | translate }}</th>
          </tr>
          </thead>
          <tbody *ngFor="let dataItem of form.get('items')?.value; let rowIndex = index">
          <tr *ngIf="dataItem.sale_offer_item_id === currentOfferLineId">
            <td style="width: 35px; min-width: 35px">
              {{ rowIndex + 1 }}
            </td>
            <td style="width: 130px; min-width: 130px">
              {{ dataItem?.data?.hotel?.name }}
              <span *ngIf="dataItem?.data?.hotel?.stars" class="text-nowrap">
                  ({{ dataItem.data?.hotel?.stars }}<span class="star-ico-wrapper"><i class="fas fa-star"></i></span>)
                </span>
            </td>
            <td style="width: 130px; min-width: 130px">
              {{ dataItem?.data?.booking?.supplier?.name }}
            </td>
            <td style="width: 75px; min-width: 75px">
              {{ dataItem?.data?.booking?.company?.abbreviation }}
            </td>
            <td style="width: 130px; min-width: 130px">
              {{ dataItem.data?.hotel?.city?.name }}
              <span *ngIf="dataItem?.data?.hotel?.city?.country?.name">
                  ({{ dataItem?.data?.hotel?.city?.country?.name }})
                </span>
            </td>
            <!--            <td style="width: 70px">-->
            <!--              {{ dataItem.data?.distance_to_fair }}-->
            <!--            </td>-->
            <!--            <td style="width: 70px">-->
            <!--              {{ dataItem.data?.hotel?.distance_to_center }}-->
            <!--            </td>-->
            <td style="width: 110px; min-width: 110px">
              {{ dataItem.data?.type.value }}
            </td>
            <td style="width: 35px; min-width: 35px">
              {{ dataItem.data?.meal_plan.value }}
            </td>
            <td style="width: 110px; min-width: 110px">
              <p-calendar
                inputStyleClass="form-control"
                class="prime-ng-datapicker"
                styleClass="d-block"
                monthNavigator="true"
                yearNavigator="true"
                dateFormat="dd.mm.yy"
                appendTo="body"
                baseZIndex="1"
                [(ngModel)]="dataItem.check_in"
                [ngModelOptions]="{ standalone: true }"
                [required]="true"
                [yearRange]="yearsRangeFormatted"
                [minDate]="dataItem.data.check_in"
                [maxDate]="dataItem.data.check_out"
                (onSelect)="calculateTotals()"
              ></p-calendar>
            </td>
            <td style="width: 55px; min-width: 55px">
              {{
              (dataItem.check_out
                | amParse: env.momentDateFormat
                | amDifference: (dataItem.check_in | amParse: env.momentDateFormat):'days') || null
              }}
            </td>
            <td style="width: 110px; min-width: 110px">
              <p-calendar
                inputStyleClass="form-control"
                class="prime-ng-datapicker"
                styleClass="d-block"
                monthNavigator="true"
                yearNavigator="true"
                dateFormat="dd.mm.yy"
                appendTo="body"
                baseZIndex="1"
                [(ngModel)]="dataItem.check_out"
                [ngModelOptions]="{ standalone: true }"
                [required]="true"
                [yearRange]="yearsRangeFormatted"
                [minDate]="dataItem.data.check_in"
                [maxDate]="dataItem.data.check_out"
                (onSelect)="calculateTotals()"
              ></p-calendar>
            </td>
            <td style="width: 100px; min-width: 100px">
              {{ dataItem.data?.booking?.ref_number }}
            </td>
            <td>
              {{ dataItem.data?.people }}
            </td>
            <td>
              {{ dataItem.data?.net_price | number: '1.2-2' }}
            </td>
            <td>
              {{
              dataItem.data?.net_price *
              dataItem.data?.room_count *
              getNightsCount(dataItem.check_in, dataItem.check_out) | number: '1.2-2'
              }}
            </td>
            <td>
              {{ dataItem.data?.price | number: '1.2-2' }}
            </td>
            <td>
              {{
              dataItem.data?.price *
              dataItem.data?.room_count *
              getNightsCount(dataItem?.check_in, dataItem?.check_out) | number: '1.2-2'
              }}
            </td>
            <td>
                <span>
                  {{ dataItem.data?.client_margin | number: '1.2-2' }}
                </span>
            </td>
            <td>
              {{
              dataItem.data?.client_margin *
              dataItem.data?.room_count *
              getNightsCount(dataItem.check_in, dataItem.check_out) | number: '1.2-2'
              }}
            </td>
            <td>
              {{ dataItem.data?.room_count }}
            </td>
            <td>
              <app-edit-button
                *ngIf="dataItem?.id"
                (click)="
                    editSelectedHotelBooking(dataItem?.hotel_booking_room?.booking, dataItem?.hotel_booking_nights)
                  "
              >
              </app-edit-button>

              <button (click)="removeRow(rowIndex)" type="button" class="btn btn-danger btn-ico align-top">
                <i class="fal fa-times"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-bottom-section pl-2 pr-2">
    <div class="row">
      <div class="col-sm-6">
        <div class="modal-section-title">{{ 'SALES.SALES_FINAL_FORM.PAYMENT' | translate }}</div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label>{{ 'SALES.SALES_FINAL_FORM.PAYMENT_METHOD' | translate }}</label>
              <app-type-select
                [selectFirst]="!saleFinalId"
                [parentFormGroup]="form"
                [passFormControlName]="'payment_method_id'"
                [groupType]="'payment_method'"
              ></app-type-select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label>{{ 'SALES.SALES_FINAL_FORM.CLIENT_PENALTY' | translate }}</label>
              <input type="text" class="form-control" formControlName="client_penalty"/>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label>{{ 'SALES.SALES_FINAL_FORM.CANCELLATION_TERMS' | translate }}</label>

              <div class="text-truncate" [ngbTooltip]="offer?.cancellation_terms" container="body">
                {{ offer?.cancellation_terms }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="modal-section-title">{{ 'SALES.SALES_FINAL_FORM.PRICES' | translate }}</div>
        <div class="d-flex">
          <div class="mr-3">
            <div class="prices-title">
              {{ 'SALES.SALES_FINAL_FORM.NET_PRICE' | translate }}
            </div>
            <!--            all row prices - -->
            {{ totals?.netPrice | number: '1.2-2' }}
          </div>
          <div class="mr-3">
            <div class="prices-title">
              {{ 'SALES.SALES_FINAL_FORM.TOTAL_NET_PRICE' | translate }}
            </div>
            <!--            all row prices*rooms*count - -->
            {{ totals?.netPriceNightsCount | number: '1.2-2' }}
          </div>

          <div class="mr-3">
            <div class="prices-title">
              {{ 'SALES.SALES_FINAL_FORM.CLIENT_PRICE' | translate }}
            </div>
            <!--            all row prices - -->
            {{ totals?.price | number: '1.2-2' }}
          </div>
          <div class="mr-3">
            <div class="prices-title">
              {{ 'SALES.SALES_FINAL_FORM.CLIENT_TOTAL_PRICE' | translate }}
            </div>
            <!--            all row prices*rooms*count - -->
            {{ totals?.priceNightsCount | number: '1.2-2' }}
          </div>

          <div class="mr-3">
            <div class="prices-title">
              {{ 'SALES.SALES_FINAL_FORM.CLIENT_MARGIN' | translate }}
            </div>
            <!--            all row total margins - -->
            {{ totals?.margin | number: '1.2-2' }}
          </div>
          <div class="mr-3">
            <div class="prices-title">
              {{ 'SALES.SALES_FINAL_FORM.CLIENT_TOTAL_MARGIN' | translate }}
            </div>
            <!--            all row total margins - -->
            {{ totals?.marginNightsCount | number: '1.2-2' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="modal-footer d-flex justify-content-between">
  <div>
    {{ 'SALES.SALES_FINAL_FORM.ALL_PRICES_MSG' | translate }}
  </div>
  <div>

    <form [formGroup]="additionalForm" novalidate class="d-inline-block mr-3">
      <input type="checkbox" formControlName="fullAvailable" id="fullAvailable"/>
      <label for="fullAvailable">
        {{ 'SALES.SALES_FINAL_FORM.FULL_AVAILABLE' | translate }}
      </label>
    </form>

    <button type="button" class="btn btn-outline-dark btn-xs mr-2" (click)="save(false)" [disabled]="form.invalid">
      {{ 'SALES.SALES_FINAL_FORM.SAVE' | translate }}
    </button>

    <button type="submit" class="btn btn-outline-dark btn-xs" form="sale-final-form" [disabled]="form.invalid">
      {{ 'SALES.SALES_FINAL_FORM.SAVE_AND_CLOSE' | translate }}
    </button>
  </div>
</div>
