import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientHistoryComponent } from '@app/shared/components/client-history/client-history.component';

@Injectable({
  providedIn: 'root',
})
export class ClientHistoryService {
  constructor(private modalService: NgbModal) {}

  public open(client_id: number): Promise<any> {
    const modal = this.modalService.open(ClientHistoryComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.client_id = client_id;
    return modal.result;
  }
}
