<div class="container-fluid" *ngIf="accessService.hasAccess([], ['bonuses.menu'])">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'BONUSES.BONUSES_LIST.TITLE' | translate }}
      </h5>
    </div>

    <div class="d-flex align-items-center">
      <div>
        <button type="button" class="btn btn-link" (click)="clearFilters()">
          {{ 'BONUSES.BONUSES_LIST.CLEAR_FILTERS' | translate }}
        </button>
      </div>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="requestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'bonuses_list', grid)"
    [loading]="loader"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'BONUSES.BONUSES_LIST.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'BONUSES.BONUSES_LIST.SALE_ID' | translate }}"
      filter="numeric"
      [width]="120"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="client.name"
      title="{{ 'BONUSES.BONUSES_LIST.CLIENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('client.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-client-box [client]="dataItem.client" [flag]="true"></app-client-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.formattedName"
      title="{{ 'BONUSES.BONUSES_LIST.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.formattedName', true)"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.event?.id }" target="_blank">
          {{ dataItem?.event?.formattedName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="status.value"
      title="{{ 'BONUSES.BONUSES_LIST.STATUS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('status.value', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_status'"
          [filter]="filter"
          [field]="'status_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.status"></app-type-badge>
        <div class="text-truncate" [ngbTooltip]="dataItem.status_reason" container="body">
          {{ dataItem.status_reason }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="company.name"
      title="{{ 'BONUSES.BONUSES_LIST.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('company.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/settings/companies/view']" [queryParams]="{ id: dataItem?.company_id }" target="_blank">
          {{ dataItem?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sales_agent.fake_first_name"
      title="{{ 'BONUSES.BONUSES_LIST.SALES_AGENT_AND_SALES_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sales_agent.fake_first_name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
          [field]="'sales_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sales_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="reservation_agent.fake_first_name"
      title="{{ 'BONUSES.BONUSES_LIST.RESERVATION_AGENT_AND_RESERVATION_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('reservation_agent.fake_first_name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
          [field]="'reservation_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.reservation_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="customer_care_agent.fake_first_name"
      title="{{ 'BONUSES.BONUSES_LIST.CUSTOMER_CARE_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('customer_care_agent.fake_first_name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.CUSTOMER_CARE_AGENT"
          [field]="'customer_care_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.customer_care_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="total_bonus_amount"
      title="{{ 'BONUSES.BONUSES_LIST.TOTAL_BONUS_AMOUNT' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('total_bonus_amount')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem.total_bonus_amount">
          {{ dataItem.total_bonus_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="total_margin_amount"
      title="{{ 'BONUSES.BONUSES_LIST.TOTAL_MARGIN_AMOUNT' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('total_margin_amount')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem.total_margin_amount">
          {{ dataItem.total_margin_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      field="total_amount"
      title="{{ 'BONUSES.BONUSES_LIST.TOTAL_AMOUNT' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('total_amount', true)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.total_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
      field="balance"
      title="{{ 'BONUSES.BONUSES_LIST.BALANCE' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('balance', true)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span
          class="font-weight-bold"
          [ngClass]="{ 'text-danger': dataItem?.balance < 0, 'text-success': dataItem?.balance >= 0 }"
        >
          {{ dataItem.balance | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'BONUSES.BONUSES_LIST.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-sale-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER_LEAD
              ],
              ['sales.show']
            )
          "
          [link]="['/sales/view']"
          [params]="{ id: dataItem.id }"
        >
        </app-sale-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
