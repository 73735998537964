<div
  *ngIf="!form.get(newControlName).value"
  class="image-holder"
  [ngClass]="placeholder ? placeholder : null"
  [style.background-image]="form.get(controlName).value && getBackgroundImg(form.get(controlName).value)"
>
  <div
    (click)="clearImage()"
    class="btn btn-ico btn-danger delete-image"
    *ngIf="form.get(controlName).value || form.get(newControlName).value"
  >
    <i class="fal fa-times"></i>
  </div>
</div>

<div
  *ngIf="form.get(newControlName).value"
  class="image-holder"
  [ngClass]="placeholder ? placeholder : null"
  [style.background-image]="form.get(newControlName).value && getBackgroundImg(form.get(newControlName).value)"
>
  <div
    (click)="clearImage()"
    class="btn btn-ico btn-danger delete-image"
    *ngIf="form.get(controlName).value || form.get(newControlName).value"
  >
    <i class="fal fa-times"></i>
  </div>
</div>

<p-fileUpload
  #fileUpload
  mode="basic"
  [name]="newControlName"
  [auto]="true"
  [accept]="MediaLibraryAcceptTypes.IMAGES"
  customUpload="true"
  (uploadHandler)="addImage($event)"
></p-fileUpload>
