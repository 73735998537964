<div class="modal-header">
  <h4 class="modal-title">
    {{ 'TEMPLATES.TEMPLATES_PREVIEW_FORM.PREVIEW_TEMPLATE' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ps" [perfectScrollbar]>
  <div class="form-group">
    <label>{{ 'TEMPLATES.TEMPLATES_PREVIEW_FORM.SUBJECT' | translate }}</label>
    <input type="text" class="form-control" [ngModel]="template.subject" [disabled]="true" />
  </div>

  <editor
    [ngModel]="template.html_template"
    [disabled]="true"
    [init]="{
      toolbar: '',
      plugins: '',
      base_url: '/assets/tinymce',
      suffix: '.min',
      height: 520
    }"
  ></editor>
</div>

<div class="modal-footer d-flex">
  <div>
    <button type="button" class="btn btn-outline-dark btn-xs" (click)="modal.dismiss('Cross click')">
      {{ 'TEMPLATES.TEMPLATES_PREVIEW_FORM.CLOSE' | translate }}
    </button>
  </div>
</div>
