import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Sale } from '@app/sales/sales/sale.model';
import { SalesRequestsFormComponent } from '@app/sales/sales/sales-requests-form/sales-requests-form.component';
import { ActivitiesService } from '@app/shared/components/activities/activities.service';

@Injectable({
  providedIn: 'root',
})
export class SalesRequestsFormService {
  constructor(private modalService: NgbModal, public activitiesService: ActivitiesService) {}

  public open(sale: null | Sale = null, withSideBar: boolean = true): Promise<any> {
    const size = withSideBar ? 'full-width-sidebar' : 'full';
    const backdrop = !withSideBar;
    const windowClass = withSideBar ? 'pointer-events-none' : '';

    const modal = this.modalService.open(SalesRequestsFormComponent, {
      size: size as 'lg',
      backdropClass: 'custom-z-index-modal',
      backdrop: backdrop,
      windowClass: windowClass,
      // windowClass: this.activitiesService.isOpened ? 'activities-opened' : ''
    });
    modal.componentInstance.sale = sale;
    return modal.result;
  }
}
