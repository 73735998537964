import { Component, Input } from '@angular/core';
import { Events } from '@app/events/events/events.model';
import { EventGroup } from '@app/events/events-groups/event-group.model';
import { Hotel } from '@app/hotels/hotels/hotel.model';
import { Logger } from '@app/core';
import { WebsitesService } from '@app/settings/websites/websites.service';
import { HotelsService } from '@app/hotels/hotels/hotels.service';
import { ApiQuery } from '@app/core/http/api-query';

const log = new Logger('LinksCompactComponent');

@Component({
  selector: 'app-links-compact',
  templateUrl: './links-compact.component.html',
  styleUrls: ['./links-compact.component.scss'],
})
export class LinksCompactComponent {
  @Input() data: Events | EventGroup | Hotel;
  companyWebsiteLocales: Array<any>;
  currentWebsiteLocaleId: number;

  constructor(private websitesService: WebsitesService, private hotelService: HotelsService) {}

  shown() {
    if (!this.data) {
      return log.error('No data passed to component!');
    }

    log.debug(this.data);
    log.debug(this.data instanceof Hotel);
    this.getData();
  }

  selectCompanyWebSiteLocale(id: number): void {
    this.currentWebsiteLocaleId = id;
  }

  getWebsiteLocaleName(id: number): string {
    log.debug('getWebsiteLocaleName id', id);
    const localeObj = this.companyWebsiteLocales.find((x) => x.id === id);
    return localeObj.website.name + ' ' + localeObj.website.url + ' ' + localeObj.locale.locale;
  }

  copyInputMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private getData() {
    this.websitesService.getLocales().subscribe((websiteLocales: []) => {
      this.companyWebsiteLocales = websiteLocales;

      const query = new ApiQuery().addInclude('links');
      if (this.data instanceof Hotel) {
        this.hotelService.show(this.data.id, query).subscribe((hotel: Hotel) => {
          log.debug('load hotel', hotel);

          this.companyWebsiteLocales.forEach((webSiteLocale: any, index: number) => {
            if (index === 0) {
              this.selectCompanyWebSiteLocale(webSiteLocale.id);
            }
            webSiteLocale['links'] = [];
            hotel.links.forEach((link: any) => {
              if (link.company_website_locale_id === Number(webSiteLocale.id)) {
                webSiteLocale.links.push(link);
              }
            });
          });
        });
      }
    });
  }
}
