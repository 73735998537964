import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { HotelBookingsFormService } from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, DetailExpandEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { ActivitiesService } from '@app/shared/components/activities/activities.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { CurrenciesAdditionsService } from '@app/shared/services/currencies-additions.service';
import { OfferMultipleClientsService } from '@app/shared/components/offer-multiple-clients/offer-multiple-clients.service';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { SalesFinalService } from '@app/sales/sales/sales-final.service';
import * as moment from 'moment';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Sale } from '@app/sales/sales/sale.model';
import { PymentableType } from '@app/finance/payments/paymentable-type.enum';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { Room } from '@app/hotels/rooms/room.model';
import { PaymentsService } from '@app/finance/payments/payments.service';
import { PaymentsTypes } from '@app/finance/payments/payments-types.enum';
import {Payment} from '@app/finance/payments/payment.model';
import {PaymentsFormService} from '@app/finance/payments/payments-form.service';

const log = new Logger('HotelBookingsViewComponent');

@Component({
  selector: 'app-hotel-bookings-view',
  templateUrl: './hotel-bookings-view.component.html',
  styleUrls: ['./hotel-bookings-view.component.scss'],
})
export class HotelBookingsViewComponent extends KendoGridComponent implements OnInit, OnDestroy {
  hotelBookingId: number;
  hotelBooking: HotelBooking;
  env = environment;
  LaravelModelsEnum = LaravelModelsEnum;

  hotelBookingsRoomsResponse: PaginatedResponse;
  hotelBookingsRoomsData: GridDataResult;
  hotelBookingsRoomsState: DataStateChangeEvent;
  hotelBookingsRoomsApiQuery: ApiQuery = new ApiQuery();
  CurrenciesAdditionsService = CurrenciesAdditionsService;
  loading = false;

  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;

  saleFinalsItemsResponse: PaginatedResponse;
  saleFinalsItemsData: GridDataResult;
  saleFinalsItemsState: DataStateChangeEvent;
  saleFinalsItemsApiQuery: ApiQuery = new ApiQuery();

  UserRoleType = UserRoleType;

  @ViewChild('perfectScroll', { static: true }) perfectScroll: PerfectScrollbarDirective;

  hotelBookingPaymentsResponse: PaginatedResponse;
  hotelBookingPaymentsData: GridDataResult;
  hotelBookingPaymentsState: DataStateChangeEvent;
  hotelBookingPaymentsApiQuery: ApiQuery = new ApiQuery();
  paymentTypes = PaymentsTypes;

  totals: any;

  constructor(
    public activitiesService: ActivitiesService,
    private route: ActivatedRoute,
    private hotelBookingsService: HotelBookingsService,
    private hotelBookingsFormService: HotelBookingsFormService,
    protected kendoGridService: KendoGridService,
    private offerMultipleClientsService: OfferMultipleClientsService,
    private saleFinalsService: SalesFinalService,
    public accessService: AccessService,
    private auth: AuthenticationService,
    private paymentsService: PaymentsService,
    private paymentsFormService: PaymentsFormService,
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.hotelBookingId = Number(params.get('id'));
        this.loadData();
        this.loadHotelBookingsRoomsData();
        this.activitiesService.auto(LaravelModelsEnum.HOTELBOOKING, this.hotelBookingId, false);
        this.loadSaleFinalItemsData();
        this.loadHotelBookingPaymentsData();
        this.getBookingTotals();
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  ngOnDestroy(): void {
    // крие sidebar-a s activity-тата и съобщенията
    this.activitiesService.hide();
  }

  loadData(): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes(
      'hotel',
      'contracting_agent',
      'reservation_agent',
      'supplier',
      'company',
      'cancellation_terms',
      'property',
      'currency'
    );
    this.hotelBookingsService.show(this.hotelBookingId, apiQuery).subscribe((hotelBooking: HotelBooking) => {
      this.hotelBooking = hotelBooking;
    });
  }

  edit(hotelBookingRoomsGrid: any) {
    this.hotelBookingsFormService.open(this.hotelBooking).then(() => {
      this.loadData();
      this.collapseAll(hotelBookingRoomsGrid);
      this.loadHotelBookingsRoomsData();
    });
  }

  editPayment(payment: Payment): void {
    this.paymentsFormService.open(payment).then(
      (res) => {
        this.loadHotelBookingPaymentsData();
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  add() {
    this.hotelBookingsFormService.open().then(
      (response) => {
        this.loadData();
        this.loadHotelBookingsRoomsData();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  loadHotelBookingsRoomsData(state: DataStateChangeEvent = null) {
    this.loading = true;
    this.hotelBookingsRoomsState = state;
    this.hotelBookingsRoomsApiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'booking.company',
        'booking.events',
        'booking.supplier',
        'booking.cancellation_terms',
        'booking.property',
        'hotel.city.country',
        'type',
        'meal_plan',
        'nights.net_price_currency',
        'nights.price_currency',
        'hotel.venues',
        'free_rooms_allotment'
      )
      .addFilter('booking.id', this.hotelBookingId);

    this.hotelBookingsService.indexRooms(this.hotelBookingsRoomsApiQuery).subscribe((response: PaginatedResponse) => {
      this.hotelBookingsRoomsResponse = response;
      this.hotelBookingsRoomsData = response.getGridData();
      this.loading = false;
      this.perfectScroll.update();
    });
  }

  loadSaleFinalItemsData(state: DataStateChangeEvent = null) {
    this.saleFinalsItemsState = state;
    this.saleFinalsItemsApiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'saleFinal.sale.client',
        'saleFinal.sale.requests.status',
        'saleFinal.sale.client.country',
        'saleFinal.sale.event',
        'hotel_booking_room.type',
        'hotel_booking_room.meal_plan',
        'hotelBookingNights.net_price_currency',
        'sale_offer_item'
      )
      .addFilter('hotel_booking_room.booking.id', this.hotelBookingId);

    this.saleFinalsService.itemsIndex(this.saleFinalsItemsApiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((item: any) => {
        const nights = this.getNightsCount(item.check_in, item.check_out);

        if (item.hotel_booking_nights) {
          let netPrice = 0;

          item.hotel_booking_nights.forEach((night: any, index: number) => {
            netPrice += CurrenciesAdditionsService.toEUR(
              Number(night.net_price),
              night.net_price_currency,
              night.exchange_rate
            );
          });

          item.hotel_booking_room['net_price'] = netPrice / nights / item.count;

          item.sale_final.sale = new Sale().deserialize(item.sale_final.sale);
        }
      });

      this.saleFinalsItemsResponse = response;
      this.saleFinalsItemsData = response.getGridData();
      this.perfectScroll.update();
    });
  }

  /**
   * Връща разликата в дни между две дати
   * @param date1 Дата 1
   * @param date2 Дата 2
   */
  getNightsCount(date1: string | Date, date2: string | Date): number {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }

  deleteHotelBookingRoom(hotelBooking: HotelBooking) {
    this.hotelBookingsService.destroy(hotelBooking.id).subscribe(() => {
      this.loadHotelBookingsRoomsData(this.hotelBookingsRoomsState);
    });
  }

  /**
   * Отваря sidebar-a s activity-тата и съобщенията
   */
  openActivities(): void {
    this.activitiesService.show(LaravelModelsEnum.HOTELBOOKING, this.hotelBookingId, false);
  }

  public collapseAll(topGrid: any) {
    this.hotelBookingsRoomsData.data.forEach((item, idx) => {
      topGrid.collapseRow(idx);
    });
  }

  /**
   *  Когато се отваря details row на hotelBookingsRooms таблицата
   * @param event kendo grid event obj
   */
  hotelBookingsRoomNnExpand(event: DetailExpandEvent) {
    if (!event.dataItem['meta_front'] || !event.dataItem['meta_front']['detail_expanded']) {
      this.loading = true;
      this.hotelBookingsService.showRooms(event.dataItem.allotment_id).subscribe((room: any) => {
        event.dataItem['nights_info'] = room.nights_info;
        event.dataItem['meta_front'] = {};
        event.dataItem['meta_front']['detail_expanded'] = true;
        event.dataItem['meta_front']['totals'] = {
          blocked: 0,
          blocked_amount: 0,
          blocked_net_amount: 0,
          sold: 0,
          sold_amount: 0,
          sold_net_amount: 0,
          total: 0,
          total_net_amount: 0,
        };
        room.nights_info.forEach((night: any) => {
          event.dataItem['meta_front']['totals']['blocked'] += night.blocked;
          event.dataItem['meta_front']['totals']['blocked_amount'] += !isNaN(night.blocked_amount)
            ? night.blocked_amount
            : 0;
          event.dataItem['meta_front']['totals']['blocked_net_amount'] += !isNaN(night.blocked_net_amount)
            ? night.blocked_net_amount
            : 0;

          event.dataItem['meta_front']['totals']['sold'] += night.sold;
          event.dataItem['meta_front']['totals']['sold_amount'] += !isNaN(night.sold_amount) ? night.sold_amount : 0;
          event.dataItem['meta_front']['totals']['sold_net_amount'] += !isNaN(night.sold_net_amount)
            ? night.sold_net_amount
            : 0;
          event.dataItem['meta_front']['totals']['total'] += night.total;
          event.dataItem['meta_front']['totals']['total_net_amount'] += !isNaN(night.total_net_amount)
            ? night.total_net_amount
            : 0;
        });
        event.dataItem['meta_front']['totals']['blocked_amount'] =
          event.dataItem['meta_front']['totals']['blocked_amount'] / event.dataItem['meta_front']['totals']['blocked'];
        event.dataItem['meta_front']['totals']['blocked_net_amount'] =
          event.dataItem['meta_front']['totals']['blocked_net_amount'] /
          event.dataItem['meta_front']['totals']['blocked'];
        event.dataItem['meta_front']['totals']['sold_amount'] =
          event.dataItem['meta_front']['totals']['sold_amount'] / event.dataItem['meta_front']['totals']['sold'];
        event.dataItem['meta_front']['totals']['sold_net_amount'] =
          event.dataItem['meta_front']['totals']['sold_net_amount'] / event.dataItem['meta_front']['totals']['sold'];
        event.dataItem['meta_front']['totals']['total_net_amount'] =
          event.dataItem['meta_front']['totals']['total_net_amount'] / event.dataItem['meta_front']['totals']['total'];

        this.loading = false;
        log.debug('event', event);
      });
    }
  }

  offerMultipleClientsOpen() {
    this.offerMultipleClientsService.open(this.hotelBookingId).then(
      () => {},
      () => {}
    );
  }

  loadHotelBookingPaymentsData(state: DataStateChangeEvent = null) {
    this.hotelBookingPaymentsState = state;
    this.hotelBookingPaymentsApiQuery
      .setDataSetFilters(state)
      .addFilter('paymentable_type', PymentableType.HOTELBOOKING)
      .addFilter('paymentable_id', this.hotelBookingId)
      .addIncludes(
        'bank_account',
        'company',
        'payment_method',
        'type',
        'clientable',
        'currency',
        'fee_currency',
        'refundable',
        'paymentable.events',
        'paymentable.rooms'
      );

    // проверява дали юзъра има определени роли, но не админ
    if (!this.accessService.hasAccess([], [])) {
      this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
        if (role.id === UserRoleType.RESERVATION_AGENT || role.id === UserRoleType.CONTRACTING_AGENT) {
          this.hotelBookingPaymentsApiQuery.addFilter('user_id', this.auth.accessToken.profile.id);
        }
      });
    }

    this.paymentsService.index(this.hotelBookingPaymentsApiQuery).subscribe((response: PaginatedResponse) => {
      this.hotelBookingPaymentsResponse = response;
      this.hotelBookingPaymentsData = response.getGridData();

      this.hotelBookingPaymentsResponse.data.forEach((item: any) => {
        if (!item.paymentable || !item.paymentable.rooms) {
          return false;
        }

        item.paymentable.earliest_check_in = moment
          .min(item.paymentable.rooms.map((room: Room) => moment(room.check_in)))
          .format(this.env.apiPipeDateFormat);
      });
    });
  }

  getBookingTotals() {
    this.hotelBookingsService.totals(this.hotelBookingId).subscribe((totals: any) => {
      this.totals = totals;
    });
  }
}
