import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { Logger } from '@app/core';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';
import { SalesService } from '@app/sales/sales/sales.service';
import { FormBuilder, FormGroup } from '@angular/forms';

const log = new Logger('GuestNamesListComponent');

@Component({
  selector: 'app-guest-names',
  templateUrl: './guest-names-list.component.html',
  styleUrls: ['./guest-names-list.component.scss'],
})
export class GuestNamesListComponent extends KendoGridComponent implements OnInit {
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  loader = false;
  UserRoleType = UserRoleType;
  form: FormGroup;

  constructor(
    private hotelBookingService: HotelBookingsService,
    protected kendoGridService: KendoGridService,
    public accessService: AccessService,
    private salesService: SalesService,
    private formBuilder: FormBuilder
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.state = CustomKendoFilter.getStateFromStorage('GuestNamesListComponent');
    this.createForm();
    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData');
    this.loader = true;
    this.state = state;
    this.apiQuery.addIncludes('client.country', 'event', 'company', 'status', 'sold_nights').setDataSetFilters(state);

    let searchStr = this.form.get('keywords').value;

    if (this.form.get('exact').value && this.form.get('keywords').value) {
      searchStr = '"' + searchStr + '"';
    }

    this.salesService.index(this.apiQuery, searchStr).subscribe((response: PaginatedResponse) => {
      this.requestsResponse = response;
      this.data = response.getGridData();
      this.loader = false;
    });

    CustomKendoFilter.pushStateInStorage('GuestNamesListComponent', this.state);
  }

  clearFilters() {
    this.form.patchValue({
      keywords: null,
      exact: true,
    });

    this.apiQuery = new ApiQuery();
    this.loadData();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      keywords: [null],
      exact: [true],
    });
  }
}
