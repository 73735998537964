import { Injectable } from '@angular/core';
import { ResourceInterface } from '@app/shared/models/resource.interface';
import { HttpClient } from '@angular/common/http';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { map } from 'rxjs/operators';
import { HttpResponse, Logger } from '@app/core';
import { Model } from '@app/shared/models/model.model';
import { EmailTemplate } from '@app/settings/templates/email-template.model';
import { EmailTemplateCompiler } from '@app/settings/templates/email-template-compiler.model';
import { Email } from '@app/shared/models/email.model';

const log = new Logger('TemplatesService');

@Injectable({
  providedIn: 'root',
})
export class TemplatesService implements ResourceInterface {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    log.debug('index');
    return this.http.get('v1/templates', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((template: any) => {
          return new EmailTemplate().deserialize(template);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  destroy(id: number): Observable<any> {
    return this.http.delete('v1/templates/' + id);
  }

  store(template: EmailTemplate): Observable<EmailTemplate> {
    return this.http.post('v1/templates', template).pipe(
      map((response: HttpResponse) => {
        return new EmailTemplate().deserialize(response.data);
      })
    );
  }

  update(template: EmailTemplate): Observable<EmailTemplate> {
    return this.http.put('v1/templates/' + template.id, template).pipe(
      map((response: HttpResponse) => {
        return new EmailTemplate().deserialize(response.data);
      })
    );
  }

  show(id: number, query: ApiQuery = new ApiQuery()): Observable<Model> {
    return this.http.get('v1/templates/' + id, query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return new EmailTemplate().deserialize(response.data);
      })
    );
  }

  compile(emailTemplate: EmailTemplateCompiler): Observable<any> {
    return this.http.post('v1/templates/compiler', emailTemplate).pipe(
      map((response: HttpResponse) => {
        log.debug('response', response);
        return response.data;
      })
    );
  }

  send(email: Email): Observable<any> {
    return this.http.post('v1/templates/send ', email).pipe(
      map((response: HttpResponse) => {
        log.debug('response', response);
        return response.data;
      })
    );
  }

  export(query: ApiQuery = new ApiQuery()): Observable<any> {
    return this.http.download('v1/templates', query.buildGetOptions());
  }
}
