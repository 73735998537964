<div class="reminders-dropdown-wrapper" ngbDropdown placement="bottom-right">
  <a
    class="btn btn-sm btn-primary"
    ngbDropdownToggle
    [ngbTooltip]="'SALES.REMINDERS_DROPDOWN_TRIGGER' | translate"
    container="body"
  >
    <i class="far fa-bell"></i>
  </a>

  <div class="reminders-dropdown-menu" ngbDropdownMenu>
    <ul class="list list-unstyled">
      <li (click)="openReminder()" ngbDropdownToggle>
        <i class="far fa-plus"></i>
        {{ 'REMINDER_BUTTON.ADD_REMINDER' | translate }}
      </li>
      <li (click)="openReminders()">
        <i class="fas fa-eye"></i>
        {{ 'REMINDER_BUTTON.VIEW_MORE_LINK' | translate }}
      </li>
    </ul>
  </div>
</div>
