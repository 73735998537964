import {Component, OnInit} from '@angular/core';
import {KendoGridService} from '@app/shared/kendo/kendo-grid.service';
import {AccessService} from '@app/core/access/access.service';
import {Router} from '@angular/router';
import {KendoGridComponent} from '@app/shared/kendo/kendo-grid.component';
import {ApiQuery} from '@app/core/http/api-query';
import {PaginatedResponse} from '@app/shared/models/paginated-response.model';
import {DataStateChangeEvent, GridDataResult, RowClassArgs} from '@progress/kendo-angular-grid';
import {environment} from '@env/environment';
import {UserRoleType} from '@app/settings/users/user-role-type.enum';
import {CustomKendoFilter} from '@app/shared/kendo/custom-kendo-filter';
import {Logger} from '@app/core';
import {InvoicesService} from '@app/finance/invoices/invoices.service';
import {InvoiceType} from '@app/finance/invoices/invoice-type.enum';
import {Invoice} from '@app/finance/invoices/invoice.model';
import {InvoicesFormService} from '@app/finance/invoices/invoices-form.service';
import {LaravelModelsEnum} from '@app/shared/models/model.model';

const log = new Logger('InvoicesWithoutBonusesListComponent');

@Component({
  selector: 'app-invoices-without-bonuses-list',
  templateUrl: './invoices-without-bonuses-list.component.html',
  styleUrls: ['./invoices-without-bonuses-list.component.scss']
})
export class InvoicesWithoutBonusesListComponent extends KendoGridComponent implements OnInit {
  response: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  UserRoleType = UserRoleType;
  loader = false;

  constructor(
    protected kendoGridService: KendoGridService,
    public accessService: AccessService,
    private router: Router,
    private invoicesService: InvoicesService,
    private invoicesFormService: InvoicesFormService,
  ) {
    super(kendoGridService);
  }

  ngOnInit(): void {

    const storageFilter = CustomKendoFilter.getStateFromStorage('InvoicesWithoutBonusesListComponent');
    if (storageFilter) {
      this.state = storageFilter;
    }

    this.loadData(this.state);
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData', state);
    this.loader = true;
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addFilters({
        invoiceable_type: LaravelModelsEnum.SALE_FINAL,
        paid: true
      })
      .addIncludes(
        'invoiceable.sale.sales_agent',
        'invoiceable.sale.reservation_agent',
        'invoiceable.sale.customer_care_agent',
        'invoiceable.sale.source',
        'invoiceable.sale.event',
        'bonuses',
        'payments',
        'invoiceable.items.hotel_booking_room.booking.hotel',
        'invoiceable.items.hotel_booking_room.booking.contracting_agent',
      );


    this.invoicesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {

      response.data.forEach((item: Invoice) => {
        item['meta_front'] = {};
        item.meta_front['contracting_agents'] = [];
        item.meta_front['hotels'] = [];
        item.invoiceable.items.forEach((finalItem: any) => {
          const userName = finalItem.hotel_booking_room.booking.contracting_agent.fake_first_name + ' ' + finalItem.hotel_booking_room.booking.contracting_agent.fake_last_name;
          const hotelsNames = finalItem.hotel_booking_room.booking.hotel.name;
          if (item.meta_front['contracting_agents'].indexOf(userName) < 0) {
            item.meta_front['contracting_agents'].push(userName);
          }
          if (item.meta_front['hotels'].indexOf(hotelsNames) < 0) {
            item.meta_front['hotels'].push(hotelsNames);
          }
        });
      });
      log.debug('response', response);
      this.response = response;
      this.data = response.getGridData();
      this.loader = false;
    });

    CustomKendoFilter.pushStateInStorage('InvoicesWithoutBonusesListComponent', this.state);
  }

  edit(invoice: Invoice): void {
    this.invoicesFormService.open(invoice).then(
      (res) => {
        this.loadData(this.state);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  invoiceRowCallback(context: RowClassArgs) {
    let rowOpacity = false;

    if (context.dataItem.linked_id && context.dataItem.type_id === InvoiceType.PROFORMA) {
      rowOpacity = true;
    }
    return {
      'kendo-row-opacity': rowOpacity,
    };
  }


  clearFilters() {
    this.router.navigate(['/invoices-without-bonuses']);

    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData(this.state);
    });
  }

}
