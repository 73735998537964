import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { RolesService } from '@app/settings/users/roles/roles.service';
import { ApiQuery } from '@app/core/http/api-query';
import { Logger } from '@app/core';
import { UsersService } from '@app/settings/users/users.service';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { User } from '@app/settings/users/user.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { isArray } from 'lodash';

const log = new Logger('UserAndGroupSelectComponent');

@Component({
  selector: 'app-user-and-group-select',
  templateUrl: './user-and-group-select.component.html',
  styleUrls: ['./user-and-group-select.component.scss'],
})
export class UserAndGroupSelectComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() passFormControlNameUserId = 'assign_to_user_id';
  @Input() passFormControlNameRoleId = 'assign_to_role_id';
  @Input() roleType: UserRoleType | Array<UserRoleType> = null;
  @Output() select = new EventEmitter();
  loader: boolean;
  data: Array<any> = [];
  selected: Array<any> = [];
  preSelectedObj: any = {
    user_id: null,
    role_id: null,
  };
  @Input() selectableGroup = true;
  @Input() onlyActive = true;

  constructor(private roleService: RolesService, private usersService: UsersService) {}

  ngOnInit() {
    log.debug('init');

    const preSelectedUserId = this.parentFormGroup.get(this.passFormControlNameUserId);
    const preSelectedRoleId = this.parentFormGroup.get(this.passFormControlNameRoleId);

    const preselectedUserIdSubscriber = preSelectedUserId.valueChanges.subscribe((user_id: number) => {
      log.debug('preselectedUserIdSubscriber', user_id);
      this.preSelectedObj.user_id = user_id;
      this.patch();
      preselectedUserIdSubscriber.unsubscribe();
    });

    const preselectedRoleIdSubscriber = preSelectedRoleId.valueChanges.subscribe((role_id: number) => {
      this.preSelectedObj.role_id = role_id;
      this.patch();
      preselectedRoleIdSubscriber.unsubscribe();
    });

    this.getUsers();
  }

  patch() {
    for (const item of this.data) {
      if (this.preSelectedObj.user_id === item.user_id && this.preSelectedObj.role_id === item.role_id) {
        this.selected = [item];
        break;
      }

      if (this.preSelectedObj.user_id === item.user_id) {
        this.selected = [item];
      }

      if (this.preSelectedObj.role_id === item.role_id) {
        this.selected = [item];
      }
    }
  }

  getUserRoles() {
    const apiQuery = new ApiQuery().setLimit(9999);

    if (this.roleType) {
      apiQuery.addFilter('id', this.roleType.toString());
    }

    this.roleService.index(apiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((userRole: UserRole) => {
        this.data = [
          ...this.data,
          {
            role_id: userRole.id,
            role_name: userRole.name,
          },
        ];
      });

      this.loader = false;
      this.patch();
    });
  }

  getUsers() {
    this.loader = true;
    const apiQuery = new ApiQuery();
    apiQuery.setLimit(9999).addInclude('roles').addFilter('employee', true);

    if (this.onlyActive) {
      apiQuery.addFilter('active', true);
    }

    if (this.roleType) {
      apiQuery.addFilter('roles.id', this.roleType.toString());
    }

    this.usersService.index(apiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((user: User) => {
        if (user.roles.length > 0) {
          user.roles.forEach((userRole: UserRole) => {
            // Ако има подаден масив с роли в this.roleType добавя само тях
            if (this.roleType && isArray(this.roleType)) {
              this.roleType.forEach((roleId: number) => {
                if (roleId === userRole.id) {
                  this.data = [
                    ...this.data,
                    {
                      role_id: userRole.id,
                      role_name: userRole.name,
                      user_id: user.id,
                      user_name: user.names,
                    },
                  ];
                }
              });
              // Ако има подадена само една роля в this.roleType добавя само нея
            } else if (this.roleType) {
              if (this.roleType === userRole.id) {
                this.data = [
                  ...this.data,
                  {
                    role_id: userRole.id,
                    role_name: userRole.name,
                    user_id: user.id,
                    user_name: user.names,
                  },
                ];
              }
              // Ако няма подадено this.roleType добавя всички
            } else {
              this.data = [
                ...this.data,
                {
                  role_id: userRole.id,
                  role_name: userRole.name,
                  user_id: user.id,
                  user_name: user.names,
                },
              ];
            }
          });
        } else {
          this.data = [
            ...this.data,
            {
              user_id: user.id,
              user_name: user.names,
            },
          ];
        }
      });
      this.getUserRoles();
    });
  }

  /**
   * Когато избере групата
   * В childs има един фиктивен ред, който е скрит. Той е json с информацията на групата. Връща него
   * @param groupKey groupKey
   * @param children children
   */
  groupValue(groupKey: string, children: any[]) {
    let temp: any;

    children.forEach((child: any) => {
      if (!child.user_id) {
        temp = child;
      }
    });
    return temp;
  }

  compare(item: any, selected: any) {
    if (selected[0] === item) {
      return true;
    }
    return false;
  }

  onChange($event: any) {
    if (!$event) {
      this.parentFormGroup.get(this.passFormControlNameUserId).patchValue(null);
      this.parentFormGroup.get(this.passFormControlNameRoleId).patchValue(null);
    }

    if ($event && $event.user_id) {
      this.parentFormGroup.get(this.passFormControlNameUserId).patchValue($event.user_id);
    } else {
      this.parentFormGroup.get(this.passFormControlNameUserId).patchValue(null);
    }

    if ($event && $event.role_id) {
      this.parentFormGroup.get(this.passFormControlNameRoleId).patchValue($event.role_id);
    } else {
      this.parentFormGroup.get(this.passFormControlNameRoleId).patchValue(null);
    }

    this.select.emit($event);
  }

  keydown($event: KeyboardEvent) {
    if ($event.code === 'ArrowUp' || $event.code === 'ArrowDown') {
      return false;
    }
    return true;
  }
}
