import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { BanksRoutingModule } from '@app/banks/banks-routing.module';
import { BankAccountsListComponent } from './bank-accounts/bank-accounts-list/bank-accounts-list.component';
import { BankAccountsFormComponent } from './bank-accounts/bank-accounts-form/bank-accounts-form.component';
import { BankAccountsViewComponent } from './bank-accounts/bank-accounts-view/bank-accounts-view.component';
import { BankAccountsStatementsMatchFormComponent } from './bank-accounts/bank-accounts-statements-match-form/bank-accounts-statements-match-form.component';

@NgModule({
  declarations: [
    BankAccountsListComponent,
    BankAccountsFormComponent,
    BankAccountsViewComponent,
    BankAccountsStatementsMatchFormComponent,
  ],
  imports: [CommonModule, BanksRoutingModule, SharedModule],
  providers: [],
})
export class BanksModule {}
