<ng-template [ngIf]="client">
  <span
    *ngIf="client?.country && client?.country?.code && flag"
    class="flag-icon"
    [ngClass]="'flag-icon-' + (client?.country?.code | lowercase)"
  >
  </span>

  <a [routerLink]="['/clients/view']" [queryParams]="{ id: client?.id }" [target]="targetBlank ? '_blank' : '_self'">
    {{ client?.name }}
  </a>
</ng-template>
