import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Invoice } from '@app/finance/invoices/invoice.model';
import { InvoicesFormComponent } from '@app/finance/invoices/invoices-form/invoices-form.component';

@Injectable({
  providedIn: 'root',
})
export class InvoicesFormService {
  constructor(private modalService: NgbModal) {}

  public open(invoice: null | Invoice = null, hideBackdrop: boolean = false): Promise<any> {
    const config: NgbModalOptions = {
      size: 'xl' as 'lg',
      windowClass: 'modal-custom',
    };
    if (hideBackdrop) {
      config.backdropClass = 'transparent';
    }

    const modal = this.modalService.open(InvoicesFormComponent, config);
    modal.componentInstance.invoice = invoice;
    return modal.result;
  }
}
