import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesOffersFormComponent } from '@app/sales/sales/sales-offers-form/sales-offers-form.component';
import { Sale } from '@app/sales/sales/sale.model';

@Injectable({
  providedIn: 'root',
})
export class SalesOffersFormService {
  constructor(private modalService: NgbModal) {}

  public open(sale: Sale, offerId: number = null): Promise<any> {
    const modal = this.modalService.open(SalesOffersFormComponent, {
      size: 'full' as 'lg',
    });
    modal.componentInstance.sale = sale;
    modal.componentInstance.offerId = offerId;
    return modal.result;
  }
}
