import { Model } from '@app/shared/models/model.model';
import { Type } from '@app/settings/types/types.model';
import { Company } from '@app/settings/companies/company.model';
import { Locale } from '@app/settings/locales/locales.model';

export class EmailTemplate extends Model {
  id: number;
  name: string;
  subject: string;
  html_template: string;
  text_template: string;
  company_id: number;
  company?: Company;
  locale_id: number;
  locale?: Locale;
  type_id: number;
  type?: Type;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.company) {
      this.company = new Company().deserialize(input.company);
    }

    if (input.locale) {
      this.locale = new Locale().deserialize(input.locale);
    }

    if (input.type) {
      this.type = new Type().deserialize(input.type);
    }

    return this;
  }
}
