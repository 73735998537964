<div class="form-group" [formGroup]="form">
  <label>
    {{ 'MIN_NIGHT_PICKER.MIN_NIGHTS' | translate }}
    <span class="min-nights-count" *ngIf="minNightsCount">({{ minNightsCount }})</span>
  </label>
  <p-calendar
    inputStyleClass="form-control"
    class="prime-ng-datapicker"
    styleClass="d-block"
    appendTo="body"
    baseZIndex="1"
    formControlName="min_nights_fake"
    selectionMode="range"
    [readonlyInput]="true"
    [hideOnDateTimeSelect]="true"
    dateFormat="dd.mm.yy"
    (onSelect)="onSelect()"
    [defaultDate]="defaultDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
  >
  </p-calendar>
</div>
