import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { ApiQuery, ApiQuerySortTypes } from '@app/core/http/api-query';
import { Events } from '@app/events/events/events.model';
import { EventsService } from '@app/events/events/events.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { DataSet } from '@app/shared/kendo/data-set';
import { Router } from '@angular/router';
import { ProfileService } from '@app/profile/profile.service';

@Component({
  selector: 'app-upcoming-events-list',
  templateUrl: './upcoming-events-list.component.html',
  styleUrls: ['./upcoming-events-list.component.scss'],
})
export class UpcomingEventsListComponent implements OnInit {
  apiQuery: ApiQuery = new ApiQuery();
  data: Array<Events>;
  env = environment;
  collapsed = this.profileService.getSetting('UpcomingEventsListComponent', 'dashboardBoxes').collapsed;

  constructor(private eventsService: EventsService, private router: Router, private profileService: ProfileService) {}

  ngOnInit() {
    if (!this.collapsed) {
      this.loadData();
    }
  }

  loadData() {
    const filter: DataStateChangeEvent = {
      filter: {
        filters: [{ field: 'date_from', operator: 'gte', value: moment() }],
        logic: 'and',
      },
      group: undefined,
      skip: 0,
      sort: undefined,
      take: 0,
    };

    this.apiQuery
      .setLimit(15)
      .setDataSetFilters(filter)
      .addIncludes('city.country')
      .setSort('date_from', ApiQuerySortTypes.ASC);

    this.eventsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.data = response.data;
    });
  }

  viewAllUpcomingEvents() {
    this.router.navigate(['/events'], {
      queryParams: {
        dataSet: new DataSet()
          .addFilter('date_from', moment().format(this.env.apiPipeDateFormat), 'gte')
          .getStringifyDataSet(),
      },
    });
  }

  toggle() {
    this.collapsed = !this.collapsed;
    this.profileService
      .setSetting('UpcomingEventsListComponent', { collapsed: this.collapsed }, 'dashboardBoxes')
      .subscribe();
    if (!this.collapsed && !this.data) {
      this.loadData();
    }
  }
}
