import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Logger } from '@app/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssignService } from '@app/shared/services/assign.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';

const log = new Logger('QuickSetAssignAndDateComponent');

@Component({
  selector: 'app-quick-set-assign-and-date',
  templateUrl: './quick-set-assign-and-date.component.html',
  styleUrls: ['./quick-set-assign-and-date.component.scss'],
})
export class QuickSetAssignAndDateComponent implements OnInit {
  @Input() modelId: number;
  @Input() modelType: LaravelModelsEnum;
  @Input() preSelectedUserId: number = null;
  @Input() preSelectedRoleId: number = null;
  @Input() preSelectedDate: string = null;
  @Input() roleType: UserRoleType | Array<UserRoleType> = null;
  @Input() selectableGroup = true;

  dateNow = moment().format(environment.apiPipeDateFormat);
  form: FormGroup;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private assignService: AssignService
  ) {}

  ngOnInit() {
    this.createForm();
    this.patchForm();
  }

  updateSaleAssign(): void {
    this.form.get('assign_date').patchValue(moment().format(environment.apiPipeDateFormat));

    this.assignService.update(this.form.value).subscribe(() => {
      this.translate.get('QUICK_SET_ASSIGN_AND_DATE.ASSIGN_UPDATED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  updateSaleAssignDate() {
    log.debug('updateSaleAssignDate');

    this.assignService.update(this.form.value).subscribe(() => {
      this.translate.get('QUICK_SET_ASSIGN_AND_DATE.ASSIGN_DATE_UPDATED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      assign_to_user_id: [null],
      assign_to_role_id: [null],
      assign_date: [null],
      model_id: [this.modelId],
      model_type: [this.modelType],
    });
  }

  private patchForm() {
    setTimeout(() => {
      this.form.get('assign_to_user_id').patchValue(this.preSelectedUserId);
      this.form.get('assign_to_role_id').patchValue(this.preSelectedRoleId);
      this.form.get('assign_date').patchValue(this.preSelectedDate);
    });
  }
}
