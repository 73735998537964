import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiQuery } from '@app/core/http/api-query';
import { FormGroup } from '@angular/forms';
import { TimezonesService } from '@app/shared/services/timezones.service';
import { Logger } from '@app/core';

const log = new Logger('TimezoneSelectComponent');

@Component({
  selector: 'app-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss'],
})
export class TimezoneSelectComponent implements OnInit {
  data: Array<any>;
  apiQuery: ApiQuery = new ApiQuery();
  loader: boolean;
  @Input() parentFormGroup: FormGroup;
  @Output() select = new EventEmitter();

  constructor(private timezonesService: TimezonesService) {}

  ngOnInit() {
    if (this.parentFormGroup.get('country') && !this.parentFormGroup.get('country').value) {
      this.parentFormGroup.get('country').valueChanges.subscribe((country: any) => {
        this.loadTimezones();
      });
    } else {
      this.loadTimezones();
    }
  }

  loadTimezones() {
    this.loader = true;
    if (
      this.parentFormGroup.get('country') &&
      this.parentFormGroup.get('country').value &&
      this.parentFormGroup.get('country').value.code
    ) {
      this.apiQuery.addFilter('country_code', this.parentFormGroup.get('country').value.code);
    }

    this.timezonesService.index(this.apiQuery.setLimit(9999)).subscribe((response: any) => {
      log.debug('response', response);
      this.data = response.data;
      this.loader = false;
    });
  }
}
