import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelsService } from '@app/hotels/hotels/hotels.service';
import { ApiQuery } from '@app/core/http/api-query';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { Logger } from '@app/core';
import { Hotel } from '@app/hotels/hotels/hotel.model';
import { VenuesService } from '@app/settings/venues/venues.service';
import { Venue } from '@app/settings/venues/venue.model';

const log = new Logger('MapModalComponent');

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent implements OnInit {
  @Input() hotelsIds: Array<number> = [];
  @Input() venuesIds: Array<number> = [];
  hotels: Array<Hotel>;
  venues: Array<Venue>;
  previous: any;

  constructor(
    public modal: NgbActiveModal,
    private hotelsService: HotelsService,
    private venuesService: VenuesService
  ) {}

  ngOnInit() {
    log.debug('hotelsIds', this.hotelsIds);
    log.debug('venuesIds', this.venuesIds);
    this.getHotels();
    this.getVenues();
  }

  number(number: any): number {
    return Number(number);
  }

  getHotels() {
    if (this.hotelsIds.length > 0) {
      const query = new ApiQuery().setLimit(99999).addFilter('id', this.hotelsIds.toString());
      this.hotelsService.index(query).subscribe((hotels: PaginatedResponse) => {
        this.hotels = hotels.data;
      });
    }
  }

  getVenues() {
    if (this.venuesIds.length > 0) {
      log.debug('this.venuesIds.toString()', this.venuesIds.toString());
      const query = new ApiQuery().setLimit(99999).addFilter('id', this.venuesIds.toString());
      this.venuesService.index(query).subscribe((venues: PaginatedResponse) => {
        this.venues = venues.data;
      });
    }
  }

  clickedMarker(infowindow: any) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  test() {
    log.debug(111111111111111111111111111111111111111111111);
  }
}
