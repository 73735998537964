import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Payment } from '@app/finance/payments/payment.model';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { PaymentsFormService } from '@app/finance/payments/payments-form.service';
import { Logger } from '@app/core';

const log = new Logger('PaymentButtonComponent');

@Component({
  selector: 'app-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss'],
})
export class PaymentButtonComponent implements OnInit {
  @Input() paymentable_id: number;
  @Input() paymentable_type: LaravelModelsEnum;
  @Input() iconBtn = true;
  @Input() additionalJson = {};
  @Input() hideBackdrop = false;
  @Output() close = new EventEmitter();

  constructor(private paymentsFormService: PaymentsFormService) {}

  ngOnInit() {
    if (!this.paymentable_id || !this.paymentable_type) {
      log.error('Invalid paymentable_id or paymentable_type', this.paymentable_id, this.paymentable_type);
    }
  }

  createPayment() {
    const payment = new Payment().deserialize({
      ...this.additionalJson,
      paymentable_id: this.paymentable_id,
      paymentable_type: this.paymentable_type,
    });
    this.paymentsFormService.open(payment, this.hideBackdrop).then((res: any) => {
      this.close.emit(res);
    });
  }
}
