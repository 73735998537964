import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { ApiQuery } from '@app/core/http/api-query';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { Logger } from '@app/core';
import { ProfileService } from '@app/profile/profile.service';
import { DataSet } from '@app/shared/kendo/data-set';
import * as moment from 'moment';

const log = new Logger('Dashboard LastBookingsListComponent');

@Component({
  selector: 'app-last-bookings-list',
  templateUrl: './last-bookings-list.component.html',
  styleUrls: ['./last-bookings-list.component.scss'],
})
export class LastBookingsListComponent implements OnInit {
  data: Array<HotelBooking>;
  apiQuery: ApiQuery = new ApiQuery();
  collapsed = this.profileService.getSetting('LastBookingsListComponent', 'dashboardBoxes').collapsed;

  constructor(private hotelBookingService: HotelBookingsService, private profileService: ProfileService) {}

  ngOnInit() {
    if (!this.collapsed) {
      this.loadData();
    }
  }

  loadData() {
    // Венко го иска така в скайп на 30.04.2020 12:35
    const dataSet = new DataSet().addFilter('check_in', moment().subtract(2, 'week').toDate(), 'gte');

    this.apiQuery
      .setLimit(15)
      .addIncludes('booking.events', 'hotel', 'nights.net_price_currency', 'nights.price_currency')
      .setDataSetFilters(dataSet);

    this.hotelBookingService.indexRooms(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.data = response.data;
      log.debug('this data', this.data);
    });
  }

  toggle() {
    this.collapsed = !this.collapsed;
    this.profileService
      .setSetting('LastBookingsListComponent', { collapsed: this.collapsed }, 'dashboardBoxes')
      .subscribe();
    if (!this.collapsed && !this.data) {
      this.loadData();
    }
  }
}
