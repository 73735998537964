import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Sale } from '@app/sales/sales/sale.model';
import { SalesFormComponent } from '@app/sales/sales/sales-form/sales-form.component';

@Injectable({
  providedIn: 'root',
})
export class SalesFormService {
  constructor(private modalService: NgbModal) {}

  public open(sale: null | Sale = null, enquiry_id: number = null, redirectAfterSave: boolean = true): Promise<any> {
    const modal = this.modalService.open(SalesFormComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.sale = sale;
    modal.componentInstance.enquiry_id = enquiry_id;
    modal.componentInstance.redirectAfterSave = redirectAfterSave;
    return modal.result;
  }
}
