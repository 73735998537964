import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { AuthenticationService, Logger } from '@app/core';
import { AccessService } from '@app/core/access/access.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { BankAccountsService } from '@app/banks/bank-accounts/bank-accounts.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { DataSet } from '@app/shared/kendo/data-set';
import { BankAccountsFormService } from '@app/banks/bank-accounts/bank-accounts-form.service';
import { BankAccount } from '@app/shared/components/bank-accounts/bank-account.model';
import { BankaccountableType } from '@app/banks/bankaccountable-type.enum';

const log = new Logger('BankAccountsListComponent');

@Component({
  selector: 'app-bank-accounts-list',
  templateUrl: './bank-accounts-list.component.html',
  styleUrls: ['./bank-accounts-list.component.scss'],
})
export class BankAccountsListComponent extends KendoGridComponent implements OnInit {
  bankAccountsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  AuthenticationService = AuthenticationService;

  UserRoleType = UserRoleType;
  LaravelModels = LaravelModelsEnum;

  constructor(
    public accessService: AccessService,
    private router: Router,
    private bankAccountsService: BankAccountsService,
    protected kendoGridService: KendoGridService,
    private route: ActivatedRoute,
    private bankAccountsFormService: BankAccountsFormService
  ) {
    super(kendoGridService);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.state = null;

      this.state = CustomKendoFilter.getStateFromStorage('BankAccountsListComponent');

      if (params.get('dataSet')) {
        this.state = DataSet.decodeFromJson(params.get('dataSet'));
      }
      this.loadData(this.state);
    });
  }

  add() {
    this.bankAccountsFormService.open().then(
      (response) => {
        this.loadData(this.state);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  edit(bankAccount: BankAccount) {
    this.bankAccountsFormService.open(bankAccount).then(
      () => {
        this.loadData(this.state);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addFilter('bankaccountable_type', BankaccountableType.COMPANY)
      .addIncludes('currency', 'bankaccountable');

    this.bankAccountsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.bankAccountsResponse = response;
      this.data = response.getGridData();
    });

    CustomKendoFilter.pushStateInStorage('BankAccountsListComponent', this.state);
  }

  clearFilters() {
    this.router.navigate(['/bank-accounts']);

    this.apiQuery = new ApiQuery();
    this.loadData();
  }

  delete(bankAccount: BankAccount) {
    this.bankAccountsService.destroy(bankAccount.id).subscribe(() => {
      this.loadData(this.state);
    });
  }
}
