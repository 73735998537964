import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { InvoicesListComponent } from '@app/finance/invoices/invoices-list/invoices-list.component';
import { PaymentsListComponent } from '@app/finance/payments/payments-list/payments-list.component';
import { MenuService } from '@app/core/menu/menu.service';
import { InterCompanySalesListComponent } from '@app/finance/inter-company-sales/inter-company-sales-list.component';

/* tslint:disable */
import { HotelsBookingsPaymentsListComponent } from '@app/finance/hotel-bookings-payments/hotels-bookings-payments-list/hotels-bookings-payments-list.component';
import { ClientInvoicesListComponent } from '@app/finance/invoices/client-invoices-list/client-invoices-list.component';
/* tslint:enable */

const routes: Routes = [
  Shell.childRoutes([{ path: 'invoices', component: InvoicesListComponent, data: { title: extract('Invoices') } }]),
  Shell.childRoutes([
    {
      path: 'client-invoices',
      component: ClientInvoicesListComponent,
      data: { title: extract('Client invoices') },
    },
  ]),
  Shell.childRoutes([{ path: 'payments', component: PaymentsListComponent, data: { title: extract('Payments') } }]),
  Shell.childRoutes([
    {
      path: 'inter-company-sales',
      component: InterCompanySalesListComponent,
      data: { title: extract('Inter company sales') },
    },
  ]),
  Shell.childRoutes([
    {
      path: 'hotel-bookings-payments',
      component: HotelsBookingsPaymentsListComponent,
      data: { title: extract('Hotel bookings payments') },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinanceRoutingModule {
  constructor(menuService: MenuService) {
    menuService
      .addItem({
        key: 'finance',
        title: 'Finance',
        icon: 'fal fa-file-invoice-dollar',
      })
      .addItem({
        key: 'finance.invoices',
        title: 'Invoices',
        requiredPermission: 'invoices.menu',
        path: '/invoices',
      })
      .addItem({
        key: 'finance.client-invoices',
        title: 'Client invoices',
        requiredPermission: 'invoices.menu',
        path: '/client-invoices',
      })
      .addItem({
        key: 'finance.payments',
        title: 'Payments',
        requiredPermission: 'payments.menu',
        path: '/payments',
      })
      .addItem({
        key: 'finance.inter-company-sales',
        title: 'Inter company sales',
        requiredPermission: 'sales.finals.items.menu',
        path: '/inter-company-sales',
      })
      .addItem({
        key: 'finance.hotel-bookings-payments',
        title: 'Hotel bookings payments',
        requiredPermission: 'payments.menu',
        path: '/hotel-bookings-payments',
      });
  }
}
