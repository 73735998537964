<div class="modal-header">
  <h4 class="modal-title">
    <span>
      <i class="fal fa-envelope"></i>
      {{ 'EMAIL_TEMPLATE_SENDER.EMAIL_SENDER' | translate }}
    </span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0 d-flex flex-column">
  <div class="d-flex overflow-hidden flex-grow-1">
    <div class="filter-wrapper ps" [perfectScrollbar]>
      <form [formGroup]="filterForm" novalidate (ngSubmit)="compile()">
        <div class="form-group">
          <label>{{ 'EMAIL_TEMPLATE_SENDER.COMPANY' | translate }}</label>
          <ng-select
            [items]="companies"
            bindLabel="shortName"
            bindValue="id"
            formControlName="company_id"
            (change)="filterTemplates()"
            appendTo="body"
          ></ng-select>
        </div>

        <div class="form-group">
          <label>{{ 'EMAIL_TEMPLATE_SENDER.LOCALE' | translate }}</label>
          <ng-select
            [items]="locales"
            bindLabel="name"
            bindValue="id"
            formControlName="locale_id"
            (change)="filterTemplates()"
            appendTo="body"
          ></ng-select>
        </div>

        <div class="form-group">
          <label>{{ 'EMAIL_TEMPLATE_SENDER.TEMPLATE' | translate }}</label>
          <ng-select
            [items]="filteredTemplates"
            formControlName="template_id"
            bindLabel="name"
            bindValue="id"
            appendTo="body"
          ></ng-select>
        </div>

        <div *ngIf="typeId === templatesTypes.SALECLIENTOFFER || typeId === templatesTypes.SALESENDTERMS">
          {{ 'EMAIL_TEMPLATE_SENDER.OFFERS' | translate }}
          <div class="custom-control custom-checkbox" *ngFor="let offerLIne of data?.offers; let I = index">
            <input
              type="checkbox"
              class="custom-control-input"
              checked
              [attr.id]="'offerLIne' + '-' + offerLIne.id"
              (change)="offerCheckboxChange(offerLIne.id)"
            />
            <label class="custom-control-label" [attr.for]="'offerLIne' + '-' + offerLIne.id">
              {{ offerLIne?.name }}
            </label>
          </div>
        </div>

        <div *ngIf="typeId === templatesTypes.SALEWEBOFFERS">
          {{ 'EMAIL_TEMPLATE_SENDER.WEB_OFFERS' | translate }}
          <div class="form-check" *ngFor="let webOffer of dataWebOffers; let I = index">
            <input
              type="radio"
              name="webOffers"
              class="form-check-input"
              [attr.id]="'webOffer' + '-' + webOffer.id"
              (change)="webOfferCheckboxChange(webOffer.id)"
            />
            <label class="form-check-label" [attr.for]="'webOffer' + '-' + webOffer.id">
              {{ webOffer?.id }} / {{ webOffer?.title }}
            </label>
          </div>
        </div>

        <div *ngIf="typeId === templatesTypes.SALEGENERAL">
          {{ 'EMAIL_TEMPLATE_SENDER.REQUESTS' | translate }}
          <div class="custom-control custom-checkbox" *ngFor="let request of data?.requests; let I = index">
            <input
              type="checkbox"
              class="custom-control-input"
              checked
              [attr.id]="'request' + '-' + request.id"
              (change)="requestCheckboxChange(request.id)"
            />
            <label class="custom-control-label" [attr.for]="'request' + '-' + request.id">
              {{ request?.formattedName }}
            </label>
          </div>
        </div>

        <div *ngIf="typeId === templatesTypes.SALEINVOICES">
          {{ 'EMAIL_TEMPLATE_SENDER.INVOICES' | translate }}
          <div class="custom-control custom-checkbox" *ngFor="let invoice of dataInvoices; let I = index">
            <input
              type="checkbox"
              class="custom-control-input"
              [attr.id]="'invoice' + '-' + invoice.id"
              (change)="invoiceCheckboxChange(invoice.id)"
            />
            <label class="custom-control-label" [attr.for]="'invoice' + '-' + invoice.id">
              {{ invoice?.formattedName }}
            </label>
          </div>
        </div>

        <div *ngIf="typeId === templatesTypes.SALEFEEDBACK || typeId === templatesTypes.SALEVOUCHERS">
          {{ 'EMAIL_TEMPLATE_SENDER.FINALS' | translate }}
          <div class="custom-control custom-checkbox" *ngFor="let finalLIne of data?.finals; let I = index">
            <input
              type="checkbox"
              class="custom-control-input"
              checked
              [attr.id]="'finalLIne' + '-' + finalLIne.id"
              (change)="finalCheckboxChange(finalLIne.id)"
            />
            <label class="custom-control-label" [attr.for]="'finalLIne' + '-' + finalLIne.id">
              {{ finalLIne?.sale_offer?.name }}
              / {{ finalLIne?.payment_method?.value }}
              <span *ngIf="finalLIne?.client_penalty"> / {{ finalLIne?.client_penalty }} </span>
              <span
                *ngIf="finalLIne?.sale_offer?.cancellation_terms"
                class="cancellation-terms-span"
                [ngbTooltip]="finalLIne?.sale_offer?.cancellation_terms | translate"
                container="body"
              >
                / {{ finalLIne?.sale_offer?.cancellation_terms }}
              </span>
            </label>
          </div>

          <div *ngIf="typeId === templatesTypes.SALEVOUCHERS">
            {{ 'EMAIL_TEMPLATE_SENDER.VOUCHERS' | translate }}

            <div class="form-check" *ngFor="let media of mediaData?.data; let I = index">
              <input
                type="radio"
                name="vouchers"
                class="form-check-input"
                [attr.id]="'media' + '-' + media.id"
                (change)="mediaCheckboxChange(media.id)"
              />
              <label class="form-check-label" [attr.for]="'media' + '-' + media.id">
                {{ media?.name || media?.file_name }}
              </label>
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-success mt-2"
          [disabled]="
            filterForm.get('template_id').invalid ||
            (filterForm.get('offers').value.length <= 0 &&
              filterForm.get('webOffers').value.length <= 0 &&
              filterForm.get('requests').value.length <= 0 &&
              filterForm.get('invoices').value.length <= 0 &&
              filterForm.get('finals').value.length <= 0) ||
            (typeId === templatesTypes.SALEVOUCHERS && !filterForm.get('media_id').value)
          "
        >
          {{ 'EMAIL_TEMPLATE_SENDER.COMPILE' | translate }}
        </button>
      </form>
    </div>

    <form
      class="email-content-wrapper flex-grow-1 overflow-auto"
      id="email-template-sender-form"
      (ngSubmit)="sendEmail()"
      [formGroup]="form"
      novalidate
    >
      <div class="row">
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>{{ 'EMAIL_TEMPLATE_SENDER.FROM' | translate }}</label>
                <ng-select
                  [items]="senderSmtpSetting"
                  bindValue="from_email"
                  bindLabel="from_email"
                  formControlName="from"
                  (change)="attachSignature()"
                ></ng-select>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label>{{ 'EMAIL_TEMPLATE_SENDER.TO' | translate }}</label>
                <ng-select
                  [items]="toEmails"
                  [addTag]="addTagFn"
                  [hideSelected]="true"
                  multiple="true"
                  formControlName="to"
                >
                </ng-select>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <label>{{ 'EMAIL_TEMPLATE_SENDER.SUBJECT' | translate }}</label>
                <input type="text" class="form-control" formControlName="subject" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          {{ 'EMAIL_TEMPLATE_SENDER.ATTACHMENTS' | translate }}
          <div class="files-wrapper">
            <div class="row">
              <div class="col-sm-4">
                <div>
                  {{ 'EMAIL_TEMPLATE_SENDER.UPLOADS' | translate }}
                </div>
                <div *ngFor="let file of files?.uploads; let I = index">
                  <input type="checkbox" [(ngModel)]="file.checked" [ngModelOptions]="{ standalone: true }" />
                  <label>
                    <a [href]="safeUrl(file.base64)" [download]="file.file_name">{{ file.file_name }}</a>
                  </label>
                </div>

                <p-fileUpload
                  #fileUpload
                  [styleClass]="'btn btn-upload'"
                  [chooseLabel]="'EMAIL_TEMPLATE_SENDER.UPLOAD' | translate"
                  mode="basic"
                  [auto]="true"
                  customUpload="true"
                  multiple="true"
                  (uploadHandler)="uploadFile($event)"
                ></p-fileUpload>
              </div>

              <ng-container *ngFor="let fileGroups of files | keyvalue; let I = index">
                <div class="col-sm-4" *ngIf="fileGroups.key !== 'uploads'">
                  <div>
                    {{ fileGroups.key | titlecase }}
                  </div>

                  <div *ngFor="let file of fileGroups.value; let I = index">
                    <input type="checkbox" [(ngModel)]="file.checked" [ngModelOptions]="{ standalone: true }" />
                    <label>
                      <a [href]="safeUrl(file.base64)" [download]="file.file_name">{{ file.file_name }}</a>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <app-editor [form]="form" passFormControlName="body"></app-editor>
    </form>
  </div>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-outline-dark btn-xs" form="email-template-sender-form" [disabled]="form.invalid">
    {{ 'EMAIL_TEMPLATE_SENDER.SEND_EMAIL' | translate }}
  </button>
</div>
