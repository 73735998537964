import { Component, Input } from '@angular/core';
import * as FileSaver from 'file-saver';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { Invoice } from '@app/finance/invoices/invoice.model';
import { Companies } from '@app/settings/companies/companies.enum';

@Component({
  selector: 'app-download-invoice-button',
  templateUrl: './download-invoice-button.component.html',
  styleUrls: ['./download-invoice-button.component.scss'],
})
export class DownloadInvoiceButtonComponent {
  @Input() invoice: Invoice;
  CompaniesEnum = Companies;

  constructor(private invoicesService: InvoicesService) {}

  download(locale: string = null) {
    this.invoicesService.download(this.invoice.id, locale).subscribe((data) => {
      const blob = new Blob([data], {
        type: 'application/pdf;charset=utf-8',
      });
      FileSaver.saveAs(blob, 'invoice-' + this.invoice.id + '.pdf');
    });
  }
}
