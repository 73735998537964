import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery, GeoFenceFilter } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { HotelBookingsFormService } from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { Logger } from '@app/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DataSet } from '@app/shared/kendo/data-set';
import * as moment from 'moment';
import { Room } from '@app/hotels/rooms/room.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { BookingPropertiesType } from '@app/settings/types/booking-properties-type.enum';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { AccessService } from '@app/core/access/access.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';

const log = new Logger('HotelBookingsRoomsListComponent');

@Component({
  selector: 'app-hotel-bookings-rooms-list',
  templateUrl: './hotel-bookings-rooms-list.component.html',
  styleUrls: ['./hotel-bookings-rooms-list.component.scss'],
})
export class HotelBookingsRoomsListComponent extends KendoGridComponent implements OnInit {
  hotelBookingsRoomsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  form: FormGroup;
  UserRoleType = UserRoleType;
  loader = false;
  LaravelModelsEnum = LaravelModelsEnum;

  constructor(
    private hotelBookingService: HotelBookingsService,
    private hotelBookingsFormService: HotelBookingsFormService,
    protected kendoGridService: KendoGridService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.state = new DataSet()
      .addFilter('check_in', new Date(), 'gte')
      .addFilter('booking.property_id', BookingPropertiesType.ADV);

    const storageFilter = CustomKendoFilter.getStateFromStorage('HotelBookingsRoomsListComponent');
    if (storageFilter) {
      this.state = storageFilter;
    }
    this.createForm();
    this.loadData(this.state);
  }

  /**
   *  Зарежда данните от апи-то
   * @param state state
   * @param searchButton дали функцията се вика от бутона
   */
  loadData(state: DataStateChangeEvent = null, searchButton: boolean = false) {
    this.loader = true;
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'booking.company',
        'booking.events',
        'booking.supplier',
        'booking.cancellation_terms',
        'booking.property',
        'booking.contracting_agent',
        'booking.reservation_agent',
        'booking.tags',
        'hotel.city.country',
        'type',
        'meal_plan',
        'nights.net_price_currency',
        'nights.price_currency',
        'hotel.venues'
      )
      .addFilter('full_available', true);

    if (searchButton) {
      // Проверява дали избрания във филтъра(допълнителния) event има закачено venue
      if (this.form.get('event').value && !this.form.get('event').value.venue) {
        return this.translate
          .get('HOTELS_BOOKINGS.HOTEL_BOOKINGS_ROOMS_LIST.EVENT_VENUE_NOT_FOUND')
          .subscribe((trans: string) => {
            this.toastr.warning(trans + this.form.get('event').value.name);
          });
      }

      // Проверява дали избрания във филтъра(допълнителния) event има закачено venue и дали то има координати
      if (
        this.form.get('event').value &&
        this.form.get('event').value.venue &&
        (!this.form.get('event').value.venue.lat || !this.form.get('event').value.venue.long)
      ) {
        return this.translate
          .get('HOTELS_BOOKINGS.HOTEL_BOOKINGS_ROOMS_LIST.EVENT_VENUE_LAT_LONG_NOT_FOUND')
          .subscribe((trans: string) => {
            this.toastr.warning(trans + this.form.get('event').value.venue.name);
          });
      }

      // Ако има валиден филтър закача към query-то geofence и датите от евента
      if (
        this.form.get('radius').value &&
        this.form.get('event').value &&
        this.form.get('event').value.venue &&
        this.form.get('event').value.venue.lat &&
        this.form.get('event').value.venue.long
      ) {
        const geoFenceFilter = new GeoFenceFilter();
        geoFenceFilter.lat = Number(this.form.get('event').value.venue.lat);
        geoFenceFilter.long = Number(this.form.get('event').value.venue.long);
        geoFenceFilter.inner_radius = 0;
        geoFenceFilter.outer_radius = Number(this.form.get('radius').value);
        this.apiQuery.setGeoFence(geoFenceFilter, 'hotel.geofence');

        // добавя датите от евента към филтрите в таблицата
        this.state = new DataSet()
          .deserialize(this.state)

          .addFilter(
            'check_in',
            moment(this.form.get('event').value.date_from)
              .subtract(this.form.get('additional_days').value || 0, 'days')
              .toDate(),
            'gte'
          )

          .addFilter(
            'check_out',
            moment(this.form.get('event').value.date_to)
              .add(this.form.get('additional_days').value || 0, 'days')
              .toDate(),
            'lte'
          );

        this.apiQuery.setDataSetFilters(this.state);
      }
    }

    this.hotelBookingService.indexRooms(this.apiQuery).subscribe((response: PaginatedResponse) => {
      if (this.form.get('event').value) {
        response.data.forEach((room: Room) => {
          room.hotel.venues.forEach((venue: any) => {
            if (venue.id === this.form.get('event').value.venue_id) {
              room['distance_to_fair'] = venue.pivot.distance;
            }
          });
        });
      }

      this.hotelBookingsRoomsResponse = response;
      this.data = response.getGridData();
      this.loader = false;
    });
    CustomKendoFilter.pushStateInStorage('HotelBookingsRoomsListComponent', this.state);
  }

  /**
   * Използва се, за да премахва geofence когато някое от полетата form.event или form.radius е празно.
   * Вика се на change на някое от двете полета
   */
  eventFilterChange() {
    if (!this.form.get('event').value || !this.form.get('radius').value) {
      this.apiQuery.removeGeoFence('hotel.geofence').setPage(1);
      this.loadData(this.state);
    }
  }

  clearFilters() {
    this.form.get('event').patchValue(null);
    this.form.get('event_id').patchValue(null);
    this.form.get('radius').patchValue(null);

    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData(
        new DataSet()
          .addFilter('check_in', new Date(), 'gte')
          .addFilter('booking.property_id', BookingPropertiesType.ADV)
      );
    });
  }

  add() {
    this.hotelBookingsFormService.open().then(
      (response) => {
        this.loadData(this.state);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  edit(hotelBooking: HotelBooking) {
    this.hotelBookingsFormService.open(hotelBooking).then(
      (response) => {
        this.loadData();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  /**
   * @param hotelBooking HotelBooking object
   */
  delete(hotelBooking: HotelBooking) {
    this.hotelBookingService.destroy(hotelBooking.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      event_id: [null],
      event: [null],
      radius: [50],
      additional_days: [3],
    });
  }
}
