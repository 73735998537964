import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AssignmentsTableComponent } from './components/assignments-table/assignments-table.component';
import { DashboardRoutingModule } from '@app/dashboard/dashboard-routing.module';
import { SharedModule } from '@app/shared';
import { RemindersListComponent } from './components/reminders-list/reminders-list.component';
import { UpcomingEventsListComponent } from './components/upcoming-events-list/upcoming-events-list.component';
import { LastBookingsListComponent } from './components/last-bookings-list/last-bookings-list.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { BookingsAssignmentsTableComponent } from './components/bookings-assignments-table/bookings-assignments-table.component';

@NgModule({
  declarations: [
    DashboardComponent,
    AssignmentsTableComponent,
    RemindersListComponent,
    UpcomingEventsListComponent,
    LastBookingsListComponent,
    NotificationsListComponent,
    BookingsAssignmentsTableComponent,
  ],
  imports: [CommonModule, DashboardRoutingModule, SharedModule],
  exports: [AssignmentsTableComponent, RemindersListComponent, UpcomingEventsListComponent, LastBookingsListComponent],
})
export class DashboardModule {}
