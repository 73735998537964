<div *ngFor="let contact of contacts; let i = index" class="d-table-row">
  <div class="d-table-cell sub-title">
    <i *ngIf="contact?.type?.icon" [ngClass]="contact?.type?.icon"></i>
    {{ contact?.type?.value }}
  </div>
  <div class="d-table-cell value word-break">
    {{ contact?.value }}
  </div>
  <div></div>
</div>
