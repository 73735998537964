<div class="form-group" [formGroup]="form">
  <p-calendar
    [minDate]="minDate"
    [defaultDate]="defaultDate"
    formControlName="date_fake"
    inputStyleClass="form-control"
    class="prime-ng-datapicker"
    styleClass="d-block"
    monthNavigator="true"
    yearNavigator="true"
    dateFormat="dd.mm.yy"
    appendTo="body"
    baseZIndex="1"
    showTime="true"
    showButtonBar="true"
    [yearRange]="yearsRangeFormatted"
    (onSelect)="onSelect()"
    (onInput)="onType()"
    [locale]="en"
  >
  </p-calendar>
</div>
