import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  // Form attrs
  @Input() form: FormGroup;
  @Input() passFormControlName: string;

  // Tinymce's default settings
  @Input() plugins: string | Array<string> = 'table link advlist lists';
  // tslint:disable-next-line:max-line-length
  @Input() toolbar: string | Array<string> =
    'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat';

  @Input() autoResize = true;

  // Any additional attributes may be passed here
  @Input() attrs: Object = {};

  // If needed, an entire settings object can be passed instead
  @Input() init: Object = {
    base_url: '/assets/tinymce',
    suffix: '.min',
    min_height: 760,
  };

  constructor() {}

  ngOnInit() {
    if (this.autoResize) {
      this.plugins += ' autoresize';
    }

    // Extend the default init's attributes
    this.init = { ...this.init, ...this.attrs };
    this.init['plugins'] = this.plugins;
    this.init['toolbar'] = this.toolbar;
  }
}
