import { Component, Input, OnInit } from '@angular/core';
import { Currency } from '@app/settings/currencies/currency.model';

@Component({
  selector: 'app-currency-box',
  templateUrl: './currency-box.component.html',
  styleUrls: ['./currency-box.component.scss'],
})
export class CurrencyBoxComponent implements OnInit {
  @Input() currency: Currency;

  constructor() {}

  ngOnInit() {}
}
