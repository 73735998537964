<div class="container-fluid" *ngIf="accessService.hasAccess([], ['bonuses.menu'])">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'BONUSES.USER_BONUSES_LIST.TITLE' | translate }}
      </h5>
    </div>

    <div class="d-flex align-items-center">
      <div>
        <button type="button" class="btn btn-link" (click)="clearFilters()">
          {{ 'BONUSES.USER_BONUSES_LIST.CLEAR_FILTERS' | translate }}
        </button>
      </div>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="response?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'bonuses_list', grid)"
    [loading]="loader"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'BONUSES.USER_BONUSES_LIST.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'BONUSES.USER_BONUSES_LIST.ID' | translate }}"
      filter="numeric"
      [width]="120"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="user_id"
      title="{{ 'BONUSES.USER_BONUSES_LIST.USER' | translate }}"
      [hidden]="isHiddenColumn('user_id')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select [filter]="filter" [field]="'user_id'"></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-user-box [user]="dataItem.user"></app-user-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="percent"
      title="{{ 'BONUSES.USER_BONUSES_LIST.PERCENT' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('percent')">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.percent | number: '1.2-2' }}%
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="amount"
      title="{{ 'BONUSES.USER_BONUSES_LIST.AMOUNT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('amount')">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.date"
      title="{{ 'BONUSES.USER_BONUSES_LIST.SALE_BONUS_DATE' | translate }}"
      [hidden]="isHiddenColumn('sale_bonus.date')"
      filter="date"
      format="{0:dd/MM/yyyy}"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.sale_bonus?.date | date: env.pipeDateFormat}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="paid_at"
      title="{{ 'BONUSES.USER_BONUSES_LIST.PAID_AT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('paid_at')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem?.paid_at | date: env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.sale.client.name"
      title="{{ 'BONUSES.USER_BONUSES_LIST.CLIENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_bonus.sale.client.name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter" field="sale_bonus.sale.client_id"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-client-box [client]="dataItem?.sale_bonus?.sale?.client" [flag]="true"></app-client-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.invoices.number"
      title="{{ 'BONUSES.USER_BONUSES_LIST.INVOICE_NUMBERS' | translate }}"
      [hidden]="isHiddenColumn('sale_bonus.invoices.number')"
      [sortable]="false"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let invoice of dataItem?.sale_bonus?.invoices; let k = index">
          {{ invoice.number}}<span *ngIf="k !== dataItem?.sale_bonus?.invoices?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.invoices.date"
      title="{{ 'BONUSES.USER_BONUSES_LIST.INVOICES_DATES' | translate }}"
      [hidden]="isHiddenColumn('sale_bonus.invoices.date')"
      [sortable]="false"
      filter="date"
      format="{0:dd/MM/yyyy}"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let invoice of dataItem?.sale_bonus?.invoices; let k = index">
          {{ invoice.date | date: env.pipeDateFormat }}<span
          *ngIf="k !== dataItem?.sale_bonus?.invoices?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.sale.event.name"
      title="{{ 'BONUSES.USER_BONUSES_LIST.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_bonus.sale.event.name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select
          [filter]="filter"
          field="sale_bonus.sale.event_id">
        </app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.sale_bonus?.sale?.event_id }"
           target="_blank">
          {{dataItem?.sale_bonus?.sale?.event?.name}}
          - {{ dataItem?.sale_bonus?.sale?.event?.date_from | date: 'YYYY'}}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.invoices.saleFinal.items.hotel_booking_room.booking.hotel_id"
      title="{{ 'BONUSES.USER_BONUSES_LIST.HOTELS' | translate }}"
      [hidden]="isHiddenColumn('sale_bonus.invoices.saleFinal.items.hotel_booking_room.booking.hotel_id')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-hotel-select
          [filter]="filter"
          field="sale_bonus.invoices.saleFinal.items.hotel_booking_room.booking.hotel_id">
        </app-kendo-hotel-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let hotel of dataItem?.meta_front?.hotels; let k = index">
          {{ hotel }}
          <span *ngIf="k !== dataItem?.meta_front?.hotels?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column
      field="sale_bonus.sale.total_margin_amount"
      filter="numeric"
      title="{{ 'BONUSES.USER_BONUSES_LIST.TOTAL_MARGIN_AMOUNT' | translate }}"
      [hidden]="isHiddenColumn('sale_bonus.sale.total_margin_amount',true)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem?.sale_bonus?.sale?.total_margin_amount">
          {{ dataItem.sale_bonus.sale.total_margin_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.sale.total_bonus_amount"
      filter="numeric"
      title="{{ 'BONUSES.USER_BONUSES_LIST.TOTAL_BONUS_AMOUNT' | translate }}"
      [hidden]="isHiddenColumn('sale_bonus.sale.total_bonus_amount',true)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem?.sale_bonus?.sale?.total_bonus_amount">
          {{ dataItem.sale_bonus.sale.total_bonus_amount | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </ng-container>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column
      field="sale_bonus.sale.sales_agent.fake_first_name"
      title="{{ 'BONUSES.USER_BONUSES_LIST.SALES_AGENT_AND_SALES_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_bonus.sale.sales_agent.fake_first_name',true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
          [field]="'sale_bonus.sale.sales_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sale_bonus?.sale?.sales_agent?.fake_first_name }}
        {{ dataItem.sale_bonus?.sale?.sales_agent?.fake_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.sale.reservation_agent.fake_first_name"
      title="{{ 'BONUSES.USER_BONUSES_LIST.RESERVATION_AGENT_AND_RESERVATION_MANAGER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_bonus.sale.reservation_agent.fake_first_name',true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
          [field]="'sale_bonus.sale.reservation_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sale_bonus?.sale?.reservation_agent?.fake_first_name }}
        {{ dataItem.sale_bonus?.sale?.reservation_agent?.fake_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_bonus.sale.customer_care_agent.fake_first_name"
      title="{{ 'BONUSES.USER_BONUSES_LIST.CUSTOMER_CARE_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_bonus.sale.customer_care_agent.fake_first_name',true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.CUSTOMER_CARE_AGENT"
          [field]="'sale_bonus.sale.customer_care_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sale_bonus?.sale?.customer_care_agent?.fake_first_name }}
        {{ dataItem.sale_bonus?.sale?.customer_care_agent?.fake_last_name }}
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column
      field="sale_bonus.invoices.saleFinal.items.hotel_booking_room.booking.contracting_agent.fake_first_name"
      title="{{ 'BONUSES.USER_BONUSES_LIST.CONTRACTING_AGENTS' | translate }}"
      [hidden]="isHiddenColumn('sale_bonus.invoices.saleFinal.items.hotel_booking_room.booking.contracting_agent.fake_first_name',true)"
      [sortable]="false"
    >

      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.CONTRACTING_AGENT"
          [field]="'sale_bonus.invoices.saleFinal.items.hotel_booking_room.booking.contracting_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let contracting_agent of dataItem?.meta_front?.contracting_agents; let k = index">
          {{ contracting_agent }}<span *ngIf="k !== dataItem?.meta_front?.contracting_agents?.length - 1">, </span>
        </div>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column
      field="sale_bonus.sale.source.value"
      title="{{ 'BONUSES.USER_BONUSES_LIST.SOURCE' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_bonus.sale.source.value',true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'sale_source'"
          [filter]="filter"
          [field]="'sale_bonus.sale.source_id'"
        ></app-kendo-type-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.sale_bonus.sale.source"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'BONUSES.USER_BONUSES_LIST.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

        <app-sale-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER_LEAD
              ],
              ['sales.show']
            )
          "
          [link]="['/sales/view']"
          [params]="{ id: dataItem.sale_bonus.sale_id }"
        >
        </app-sale-button>

      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
