import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BankAccount } from '@app/shared/components/bank-accounts/bank-account.model';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { BankAccountsService } from '@app/banks/bank-accounts/bank-accounts.service';
import { ActivatedRoute } from '@angular/router';
import { BankAccountsFormService } from '@app/banks/bank-accounts/bank-accounts-form.service';
import { HttpResponse, Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { Title } from '@angular/platform-browser';
import { FileUpload } from 'primeng';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BankAccountsStatementsMatchFormService } from '@app/banks/bank-accounts/bank-accounts-statements-match-form.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { environment } from '@env/environment';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { Payment } from '@app/finance/payments/payment.model';
import { PaymentsFormService } from '@app/finance/payments/payments-form.service';
import { BankStatement } from '@app/banks/bank-accounts/bank-statement.model';
import { ActivitiesService } from '@app/shared/components/activities/activities.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';

const log = new Logger('BankAccountsViewComponent');

@Component({
  selector: 'app-bank-accounts-view',
  templateUrl: './bank-accounts-view.component.html',
  styleUrls: ['./bank-accounts-view.component.scss'],
})
export class BankAccountsViewComponent extends KendoGridComponent implements OnInit, OnDestroy {
  bankAccountId: number;
  bankAccount: BankAccount;

  bankStatementResponse: PaginatedResponse;
  bankStatementData: GridDataResult;
  bankStatementState: DataStateChangeEvent;
  bankStatementApiQuery: ApiQuery = new ApiQuery();
  @ViewChild('perfectScroll', { static: true }) perfectScroll: PerfectScrollbarDirective;
  env = environment;
  form: FormGroup;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;

  constructor(
    public activitiesService: ActivitiesService,
    private bankAccountsService: BankAccountsService,
    private route: ActivatedRoute,
    private bankAccountsFormService: BankAccountsFormService,
    private titleService: Title,
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private bankAccountStatementsMatchService: BankAccountsStatementsMatchFormService,
    protected kendoGridService: KendoGridService,
    private paymentsFormService: PaymentsFormService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    super(kendoGridService);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.bankAccountId = Number(params.get('id'));
        this.createForm();
        this.loadData();
        this.activitiesService.auto(LaravelModelsEnum.BANK_ACCOUNT, this.bankAccountId, false, true);
        this.bankStatementState = CustomKendoFilter.getStateFromStorage('BankAccountViewStatementsList');
        this.loadBankStatements(this.bankStatementState);
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  ngOnDestroy(): void {
    // крие sidebar-a s activity-тата и съобщенията
    this.activitiesService.hide();
  }

  updateLastBalance() {
    const bankAccount: BankAccount = this.bankAccount.deserialize(this.form.value);
    this.bankAccountsService.update(bankAccount).subscribe(
      (res) => {
        this.toastr.success(this.translateService.instant(marker('LAST_BALANCE_UPDATED')));
      },
      () => {
        this.toastr.error(this.translateService.instant(marker('LAST_BALANCE_IS_NOT_UPDATED')));
      }
    );
  }

  /**
   * Отваря sidebar-a s activity-тата и съобщенията
   */
  openActivities(): void {
    this.activitiesService.show(LaravelModelsEnum.BANK_ACCOUNT, this.bankAccountId, false);
  }

  loadData(): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes('currency', 'bankaccountable');
    this.bankAccountsService.show(this.bankAccountId, apiQuery).subscribe((bankAccount: BankAccount) => {
      this.bankAccount = bankAccount;

      this.titleService.setTitle(this.bankAccount.displayName);

      this.form.patchValue(this.bankAccount);
    });
  }

  edit() {
    this.bankAccountsFormService.open(this.bankAccount).then(
      (response) => {
        log.debug('res', response);
        this.loadData();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  /**
   * Качва файл на сървъра
   * @param event Event
   * @param fileUploader fileUploader
   */
  import(event: any, fileUploader: FileUpload): void {
    const promises: Array<any> = [];

    event.files.forEach((file: File) => {
      promises.push(this.readFile(file));
    });

    Promise.all(promises)
      .then((files: Array<{}>) => {
        const body = {
          file: files[0]['base64'],
          bank_account_id: this.bankAccountId,
        };

        this.http.post('v1/bank_account_statements/import', body).subscribe(
          (response: HttpResponse) => {
            this.toastr.success(this.translate.instant('BANKS.BANK_ACCOUNTS_VIEW.IMPORT_SUCCESS_MSG'));
            this.loadBankStatements();
          },
          (error: any) => {
            this.translate.get(error).subscribe((trans: string) => {
              this.toastr.error(trans);
            });
          }
        );
      })
      .finally(() => {
        fileUploader.clear();
      });
  }

  openBankAccountStatementsMatchForm() {
    this.bankAccountStatementsMatchService
      .open(this.bankAccountId)
      .then()
      .finally(() => {
        this.loadBankStatements(this.bankStatementState);
      });
  }

  loadBankStatements(state: DataStateChangeEvent = null) {
    this.bankStatementState = state;
    this.bankStatementApiQuery
      .setDataSetFilters(state)
      .addIncludes('payments.currency', 'payments.clientable')
      .addFilter('bank_account_id', this.bankAccountId);

    this.bankAccountsService
      .indexBankStatements(this.bankStatementApiQuery)
      .subscribe((response: PaginatedResponse) => {
        this.bankStatementResponse = response;
        this.bankStatementData = response.getGridData();
      });

    CustomKendoFilter.pushStateInStorage('BankAccountViewStatementsList', this.bankStatementState);
  }

  clearFilters() {
    this.bankStatementApiQuery = new ApiQuery();
    this.loadBankStatements();
  }

  editPayment(payment: Payment) {
    this.paymentsFormService.open(payment).then(
      (res) => {
        this.loadBankStatements(this.bankStatementState);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  unlinkAll(statement: BankStatement) {
    this.bankAccountsService.unLinkBankStatements(statement.id).subscribe(
      () => {
        this.loadBankStatements(this.bankStatementState);
      },
      () => {}
    );
  }

  delete(bankStatement: BankStatement) {
    this.bankAccountsService.destroyBankStatement(bankStatement.id).subscribe(() => {
      this.loadBankStatements(this.bankStatementState);
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      // Only required fields and last_balance, because we need them to update last_balance
      id: [null, [Validators.required]],
      name: [null, [Validators.required]],
      bic: [null, [Validators.required]],
      bankaccountable_id: [null, [Validators.required]],
      bankaccountable_type: [null, [Validators.required]],
      balance: [null, [Validators.required]],
      last_balance: [null],
    });
  }

  private readFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.cd.markForCheck();
        resolve({ base64: reader.result, file_name: file.name });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
}
