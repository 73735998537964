import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesService } from '@app/sales/sales/sales.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-emails',
  templateUrl: './client-emails.component.html',
  styleUrls: ['./client-emails.component.scss'],
})
export class ClientEmailsComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private salesService: SalesService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  download() {
    this.salesService.exportClientEmails(this.form.value).subscribe((response: PaginatedResponse) => {
      if (response) {
        this.translate.get('EXPORT_BUTTONS.CLIENT_EMAILS.POPOVER_SUCCESS_MSG').subscribe((trans: string) => {
          this.toastr.success(trans);
        });
      }
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      from: [null, [Validators.required]],
      to: [null, [Validators.required]],
      status_id: [null, [Validators.required]],
    });
  }
}
