import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable, of, Subject } from 'rxjs';
import { LocalesService } from '@app/settings/locales/locales.service';
import { LocalesFormService } from '@app/settings/locales/locales-form.service';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { Locale } from '@app/settings/locales/locales.model';
import { Logger } from '@app/core';

const log = new Logger('LocaleSelectComponent');

@Component({
  selector: 'app-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.scss'],
})
export class LocaleSelectComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  loader: boolean;
  apiQuery: ApiQuery = new ApiQuery();
  data: Observable<Locale[]>;
  input$ = new Subject<string>();
  lastResponse: any = null;
  @Output() select = new EventEmitter();

  constructor(private localesService: LocalesService, private localesFormService: LocalesFormService) {}

  ngOnInit() {
    const preSelectedLocale = this.parentFormGroup.get('locale');
    const preSelectedLocaleId = this.parentFormGroup.get('locale_id');

    /**
     * Попълва избрания locale на едит.
     * Ако има FormControl 'locale' в parentFormGroup.
     * Ако няма ще се опита да го get-не по ID
     */
    if (preSelectedLocale) {
      /*
       * Следи за промени в locale FormControl-a и когато намери валиден Locale спира да следи
       */
      const preselectedLocaleSubscriber = preSelectedLocale.valueChanges.subscribe((locale: Locale) => {
        if (locale && locale.name) {
          this.data = of([locale]);
          preselectedLocaleSubscriber.unsubscribe();
        }
      });
    } else if (preSelectedLocaleId) {
      /*
       * Ако не е намерен locale FormControl-a търси locale_id FormControl и се опитва да го get-не по ID
       */
      const preselectedLocaleIdSubscriber = preSelectedLocaleId.valueChanges.subscribe((localeId: any) => {
        if (localeId) {
          this.loadById(localeId);
          preselectedLocaleIdSubscriber.unsubscribe();
        }
      });
    }

    this.localeSearch();
  }

  loadById(id: number) {
    this.loader = true;
    this.localesService.show(id).subscribe((locale: Locale) => {
      this.data = of([locale]);
      this.loader = false;
    });
  }

  localeSearch() {
    this.input$
      .pipe(
        tap(() => (this.loader = true)),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((term: any) => {
          // За да ме прави излишен request след като се избере нещо
          if (!term) {
            return of(this.lastResponse);
          }

          return this.localesService.index(this.apiQuery.addFilters({ active: true, name: term })).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loader = false))
          );
        })
      )
      .subscribe((locales: PaginatedResponse) => {
        this.data = of(locales.data);
        this.lastResponse = locales;
        this.loader = false;
      });
  }

  onChange(locale: any) {
    this.select.emit(locale);

    if (this.parentFormGroup.get('locale')) {
      this.parentFormGroup.get('locale').patchValue(locale || null);
    }
  }

  add(searchTerm: string) {
    this.localesFormService.open(new Locale().deserialize({ name: searchTerm })).then(
      (response) => {
        this.loadById(response.id);
        this.parentFormGroup.get('locale_id').setValue(response.id);
      },
      (err) => {
        log.error(err);
      }
    );
  }
}
