import { Model } from '@app/shared/models/model.model';
import { Type } from '@app/settings/types/types.model';
import { Hotel } from '@app/hotels/hotels/hotel.model';
import { Supplier } from '@app/suppliers/suppliers/suppliers.model';
import { Room } from '@app/hotels/rooms/room.model';
import { Currency } from '@app/settings/currencies/currency.model';

export class SaleOfferItem extends Model {
  id?: number;
  type_id: number;
  type?: Type;
  meal_plan_id: number;
  meal_plan?: Type;
  hotel_id: number;
  hotel?: Hotel;
  check_in: string; // date
  check_out: string; // date
  people: number;
  count: number;
  price: number;
  price_currency?: Currency;
  net_price?: number;
  net_price_currency?: Currency;
  sale_request_id: number;

  ref_number?: number;
  supplier_id?: number;
  supplier?: Supplier;
  allotment?: Room;
  allotment_id?: string;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.type) {
      this.type = new Type().deserialize(input.type);
    }

    if (input.allotment) {
      this.allotment = new Room().deserialize(input.allotment);
    }

    if (input.meal_plan) {
      this.meal_plan = new Type().deserialize(input.meal_plan);
    }

    if (input.hotel) {
      this.hotel = new Hotel().deserialize(input.hotel);
    }

    return this;
  }
}
