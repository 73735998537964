import { Injectable } from '@angular/core';
import { ApiQuery } from '@app/core/http/api-query';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): any {
    return this.http.get('v1/tags', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return response.data;
      })
    );
  }
}
