import { LaravelModelsEnum, Model } from '@app/shared/models/model.model';

export class Notification extends Model {
  id: string;
  notifiable_id: number;
  notifiable_type: string;
  read_at: string;
  type: string;
  data: any;

  get cleanNotifiableType() {
    if (this.notifiable_type) {
      const type = this.notifiable_type.split('\\');
      return type[type.length - 1];
    } else {
      return null;
    }
  }

  get text() {
    if (this.data && this.type === 'App\\Notifications\\WebOfferFeedbackNotification') {
      return `New feedback for WebOffer ${this.data?.title || ''}; SALE_ID:${this.data?.sale_id}`;
    } else if (this.data && this.type === 'App\\Notifications\\External\\EnquiryCreatedNotification') {
      return `New enquiry from ${this.data?.first_name || '[UNKNOWN FIRST NAME]'} ${this.data?.last_name || ''}; ID:${
        this.data?.id
      }`;
    } else if (this.data && this.type === 'App\\Notifications\\CommentCreatedNotification') {
      return this.data.comment;
    } else if (this.data && this.data.message) {
      return this.data.message;
    } else if (this.data && this.data.title) {
      return this.data.title;
    } else if (this.data && this.data.note) {
      return this.data.note;
    }
    return 'UNKNOWN TYPE - Cant find message, title and note';
  }

  get link() {
    if (this.data && this.data.url) {
      return this.data.url;
    } else if (this.data && this.type === 'App\\Notifications\\WebOfferFeedbackNotification') {
      return `/sales/view?id=${this.data?.sale_id}`;
    } else if (this.data && this.type === 'App\\Notifications\\External\\EnquiryCreatedNotification') {
      return `/enquiries?id=${this.data?.id}`;
    } else if (this.data && this.type === 'App\\Notifications\\CommentCreatedNotification') {
      if (this.data.commentable_type === LaravelModelsEnum.SALE) {
        return `/sales/view?id=${this.data?.commentable_id}`;
      } else if (this.data.commentable_type === LaravelModelsEnum.HOTEL) {
        return `/hotels/view?id=${this.data?.commentable_id}`;
      } else if (this.data.commentable_type === LaravelModelsEnum.HOTELBOOKING) {
        return `/hotel-bookings/view?id=${this.data?.commentable_id}`;
      } else if (this.data.commentable_type === LaravelModelsEnum.EVENT) {
        return `/event/view?id=${this.data?.commentable_id}`;
      } else if (this.data.commentable_type === LaravelModelsEnum.CLIENT) {
        return `/clients/view?id=${this.data?.commentable_id}`;
      } else if (this.data.commentable_type === LaravelModelsEnum.USER) {
        return `/settings/users/view?id=${this.data?.commentable_id}`;
      }
      return `INVALID LINK`;
    }

    return false;
  }
}
