import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { PaymentsService } from '@app/finance/payments/payments.service';
import { Payment } from '@app/finance/payments/payment.model';
import { ApiQuery } from '@app/core/http/api-query';
import { findIndex, identity, pickBy } from 'lodash';
import { BankStatement } from '@app/banks/bank-accounts/bank-statement.model';
import { BankAccountsService } from '@app/banks/bank-accounts/bank-accounts.service';
import { environment } from '@env/environment';
import { PaymentsFormService } from '@app/finance/payments/payments-form.service';

const log = new Logger();

@Component({
  selector: 'app-bank-accounts-statements-match-form',
  templateUrl: './bank-accounts-statements-match-form.component.html',
  styleUrls: ['./bank-accounts-statements-match-form.component.scss'],
})
export class BankAccountsStatementsMatchFormComponent implements OnInit {
  @Input() bankAccountId: number;
  paymentsFilterForm: FormGroup;
  paymentsData: Array<Payment>;
  selectedPayments: Array<Payment> = [];
  paymentsLoading = false;

  statementsFilterForm: FormGroup;
  statementsData: Array<BankStatement>;
  selectedStatements: Array<BankStatement> = [];
  statementsLoading = false;
  env = environment;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private paymentsService: PaymentsService,
    private bankAccountsService: BankAccountsService,
    private paymentFormService: PaymentsFormService
  ) {}

  ngOnInit(): void {
    log.debug('bankAccountId', this.bankAccountId);
    this.createPaymentsFilterForm();
    this.createStatementsFilterForm();
    this.loadPayments();
    this.loadStatements();
  }

  saveAndClose() {
    this.bankAccountsService.linkBankStatements(this.formatPost()).subscribe(
      () => {
        this.modal.close();
      },
      () => {}
    );
  }

  save() {
    this.bankAccountsService.linkBankStatements(this.formatPost()).subscribe(
      () => {
        this.resetAll();
      },
      () => {}
    );
  }

  loadPayments() {
    this.paymentsLoading = true;
    const query = new ApiQuery();

    if (this.paymentsFilterForm?.value?.clientable) {
      delete this.paymentsFilterForm.value.clientable;
    }

    const filters = pickBy(this.paymentsFilterForm.value, identity);
    Object.keys(filters).forEach((key: string) => {
      query.addFilter(key, filters[key]);
    });

    query
      .addFilters({ bank_account_id: this.bankAccountId, is_paid: 1 })
      .setLimit(99999)
      .addIncludes('clientable', 'currency');
    this.paymentsService.index(query).subscribe(
      (response: PaginatedResponse) => {
        this.paymentsData = response.data.map((item: Payment) => {
          item['meta_front'] = {};
          item['meta_front']['selected'] = this.findInd(this.selectedPayments, item) > -1;
          return item;
        });
        this.paymentsLoading = false;
      },
      () => {
        this.paymentsLoading = false;
      }
    );
  }

  editPayment(payment: Payment) {
    this.paymentFormService.open(payment).then(
      () => {
        this.loadPayments();
      },
      () => {}
    );
  }

  clearPaymentsFilters() {
    this.paymentsFilterForm.reset();
    this.paymentsFilterForm.get('has_statement_links').patchValue('0');
    this.loadPayments();
  }

  loadStatements() {
    this.statementsLoading = true;
    const query = new ApiQuery();

    const filters = pickBy(this.statementsFilterForm.value, identity);
    Object.keys(filters).forEach((key: string) => {
      query.addFilter(key, filters[key]);
    });

    query.addFilter('bank_account_id', this.bankAccountId).setLimit(99999);
    this.bankAccountsService.indexBankStatements(query).subscribe(
      (response: PaginatedResponse) => {
        this.statementsData = response.data.map((item: BankStatement) => {
          item['meta_front'] = {};
          item['meta_front']['selected'] = this.findInd(this.selectedStatements, item) > -1;
          return item;
        });
        this.statementsLoading = false;
      },
      () => {
        this.statementsLoading = false;
      }
    );
  }

  clearStatementsFilters() {
    this.statementsFilterForm.reset();
    this.statementsFilterForm.get('has_payment_links').patchValue('0');
    this.loadStatements();
  }

  add(arr: Array<BankStatement | Payment>, element: BankStatement | Payment) {
    const index = this.findInd(arr, element);

    if (index > -1) {
      return false;
    }

    element.meta_front.selected = true;
    arr.push(element);
  }

  remove(
    selectedArr: Array<BankStatement | Payment>,
    element: BankStatement | Payment,
    currentData: Array<BankStatement | Payment>
  ) {
    const index = this.findInd(selectedArr, element);

    if (index <= -1) {
      return false;
    }
    selectedArr.splice(index, 1);

    const indexInCurrentData = this.findInd(currentData, element);

    if (indexInCurrentData > -1) {
      currentData[indexInCurrentData].meta_front.selected = false;
    }
  }

  findInd(arr: Array<BankStatement | Payment>, element: BankStatement | Payment): number {
    return findIndex(arr, (st: BankStatement) => {
      return st.id === element.id;
    });
  }

  private resetAll() {
    this.selectedStatements = [];
    this.selectedPayments = [];
    this.clearStatementsFilters();
    this.clearPaymentsFilters();
  }

  private formatPost() {
    return {
      statements: this.selectedStatements.map((statement: BankStatement) => {
        return statement.id;
      }),
      payments: this.selectedPayments.map((payment: Payment) => {
        return payment.id;
      }),
    };
  }

  private createStatementsFilterForm() {
    this.statementsFilterForm = this.formBuilder.group({
      amount: [null],
      payee: [null],
      date: [null],
      description: [null],
      has_payment_links: ['0'],
    });
  }

  private createPaymentsFilterForm() {
    this.paymentsFilterForm = this.formBuilder.group({
      amount: [null],
      currency_id: [null],
      date: [null],
      reference: [null],
      clientable_type: [null, [Validators.required]],
      clientable_id: [null, [Validators.required]],
      clientable: [null],
      has_statement_links: ['0'],
    });
  }
}
