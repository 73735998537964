import { Component, Input, OnInit } from '@angular/core';
import { Reminder } from '@app/reminders/reminders/reminder.model';
import { Logger } from '@app/core';
import { Router } from '@angular/router';

const log = new Logger('ReminderViewButtonComponent');

@Component({
  selector: 'app-reminder-view-button',
  templateUrl: './reminder-view-button.component.html',
  styleUrls: ['./reminder-view-button.component.scss'],
})
export class ReminderViewButtonComponent implements OnInit {
  @Input() reminder: Reminder;

  constructor(private router: Router) {}

  ngOnInit() {
    if (!this.reminder) {
      log.error('Reminder not provided.');
    }
  }

  /**
   * Go to the reminder's target
   *
   * @param reminder Reminder
   */
  preview(reminder: Reminder) {
    log.debug('preview()', reminder.cleanReminderableType);

    switch (reminder.cleanReminderableType) {
      case 'Sale': {
        window.open(`/sales/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'Hotel': {
        window.open(`/hotels/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'HotelBooking': {
        window.open(`/hotel-bookings/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'HotelBookingCancellation': {
        window.open(`/hotel-booking-cancellations/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'EVENT': {
        window.open(`/events/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'Event': {
        window.open(`/events/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'EventGroup': {
        window.open(`/event-groups/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'Client': {
        window.open(`/clients/view?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      case 'Payment': {
        window.open(`/payments?id=${reminder.reminderable_id}`, '_blank');
        break;
      }
      default: {
        log.error('Error finding the reminders target');
      }
    }
  }
}
