import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { Logger } from '@app/core';

const log = new Logger('DatetimePickerComponent');

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent implements OnInit {
  @Input() FormControl: AbstractControl;
  @Input() yearsRange = 10;
  @Input() required = true;
  @Input() select = new EventEmitter();
  yearsRangeFormatted: string;
  form: FormGroup;
  env = environment;

  _minDate: string | Date = null;
  @Input() set minDate(value: string | Date) {
    this._minDate = moment(value, this.env.apiPipeDateTimeFormat).toDate() || null;
  }

  get minDate(): string | Date {
    return this._minDate;
  }

  _defaultDate: string | Date = null;
  @Input() set defaultDate(value: string | Date) {
    this._defaultDate = moment(value, this.env.apiPipeDateTimeFormat).toDate() || null;
  }

  get defaultDate(): string | Date {
    return this._defaultDate;
  }

  en = {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear',
    weekHeader: 'Wk',
  };

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.yearsRangeFormatted =
      moment().subtract(this.yearsRange, 'years').format('YYYY') +
      ':' +
      moment().add(this.yearsRange, 'years').format('YYYY');

    let required: any = [];
    if (this.required) {
      required = [Validators.required];
    }
    this.form = this.formBuilder.group({
      date_fake: ['', required],
    });

    if (this.FormControl.value && this.FormControl.value.length > 0) {
      this.form.get('date_fake').setValue(moment(this.FormControl.value).format(this.env.momentDateTimeFormat));
    }
    this.FormControl.valueChanges.subscribe((val) => {
      log.debug('value', val);
      if (this.FormControl.value) {
        this.form.get('date_fake').setValue(moment(this.FormControl.value).format(this.env.momentDateTimeFormat));
      } else {
        this.form.get('date_fake').setValue(null);
      }
    });
  }

  onSelect() {
    const date = moment(this.form.get('date_fake').value).format(this.env.apiPipeDateTimeFormat);

    this.FormControl.setValue(date);
    this.FormControl.markAsDirty();

    // Праща дата-та към parent-елемента.
    // Когато се вика директивата може да се закачи "(onSelect)" event
    this.select.emit(date);
  }

  /**
   * Когато пипше проверява дали е налисана валидна дата.
   * При написана валидна дата сетва стойността и в истинския инпут.
   * При написана невалиднта дата сетва null в истинския инпут.
   * Налага се ръчно обновяване на валидатора и  emitEvent: false, защото иначе зацикля.
   */
  onType() {
    if (moment(this.form.get('date_fake').value).isValid()) {
      this.onSelect();
    } else {
      this.FormControl.setValue(null, { emitEvent: false });
      this.select.emit(null);
      if (this.required) {
        this.FormControl.setErrors({ invalid: true });
      }
    }
  }
}
