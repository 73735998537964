import { Injectable } from '@angular/core';
import { ResourceInterface } from '@app/shared/models/resource.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { Invoice } from '@app/finance/invoices/invoice.model';
import { Model } from '@app/shared/models/model.model';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService implements ResourceInterface {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/invoices', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((invoice: any) => {
          return new Invoice().deserialize(invoice);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }

  update(invoice: Invoice): Observable<Invoice> {
    return this.http.put('v1/invoices/' + invoice.id, invoice).pipe(
      map((response: HttpResponse) => {
        return new Invoice().deserialize(response.data);
      })
    );
  }

  store(invoice: Invoice): Observable<Invoice> {
    return this.http.post('v1/invoices', invoice).pipe(
      map((response: HttpResponse) => {
        return new Invoice().deserialize(response.data);
      })
    );
  }

  show(id: number, query: ApiQuery = new ApiQuery()): Observable<Model> {
    return this.http.get('v1/invoices/' + id, query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return new Invoice().deserialize(response.data);
      })
    );
  }

  destroy(id: number): Observable<any> {
    return this.http.delete('v1/invoices/' + id);
  }

  download(id: number, locale: string = null): Observable<any> {
    if (locale) {
      return this.http.download('v1/invoices/' + id + '/download', { params: new HttpParams().set('locale', locale) });
    } else {
      return this.http.download('v1/invoices/' + id + '/download');
    }
  }

  /**
   * GetNextNumber
   * @param type_id type_id
   * @param company_id company_id
   * @param query query optional
   */
  getNextNumber(type_id: number, company_id: number, query: ApiQuery = new ApiQuery()): Observable<any> {
    return this.http
      .get('v1/invoices/next_number?type_id=' + type_id + '&company_id=' + company_id, query.buildGetOptions())
      .pipe(
        map((response: HttpResponse) => {
          return response.data;
        })
      );
  }
}
