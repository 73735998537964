import { LaravelModelsEnum, Model } from '@app/shared/models/model.model';
// import { DateTime } from '@app/shared/models/date-time.model';
import { User } from '@app/settings/users/user.model';
import { Logger } from '@app/core';
import * as moment from 'moment';
import { Events } from '@app/events/events/events.model';

const log = new Logger('Reminder model');

export class Reminder extends Model {
  id: number;
  user_id: number;
  user?: User;
  creator_id: number;
  creator?: User;
  note: string;
  remind_at: string;
  reminderable_id: number;
  reminderable?: any;
  reminderable_type: LaravelModelsEnum;
  completed: boolean;

  deserialize(input: any): this {
    super.deserialize(input);

    /*if (input.remind_at && !(input.remind_at instanceof DateTime)) {
      this.remind_at = new DateTime().deserialize(input.remind_at);
    }*/

    if (input.user) {
      this.user = new User().deserialize(input.user);
    }

    if (input.creator) {
      this.creator = new User().deserialize(input.creator);
    }

    return this;
  }

  get cleanReminderableType() {
    if (this.reminderable_type) {
      const type = this.reminderable_type.split('\\');
      return type[type.length - 1];
    } else {
      return null;
    }
  }

  get about() {
    let about: any = null;
    if (!this.reminderable) {
      return about;
    }
    switch (this.cleanReminderableType) {
      case 'Sale': {
        if (this.reminderable.client) {
          about = this.reminderable.client.name;
        }

        if (this.reminderable.event) {
          about +=
            ' / ' + this.reminderable.event.name + ' ' + moment(this.reminderable.event.date_from).format('YYYY');
        }
        break;
      }
      case 'Hotel': {
        about = this.reminderable.name;
        break;
      }
      case 'HotelBooking': {
        if (this.reminderable.hotel) {
          about = this.reminderable.hotel.name;
        }
        if (this.reminderable && this.reminderable.events) {
          about += ' / ';
          this.reminderable.events.forEach((event: Events, index: Number) => {
            about += event.name + ' ' + moment(event.date_from).format('YYYY');

            if (index !== this.reminderable.events.length - 1) {
              about += ', ';
            }
          });
        }
        break;
      }
      case 'HotelBookingCancellation': {
        if (this.reminderable.booking && this.reminderable.booking.hotel) {
          about = this.reminderable.booking.hotel.name;
        }

        if (this.reminderable.booking && this.reminderable.booking.events) {
          about += ' / ';
          this.reminderable.booking.events.forEach((event: Events, index: Number) => {
            about += event.name + ' ' + moment(event.date_from).format('YYYY');

            if (index !== this.reminderable.booking.events.length - 1) {
              about += ', ';
            }
          });
        }
        break;
      }
      case 'EVENT': {
        about = this.reminderable.name;
        break;
      }
      case 'Event': {
        about = this.reminderable.name;
        break;
      }
      case 'EventGroup': {
        about = this.reminderable.name;
        break;
      }
      case 'Client': {
        about = this.reminderable.name;
        break;
      }
      case 'Payment': {
        about = 'Payment# ' + this.reminderable.id;
        break;
      }
      default: {
        log.error('Unknown reminderable type!!! ' + this.cleanReminderableType);
      }
    }
    return about;
  }
}
