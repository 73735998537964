<ng-template [ngIf]="country">
  <span
    *ngIf="country && country?.code && flag"
    class="flag-icon"
    [ngClass]="'flag-icon-' + (country?.code | lowercase)"
  ></span>

  <span>
    {{ country?.name }}
  </span>
</ng-template>
