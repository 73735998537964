import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiQuery } from '@app/core/http/api-query';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { TimeTracking } from '@app/reports/time-tracking/time-tracking.model';

@Injectable({
  providedIn: 'root',
})
export class TimeTrackingService {
  constructor(private http: HttpClient) {}

  index(query: ApiQuery = new ApiQuery()): Observable<PaginatedResponse> {
    return this.http.get('v1/time_tracking', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        response.data = response.data.map((client: any) => {
          return new TimeTracking().deserialize(client);
        });

        return new PaginatedResponse().deserialize(response);
      })
    );
  }
}
