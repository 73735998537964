import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, Logger } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SmtpSettings } from '@app/shared/components/smtp-settings/smtp-settings.model';
import { Sale } from '@app/sales/sales/sale.model';
import { Templates } from '@app/settings/types/templates.enum';
import { TemplatesService } from '@app/settings/templates/templates.service';
import { ApiQuery } from '@app/core/http/api-query';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { EmailTemplate } from '@app/settings/templates/email-template.model';
import { SalesService } from '@app/sales/sales/sales.service';
import { EmailTemplateCompiler } from '@app/settings/templates/email-template-compiler.model';
import { Email } from '@app/shared/models/email.model';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';
import { Company } from '@app/settings/companies/company.model';
import { Locale } from '@app/settings/locales/locales.model';
import { SaleRequest } from '@app/sales/sales/sale-request.model';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { Invoice } from '@app/finance/invoices/invoice.model';
import { SaleFinal } from '@app/sales/sales/sale-final.model';
import { WebOffersService } from '@app/sales/web-offers/web-offers.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { WebOffer } from '@app/sales/web-offers/web-offer.model';
import { FileUpload } from 'primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaLibraryService } from '@app/shared/components/media-library/media-library.service';
import { DataSet } from '@app/shared/kendo/data-set';

const log = new Logger('EmailTemplateSenderComponent');

@Component({
  selector: 'app-email-template-sender',
  templateUrl: './email-template-sender.component.html',
  styleUrls: ['./email-template-sender.component.scss'],
})
export class EmailTemplateSenderComponent implements OnInit {
  @Input() id: number;
  @Input() typeId: Templates;
  form: FormGroup;
  filterForm: FormGroup;
  senderSmtpSetting: Array<SmtpSettings> = [];
  templatesTypes = Templates;
  templates: Array<EmailTemplate> = [];
  filteredTemplates: Array<EmailTemplate> = [];
  data: Sale = null;
  dataInvoices: Array<Invoice> = null;
  companies: Array<Company> = [];
  locales: Array<Locale> = [];
  LaravelModelsEnum = LaravelModelsEnum;
  dataWebOffers: Array<WebOffer> = null;

  mediaData: PaginatedResponse = new PaginatedResponse();

  files: any = {
    uploads: [],
  };
  @ViewChild('fileUpload', { static: true }) fileUpload: FileUpload;

  toEmails: Array<string> = null;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private templatesService: TemplatesService,
    private salesService: SalesService,
    private invoicesService: InvoicesService,
    private webOffersService: WebOffersService,
    private sanitizer: DomSanitizer,
    private mediaService: MediaLibraryService
  ) {}

  ngOnInit() {
    this.createForm();
    this.createFilterForm();
    this.loadTemplates();
    this.loadData();
  }

  test(form: any) {
    log.debug(form);
    log.debug(this.form);

    this.attachSignature();
  }

  safeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getFileObj(base64: any = null, fileName: string = null, checked: boolean = true): any {
    return {
      base64: base64,
      file_name: fileName,
      checked: checked,
    };
  }

  uploadFile(event: any) {
    event.files.forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.files.uploads.push(this.getFileObj(reader.result, file.name));
      };
    });
    this.fileUpload.files = [];
  }

  /**
   * Пълни селекта From
   */
  getCurrentUserEmails(): void {
    this.authenticationService.loadProfile().subscribe((profile: any) => {
      profile.profile.smtp_settings.forEach((smtpSetting: SmtpSettings, index: null) => {
        this.senderSmtpSetting = [...this.senderSmtpSetting, smtpSetting];
        if (smtpSetting.company_id === this.data.company_id) {
          this.form.get('from').patchValue(smtpSetting.from_email);
        }
      });
    });
  }

  sendEmail() {
    this.form.get('attachments').setValue([]);

    for (const key in this.files) {
      if (key) {
        const value = this.files[key];
        // Use `key` and `value`
        log.debug('value', value);

        value.forEach((file: any) => {
          if (file.checked) {
            this.form.get('attachments').value.push(file);
          }
        });
      }
    }

    const formattedData = this.form.value;

    formattedData.to = formattedData.to.join(';');

    this.templatesService.send(new Email().deserialize(formattedData)).subscribe(
      (res) => {
        log.debug(res);
        this.modal.close(res);
      },
      () => {}
    );
  }

  /**
   * Взима всички темплейти от подадения тип
   */
  loadTemplates() {
    const query = new ApiQuery().setLimit(9999).addFilter('type_id', this.typeId).addIncludes('company', 'locale');

    this.templatesService.index(query).subscribe((response: PaginatedResponse) => {
      this.templates = [...this.templates, ...response.data];

      this.templates.forEach((template: EmailTemplate) => {
        log.debug('forEach template');
        log.debug('this.companies', this.companies);
        // предотвратява дублиране
        if (this.companies.map((x) => x.id).indexOf(template.company_id) === -1 && template.company) {
          log.debug('push company', template);
          this.companies = [...this.companies, template.company];
        }
        // предотвратява дублиране
        if (this.locales.map((x) => x.id).indexOf(template.locale_id) === -1) {
          this.locales = [...this.locales, template.locale];
        }
      });
      this.filterTemplates();
    });
  }

  /**
   * Взима допълнителните данни, които са нужни(за преселектване на някои полета или нещо друго)
   */
  loadData() {
    const salesServiceQuery = new ApiQuery().addIncludes('client.contact_persons.contacts', 'client.contacts');

    if (this.typeId === this.templatesTypes.SALECLIENTOFFER || this.typeId === this.templatesTypes.SALESENDTERMS) {
      salesServiceQuery.addInclude('offers');
    } else if (this.typeId === this.templatesTypes.SALEWEBOFFERS) {
      const webOffersServiceQuery = new ApiQuery()
        .addFilters({
          web_offerable_id: this.id,
          web_offerable_type: this.LaravelModelsEnum.SALE,
        })
        .setLimit(9999);
      this.webOffersService.index(webOffersServiceQuery).subscribe((response: PaginatedResponse) => {
        this.dataWebOffers = response.data;
      });
    } else if (this.typeId === this.templatesTypes.SALEGENERAL) {
      salesServiceQuery.addIncludes('requests', 'requests.status', 'requests.type', 'requests.hotel');
    } else if (this.typeId === this.templatesTypes.SALEINVOICES) {
      const invoiceServiceQuery = new ApiQuery()
        .addInclude('type')
        .addFilters({
          sale_id: this.id,
        })
        .setLimit(9999);
      this.invoicesService.index(invoiceServiceQuery).subscribe((response: PaginatedResponse) => {
        this.dataInvoices = response.data;
      });
    } else if (this.typeId === this.templatesTypes.SALEFEEDBACK || this.typeId === this.templatesTypes.SALEVOUCHERS) {
      salesServiceQuery.addIncludes('finals.sale_offer', 'finals.payment_method');
    }

    this.salesService.show(this.id, salesServiceQuery).subscribe((sale: Sale) => {
      this.data = sale;
      this.toEmails = this.data.client.clientEmails;
      this.patchFilterForm();
      this.loadVouchers();
      this.getCurrentUserEmails();
    });
  }

  /**
   * Филтрира темплейтите на база избраните филтри от filterForm
   */
  filterTemplates() {
    const filterObj: any = {
      company_id: null,
      locale_id: null,
    };

    if (this.filterForm.get('company_id').value) {
      filterObj.company_id = this.filterForm.get('company_id').value;
    }

    if (this.filterForm.get('locale_id').value) {
      filterObj.locale_id = this.filterForm.get('locale_id').value;
    }
    this.filteredTemplates = this.templates.filter((template: EmailTemplate) => {
      if (filterObj.company_id && filterObj.locale_id) {
        return template.company_id === filterObj.company_id && template.locale_id === filterObj.locale_id;
      }

      if (filterObj.company_id && !filterObj.locale_id) {
        return template.company_id === filterObj.company_id;
      }

      if (!filterObj.company_id && filterObj.locale_id) {
        return template.locale_id === filterObj.locale_id;
      }
      return template;
    });
    this.filterForm.get('template_id').reset();
  }

  loadVouchers() {
    if (this.typeId === this.templatesTypes.SALEVOUCHERS) {
      const query = new ApiQuery()
        .addFilters({
          collection_name: 'sale_vouchers',
          model_id: this.data.company_id,
          model_type: LaravelModelsEnum.COMPANY,
        })
        .setLimit(9999999999);

      this.mediaService.index(query).subscribe((data: PaginatedResponse) => {
        this.mediaData = data;
        log.debug('this.mediaData', this.mediaData);
      });
    }
  }

  /**
   * Грижи се за добавянето и премахването на id-та в this.filterForm.get('offers')
   * @param id ID на offer
   */

  offerCheckboxChange(id: number): void {
    const currentSelectedOffers = this.filterForm.get('offers').value;

    if (currentSelectedOffers.indexOf(id) < 0) {
      currentSelectedOffers.push(id);
    } else if (currentSelectedOffers.indexOf(id) >= 0) {
      const index = currentSelectedOffers.indexOf(id);
      currentSelectedOffers.splice(index, 1);
    }
  }

  /**
   * Грижи се за добавянето и премахването на id-та в this.filterForm.get('webOffers')
   * @param id ID на webOffer
   */

  webOfferCheckboxChange(id: number): void {
    this.filterForm.get('webOffers').setValue([id]);
  }

  /**
   * Грижи се за добавянето и премахването на id-та в this.filterForm.get('requests')
   * @param id ID на request
   */

  requestCheckboxChange(id: number): void {
    const currentSelectedRequests = this.filterForm.get('requests').value;

    if (currentSelectedRequests.indexOf(id) < 0) {
      currentSelectedRequests.push(id);
    } else if (currentSelectedRequests.indexOf(id) >= 0) {
      const index = currentSelectedRequests.indexOf(id);
      currentSelectedRequests.splice(index, 1);
    }
  }

  /**
   * Грижи се за добавянето и премахването на id-та в this.filterForm.get('invoices')
   * @param id ID на invoice
   */

  invoiceCheckboxChange(id: number): void {
    const currentSelectedInvoices = this.filterForm.get('invoices').value;

    if (currentSelectedInvoices.indexOf(id) < 0) {
      currentSelectedInvoices.push(id);
    } else if (currentSelectedInvoices.indexOf(id) >= 0) {
      const index = currentSelectedInvoices.indexOf(id);
      currentSelectedInvoices.splice(index, 1);
    }
  }

  /**
   * Грижи се за добавянето и премахването на id-та в this.filterForm.get('finals')
   * @param id ID на final
   */

  finalCheckboxChange(id: number): void {
    log.debug('id', id);
    const currentSelectedFinals = this.filterForm.get('finals').value;

    if (currentSelectedFinals.indexOf(id) < 0) {
      currentSelectedFinals.push(id);
    } else if (currentSelectedFinals.indexOf(id) >= 0) {
      const index = currentSelectedFinals.indexOf(id);
      currentSelectedFinals.splice(index, 1);
    }
  }

  /**
   * Грижи се за добавянето и премахването на id-та в this.filterForm.get('media')
   * @param id ID на media
   */

  mediaCheckboxChange(id: number): void {
    this.filterForm.get('media_id').patchValue(id);
  }

  compile() {
    const formattedFilterDataObj: any = {
      template_id: this.filterForm.get('template_id').value,
      filters: {
        sale_offer_ids: null,
        sale_request_ids: null,
        sale_invoice_ids: null,
        sale_final_ids: null,
        sale_web_offer_ids: null,
        media_id: null,
      },
      object_id: this.id,
    };

    if (this.typeId === this.templatesTypes.SALECLIENTOFFER || this.typeId === this.templatesTypes.SALESENDTERMS) {
      formattedFilterDataObj.filters.sale_offer_ids = this.filterForm.get('offers').value;
    }

    if (this.typeId === this.templatesTypes.SALEWEBOFFERS) {
      formattedFilterDataObj.filters.sale_web_offer_ids = this.filterForm.get('webOffers').value;
    }

    if (this.typeId === this.templatesTypes.SALEGENERAL) {
      formattedFilterDataObj.filters.sale_request_ids = this.filterForm.get('requests').value;
    }

    if (this.typeId === this.templatesTypes.SALEINVOICES) {
      formattedFilterDataObj.filters.sale_invoice_ids = this.filterForm.get('invoices').value;
    }

    if (this.typeId === this.templatesTypes.SALEFEEDBACK || this.typeId === this.templatesTypes.SALEVOUCHERS) {
      formattedFilterDataObj.filters.sale_final_ids = this.filterForm.get('finals').value;
    }

    if (this.typeId === this.templatesTypes.SALEVOUCHERS) {
      formattedFilterDataObj.filters.media_id = this.filterForm.get('media_id').value;
    }

    const formattedFilterData = new EmailTemplateCompiler().deserialize(formattedFilterDataObj);

    this.templatesService.compile(formattedFilterData).subscribe((res) => {
      this.form.get('body').patchValue(res.compiled.html_template);
      this.form.get('subject').patchValue(res.compiled.subject);

      if (res.compiled.attachments) {
        for (const key in res.compiled.attachments) {
          if (key) {
            const value = res.compiled.attachments[key];
            // Use `key` and `value`

            if (!this.files[key]) {
              this.files[key] = [];
            }
            value.forEach((file: any) => {
              this.files[key].push(this.getFileObj(file.base64, file.file_name));
            });
          }
        }
      }
      this.attachSignature();
    });
  }

  attachSignature() {
    let sig = '';
    const currSelectedEmail = this.form.get('from').value;
    if (currSelectedEmail) {
      this.senderSmtpSetting.forEach((smtp: SmtpSettings) => {
        if (smtp.from_email === currSelectedEmail) {
          sig = smtp.signature;
        }
      });
    }

    const bodyHtml = $('<div>' + this.form.get('body').value + '</div>');

    if (bodyHtml.find('.signature').length > 0) {
      bodyHtml.find('.signature').html(sig);
    } else {
      bodyHtml.append('<div class="signature">' + sig + '</div>');
    }

    this.form.get('body').patchValue(bodyHtml.html());
  }

  addTagFn(name: string) {
    log.debug('addTagFn', name);
    log.debug('this.data', this.data);
    return name;
  }

  /**
   * Създава основната форма (финалната с рендерирания темплейт)
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      from: [null, [Validators.required]],
      to: [null, [Validators.required]],
      subject: [null, [Validators.required]],
      body: ['', [Validators.required]],
      attachments: [[]],
    });
  }

  /**
   * Създава допълнителната форма( тази с филтрите)
   */
  private createFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      //  company_id: [null, [Validators.required]],
      company_id: [null],
      locale_id: [null, [Validators.required]],
      template_id: [null, [Validators.required]],
      offers: [[]],
      webOffers: [[]],
      requests: [[]],
      invoices: [[]],
      finals: [[]],
      media_id: [null],
    });
  }

  /**
   * Patch-ва допълнителната форма( тази с филтрите)
   */
  private patchFilterForm() {
    const patchObj: any = {
      company_id: this.data.company_id,
      locale_id: null,
      offers: [],
      webOffers: [],
      requests: [],
      invoices: [],
      finals: [],
      media_id: null,
    };

    // преселектва англ
    this.locales.forEach((locale: Locale) => {
      if (locale.locale.toLowerCase() === 'en') {
        patchObj.locale_id = locale.id;
      }
    });

    if (this.typeId === this.templatesTypes.SALECLIENTOFFER || this.typeId === this.templatesTypes.SALESENDTERMS) {
      this.data.offers.forEach((offer: SaleOffer) => {
        patchObj.offers.push(offer.id);
      });
    }

    if (this.typeId === this.templatesTypes.SALEGENERAL) {
      this.data.requests.forEach((request: SaleRequest) => {
        patchObj.requests.push(request.id);
      });
    }

    if (this.typeId === this.templatesTypes.SALEFEEDBACK || this.typeId === this.templatesTypes.SALEVOUCHERS) {
      this.data.finals.forEach((final: SaleFinal) => {
        patchObj.finals.push(final.id);
      });
    }

    this.filterForm.patchValue(patchObj);
    this.filterTemplates();
    log.debug('this.filterForm', this.filterForm);
  }
}
