<ng-template #popContent>
  <div class="popover-wrapper">
    <form
      id="hotel-booking-mass-reassign-form"
      (ngSubmit)="reassign(HotelBookingsMassReassignPopOver)"
      [formGroup]="form"
      novalidate
    >
      <div class="form-group">
        <label>{{ 'HOTELS_BOOKINGS_MASS_REASSIGN.FIELD' | translate }}</label>
        <select class="form-control" formControlName="field" (change)="fieldChange()">
          <option value="reservation_agent_id">
            {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.RESERVATION_AGENT' | translate }}
          </option>

          <option value="contracting_agent_id">
            {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.CONTRACTING_AGENT' | translate }}
          </option>
        </select>
      </div>

      <div *ngIf="form.get('field').value" class="modal-section-title">
        {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.FROM' | translate }}
      </div>

      <app-user-select
        *ngIf="form.get('field').value === 'reservation_agent_id'"
        [hideLabel]="true"
        [passFormControlName]="'from_id'"
        [parentFormGroup]="form"
        [roleType]="UserRoleType.RESERVATION_AGENT"
        [onlyActive]="false"
      ></app-user-select>

      <app-user-select
        *ngIf="form.get('field').value === 'contracting_agent_id'"
        [hideLabel]="true"
        [passFormControlName]="'from_id'"
        [parentFormGroup]="form"
        [roleType]="UserRoleType.CONTRACTING_AGENT"
        [onlyActive]="false"
      ></app-user-select>

      <div *ngIf="form.get('field').value" class="modal-section-title">
        {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.TO' | translate }}
      </div>

      <app-user-select
        *ngIf="form.get('field').value === 'reservation_agent_id'"
        [hideLabel]="true"
        [passFormControlName]="'to_id'"
        [parentFormGroup]="form"
        [roleType]="UserRoleType.RESERVATION_AGENT"
      ></app-user-select>

      <app-user-select
        *ngIf="form.get('field').value === 'contracting_agent_id'"
        [hideLabel]="true"
        [passFormControlName]="'to_id'"
        [parentFormGroup]="form"
        [roleType]="UserRoleType.CONTRACTING_AGENT"
      ></app-user-select>

      <div class="d-flex justify-content-between">
        <button [disabled]="form.invalid" type="submit" class="btn btn-primary btn-xs">
          {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.REASSIGN' | translate }}
        </button>

        <button type="button" class="btn btn-danger btn-xs" (click)="close(HotelBookingsMassReassignPopOver)">
          {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.POP_BTN_CLOSE' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #popTitle>
  {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.MASS_REASSIGN' | translate }}
</ng-template>

<button
  container="body"
  [autoClose]="false"
  type="button"
  popoverClass="hotel-bookings-mass-reassign-popover"
  class="btn btn-outline-secondary"
  #HotelBookingsMassReassignPopOver="ngbPopover"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
>
  {{ 'HOTELS_BOOKINGS_MASS_REASSIGN.MASS_REASSIGN' | translate }}
</button>
