import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplateSenderComponent } from '@app/shared/components/email-template-sender/email-template-sender.component';
import { Templates } from '@app/settings/types/templates.enum';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateSenderService {
  constructor(private modalService: NgbModal) {}

  public open(id: number = null, typeId: Templates): Promise<any> {
    const modal = this.modalService.open(EmailTemplateSenderComponent, { size: 'full' as 'lg' });
    modal.componentInstance.id = id;
    modal.componentInstance.typeId = typeId;
    return modal.result;
  }
}
