import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelFinderComponent } from '@app/shared/components/hotel-finder/hotel-finder.component';

@Injectable({
  providedIn: 'root',
})
export class HotelFinderService {
  constructor(private modalService: NgbModal) {}

  public open(data: object = {}, multiple: boolean = false): Promise<any> {
    const modal = this.modalService.open(HotelFinderComponent, { size: 'full' as 'lg' });
    modal.componentInstance.data = data;
    modal.componentInstance.multiple = multiple;
    return modal.result;
  }
}
