<button
  *ngIf="invoice.company_id !== CompaniesEnum.TFT"
  [ngbTooltip]="'DOWNLOAD_INVOICES_BUTTON.DOWNLOAD' | translate"
  container="body"
  (click)="download()"
  class="btn btn-primary btn-ico"
>
  <i class="fas fa-download"></i>
</button>

<div *ngIf="invoice.company_id === CompaniesEnum.TFT" ngbDropdown class="d-inline-block" container="body">
  <button
    [ngbTooltip]="'DOWNLOAD_INVOICES_BUTTON.DOWNLOAD' | translate"
    container="body"
    class="btn btn-primary btn-ico btn-download-invoice"
    id="invoices-dropdown"
    ngbDropdownToggle
  >
    <i class="fas fa-download"></i>
  </button>
  <div ngbDropdownMenu aria-labelledby="invoices-dropdown">
    <button ngbDropdownItem (click)="download()">EN</button>
    <button ngbDropdownItem (click)="download('bg')">BG</button>
  </div>
</div>
