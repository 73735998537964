import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesOffersOnlineBookingTermsComponent } from '@app/sales/sales/sales-offers-online-booking-terms/sales-offers-online-booking-terms.component';
import { Sale } from '@app/sales/sales/sale.model';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';

@Injectable({
  providedIn: 'root',
})
export class SalesOffersOnlineBookingTermsFormService {
  constructor(private modalService: NgbModal) {}

  public open(sale: Sale, offerId: number): Promise<any> {
    const modal = this.modalService.open(SalesOffersOnlineBookingTermsComponent, { size: 'full' as 'lg' });
    modal.componentInstance.sale = sale;
    modal.componentInstance.offerId = offerId;
    return modal.result;
  }
}
