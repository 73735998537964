import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { SalesFinalService } from '@app/sales/sales/sales-final.service';
import { environment } from '@env/environment';
import * as moment from 'moment';

const log = new Logger('ClientHistoryComponent');

@Component({
  selector: 'app-client-history',
  templateUrl: './client-history.component.html',
  styleUrls: ['./client-history.component.scss'],
})
export class ClientHistoryComponent implements OnInit {
  @Input() client_id: number;
  saleFinalsResponse: PaginatedResponse;
  gridData: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;

  constructor(public modal: NgbActiveModal, private saleFinalsService: SalesFinalService) {}

  ngOnInit() {
    this.loadData();
  }

  loadData(state: DataStateChangeEvent = null): void {
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addFilters({ 'saleFinal.sale.client_id': this.client_id })
      .addIncludes('saleFinal.sale.event', 'hotel_booking_room.hotel', 'saleOfferItem.meal_plan');

    this.saleFinalsService.itemsIndex(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.saleFinalsResponse = response;
      this.gridData = response.getGridData();
      log.debug('this.saleFinalsResponse', this.saleFinalsResponse);
      log.debug('this.gridData', this.gridData);
    });
  }

  getNightsCount(date1: string | Date, date2: string | Date): number {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }
}
