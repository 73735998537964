import { Component, Input } from '@angular/core';
import { BankAccount } from '@app/shared/components/bank-accounts/bank-account.model';

@Component({
  selector: 'app-bank-accounts-box',
  templateUrl: './bank-accounts-box.component.html',
  styleUrls: ['./bank-accounts-box.component.scss'],
})
export class BankAccountsBoxComponent {
  @Input() bankAccounts: Array<BankAccount>;

  constructor() {}
}
