import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountsStatementsMatchFormComponent } from '@app/banks/bank-accounts/bank-accounts-statements-match-form/bank-accounts-statements-match-form.component';

@Injectable({
  providedIn: 'root',
})
export class BankAccountsStatementsMatchFormService {
  constructor(private modalService: NgbModal) {}

  public open(bankAccountId: number) {
    const modal = this.modalService.open(BankAccountsStatementsMatchFormComponent, { size: 'full' as 'lg' });
    modal.componentInstance.bankAccountId = bankAccountId;
    return modal.result;
  }
}
