import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccount } from '@app/shared/components/bank-accounts/bank-account.model';
import { BankAccountsFormComponent } from '@app/banks/bank-accounts/bank-accounts-form/bank-accounts-form.component';

@Injectable({
  providedIn: 'root',
})
export class BankAccountsFormService {
  constructor(private modalService: NgbModal) {}

  public open(bankAccount: null | BankAccount = null) {
    const modal = this.modalService.open(BankAccountsFormComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.bankAccount = bankAccount;
    return modal.result;
  }
}
