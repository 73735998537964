import { Component, Input } from '@angular/core';
import { Country } from '@app/settings/countries/countries.model';

@Component({
  selector: 'app-country-box',
  templateUrl: './country-box.component.html',
  styleUrls: ['./country-box.component.scss'],
})
export class CountryBoxComponent {
  @Input() country: Country;
  @Input() flag = true;

  constructor() {}
}
