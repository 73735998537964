import { Injectable } from '@angular/core';
import { ApiQuery } from '@app/core/http/api-query';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@app/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SaleGuestNamesService {
  constructor(private http: HttpClient) {}

  index(saleId: number, finalID: number, query: ApiQuery = new ApiQuery()): any {
    return this.http.get('v1/sales/' + saleId + '/finals/' + finalID + '/guests', query.buildGetOptions()).pipe(
      map((response: HttpResponse) => {
        return response.data;
      })
    );
  }

  store(saleId: number, finalID: number, items: any): any {
    return this.http.post('v1/sales/' + saleId + '/finals/' + finalID + '/guests', items).pipe(
      map((response: HttpResponse) => {
        return response.data;
      })
    );
  }
}
