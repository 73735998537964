import { Model } from '@app/shared/models/model.model';
import { User } from '@app/settings/users/user.model';

export class Recipient extends Model {
  id: number;
  amount?: number;
  paid_at: string;
  percent: number;
  sale_bonus_id: number;
  user_id: number;
  user?: User;
  user_type: 'customer_care_agent' | 'reservation_agent' | 'sales_agent' | 'contracting_agent';

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.user) {
      this.user = new User().deserialize(input.user);
    }

    return this;
  }
}
