import { Component, Input } from '@angular/core';
import * as FileSaver from 'file-saver';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-download-rooming-list-button',
  templateUrl: './download-rooming-list-button.component.html',
  styleUrls: ['./download-rooming-list-button.component.scss'],
})
export class DownloadRoomingListButtonComponent {
  @Input() bookingId: number;
  @Input() iconButton = false;

  constructor(
    private hotelBookingService: HotelBookingsService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  downloadRoomingList() {
    if (!this.bookingId) {
      return this.translate.get('DOWNLOAD_ROOMING_LIST_BUTTON.ID_ERROR_MSG').subscribe((trans: string) => {
        this.toastr.error(trans);
      });
    }

    this.hotelBookingService.exportSoldRooms(this.bookingId).subscribe((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.ms-excel;charset=utf-8',
      });
      FileSaver.saveAs(blob, 'sold-rooming-list-' + this.bookingId + '.xlsx');
    });
  }
}
