import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { ApiQuery, ApiQuerySortTypes } from '@app/core/http/api-query';
import { RemindersService } from '@app/reminders/reminders/reminders.service';
import { AuthenticationService } from '@app/core';
import { Reminder } from '@app/reminders/reminders/reminder.model';
import { environment } from '@env/environment';
import { ProfileService } from '@app/profile/profile.service';

@Component({
  selector: 'app-reminders-list',
  templateUrl: './reminders-list.component.html',
  styleUrls: ['./reminders-list.component.scss'],
})
export class RemindersListComponent implements OnInit {
  apiQuery: ApiQuery = new ApiQuery();
  data: Array<Reminder>;
  env = environment;
  collapsed = this.profileService.getSetting('RemindersListComponent', 'dashboardBoxes').collapsed;

  constructor(
    private reminderService: RemindersService,
    private auth: AuthenticationService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    if (!this.collapsed) {
      this.loadData();
    }
  }

  loadData() {
    this.apiQuery
      .addFilters({
        user_id: this.auth.accessToken.profile.id,
        completed: false,
      })
      .addIncludes('creator', 'reminderable')
      .setSort('remind_at', ApiQuerySortTypes.ASC)
      .setLimit(15);

    this.reminderService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      this.data = response.data;
    });
  }

  complete(reminder: Reminder) {
    reminder.completed = true;

    this.reminderService.update(reminder).subscribe((res: Reminder) => {
      this.loadData();
    });
  }

  toggle() {
    this.collapsed = !this.collapsed;
    this.profileService
      .setSetting('RemindersListComponent', { collapsed: this.collapsed }, 'dashboardBoxes')
      .subscribe();
    if (!this.collapsed && !this.data) {
      this.loadData();
    }
  }
}
