import { Model } from '@app/shared/models/model.model';
import { Sale } from '@app/sales/sales/sale.model';
import { Recipient } from '@app/bonuses/sale-bonuses/recipient.model';
import { Invoice } from '@app/finance/invoices/invoice.model';

export class SaleBonus extends Model {
  id: number;
  amount: number;
  date: string;
  refundable: boolean;
  active: boolean;
  total_bonus_amount: number;
  paid_bonus_amount: number;
  sale_id: number;
  sale?: Sale;
  recipients?: Array<Recipient>;
  invoices?: Array<Invoice>;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.sale) {
      this.sale = new Sale().deserialize(input.sale);
    }

    if (input.recipients) {
      this.recipients = input.recipients.map((recipient: Recipient) => new Recipient().deserialize(recipient));
    }

    return this;
  }
}
