import { AuthenticationService } from '@app/core';

export class MenuItem {
  key: string; // settings.locales
  icon?: string; // fontawesome classes
  path?: string;
  title: string;
  childrens: Array<MenuItem> = [];
  requiredPermission?: string;
  collapsed: boolean;

  constructor(data: MenuItemInterface) {
    for (const key in data) {
      if (key) {
        const value = data[key];
        // Use `key` and `value`
        this[key] = value;
      }
    }
  }

  /**
   * is visible
   */
  isVisible(): boolean {
    if (this.requiredPermission) {
      return AuthenticationService.checkPermission(this.requiredPermission);
    } else {
      return true;
    }
  }

  hasVisibleChild(): boolean {
    let hasVisibleChild = false;
    this.childrens.forEach((child: MenuItem) => {
      if (AuthenticationService.checkPermission(child.requiredPermission)) {
        hasVisibleChild = true;
      }
    });
    return hasVisibleChild;
  }
}

export interface MenuItemInterface {
  key: string;
  requiredPermission?: string;
  icon?: string; // fontawesome classes
  path?: string;
  title: string;
  childrens?: Array<MenuItem>;
  collapsed?: boolean;
}
