import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ips-select',
  templateUrl: './ips-select.component.html',
  styleUrls: ['./ips-select.component.scss'],
})
export class IpsSelectComponent {
  @Input() parentFormGroup: FormGroup;
  @Input() passFormControlName = 'allowed_ip_addresses';

  constructor() {}

  addTagFn(name: string) {
    return name;
  }
}
