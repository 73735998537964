<div class="modal-header">
  <h4 class="modal-title">{{ 'SALES.SALES_OFFERS_ONLINE_BOOKING_TERMS_FORM.TITLE' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0 d-flex flex-column">
  <div class="d-flex overflow-hidden flex-grow-1">
    <div class="filter-wrapper ps" [perfectScrollbar]>
      <form [formGroup]="filterForm" novalidate (ngSubmit)="compile()">
        <div class="form-group">
          <label>{{ 'EMAIL_TEMPLATE_SENDER.COMPANY' | translate }}</label>
          <ng-select
            [items]="companies"
            bindLabel="shortName"
            bindValue="id"
            formControlName="company_id"
            (change)="filterTemplates()"
            appendTo="body"
          ></ng-select>
        </div>

        <div class="form-group">
          <label>{{ 'EMAIL_TEMPLATE_SENDER.LOCALE' | translate }}</label>
          <ng-select
            [items]="locales"
            bindLabel="name"
            bindValue="id"
            formControlName="locale_id"
            (change)="filterTemplates()"
            appendTo="body"
          ></ng-select>
        </div>

        <div class="form-group">
          <label>{{ 'EMAIL_TEMPLATE_SENDER.TEMPLATE' | translate }}</label>
          <ng-select
            [items]="filteredTemplates"
            formControlName="template_id"
            bindLabel="name"
            bindValue="id"
            appendTo="body"
          ></ng-select>
        </div>

        <button type="submit" class="btn btn-success mt-2" [disabled]="filterForm.get('template_id').invalid">
          {{ 'EMAIL_TEMPLATE_SENDER.COMPILE' | translate }}
        </button>
      </form>
    </div>

    <form
      class="email-content-wrapper flex-grow-1 overflow-auto"
      id="sales-offers-online-booking-terms-form"
      [formGroup]="form"
      novalidate
    >
      <div class="row">
        <div class="col-sm-1">
          <div class="form-group">
            <label>{{ 'SALES.SALES_OFFERS_ONLINE_BOOKING_TERMS_FORM.PAYMENT_PERCENT' | translate }}</label>
            <input type="number" class="form-control" formControlName="online_booking_payment_percent" />
          </div>
        </div>
      </div>

      <app-editor [form]="form" passFormControlName="sale_online_booking_terms"></app-editor>
    </form>
  </div>
</div>

<div class="modal-footer">
  <button
    (click)="save()"
    type="submit"
    class="btn btn-outline-dark btn-xs"
    form="sales-offers-online-booking-terms-form"
  >
    {{ 'SALES.SALES_OFFERS_ONLINE_BOOKING_TERMS_FORM.SAVE' | translate }}
  </button>
</div>
