<button
  (shown)="shown()"
  [ngbPopover]="popLinksCompact"
  [autoClose]="false"
  triggers="manual"
  #LinksCompactPopOver="ngbPopover"
  (click)="LinksCompactPopOver.open()"
  popoverClass="links-compact-popover"
  container="body"
  class="btn btn-link p-0"
  type="button"
>
  {{ 'LINKS_COMPACT.LINKS_BTN' | translate }}
</button>

<ng-template #popLinksCompact>
  <div class="popover-wrapper">
    <div class="popover-custom-header d-flex align-items-center justify-content-between">
      <div class="popover-title">
        {{ 'LINKS_COMPACT.LINKS_COMPACT_MANAGER' | translate }}
        <span *ngIf="data && data.name"> - {{ data.name }}</span>
      </div>

      <div>
        <button type="button" class="btn btn-danger btn-ico align-top mb-1" (click)="LinksCompactPopOver.close()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>

    <form id="links-compact-form" novalidate>
      <div class="row no-gutters">
        <div class="col-sm-5">
          <div class="locales-menu">
            <div class="ps" [perfectScrollbar]>
              <div class="modal-section-title mt-1 mb-1 pl-2">
                {{ 'LINKS_COMPACT.WEBSITES_LIST_TITLE' | translate }}
              </div>

              <div
                *ngFor="let websiteLocale of companyWebsiteLocales"
                (click)="selectCompanyWebSiteLocale(websiteLocale.id)"
                class="website-locale d-flex"
                [ngClass]="{ active: currentWebsiteLocaleId === websiteLocale.id }"
              >
                <div class="ico">
                  <i class="fal fa-chevron-right"></i>
                </div>
                <div class="name">{{ websiteLocale.website.name }} - {{ websiteLocale.website.url }}</div>
                <div class="flag">
                  <span class="flag-icon" [ngClass]="'flag-icon-' + websiteLocale.locale.locale"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="locales-content-wrapper">
            <div class="ps" [perfectScrollbar]>
              <div *ngIf="!currentWebsiteLocaleId" class="select-website-msg modal-section-title pl-3 mt-2">
                {{ 'LINKS_COMPACT.SELECT_WEBSITE_MSG' | translate }}
              </div>

              <div *ngFor="let websiteLocale of companyWebsiteLocales; let i = index" class="locales-content">
                <div *ngIf="currentWebsiteLocaleId === websiteLocale.id">
                  <div class="modal-section-title mt-1 mb-1">
                    {{ getWebsiteLocaleName(websiteLocale.id) }}
                  </div>

                  <div *ngIf="!websiteLocale.links || websiteLocale.links.length <= 0">
                    {{ 'LINKS_COMPACT.NO_LINKS_MSG' | translate }}
                  </div>

                  <div *ngFor="let link of websiteLocale.links; let j = index" class="single-link">
                    <div class="type">{{ link?.type?.value }}</div>

                    <div class="value">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <button
                            (click)="copyInputMessage(link.value)"
                            class="btn btn-outline-secondary"
                            type="button"
                          >
                            <i class="fal fa-copy"></i>
                          </button>
                          <a class="btn btn-outline-secondary" target="_blank" [attr.href]="link.value">
                            <i class="fal fa-external-link"></i>
                          </a>
                        </div>
                        <input type="text" class="form-control" disabled [value]="link.value" #userinput />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
