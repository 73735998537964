<small class="user-states">
  <div *ngIf="userStates.length > 0">
    <div *ngIf="countView">{{ userStates.length }}</div>

    <ng-template *ngIf="!countView">
      <div *ngFor="let userState of userStates">{{ userState.userNames }} / {{ userState.userId }}</div>
    </ng-template>
  </div>

  <div *ngIf="userStates.length == 0">free</div>
</small>
