import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterService } from '@progress/kendo-angular-grid';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { ApiQuery } from '@app/core/http/api-query';
import { TagsService } from '@app/shared/components/tags-select/tags.service';
import { Logger } from '@app/core';

const log = new Logger('KendoTagSelectComponent');

@Component({
  selector: 'app-kendo-tag-select',
  templateUrl: './kendo-tag-select.component.html',
  styleUrls: ['./kendo-tag-select.component.scss'],
})
export class KendoTagSelectComponent extends CustomKendoFilter implements OnInit, OnChanges {
  @Input() model: LaravelModelsEnum;
  @Input() field = 'tags';
  data: any = [];
  loader = false;

  constructor(private formBuilder: FormBuilder, filterService: FilterService, private tagsService: TagsService) {
    super(filterService);
  }

  ngOnInit(): void {
    const tag = this.getObjectId(this.field);
    this.form = this.formBuilder.group({
      tags: [tag],
    });

    if (tag) {
      this.data = [tag];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Изчиства формата
    // Използва се, за да чисти филтрите, когато се сетне null state на таблицата
    if (this.form && changes && changes.filter && !changes.filter.currentValue) {
      this.form.reset();
    }
  }

  loadData() {
    if (this.model) {
      this.loader = true;
      const query = new ApiQuery().setLimit(99999).addFilters({ model: this.model });
      this.tagsService.index(query).subscribe(
        (tags: any) => {
          log.debug(tags);
          this.data = tags;
          this.loader = false;
        },
        () => {
          this.loader = false;
        }
      );
    } else {
      throw new Error('No model provided.');
    }
  }

  onSelect(event: any) {
    log.debug('event', event);

    this.filter = this.removeFilter(this.field);

    const filters = [];
    if (event) {
      filters.push({
        field: this.field,
        operator: 'eq',
        value: event,
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: [],
    };

    if (filters.length) {
      root.filters.push(...filters);
    }
    this.filterService.filter(root);
    log.debug('filters', filters);
  }
}
