import { BaseFilterCellComponent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Logger } from '@app/core';
import { FormGroup } from '@angular/forms';
import { Component, Directive, Injectable, Input } from '@angular/core';
import * as moment from 'moment';

const log = new Logger('CustomKendoFilter');

// https://github.com/angular/angular/issues/30080
// https://next.angular.io/guide/migration-undecorated-classes#what-does-it-mean-to-have-a-directive-decorator-with-no-metadata-inside-of-it
@Directive({
  selector: '[appCustomKendoFilter]',
})
export class CustomKendoFilter extends BaseFilterCellComponent {
  form: FormGroup;
  @Input() filter: CompositeFilterDescriptor;

  /**
   * Добавя state в Storage-a
   * @param key ключ, на който ще запише state-а
   * @param state state
   */
  public static pushStateInStorage(key: string, state: DataStateChangeEvent): void {
    // трие празните филтри.  @todo: ПОД ВЪПРОС Е!
    if (state && state.filter && state.filter.filters && state.filter.filters.length === 0) {
      delete state.filter;
    }

    window.localStorage.setItem('FILTERSTATE_' + key, JSON.stringify(state));
  }

  /**
   * Връща state по зададен ключ
   * @param key ключ по който търси в Storage-a
   */
  public static getStateFromStorage(key: string) {
    const obj = JSON.parse(window.localStorage.getItem('FILTERSTATE_' + key));
    if (obj && obj.filter && obj.filter.filters) {
      obj.filter.filters.forEach((singleFilter: any) => {
        // ако value-то на полето е дата го обръща в js date object, защото филтъра не приема нищо друго
        if (
          singleFilter.value &&
          singleFilter.value.length >= 10 &&
          moment(singleFilter.value, moment.ISO_8601, true).isValid()
        ) {
          singleFilter.value = new Date(Date.parse(singleFilter.value));
        }
      });
    }
    return obj;
  }

  /**
   * Добавя обект в  Storage-a
   * @param object обекта, който добавя
   * @param type типа на обекта
   */
  public static pushObjectInStorage(object: any, type: string): void {
    if (object) {
      let datasetFiltersObjects: any = null;

      if (window.localStorage.getItem('datasetFiltersObjects')) {
        datasetFiltersObjects = JSON.parse(window.localStorage.getItem('datasetFiltersObjects'));
        if (datasetFiltersObjects[type]) {
          let match = false;
          datasetFiltersObjects[type].forEach((obj: any) => {
            if (obj.id === object.id) {
              match = true;
            }
          });

          if (!match) {
            datasetFiltersObjects[type].push(object);
          }
        } else {
          datasetFiltersObjects[type] = [object];
        }
      } else {
        datasetFiltersObjects = {};
        datasetFiltersObjects[type] = [object];
      }
      window.localStorage.setItem('datasetFiltersObjects', JSON.stringify(datasetFiltersObjects));
    }
  }

  /**
   * Връща id по даден field
   * @param field полето което да търси в подадения в първия аргумент филтър
   */
  getObjectId(field: string): number | string {
    log.debug('field', field);
    log.debug('this.filter', this.filter);
    let objectId: number | string = null;

    if (this.filter && this.filter.filters) {
      this.filter.filters.forEach((singleFilter: any) => {
        if (singleFilter.field && singleFilter.field === field) {
          objectId = singleFilter.value;
        }
      });
    }
    return objectId;
  }

  /**
   *  Като getObjectId() само, че връша масив
   *  Използва се за мултиселект филтри(като KendoTypesSelectComponent)
   */
  getObjectIdMultiple(field: string): Array<number> {
    const objectIds: Array<number> = [];

    if (this.filter && this.filter.filters) {
      this.filter.filters.forEach((level1: any) => {
        if (level1.filters) {
          level1.filters.forEach((level2: any) => {
            if (level2.field === field) {
              objectIds.push(level2.value);
            }
          });
        }
      });
    }
    return objectIds.length > 0 ? objectIds : null;
  }

  /**
   * Намира id на обекта от dataset филтъра(ако има такъв) и връща целия обект ако го намери в Storage-a
   * @param field полето което да търси в подадения в първия аргумент филтър
   * @param type какъв тип е обекта от който търси. За да знае в кой масив в Storage-a да го търси
   */
  getObjectFromStorage(field: string, type: string): any {
    let object: any = null;
    let objectId: number = null;

    if (this.filter && this.filter.filters) {
      this.filter.filters.forEach((singleFilter: any) => {
        if (singleFilter.field && singleFilter.field === field) {
          objectId = singleFilter.value;
        }
      });

      let datasetFiltersObjects = null;
      if (window.localStorage.getItem('datasetFiltersObjects')) {
        datasetFiltersObjects = JSON.parse(window.localStorage.getItem('datasetFiltersObjects'));
      }
      if (datasetFiltersObjects && datasetFiltersObjects[type] && datasetFiltersObjects[type].length > 0) {
        datasetFiltersObjects[type].forEach((obj: any) => {
          if (obj.id === objectId) {
            object = obj;
          }
        });
      }
    }
    log.debug('object', object);
    return object;
  }
}
