import { Component, Input, OnInit } from '@angular/core';
import { LinksService } from '@app/shared/components/links/links.service';
import { Logger } from '@app/core';

const log = new Logger('LinksButtonComponent');

@Component({
  selector: 'app-links-button',
  templateUrl: './links-button.component.html',
  styleUrls: ['./links-button.component.scss'],
})
export class LinksButtonComponent implements OnInit {
  @Input() data: any;

  constructor(private linksService: LinksService) {}

  ngOnInit() {
    if (!this.data) {
      log.warn('No data passed to component!');
    }
  }

  openLinks() {
    this.linksService.open(this.data);
  }
}
