import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@app/shared/components/contact/contact.model';

@Component({
  selector: 'app-contacts-box',
  templateUrl: './contacts-box.component.html',
  styleUrls: ['./contacts-box.component.scss'],
})
export class ContactsBoxComponent implements OnInit {
  @Input() contacts: Array<Contact>;
  @Input() title: string = null;

  constructor() {}

  ngOnInit() {}
}
