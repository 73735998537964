import { Component, OnInit } from '@angular/core';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { AuthenticationService, Logger } from '@app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { UserRole } from '@app/settings/users/roles/user-role.model';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { DataSet } from '@app/shared/kendo/data-set';
import { NotificationsService } from '@app/notifications/notifications/notifications.service';
import { Notification } from '@app/notifications/notifications/notification.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';

const log = new Logger('NotificationsListComponent');

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent extends KendoGridComponent implements OnInit {
  notificationsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  isCurrentUserAdmin = false;

  constructor(
    private notificationsService: NotificationsService,
    protected kendoGridService: KendoGridService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.auth.accessToken.profile.roles.forEach((role: UserRole) => {
      if (role.id === UserRoleType.ADMINISTRATOR) {
        this.isCurrentUserAdmin = true;
      }
    });

    this.route.queryParamMap.subscribe((params) => {
      this.state = CustomKendoFilter.getStateFromStorage('NotificationsListComponent');

      if (params.get('dataSet')) {
        this.state = DataSet.decodeFromJson(params.get('dataSet'));
      }

      this.loadData(this.state);
    });
  }

  /**
   * Load data from API
   */
  loadData(state: DataStateChangeEvent = null) {
    this.state = state;
    this.apiQuery.setDataSetFilters(state);

    /*// Filter reminders if the user isn't an administrator
    if (!this.isCurrentUserAdmin) {
      this.apiQuery.addFilter('user_id', this.auth.accessToken.profile.id);
    }*/

    this.notificationsService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      log.debug('response', response);
      this.notificationsResponse = response;
      this.data = response.getGridData();
    });

    CustomKendoFilter.pushStateInStorage('NotificationsListComponent', this.state);
  }

  /**
   * Go to notification's target
   *
   * @param url string
   */

  /*goToTarget(url: string): void {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }*/

  /**
   * Mark a notification as 'read'
   *
   * @param notification Notification
   */
  markAsRead(notification: Notification): void {
    this.notificationsService.update(notification).subscribe(() => {
      this.translate.get('NOTIFICATIONS.NOTIFICATION_MARKED_AS_READ_SUCCESSFULLY').subscribe((trans: string) => {
        this.toastr.success(trans);
        this.loadData(this.state);
      });
    });
    log.debug('Mark as read');
  }

  clearFilters() {
    this.router.navigate(['/notifications']);

    this.apiQuery = new ApiQuery();
    this.loadData();
  }
}
