<div class="container-fluid">
  <form [formGroup]="form" class="row">
    <div class="col-sm-2">
      <app-user-select [parentFormGroup]="form" (select)="userSelect()"></app-user-select>
    </div>
    <div class="col-sm-2 pt-4">
      <p-calendar
        formControlName="date"
        [showIcon]="true"
        inputStyleClass="d-none"
        styleClass="time-track-datepicker"
        baseZIndex="1"
        [monthNavigator]="true"
        [selectOtherMonths]="true"
        [locale]="en"
        [defaultDate]="defaultDate"
        (onSelect)="selectDate()"
      >
      </p-calendar>
    </div>
  </form>

  <div class="calendar-wrapper">
    <div class="spinner-wrapper d-flex justify-content-center align-items-center" *ngIf="loading">
      <div class="spinner-border text-secondary"></div>
    </div>
    <full-calendar [options]="calendarOptions" #calendar></full-calendar>
  </div>
</div>
