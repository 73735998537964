import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Payment } from '@app/finance/payments/payment.model';
import { PaymentsFormComponent } from '@app/finance/payments/payments-form/payments-form.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentsFormService {
  constructor(private modalService: NgbModal) {}

  public open(payment: null | Payment = null, hideBackdrop: boolean = false): Promise<any> {
    const config: NgbModalOptions = {
      size: 'xl' as 'lg',
      windowClass: 'modal-custom',
    };
    if (hideBackdrop) {
      config.backdropClass = 'transparent';
    }

    const modal = this.modalService.open(PaymentsFormComponent, config);
    modal.componentInstance.payment = payment;
    return modal.result;
  }
}
