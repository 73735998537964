<button
  [ngbPopover]="popExportsClientEmails"
  [autoClose]="false"
  triggers="manual"
  #ExportsClientEmailsPopOver="ngbPopover"
  (click)="ExportsClientEmailsPopOver.open()"
  popoverClass="exports-client-emails-popover"
  container="body"
  class="btn btn-link p-0"
  type="button"
>
  {{ 'EXPORT_BUTTONS.CLIENT_EMAILS.BUTTON_TEXT' | translate }}
</button>

<ng-template #popExportsClientEmails>
  <div class="popover-wrapper">
    <div class="popover-custom-header d-flex align-items-center justify-content-between">
      <div class="popover-title">
        {{ 'EXPORT_BUTTONS.CLIENT_EMAILS.POPOVER_TITLE' | translate }}
      </div>
    </div>

    <form id="exports-client-emails-form" novalidate [formGroup]="form" (ngSubmit)="download()">
      <div class="form-group">
        <label>{{ 'EXPORT_BUTTONS.CLIENT_EMAILS.POPOVER_FROM' | translate }}</label>
        <app-date-picker [FormControl]="form.get('from')"></app-date-picker>
      </div>
      <div class="form-group">
        <label>{{ 'EXPORT_BUTTONS.CLIENT_EMAILS.POPOVER_TO' | translate }}</label>
        <app-date-picker [FormControl]="form.get('to')"></app-date-picker>
      </div>
      <div class="form-group">
        <label>{{ 'EXPORT_BUTTONS.CLIENT_EMAILS.POPOVER_STATUS' | translate }}</label>
        <app-type-select
          [parentFormGroup]="form"
          [groupType]="'sale_status'"
          [passFormControlName]="'status_id'"
        ></app-type-select>
      </div>
    </form>

    <button type="submit" class="btn btn-primary btn-xs" form="exports-client-emails-form" [disabled]="form.invalid">
      {{ 'EXPORT_BUTTONS.CLIENT_EMAILS.POPOVER_DOWNLOAD' | translate }}
    </button>
    <button type="button" class="btn btn-danger btn-xs" (click)="ExportsClientEmailsPopOver.close()">
      {{ 'EXPORT_BUTTONS.CLIENT_EMAILS.POPOVER_CLOSE' | translate }}
    </button>
  </div>
</ng-template>
