import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BankAccount } from '@app/shared/components/bank-accounts/bank-account.model';

@Component({
  selector: 'app-bank-account-select',
  templateUrl: './bank-account-select.component.html',
  styleUrls: ['./bank-account-select.component.scss'],
})
export class BankAccountSelectComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() hideLabel = false;
  @Input() data: Array<BankAccount>;
  @Output() select = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onChange(bankAccount: any) {
    this.select.emit(bankAccount);

    if (this.parentFormGroup.get('bank_account')) {
      this.parentFormGroup.get('bank_account').patchValue(bankAccount || null);
    }
  }
}
