import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileFormComponent } from '@app/profile/profile-form/profile-form.component';

@Injectable({
  providedIn: 'root',
})
export class ProfileFormService {
  constructor(private modalService: NgbModal) {}

  /**
   * Open form
   */
  public open() {
    const modal = this.modalService.open(ProfileFormComponent, { size: 'xl' as 'lg' });
    return modal.result;
  }
}
