import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiQuery } from '@app/core/http/api-query';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Locale } from '@app/settings/locales/locales.model';
import { LocalesService } from '@app/settings/locales/locales.service';
import { LocalesFormService } from '@app/settings/locales/locales-form.service';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { Logger } from '@app/core';

const log = new Logger('LocalesSelectComponent');

@Component({
  selector: 'app-locales-select',
  templateUrl: './locales-select.component.html',
  styleUrls: ['./locales-select.component.scss'],
})
export class LocalesSelectComponent implements OnInit {
  data: Array<Locale>;
  apiQuery: ApiQuery = new ApiQuery();
  loader: boolean;
  @Input() FormControl: FormControl;
  form: FormGroup;
  input$ = new Subject<string>();
  @Input() required = false;

  constructor(
    private localeService: LocalesService,
    private formBuilder: FormBuilder,
    private localeFormService: LocalesFormService
  ) {}

  ngOnInit() {
    this.loadLocales();
    /*
     *  създава fake formGroup, за да може да пази временно стойностите някъде
     *  после чрез евентите add, remove и clear ги прехвърля към подадения на компонента контрол
     */
    if (this.required) {
      this.form = this.formBuilder.group({
        locales_fake: [[], [Validators.required]],
      });
    } else {
      this.form = this.formBuilder.group({
        locales_fake: [[]],
      });
    }

    /*
     * това помага за patch-a на parent формата
     * ако се пали в някой друг случай трябва да се измисли нещо по-добро
     */
    this.FormControl.valueChanges.subscribe((values: []) => {
      setTimeout(() => {
        const preSelectedValues: Array<any> = [];
        values.forEach((locale: any) => {
          preSelectedValues.push(locale.id);
        });
        this.form.get('locales_fake').setValue(preSelectedValues);
      });
    });

    // this.localesSearch();
  }

  loadLocaleById(id: number) {
    return this.localeService.show(id);
  }

  loadLocalesByIds(locales: any[]) {
    this.loader = true;
    for (let i = 0; i < locales.length; i++) {
      this.localeService.show(locales[i]['id']).subscribe((locale: Locale) => {
        this.data = [locale]; // не знам защо работи
      });
    }
    this.loader = false;
    // return result;
  }

  loadLocales(keyword?: string) {
    this.loader = true;
    this.localeService
      .index(new ApiQuery().addFilters({ active: true }).setLimit(9999))
      .subscribe((response: PaginatedResponse) => {
        log.debug('loadLocales response.data', response.data);
        this.data = response.data;
        this.loader = false;
      });
  }

  // localesSearch() {
  //   this.input$
  //     .pipe(
  //       tap(() => (this.loader = true)),
  //       debounceTime(1000),
  //       distinctUntilChanged(),
  //       switchMap(term =>
  //         this.localeService.index(this.apiQuery.addFilter('name', term)).pipe(
  //           catchError(() => of([])), // empty list on error
  //           tap(() => (this.loader = false))
  //         )
  //       )
  //     )
  //     .subscribe((locales: PaginatedResponse) => {
  //       this.data = of(locales.data);
  //       this.loader = false;
  //     });
  // }

  add(id: number) {
    this.FormControl.value.push({ id: id });
  }

  remove(id: any) {
    let index: number;
    this.FormControl.value.forEach((value: any, ind: number) => {
      if (value.id === id) {
        index = ind;
      }
    });
    this.FormControl.value.splice(index, 1);
  }

  clear() {
    this.FormControl.setValue([]);
  }

  addNew(searchTerm: string) {
    this.localeFormService.open(new Locale().deserialize({ name: searchTerm })).then(
      (response) => {
        const newValues = [...this.form.get('locales_fake').value, response.id];

        const newValuesObject = newValues.map((id: number) => {
          return new Locale().deserialize({ id: id });
        });
        this.loadLocalesByIds(newValuesObject);
        this.form.get('locales_fake').setValue(newValues);
        this.add(response.id);
      },
      (err) => {
        log.error(err);
      }
    );
  }
}
