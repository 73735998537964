import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BonusesRoutingModule } from '@app/bonuses/bonuses-routing.module';
import { SharedModule } from '@app/shared';
import { SaleBonusesListComponent } from './sale-bonuses/sale-bonuses-list/sale-bonuses-list.component';
import { SaleBonusesFormComponent } from './sale-bonuses/sale-bonuses-form/sale-bonuses-form.component';
import { UserBonusesListComponent } from './user-bonuses/user-bonuses-list/user-bonuses-list.component';
import { InvoicesWithoutBonusesListComponent } from './invoices-without-bonuses-list/invoices-without-bonuses-list.component';

@NgModule({
  declarations: [SaleBonusesListComponent, SaleBonusesFormComponent, UserBonusesListComponent, InvoicesWithoutBonusesListComponent],
  imports: [CommonModule, BonusesRoutingModule, SharedModule],
})
export class BonusesModule {}
