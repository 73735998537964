import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from '@app/core';
import { Observable, of, Subject } from 'rxjs';
import { ApiQuery } from '@app/core/http/api-query';
import { FormGroup } from '@angular/forms';
import { Events } from '@app/events/events/events.model';
import { EventsService } from '@app/events/events/events.service';
import { EventsFormService } from '@app/events/events/events-form.service';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { EventType } from '@app/events/events/event-type.enum';

const log = new Logger('EventSelectComponent');

@Component({
  selector: 'app-event-select',
  templateUrl: './event-select.component.html',
  styleUrls: ['./event-select.component.scss'],
})
export class EventSelectComponent implements OnInit {
  data: Observable<Events[]>;
  apiQuery: ApiQuery = new ApiQuery();
  loader: boolean;
  @Input() parentFormGroup: FormGroup;
  input$ = new Subject<string>();
  @Input() model: any = 'undefined';
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() hideLabel = false;
  @Input() onlyActive = true;
  @Input() onlyIncoming = true;
  @Output() select = new EventEmitter();
  @Input() includes: string = null;
  env = environment;
  lastResponse: any = null;
  page = 1;

  constructor(private eventService: EventsService, private eventFormService: EventsFormService) {}

  ngOnInit() {
    if (this.model === 'undefined') {
      const preSelectedEvent = this.parentFormGroup.get('event');
      const preSelectedEventId = this.parentFormGroup.get('event_id');
      /**
       * Попълва избрания event на едит.
       * Ако има FormControl 'event' в parentFormGroup.
       * Ако няма ще се опита да го get-не по ID
       */
      if (preSelectedEvent) {
        /*
         * Следи за промени в event FormControl-a и когато намери валиден Event спира да следи
         */
        const preselectedEventSubscriber = preSelectedEvent.valueChanges.subscribe((event: any) => {
          if (event && event.name) {
            this.data = of([event]);
            preselectedEventSubscriber.unsubscribe();
          }
        });
      } else if (preSelectedEventId) {
        /*
         * Ако не е намерен event FormControl-a търси event_id FormControl и се опитва да get-не event-a по ID
         */
        const preselectedEventIdSubscriber = preSelectedEventId.valueChanges.subscribe((eventId: any) => {
          if (eventId) {
            this.loadById(eventId);
            preselectedEventIdSubscriber.unsubscribe();
          }
        });
      }
    } else if (this.model !== 'undefined' && this.model !== null && this.model !== '') {
      this.data = of([this.model]);
    }
    this.eventSearch();
  }

  loadById(id: number) {
    this.loader = true;
    const query = new ApiQuery();

    if (this.includes) {
      query.addIncludes(this.includes);
    }
    this.eventService.show(id, query).subscribe((event: Events) => {
      this.data = of([event]);
      this.loader = false;
      this.select.emit(event);
    });
  }

  eventSearch() {
    if (this.includes) {
      this.apiQuery.addIncludes(this.includes);
    }

    if (this.onlyActive) {
      this.apiQuery.addFilter('active', true);
    }

    if (this.onlyIncoming) {
      // #590: Add event
      const filter: DataStateChangeEvent = {
        filter: {
          filters: [
            {
              filters: [
                {
                  field: 'date_from',
                  operator: 'gte',
                  value: moment(),
                },
                {
                  field: 'type_id',
                  operator: 'eq',
                  value: EventType.EVENT,
                },
              ],
              logic: 'and',
            },
            {
              filters: [
                {
                  field: 'date_to',
                  operator: 'gte',
                  value: moment(),
                },
                {
                  field: 'type_id',
                  operator: 'eq',
                  value: EventType.BUSINESS_TRIP,
                },
              ],
              logic: 'and',
            },
          ],
          logic: 'or',
        },
        group: undefined,
        skip: 0,
        sort: undefined,
        take: 0,
      };

      // Стария код за всеки случай
      // const filter: DataStateChangeEvent = {
      //   filter: {
      //     filters: [{ field: 'date_from', operator: 'gte', value: moment() }],
      //     logic: 'and'
      //   },
      //   group: undefined,
      //   skip: 0,
      //   sort: undefined,
      //   take: 0
      // };

      this.apiQuery.setDataSetFilters(filter);
    }

    this.input$
      .pipe(
        tap(() => (this.loader = true)),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((term: any) => {
          // За да ме прави излишен request след като се избере нещо
          if (!term) {
            return of(this.lastResponse);
          }

          return this.eventService
            .index(
              this.apiQuery
                .addFilters({
                  name: term,
                })
                .addIncludes('venue.city.country')
                .setPage(1)
            )
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.loader = false))
            );
        })
      )
      .subscribe((event: PaginatedResponse) => {
        this.data = of(event.data);
        this.lastResponse = event;
        this.loader = false;
        this.page = 1;
      });
  }

  onChange(event: any) {
    if (this.parentFormGroup.get('event')) {
      this.parentFormGroup.get('event').patchValue(event || null);
    }
    this.select.emit(event);
  }

  // @todo: това никога не е пробвано дали работи
  // ако се използва да се провери
  add(searchTerm: string) {
    this.eventFormService.open(new Events().deserialize({ name: searchTerm })).then(
      (response) => {
        this.loadById(response.id);
        this.parentFormGroup.get('event_id').setValue(response.id);
      },
      (err) => {
        log.error(err);
      }
    );
  }

  changeModel(event: any) {
    this.modelChange.emit(event);
  }

  edit() {
    if (this.parentFormGroup.get('event_id').value) {
      this.eventFormService.open(new Events().deserialize({ id: this.parentFormGroup.get('event_id').value })).then(
        (response) => {
          this.loadById(response.id);
          this.parentFormGroup.get('event_id').setValue(response.id);
          if (this.parentFormGroup.get('event')) {
            this.parentFormGroup.get('event').patchValue(response);
          }
        },
        (err) => {
          log.error(err);
        }
      );
    }
  }

  loadMore() {
    if (this.page >= this.lastResponse.meta.last_page) {
      return false;
    }
    this.loader = true;
    this.page = this.page + 1;
    this.eventService.index(this.apiQuery.setPage(this.page)).subscribe((event: PaginatedResponse) => {
      this.data.subscribe(
        (res) => {
          this.data = of([...res, ...event.data]);
          this.loader = false;
        },
        () => {
          this.loader = false;
        }
      );
    });
  }
}
