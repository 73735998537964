import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { FormGroup } from '@angular/forms';
import { ClientabeType } from '@app/shared/components/clientable-select/clientabe-type.enum';
import { Client } from '@app/clients/clients/client.model';
import { Hotel } from '@app/hotels/hotels/hotel.model';

const log = new Logger('ClientableSelectComponent');

@Component({
  selector: 'app-clientable-select',
  templateUrl: './clientable-select.component.html',
  styleUrls: ['./clientable-select.component.scss'],
})
export class ClientableSelectComponent implements OnInit {
  ClientabeType = ClientabeType;
  @Input() parentFormGroup: FormGroup;
  @Input() hideLabel = false;
  data: Array<any> = [];

  constructor() {}

  ngOnInit() {
    log.debug('init');
    for (const laravelModelsKey in this.ClientabeType) {
      if (laravelModelsKey) {
        this.data.push({ key: laravelModelsKey, value: this.ClientabeType[laravelModelsKey] });
      }
    }
  }

  selectClientableType() {
    this.parentFormGroup.get('clientable_id').patchValue(null);
    this.parentFormGroup.get('clientable').patchValue(null);
  }

  /**
   * Пачва компонента
   * @param clientableType clientableType
   * @param clientableId clientableId
   * @param clientable clientable(optional)
   */
  setValues(clientableType: ClientabeType, clientableId: number, clientable: Client | Hotel = null) {
    setTimeout(() => {
      this.parentFormGroup.patchValue({
        clientable_type: clientableType,
        clientable_id: clientableId,
        clientable: clientable,
      });
    });
  }
}
