import {LaravelModelsEnum, Model} from '@app/shared/models/model.model';
import {Type} from '@app/settings/types/types.model';
import {BankAccount} from '@app/shared/components/bank-accounts/bank-account.model';
import {Currency} from '@app/settings/currencies/currency.model';
import {InvoiceItem} from '@app/finance/invoices/invoice-item.model';
import {Company} from '@app/settings/companies/company.model';
import {ClientabeType} from '@app/shared/components/clientable-select/clientabe-type.enum';
import {Payment} from '@app/finance/payments/payment.model';
import {SaleBonus} from '@app/bonuses/sale-bonuses/sale-bonus.model';

export class Invoice extends Model {
  id: number;
  company_id: number;
  company: Company;
  clientable_type: ClientabeType;
  clientable_id: number;
  clientable?: any;
  type_id: number;
  type?: Type;
  payment_method_id: number;
  payment_method?: Type;
  payments?: Array<Payment>;
  bank_account_id: number;
  bank_account?: BankAccount;
  currency_id: number;
  currency?: Currency;
  date: string; // date
  due_date: string; // date
  number: number;
  formatted_number?: string;
  reference: string;
  public_note: string;
  private_note: string;
  tax_id: number;
  tax?: Type;
  items: Array<InvoiceItem>;
  invoiceable_id: number;
  invoiceable?: any;
  invoiceable_type: LaravelModelsEnum;
  auto_number?: boolean;
  total_amount?: number;
  balance?: number;
  linked_id: number;
  meta_front?: any;
  hotels?: Array<string>;

  deposit_percent: number;
  vat_number: number;
  client_address: number;
  client_name: number;

  paid: boolean;

  balance_due_date: string;

  registration_number: number;
  responsible_person: string;

  bonuses?: Array<SaleBonus>;

  get cleanClientableType() {
    const type = this.clientable_type.split('\\');
    return type[type.length - 1];
  }

  get cleanInvoiceableType() {
    if (this.invoiceable_type) {
      const type = this.invoiceable_type.split('\\');
      return type[type.length - 1];
    } else {
      return null;
    }
  }

  get formattedName(): String {
    let str = '';

    if (this.type) {
      str += this.type.value + '/';
    }

    if (this.number) {
      str += this.number;
    }

    return str;
  }

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.company) {
      this.company = new Company().deserialize(input.company);
    }

    if (input.type) {
      this.type = new Type().deserialize(input.type);
    }

    if (input.payment_method) {
      this.payment_method = new Type().deserialize(input.payment_method);
    }

    if (input.bank_account) {
      this.bank_account = new BankAccount().deserialize(input.bank_account);
    }

    if (input.currency) {
      this.currency = new Currency().deserialize(input.currency);
    }

    if (input.items) {
      this.items = input.items.map((invoiceItem: InvoiceItem) => new InvoiceItem().deserialize(invoiceItem));
    }

    if (input.payments) {
      this.payments = input.payments.map((payment: Payment) => new Payment().deserialize(payment));
    }

    if (input.tax) {
      this.tax = new Type().deserialize(input.tax);
    }

    return this;
  }
}
