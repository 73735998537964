import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-button',
  templateUrl: './side-button.component.html',
  styleUrls: ['./side-button.component.scss'],
})
export class SideButtonComponent implements OnInit {
  @Input() icon = 'fas fa-angle-left';
  @Input() tooltip: any = false;
  @Input() customClasses: string;

  constructor() {}

  ngOnInit() {}
}
