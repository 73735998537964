import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Templates } from '@app/settings/types/templates.enum';
import { EmailTemplate } from '@app/settings/templates/email-template.model';
import { Company } from '@app/settings/companies/company.model';
import { Locale } from '@app/settings/locales/locales.model';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { AuthenticationService, Logger } from '@app/core';
import { TemplatesService } from '@app/settings/templates/templates.service';
import { SalesService } from '@app/sales/sales/sales.service';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { WebOffersService } from '@app/sales/web-offers/web-offers.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiQuery } from '@app/core/http/api-query';
import { EmailTemplateCompiler } from '@app/settings/templates/email-template-compiler.model';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { Sale } from '@app/sales/sales/sale.model';
import { SalesOffersService } from '@app/sales/sales/sales-offers.service';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';

const log = new Logger('SalesOffersOnlineBookingTermsComponent');

@Component({
  selector: 'app-sales-offers-online-booking-terms',
  templateUrl: './sales-offers-online-booking-terms.component.html',
  styleUrls: ['./sales-offers-online-booking-terms.component.scss'],
})
export class SalesOffersOnlineBookingTermsComponent implements OnInit {
  @Input() sale: Sale;
  @Input() offerId: number;
  @Input() offer: SaleOffer;
  form: FormGroup;
  filterForm: FormGroup;

  templatesTypes = Templates;
  templates: Array<EmailTemplate> = [];
  filteredTemplates: Array<EmailTemplate> = [];
  companies: Array<Company> = [];
  locales: Array<Locale> = [];
  LaravelModelsEnum = LaravelModelsEnum;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private templatesService: TemplatesService,
    private salesService: SalesService,
    private invoicesService: InvoicesService,
    private webOffersService: WebOffersService,
    private sanitizer: DomSanitizer,
    private salesOffersService: SalesOffersService
  ) {}

  ngOnInit() {
    this.createForm();
    this.createFilterForm();
    this.loadSaleOffer();
    this.loadTemplates();
  }

  test(form: any) {
    log.debug(form);
    log.debug(this.form);
  }

  safeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  loadSaleOffer() {
    this.salesOffersService.show(this.sale.id, this.offerId).subscribe((saleOffer: any) => {
      this.offer = saleOffer;
      this.patchForm();
    });
  }

  /**
   * Взима всички темплейти от подадения тип
   */
  loadTemplates() {
    const query = new ApiQuery()
      .setLimit(9999)
      .addFilter('type_id', this.templatesTypes.ONLINEBOOKINGTERMS)
      .addIncludes('company', 'locale');

    this.templatesService.index(query).subscribe((response: PaginatedResponse) => {
      this.templates = [...this.templates, ...response.data];

      this.templates.forEach((template: EmailTemplate) => {
        log.debug('forEach template');
        log.debug('this.companies', this.companies);
        // предотвратява дублиране
        if (this.companies.map((x) => x.id).indexOf(template.company_id) === -1 && template.company) {
          log.debug('push company', template);
          this.companies = [...this.companies, template.company];
        }
        // предотвратява дублиране
        if (this.locales.map((x) => x.id).indexOf(template.locale_id) === -1) {
          this.locales = [...this.locales, template.locale];
        }
      });
      this.filterTemplates();
    });
  }

  /**
   * Филтрира темплейтите на база избраните филтри от filterForm
   */
  filterTemplates() {
    const filterObj: any = {
      company_id: null,
      locale_id: null,
    };

    if (this.filterForm.get('company_id').value) {
      filterObj.company_id = this.filterForm.get('company_id').value;
    }

    if (this.filterForm.get('locale_id').value) {
      filterObj.locale_id = this.filterForm.get('locale_id').value;
    }
    this.filteredTemplates = this.templates.filter((template: EmailTemplate) => {
      if (filterObj.company_id && filterObj.locale_id) {
        return template.company_id === filterObj.company_id && template.locale_id === filterObj.locale_id;
      }

      if (filterObj.company_id && !filterObj.locale_id) {
        return template.company_id === filterObj.company_id;
      }

      if (!filterObj.company_id && filterObj.locale_id) {
        return template.locale_id === filterObj.locale_id;
      }
      return template;
    });
    this.filterForm.get('template_id').reset();
  }

  compile() {
    const formattedFilterDataObj: any = {
      template_id: this.filterForm.get('template_id').value,
      object_id: this.sale.id,
      filters: {
        sale_offer_ids: [this.offer.id],
      },
    };

    const formattedFilterData = new EmailTemplateCompiler().deserialize(formattedFilterDataObj);

    this.templatesService.compile(formattedFilterData).subscribe((res) => {
      this.form.get('sale_online_booking_terms').patchValue(res.compiled.html_template);
    });
  }

  save() {
    const offer = new SaleOffer().deserialize({
      sale_online_booking_terms: this.form.get('sale_online_booking_terms').value,
      id: this.offer.id,
      name: this.offer.name,
      show_to_web: this.offer.show_to_web,
      online_booking_payment_percent: this.form.get('online_booking_payment_percent').value,
    });
    log.debug('offer offer offer ', offer);

    this.salesOffersService.update(this.sale.id, offer).subscribe((res: any) => {
      return this.modal.close();
    });
  }

  private patchForm() {
    log.debug('patch this.offer', this.offer);
    this.form.patchValue(this.offer);
  }

  /**
   * Създава основната форма (финалната с рендерирания темплейт)
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      sale_online_booking_terms: ['', [Validators.required]],
      online_booking_payment_percent: [null, [Validators.min(1), Validators.max(100)]],
    });
  }

  /**
   * Създава допълнителната форма( тази с филтрите)
   */
  private createFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      company_id: [null],
      locale_id: [null, [Validators.required]],
      template_id: [null, [Validators.required]],
    });
  }
}
