<div [formGroup]="form" class="kendo-select-filter">
  <ng-select
    (change)="onSelect($event)"
    [items]="fullData | async"
    bindLabel="formattedName"
    bindValue="id"
    [formControlName]="'value'"
    appendTo="body"
    [multiple]="true"
    [closeOnSelect]="false"
    [loading]="loader"
    [typeahead]="input$"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="event-select-option-wrapper">
        <div class="event-name">{{ item.name }}</div>
        <div class="event-dates">
          {{ item.date_from | amDateFormat: env.momentDateFormat }}
          - {{ item.date_to | amDateFormat: env.momentDateFormat }}
        </div>
        <div class="event-venue-wrapper">
          <div class="event-venue-name" *ngIf="item.venue">{{ item.venue.name }} @</div>
          <div class="event-venue-city" *ngIf="item.venue?.city">{{ item.venue.city.name }},</div>
          <div class="event-venue-country" *ngIf="item.venue?.city?.country">{{ item.venue.city.country.name }}</div>
        </div>
      </div>
    </ng-template>

    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
      <div class="ng-option disabled">
        <div>{{ 'NO_DATA_FOUND' | translate }} "{{ searchTerm }}"</div>
      </div>
    </ng-template>
  </ng-select>
</div>
